<header id="home-header" [ngClass]="url[2] == 'search' ? 'home-header': ''">

    <!-- <div class="top-header desktop-header">
        <app-topbar></app-topbar>
    </div> -->

    <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">

        <a [routerLink]="['/']" class="nav-link navbar-brand px-0">
            <img class="nav-logo-img" src="assets/images/sahlalogo.png" alt="Talabat Logo">

        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
                <li class="nav-list-li inner-li nav-item app-topbar-header">
                    <div class="top-header">
                        <app-topbar></app-topbar>
                    </div>
                </li>
                <li class="nav-list-li inner-li nav-item ">
                    <a class="nav-link" (click)="gotoHome()">
                        {{'home'|translate}}
                    </a>
                </li>

                <li class="nav-list-li inner-li nav-item ">
                    <a (click)="gotoCategories()" class="nav-link">
                        {{'categories'|translate}}
                    </a>
                </li>

                <li class="nav-list-li inner-li nav-item">
                    <a (click)="gotoAbout()" class="nav-link">
                        {{'about-us'|translate}}
                    </a>
                </li>

                <li class="nav-list-li inner-li nav-item">
                    <a (click)="gotoContact()" class="nav-link">
                        {{'contact'|translate}}
                    </a>
                </li>

                <li class="nav-list-li inner-li nav-item">
                    <app-header-widgets></app-header-widgets>
                </li>

            </ul>
        </div>
    </nav> -->


    <nav class="navbar navbar-expand-lg navbar-light bg-light">


        <a (click)="gotoHome()" class="nav-link navbar-brand px-0 mobile-logo">
            <img class="nav-logo-img" src="assets/images/sahlalogo.png" alt="Talabat Logo">

        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <div class="container px-0">

                <a (click)="gotoHome()" class="nav-link navbar-brand px-0 desktop-logo">
                    <img class="nav-logo-img" src="assets/images/sahlalogo.png" alt="Talabat Logo">

                </a>

                <ul class="navbar-nav menu-pages">
                    <li class="uppercase ">
                        <a class="nav-link active  d-flex" (click)="gotoCategories()">
                            {{'Delivery' | translate}}
                        </a>
                    </li>


                    <li class="notifications" *ngIf="isLogged || fromLocal=='true'">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text">
                                    <i class="far fa-bell"></i>
                                    <span>
                                        {{'Notifications' | translate}}
                                    </span>
                                </span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                <ul *ngIf="notifications?.data.length > 0">
                                    <li *ngFor="let item of notifs" style="cursor: pointer;" class="dropdown-item">
                                        <div class="notifitication_section" id="notifi">

                                            <div class="close-circle">
                                                <a (click)="deleteNotification(item.id)"><i class="fa fa-times"
                                                        aria-hidden="true"></i></a>
                                            </div>

                                            <div class="notifications-content">
                                                <div class="img">
                                                    <img class="nav-logo-img" src="assets/images/sahlalogo.png"
                                                        alt="Talabat Logo">
                                                </div>

                                                <div>

                                                    <p class="notification_msg theme-colored">{{item?.data.title}}</p>

                                                    <p class="notification_msg ">{{item?.data.body}}</p>


                                                    <p class="notifications_date theme-colored">
                                                        <!-- {{'withdate'|translate}} -->
                                                        <span class="span_colour">{{item?.created_at | date}}</span>
                                                    </p>

                                                </div>

                                            </div>


                                        </div>
                                    </li>
                                </ul>

                                <h4 *ngIf="notifications?.data.length == 0">
                                    {{'no notification'|translate}}
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="my-orders " *ngIf="isLogged || fromLocal=='true'">
                        <a class="nav-link" (click)="gotoOrders()">
                            <!-- {{'categories'|translate}} -->
                            <i class="far fa-list-alt"></i>
                            {{'My Orders' | translate}}
                        </a>
                    </li>

                    <li class="topbar">
                        <div class="top-header">
                            <app-topbar></app-topbar>
                        </div>
                    </li>

                    <li class="nav-list-li inner-li nav-item">
                        <app-header-widgets></app-header-widgets>
                    </li>

                    <li class="nav-list-li inner-li nav-item px-0">
                        <div class="mob-options search-group">
                            <div class="row align-items-center">
                                <div class="inner-col inline-item">

                                    <div class="icon">
                                        <img class="d-block m-auto" src="assets/images/fast-delivery-icon-png.png" />
                                    </div>
                                </div>
                                <div class="inner-col inline-item">

                                    <h3 class="col-title mb-0">
                                        {{'Header Deliver' | translate}}
                                        <span class="icon small-icon">
                                            <i class="fas fa-sign-in-alt"></i>
                                        </span>
                                    </h3>
                                </div>
                                <div class="inner-col">

                                    <div class="select-area-col">
                                        <h4 class="selected-area mb-0">
                                            Sidi Gaber, Sidi Gaber
                                        </h4>

                                    </div>
                                </div>
                                <div class="inner-col">

                                    <!-- <button type="button" class="btn select-area-btn ">
                                    {{'Change' | translate}}

                                </button> -->

                                    <button class="btn select-area-btn " mat-button
                                        (click)="openDialog()">Change</button>

                                </div>
                            </div>
                        </div>
                    </li>


                </ul>



            </div>
        </div>

        <div class="container search-container">
            <div class="search-group">


                <div class="form-row align-items-center custom-search-col">
                    <div class="col-md-6 ">
                        <div class="search-icon">
                            <i class="fas fa-search"></i>
                        </div>
                        <input type="text" on-focus="menuShow()" (ngModelChange)="search($event)"
                            class="form-control mb-2" [(ngModel)]="query" id="inlineFormInput"
                            placeholder="{{'search' | translate}}">

                    </div>
                    <div class="col-md-6 desktop-option">
                        <div class="row align-items-center col-row">
                            <div class="inner-col">

                                <div class="icon">
                                    <img class="d-block m-auto" src="assets/images/fast-delivery-icon-png.png" />
                                </div>
                            </div>
                            <div class="inner-col">

                                <h3 class="col-title mb-0 d-flex">

                                    {{'Delivery' | translate}}
                                    <span class="icon small-icon">
                                        <i class="fas fa-sign-in-alt"></i>
                                    </span>
                                </h3>
                            </div>
                            <div class="inner-col">
                                <!--  -->
                                <div class="select-area-col">
                                    <h4 class="selected-area mb-0" *ngIf="lang == 'ar'">
                                        {{areaName?.ar}}
                                    </h4>
                                    <h4 class="selected-area mb-0" *ngIf="lang == 'en'">
                                        {{areaName?.en}}
                                    </h4>

                                </div>
                            </div>
                            <div class="inner-col">

                                <!-- <button type="button" class="btn select-area-btn " (click)="gotoSearch()">
                                {{'Change' | translate}}
                            </button> -->
                                <button class="btn select-area-btn " mat-button (click)="openDialog()">Change</button>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="search-result col-md-6">
                        <ul *ngIf="merchants.length > 0 && showMenu" id="search_id2" class="list-group">
                            <a *ngFor="let merchant of merchants" appNoRightClick class="list-group-item text-left"
                                (click)="gotoMerchant(merchant?.id)">
                                <img [src]="merchant?.brand_cover_image[0]?.default? merchant?.brand_cover_image[0]?.default :'assets/images/sahlalogo.png'" width="40px" height="40px" />
                                <span class="search-text">{{merchant?.merchant_brand_title}}</span>
                            </a>
                        </ul>

                        <h4 *ngIf="merchants.length == 0 && !showMenu">
                            No Merchant Found
                        </h4>
                    </div> -->
                </div>
                <div class="search-result col-md-6">
                    <ul *ngIf="merchants.length > 0 && showMenu">
                        <li *ngFor="let merchant of merchants">
                            <div (mousedown)="gotoMerchant($event, merchant?.id)" class="searchResult">
                                <img [src]="merchant?.brand_cover_image[0]?.default? merchant?.brand_cover_image[0]?.default :'assets/images/sahlalogo.png'" width="40px" height="40px" />
                                <p class="search-text">{{merchant?.merchant_brand_title}}</p>
                            </div>
                        </li>
                    </ul>
                    <h4 *ngIf="merchants.length == 0 && !showMenu">
                        No Merchant Found
                    </h4>
                </div>
            </div>
        </div>

    </nav>

</header>