<div class="breadcrumb full-width">
    <div class="background">
        <div class="container">
            <div class="contact">
                <h1 class="contactfont" id="title-page">{{'createaccount'|translate}}</h1>
            </div>
        </div>
    </div>
</div>
<div class="main-content full-width">
    <div class="background-content"></div>
    <div class="background">
        <div class="shadow"></div>
        <div class="pattern">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div id="content" class="col-md-9 center-column body-other content-with-background">
                                <p>{{'Have Account'| translate}} <a [routerLink]="['/login6']" id="login-btn">{{'login'|translate}}</a>.
                                </p>
                                <form  id="create_customer" class="theme-form" [formGroup]="registerForm">

                                    <fieldset id="account" class="form-horizontal">
                                        <legend> {{'createaccount'|translate}}</legend>
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label" for="input-firstname">{{'firstname'|translate}}</label>
                                            <div class="col-sm-10">

                                              <input name="customer[first_name]" formControlName="fname" type="text" class="form-control {{registerForm.controls['fname'].touched && registerForm.controls['fname'].errors ? 'error-control' : ''}}"
                                                id="input-firstname" placeholder="{{'firstname' | translate}}">
                                                <span *ngIf="registerForm.controls['fname'].touched && registerForm.controls['fname']?.errors?.required" class="error-msg">
                                                    {{'fnameReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['fname'].touched && registerForm.controls['fname']?.errors?.pattern" class="error-msg">
                                                    {{'fnameMustBeCharc'|translate}}.
                                                </span>
                                            </div>

                                            
                                        </div>
                                        
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label" for="input-lastname">{{'lastname'|translate}}</label>
                                            <div class="col-sm-10">
            
                                            <input name="customer[last_name]"  formControlName="lname" type="text" class="form-control {{registerForm.controls['lname'].touched && registerForm.controls['lname'].errors ? 'error-control' : ''}}"
                                                    id="input-lastname" placeholder="{{'lastname' | translate}}">
                                                <span *ngIf="registerForm.controls['lname'].touched && registerForm.controls['lname']?.errors?.required" class="error-msg">
                                                    {{'lnameReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['lname'].touched && registerForm.controls['lname']?.errors?.pattern" class="error-msg">
                                                    {{'lnameMustBeCharc'|translate}}.
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group required">
                                            <label class="col-sm-2 control-label" for="input-email">{{'email'|translate}}</label>
                                            <div class="col-sm-10">

                                                    <input  required="required"  name="customer[email]" formControlName="email" type="email" class="form-control {{registerForm.controls['email'].touched && registerForm.controls['email'].errors ? 'error-control' : ''}}"
                                                    id="input-email" placeholder="{{'email' | translate}}">
                                                <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email']?.errors?.required" class="error-msg">
                                                    {{'emailReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email']?.errors?.email" class="error-msg">
                                                    {{'emailmustbe'|translate}}.
                                                </span>

                                            </div>
                                        </div>

                                        <div class="form-group required">
                                            <label class="col-sm-2 control-label" for="input-password">{{'phoneno'|translate}}</label>
                                            <div class="col-sm-10">
                                            
                                                    <input required="required"   formControlName="mobile" type="tel" class="form-control {{registerForm.controls['mobile'].touched && registerForm.controls['mobile'].errors ? 'error-control' : ''}}"
                                                    id="mobile" placeholder="{{'phoneno' | translate}}" style="font-family: sans-serif;">
                                                <span *ngIf="registerForm.controls['mobile'].touched && registerForm.controls['mobile']?.errors?.required" class="error-msg">
                                                    {{'phoneReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['mobile'].touched && registerForm.controls['mobile']?.errors?.pattern" class="error-msg">
                                                    {{'phonePatMes'|translate}}.
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group required">
                                            <label class="col-sm-2 control-label" for="input-password">{{'Password'|translate}}</label>
                                            <div class="col-sm-10">
                                                
                                                    <input  name="customer[password]" formControlName="password" required="required" type="password" class="form-control {{registerForm.controls['password'].touched && registerForm.controls['password'].errors ? 'error-control' : ''}}"
                                                    id="input-password" placeholder="{{'Password'|translate}}">
                                                <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password']?.errors?.required" class="error-msg">
                                                    {{'passwordReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password']?.errors?.minlength" class="error-msg">
                                                    {{'passwordMinLengMes'|translate}}.
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group required">
                                            <label class="col-sm-2 control-label" for="input-password">{{'repeatPassword'|translate}}</label>
                                            <div class="col-sm-10">
         
                                                    <input name="customer[password]" required="required" formControlName="retypePassword" type="password" class="form-control {{registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword'].errors ? 'error-control' : ''}}"
                                                    id="input-password" placeholder="{{'repeatPassword' | translate}}">
                                                <span *ngIf="registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword']?.errors?.required"
                                                    class="error-msg">
                                                    {{'repeatPasswordReqMes'|translate}}
                                                </span>
                                                <span *ngIf="registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword']?.errors?.minlength"
                                                    class="error-msg">
                                                    {{'repeatPasswordMinLengMes'|translate}}.
                                                </span>
                                            </div>
                                        </div>

                                      

                                    </fieldset>
                                    <div class="buttons">
                                        <div class="pull-right">
                                        
                                            <button [disabled]="!registerForm.valid" (click)="register()" class="btn btn-primary">{{'createaccount'|translate}}</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>