import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/observable/interval';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;


@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceComponent implements OnInit {

  // Using Output EventEmitter
  @Output() priceFilters = new EventEmitter();

  // define min, max and range
  public min: number = 1;
  public max: number = 1000000;
  public range = [1, 1000000];
  priceRange;
  blocksNum:number = 6

  constructor(private translate: TranslateService) { }
  lang;
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.priceRange = this.getRange(10000,this.blocksNum)
    console.log("arrayRange => ",this.getRange(10000,this.blocksNum))
  }

  // rangeChanged
  priceChanged(event: any) {
    console.log("price",event)
    this.priceFilters.emit(event);
  }

  getRange(max,blocksNum)
  {
    let valueAdded = max/blocksNum;
    let priceRange = [];
    let prevPrice = 1
    for (let i=0; i<blocksNum; i++){
      if(i == blocksNum-1) {
      priceRange.push([`${Math.round(prevPrice)}$`,`More`]);
      break;
      }
      priceRange.push([`${Math.round(prevPrice)}$`,`${Math.round(prevPrice+valueAdded)}$`]);
      prevPrice += valueAdded;

    }
    return priceRange;
  }
  selectPrice(range,index)
  {
    console.log("test=> ",range)
    if(index == this.blocksNum-1) {
      range = [range[0],this.max]
    }
    this.priceFilters.emit(range);
    console.log("range-> ",range)
  }
  test(){
    console.log("tesrrr")
  }
}
