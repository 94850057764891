import { AboutUsComponent } from "./information/pages/about-us/about-us.component";
import { AllAreasComponent } from "./home/pages/all-areas/all-areas.component";
import { AllProductsComponent } from "./home/pages/all-products/all-products.component";
import { AllReportsComponent } from "./home/pages/all-reports/all-reports.component";
import { AnalysisComponent } from "./home/pages/analysis/analysis.component";
import { CatComponent } from "./home/pages/cat/cat.component";
import { ChangePassComponent } from "./authentication/pages/change-pass/change-pass.component";
import { ConfirmTransactionComponent } from "./home/pages/confirm-transaction/confirm-transaction.component";
import { ContactComponent } from "./home/pages/contact/contact.component";
import { DashboardComponent } from "./home/pages/dashboard/dashboard.component";
import { EditOpeningTimesComponent } from "./home/pages/edit-opening-times/edit-opening-times.component";
import { EditProdComponent } from "./home/pages/edit-prod/edit-prod.component";
import { ErrorComponent } from "./home/pages/error/error.component";
import { ErrorPageComponent } from "./home/pages/error-page/error-page.component";
import { FaqComponent } from "./information/pages/faq/faq.component";
import { FeedbackComponent } from "./information/pages/feedback/feedback.component";
import { FinancesComponent } from "./home/pages/finances/finances.component";
import { ForgetPasswordComponent } from "./authentication/pages/forget-password/forget-password.component";
import { HelpCenterComponent } from "./home/pages/help-center/help-center.component";
import { HelpOrderComponent } from "./home/pages/help-order/help-order.component";
import { HomeMainComponent } from "./home/pages/home-main/home-main.component";
import { HomeNineComponent } from "./home-nine/home-nine.component";
import { HomeOtpComponent } from "./home/pages/home-otp/home-otp.component";
import { HomeTalabatComponent } from "./home/pages/home-talabat/home-talabat.component";
import { InStoreComponent } from "./home/pages/home/in-store/in-store.component";
import { LinksComponent } from "./home/pages/links/links.component";
import { LoginComponent } from "./authentication/pages/login/login.component";
import { MenuAvailabilityComponent } from "./home/pages/menu-availability/menu-availability/menu-availability.component";
import { MenuViewComponent } from "./home/pages/menu-view/menu-view.component";
import { MenuoperationComponent } from "./home/pages/menuoperation/menuoperation.component";
import { MerchantDetailsComponent } from "./home/pages/merchant-details/merchant-details.component";
import { MerchantGuard } from "./authentication/merchant.guard";
import { OpeningTimeComponent } from "./home/pages/opening-time/opening-time.component";
import { OrderDetailsComponent } from "./orders/pages/order-details/order-details.component";
import { OrderDetailsReportComponent } from "./home/pages/order-details-report/order-details-report.component";
import { OrderSummaryComponent } from "./orders/pages/order-summary/order-summary.component";
import { OrdersComponent } from "./orders/pages/orders/orders.component";
import { OtpActiveMenuComponent } from "./home/pages/otp-active-menu/otp-active-menu.component";
import { OtpAdvancedReportsComponent } from "./home/pages/otp-advanced-reports/otp-advanced-reports.component";
import { OtpAreaComponent } from "./home/pages/otp-area/otp-area.component";
import { OtpBranchComponent } from "./home/pages/otp-branch/otp-branch.component";
import { OtpCashierComponent } from "./home/pages/otp-cashier/otp-cashier.component";
import { OtpLoginComponent } from "./home/pages/otp-login/otp-login.component";
import { OtpMenuComponent } from "./home/pages/otp-menu/otp-menu.component";
import { OtpMonthlyReportComponent } from "./home/pages/otp-monthly-report/otp-monthly-report.component";
import { OtpOnlineOrdersComponent } from "./home/pages/otp-online-orders/otp-online-orders.component";
import { OtpOrdersComponent } from "./home/pages/otp-orders/otp-orders.component";
import { OtpPerformanceReportComponent } from "./home/pages/otp-performance-report/otp-performance-report.component";
import { OtpProductsComponent } from "./home/pages/otp-products/otp-products.component";
import { OtpSalesReportComponent } from "./home/pages/otp-sales-report/otp-sales-report.component";
import { PrintOrdersComponent } from "./home/pages/print-orders/print-orders.component";
import { PrintReceiptComponent } from "./home/pages/print-receipt/print-receipt.component";
import { PrivacyPolicyComponent } from "./information/pages/privacy-policy/privacy-policy.component";
import { ProductCompareComponent } from "./products/pages/product-compare/product-compare.component";
import { ProfileComponent } from "./user/pages/profile/profile.component";
import { RegisterComponent } from "./authentication/pages/register/register.component";
import { RevenueHourReportComponent } from "./home/pages/revenue-hour-report/revenue-hour-report.component";
import { Routes } from "@angular/router";
import { SalesByAreaComponent } from "./home/pages/sales-by-area/sales-by-area.component";
import { SalesReportComponent } from "./home/pages/sales-report/sales-report.component";
import { SingleInstoreComponent } from "./home/pages/home/single-instore/single-instore.component";
import { SupportRequestsComponent } from "./home/pages/support-requests/support-requests.component";
import { TermsConditionComponent } from "./information/pages/terms-condition/terms-condition.component";
import { UploadComponent } from "./home/pages/upload/upload.component";
import { UploadExcelComponent } from "./home/pages/upload-excel/upload-excel.component";
import { VendorPerformanceComponent } from "./home/pages/vendor-performance/vendor-performance.component";
import { VerificationComponent } from "./home/pages/verification/verification.component";
import { VerifyOtpComponent } from "./home/pages/verify-otp/verify-otp.component";
import { VerifyPhoneComponent } from "./authentication/pages/verify-phone/verify-phone.component";
import { WalletPageComponent } from "./home/pages/home/wallet-page/wallet-page.component";
import { CoverageAreaComponent } from "./home/pages/coverage-area/coverage-area.component";
import { SearchCustomerComponent } from './home/pages/search-customer/search-customer.component';


export const rootRouterConfig: Routes = [
  { path: "", component: HomeMainComponent, pathMatch: "full" },
  { path: ":lang", component: HomeMainComponent, pathMatch: "full" },
  { path: "home_main/:lang", component: HomeMainComponent },

  {
    path: "home",
    component: HomeNineComponent,
    children: [
      // {path:':lang', component: SearchPageComponent, pathMatch:'full', canActivate:[LoggedGuard]},
      { path: "search/:lang", component: HomeTalabatComponent },
      { path: "contact/:lang", component: ContactComponent },
      { path: "about-us/:lang", component: AboutUsComponent },
      { path: "categories/:lang", component: CatComponent },
      { path: "products/:lang", component: AllProductsComponent },
      {
        path: "product",
        loadChildren: () =>
          import("./products/products.module").then((m) => m.ProductsModule),
      },
      { path: "faq/:lang", component: FaqComponent },
      { path: "feedback/:lang", component: FeedbackComponent },
      { path: "privacy_policy/:lang", component: PrivacyPolicyComponent },
      { path: "terms_condition/:lang", component: TermsConditionComponent },
      { path: "order_summary/:lang", component: OrderSummaryComponent },
      {
        path: "cart",
        loadChildren: () =>
          import("./cart/cart.module").then((m) => m.CartModule),
      },
      {
        path: "category",
        loadChildren: () =>
          import("./categories/categories.module").then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },
      // {path:'favourite/:lang',component:WishlistComponent},
      { path: "compare/:lang", component: ProductCompareComponent },
      { path: "login/:lang", component: LoginComponent },
      { path: "register/:lang", component: RegisterComponent },
      {
        path: "orders",
        component: OrdersComponent,
        children: [
          { path: ":lang", component: OrdersComponent },
          { path: "order-details/:lang", component: OrderDetailsComponent },
          { path: "order-summary/:lang", component: OrderSummaryComponent },
        ],
      },
      { path: "change-password/:lang", component: ChangePassComponent },
      { path: "profile/:lang", component: ProfileComponent },
      {
        path: "payments",
        loadChildren: () =>
          import("./payments/payments.module").then((m) => m.PaymentsModule),
      },
      { path: "forget-password/:lang", component: ForgetPasswordComponent },
      { path: "confirmation/:lang", component: VerificationComponent },
      { path: "verifyError/:lang", component: ErrorComponent },
      { path: "instore/:lang", component: InStoreComponent },
      { path: "single-instore/:lang", component: SingleInstoreComponent },

      { path: "wallet/:lang", component: WalletPageComponent },
      { path: "merchant-details/:lang", component: MerchantDetailsComponent },
      { path: "verify-phone/:lang", component: VerifyPhoneComponent },
    ],
  },

  { path: "forget-password", component: ForgetPasswordComponent },
  { path: "error", component: ErrorPageComponent },

  {
    path: "otphome",
    component: HomeOtpComponent,
    children: [
      { path: ":lang", redirectTo: "otplogin/:lang" },
      {
        path: "verifyOtp/:lang",
        component: VerifyOtpComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "links/:lang",
        component: LinksComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "upload/:lang",
        component: UploadComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "upload-excel/:lang",
        component: UploadExcelComponent,
        canActivate: [MerchantGuard],
      },
      // {
      //   path: "dashboard/:lang",
      //   component: DashboardComponent,
      //   canActivate: [MerchantGuard],
      // },
      {
        path: "reports/:lang",
        component: AllReportsComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "opening/:lang",
        component: OpeningTimeComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "edit-opening/:lang/:id",
        component: EditOpeningTimesComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "help/:lang",
        component: HelpCenterComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "requests/:lang",
        component: SupportRequestsComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "help-orders/:lang",
        component: HelpOrderComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "general-analysis/:lang",
        component: AnalysisComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "search-customer/:lang",
        component: SearchCustomerComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "finances/:lang",
        component: FinancesComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "menu/:lang",
        component: MenuoperationComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "menu-availability/:lang",
        component: MenuAvailabilityComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "menu-operations/:lang",
        component: MenuViewComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "edit-product/:lang/:id",
        component: EditProdComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "confirmTransaction/:lang",
        component: ConfirmTransactionComponent,
        canActivate: [MerchantGuard],
      },
      { path: "otplogin/:lang", component: OtpLoginComponent },
      {
        path: "opt-orders/:lang",
        component: OtpOrdersComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "opt-adv-repo/:lang",
        component: OtpAdvancedReportsComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "opt-menu/:lang",
        component: OtpActiveMenuComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "opt-products/:lang",
        component: OtpProductsComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "opt-branch/:lang",
        component: OtpBranchComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },
      {
        path: "menu-performance/:lang",
        component: OtpMenuComponent,
        canActivate: [MerchantGuard],
      },
      // {
      //   path: "online-orders/:lang",
      //   component: OtpOnlineOrdersComponent,
      //   canActivate: [MerchantGfehuard],
      // },
      {
        path: "area-performance/:lang",
        component: OtpAreaComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "end-of-day/:lang",
        component: OtpCashierComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "print-receipt/:lang",
        component: PrintReceiptComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "print-order/:lang",
        component: PrintOrdersComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "all-areas/:lang",
        component: AllAreasComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "coverage-area/:lang",
        component: CoverageAreaComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "online-performance/:lang",
        component: OtpPerformanceReportComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "online-sales/:lang",
        component: OtpSalesReportComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "sales-reports/:lang",
        component: SalesReportComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "monthly-reports/:lang",
        component: OtpMonthlyReportComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "sales-by-area/:lang",
        component: SalesByAreaComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "vendor-performance/:lang",
        component: VendorPerformanceComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "order-details-report/:lang",
        component: OrderDetailsReportComponent,
        canActivate: [MerchantGuard],
      },
      {
        path: "revenue-hour-report/:lang",
        component: RevenueHourReportComponent,
        canActivate: [MerchantGuard],
      },
    ],
  },
  { path: "**", redirectTo: "error" },
];
