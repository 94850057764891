<div class="row">
    <div class="col-xl-12 col-md-12 col-sm-12 text-center">
      <div class="footer-end">
        <p class="text-center"><i class="fa fa-copyright" aria-hidden="true"></i> {{today | date:'y'}} {{'copyright'|translate}}
        <a href="https://pentavalue.com/" target="_blank" class="pentavalue">{{'companyname'|translate | titlecase}}</a>
        </p>
      </div>
    </div>
    <div class="col-xl-6 col-md-6 col-sm-12">
      <div class="payment-card-bottom">
        <!-- <ul>
          <li>
            <a [routerLink]=""><img src="assets/images/icon/visa.png" alt=""></a>
          </li>
          <li>
            <a [routerLink]=""><img src="assets/images/icon/mastercard.png" alt=""></a>
          </li>
          <li>
            <a [routerLink]=""><img src="assets/images/icon/paypal.png" alt=""></a>
          </li>
          <li>
            <a [routerLink]=""><img src="assets/images/icon/american-express.png" alt=""></a>
          </li>
          <li>
            <a [routerLink]=""><img src="assets/images/icon/discover.png" alt=""></a>
          </li>
        </ul> -->
      </div>
    </div>
</div>