<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
      <div class="row">
        <div class="col-sm-6 text-left">
            <nav aria-label="breadcrumb" class="theme-breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                    <li class="breadcrumb-item active" >{{'login'|translate}}</li>
                </ol>
            </nav>
        </div>
         
      </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>{{'customerlogin'|translate}}</h3>
                <div class="auth-card">
                    <form [formGroup]="loginForm" class="theme-form">

                        


                        <div class="form-group">
                            <label for="email">{{'phoneno'|translate}}</label>
                            <input formControlName="name" type="text"
                                class="form-control {{loginForm.controls['name'].touched && loginForm.controls['name'].errors ? 'error-control' : ''}}"
                                id="email" placeholder="{{'phoneno' | translate}}">
                            <span
                                *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.required"
                                class="error-msg">
                                {{'phoneReqMes'|translate}}
                            </span>
                            <span *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.pattern"
                                class="error-msg">
                                {{'phonePatMes'|translate}}.
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="review">{{'Password'|translate}}</label>

                            <div class="input-group">

                                <input formControlName="password" type="password"
                                class="form-control {{loginForm.controls['password'].touched && loginForm.controls['password'].errors ? 'error-control' : ''}}"
                                id="password" placeholder="{{'Password'|translate}}">

                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i
                                        class="fa"
                                        [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }"
                                        (click)="toggleFieldTextType()"
                                      ></i>
                                    </span>
                                </div>

                            </div>


                            <span
                                *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.required"
                                class="error-msg">
                                {{'passwordReqMes'|translate}}
                            </span>
                            <span
                                *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.minlength"
                                class="error-msg">
                                {{'passwordMinLengMes'|translate}}.
                            </span>
                        </div>
                        <a class="forget-password" (click)="gotoFrogetPassword()">{{'forget-password'|translate}}</a><br>
                        <button [disabled]="!loginForm.valid || isLoading" (click)="login()"
                            class="btn btn-solid margin-t login-btn">
                            <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                            {{'login'|translate}}</button>
                    </form>

                    <button class="btn btn-solid margin-t google-btn" (click)="googleLogin()"><i class="fa fa-google"></i> Google Login</button>
                    <button class="btn btn-solid margin-t facebook-btn" (click)="facebookLogin()"><i class="fa fa-facebook"></i> Facebook Login</button>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>{{'newcustomer'|translate}}</h3>
                <div class="theme-card authentication-right">
                    <!-- <h6 class="title-font">{{'createaccount'|translate}}</h6> -->
                    <p>
                        {{'createaccounttext'|translate}}</p>
                    <a (click)="gotoRegister()" class="btn btn-solid login-btn">{{'createaccount'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->