import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from "@angular/animations";
import { Product, ColorFilter, TagFilter } from '../../../shared/classes/product';
import { ProductsService } from '../../../shared/services/products.service';
import * as _ from 'lodash'
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { AllproductsService } from '../../../shared/services/allproducts.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.scss'],
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})
export class AllProductsComponent implements OnInit {
  responseProduct;
  finished2: Boolean = false;
  allproducts: any[] = [];
  newproducts;
  allcolours;
  allsizes;
  config: any;
  totalCount: number;
  perPage: number;
  page = 1;
  id;
  categories: any[] = [];
  subCategories: any[] = [];
  currentCriteria: any = {
    'categories': [],
    'subCategories': [],
    'subSubCategories': [],
    'customFieldValue': [],
    'rank': '',
    'priceFrom': 100,
    'priceTo': 1000
  }
  currentPage: number = 0;
  lastPage: number = 0;
  filteredProducts: any[] = [];
  merchants:any[] = [];
  totalItems: number = 0;
  fromItem: number = 0;
  public items: Product[] = [];
  public allItems: Product[] = [];
  public sortByOrder: string = '';   // sorting
  public animation: any;   // Animation

  lastKey = ''      // key to offset next query from
  finished = false  // boolean when end of data is reached
  lang;
  rating = ["1", "2", "3", "4", "5"];
  newproduct_id: any;
  newproduct_name: any;
  newproduct_name_ar: any;
  newproduct_image: any;
  newproduct_price: any;
  productsReq: any;
  productsClass: string = '';
  constructor(private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    public translate: TranslateService,
    private categoriesService: CategoriesService,
    private allproductservice: AllproductsService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.productsClass = this.router.url.toString().split('/')[1];
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.spinner.show();
    this.route.queryParams.subscribe(
      res=>{
        this.id = +res['id'];
      }
    );
    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)
    // new api to show all products

    this.getMerchantByCategory();

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount
    };



  }





  initLookups() {
    this.categoriesService.getCategories().subscribe(res => {
      this.categories = res['categories'];
      this.categories.forEach(element => {
        // this.subCategories = [...element['categories']];
        // console.log(this.subCategories)
      })
      // console.log("the main categories",this.categories)
      // console.log("the sub categories",this.subCategories)
    })

  }

  getAllProduct() {
    this.allproductservice.getallproducts().subscribe((res: any) => {
      this.allproducts = res.data;
      this.responseProduct = res;
      this.spinner.hide();
      this.totalItems = this.allproducts.length;
      this.totalCount = this.allproducts.length;

      console.log("Initial All Products From NEW API=========>", this.allproducts)
    },
    error=>{
      this.spinner.hide();
    })
  }

  getMerchantByCategory() {
    this.allproductservice.getMerchantByCategory(this.id).subscribe(
      res=>{
        this.merchants = res['merchants'].data;
        this.totalItems = res['merchants'].total;
        this.totalCount = res['merchants'].total;
        this.perPage = res['merchants'].per_page;
        this.spinner.hide();
      },
      error=>{
        this.spinner.hide();
      }
    );
  }


  isCatSelected(catID) {
    return this.currentCriteria['categories'].findIndex(item => item == catID) > -1;
  }


  setCategory(catID) {
    let catIndex = this.currentCriteria['categories'].findIndex(item => item == catID);

    if (catIndex > -1) {
      this.currentCriteria['categories'].splice(catIndex, 1);
    } else {
      this.currentCriteria.categories.push(catID);
    }
    // this.filterProducts();
  }

  filterProducts() {
    console.log('The Current Criteria is:-', this.currentCriteria);
    this.finished = false;
    let criteria = {
      'categories': '',
      'subCategories': '',
      'subSubCategories': '',
      'customFieldValue':[],
      'rank':'',
      'priceFrom': 1,
      'priceTo': 1000000
    }
    // console.log(JSON.stringify(this.currentCriteria['categories']).replace('[','').replace(']',''));
    criteria['categories'] = JSON.stringify(this.currentCriteria['categories']).replace('[','').replace(']','');
    criteria['subCategories'] = JSON.stringify(this.currentCriteria['subCategories']).replace('[','').replace(']','');
    criteria['subSubCategories'] = JSON.stringify(this.currentCriteria['subSubCategories']).replace('[','').replace(']','');
    criteria['priceFrom'] = this.currentCriteria['priceFrom'];
    criteria['priceTo'] = this.currentCriteria['priceTo'];
    criteria['rank'] = this.currentCriteria['rank'];

    if(criteria['categories'] == null || criteria['categories'] == ''){
      delete criteria['categories'];
    }
    if(criteria['subCategories'] == null || criteria['subCategories'] == ''){
      delete criteria['subCategories'];
    }
    if(criteria['subSubCategories'] == null || criteria['subSubCategories'] == ''){
      delete criteria['subSubCategories'];
    }
    if(criteria['rank'] == null || criteria['rank'] == ''){
      delete criteria['rank'];
    }
    if(criteria['customFieldValue'] == null || criteria['customFieldValue'] == [] || criteria['customFieldValue'].length == 0){
      delete criteria['customFieldValue'];
    }
    console.log('CriCri', criteria);
    this.productsService.getProductsByCriteria(criteria).subscribe(res => {
      this.filteredProducts = [...res['data']];
      this.currentPage = res['current_page'];
      this.lastPage = res['last_page'];
      this.totalItems = res['total'];
      this.fromItem = res['from'];
      this.totalCount = res['total'];
      this.perPage = res['per_page'];

      this.updateConfig();
      this.finished = true;
    });
  }

  updatecolour(color: any) {

    if (color == '') {
      this.currentCriteria['color'] = '';
      // this.filterProducts();

    }
    else {
      this.currentCriteria['color'] = color;
      // this.filterProducts();
    }
  }

  updatesize(size: any) {
    if (size == '') {
      this.currentCriteria['size'] = '';
      // this.filterProducts();
    }
    else {
      this.currentCriteria['size'] = size;
      // this.filterProducts();
    }

  }

  updaterate(rank: any) {
    // if(rank == ''){
    //   this.currentCriteria['rank']= '';
    //   this.filterProducts();
    // }
    // else{
    //   this.currentCriteria['rank']= rank;
    //   this.filterProducts();
    // }

    let routs: any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang], { queryParams: { 'rank': rank } });

  }




  setPages(currentPage, lastPage) {
    this.currentCriteria['page'] = currentPage;
    this.lastPage = lastPage;
  }


  // Update price filter
  public updatePriceFilters(price: any) {
    this.currentCriteria['priceFrom'] = price[0];
    this.currentCriteria['priceTo'] = price[1];
    this.filterProducts();
  }

  public twoCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-6");
    }
  }

  public threeCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-4");
    }
  }

  public fourCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-3");
    }
  }

  public sixCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-2");
    }
  }

  // For mobile filter view
  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }

  // Infinite scroll
  /* public onScroll() {
    this.lastKey = _.last(this.allItems)['id'];
    if (this.lastKey != _.last(this.items)['id']) {
      this.finished = false
    }
    // If data is identical, stop making queries
    if (this.lastKey == _.last(this.items)['id']) {
      this.finished = true
    }
    if (this.products.length < this.allItems.length) {
      let len = this.products.length;
      for (var i = len; i < len + 4; i++) {
        if (this.allItems[i] == undefined) return true
        this.products.push(this.allItems[i]);
      }
    }
  } */

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    if (val == 'a-z') {
      if (this.lang == 'en') {
        this.allproducts.sort(function (a, b) {
          var textA = a.title.En.toUpperCase();
          var textB = b.title.En.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      } else {
        this.allproducts.sort(function (a, b) {
          var textA = a.title.ar.toUpperCase();
          var textB = b.title.ar.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      }

    } else if (val == 'z-a') {
      if (this.lang == 'en') {
        this.allproducts.sort(function (a, b) {
          var textA = a.title.En.toUpperCase();
          var textB = b.title.En.toUpperCase();
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        })
      } else {
        this.allproducts.sort(function (a, b) {
          var textA = a.title.ar.toUpperCase();
          var textB = b.title.ar.toUpperCase();
          return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        })
      }
    }
  }

  onChangePage(event) {
    this.spinner.show();
    this.config.currentPage = event;
    console.log("Event", event);
    this.page = event;
    this.productsService.getProductsByCriteria({ 'page': event }).subscribe(
      res => {
        this.allproducts = res['data'];
        this.lastPage = res['last_page'];
        this.totalCount = res['total'];
        this.perPage = res['per_page'];
        this.updateConfig();
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  updateConfig() {
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount
    };
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

}
