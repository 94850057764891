import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class AllproductsService {

  constructor(private httpClientService: HttpClientService) { }


  getproducts2() {
    return this.httpClientService.get('products', []);
  }

  getnewproducts(){
    return this.httpClientService.get('products/newest/3', []);

  }

  getallproducts() {
    return this.httpClientService.get('products', []);
  }

  getProductsByfilter(queryParams) {
    return this.httpClientService.get('products', queryParams);
  }

  getProductsByCriteria(queryParams) {
    return this.httpClientService.get('products', queryParams);
  }

  getcolourlist() {
    return this.httpClientService.get('colors', []);
  }

  getsizelist() {
    return this.httpClientService.get('sizes', []);
  }
  
  getTopCashbackStores(){
    return this.httpClientService.get('top_cashback_merchants/1', []);
  }

  getTopCashbackCategories(){
    return this.httpClientService.get('categories-top-cashback-merchants/1', []);
  }

  getHomeData(id) {
    return this.httpClientService.get('home/' + id, []);
  }

  getMerchantByCategory(id){
    return this.httpClientService.get('merchants', []);
  }

  getMerchantByCriteria(queryParams) {
    return this.httpClientService.get('merchants', queryParams);
  }

  getMerchantDetails(id: number){
    return this.httpClientService.get('merchants/' + id, []);
  }

  getMerchantReviews(id: number) {
    return this.httpClientService.get('get-merchant-reviews/' + id, []);
  }

}

