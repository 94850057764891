import { Component, OnInit, ViewChild } from "@angular/core";

import { DatePipe } from "@angular/common";
import { FinancesService } from "src/app/shared/services/finances.service";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: "app-finances",
  templateUrl: "./finances.component.html",
  styleUrls: ["./finances.component.scss"],
})
export class FinancesComponent implements OnInit {
  year;
  yearsList = [];
  invoices;
  step;
  financeReports;
  merchantsList: any;
  type;
  merchant;
  startDate = new Date();
  endDate = new Date();
  @ViewChild("select") select: MatSelect;
  minDate = new Date();
  maxDate = new Date();

  allSelected = false;
  lang: string;
  selectedTab = "i";
  constructor(
    private financeService: FinancesService,
    private http: HttpClientService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getDates();
    this.getBranchList();
    if (localStorage.getItem("selectedTab"))
      this.selectedTab = localStorage.getItem("selectedTab");
    this.lang = localStorage.getItem("lang_ref");
    let merchants = JSON.parse(localStorage.getItem("Brand_Merchants_array"));
    let dates = merchants?.map((r) => r.created_at);
    let start = new Date()?.getFullYear();
    if (dates?.length > 0) start = new Date(dates[0])?.getFullYear();
    dates?.forEach((element) => {
      element = new Date(element).getFullYear();
      if (start > element) start = element;
    });
    let difference = new Date().getFullYear() - start;
    for (let index = difference; index >= 0; index--) {
      this.yearsList.push(start + index);
    }
    this.year = this.yearsList[0];
    this.getInvoices();
    this.getReports();
  }
  getInvoices() {
    this.invoices = null;
    this.financeService
      .getInvoice({ year: this.year })
      .subscribe((res: any) => {
        this.invoices = res.data;
        this.step = this.invoices[0]?.id;
      });
  }
  getDates() {
    if (this.startDate.getDate() == 1 || this.startDate.getDate() == 2) {
      this.startDate.setMonth(this.startDate.getMonth() - 1);
      this.startDate.setDate(1);
      this.minDate.setMonth(this.startDate.getMonth() - 1);
      this.minDate.setDate(1);
      this.endDate = this.startDate;
      this.maxDate.setDate(this.maxDate.getDate() - this.startDate.getDate());
    } else {
      this.minDate.setDate(1);
      this.maxDate.setDate(this.startDate.getDate() - 1);
      this.startDate.setDate(this.startDate.getDate() - 1);
      this.endDate.setDate(this.endDate.getDate() - 1);
    }
  }
  getFile(file) {
    window.open(file, "_blank");
  }
  getReports() {
    this.financeService.getReports().subscribe((res: any) => {
      this.financeReports = res.data;
      this.financeReports.forEach((element) => {
        element.status = "ready";
      });
    });
  }
  getBranchList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
        }
      });
  }
  generateReports() {
    let body = {
      report_type: this.type,
      merchants: [...this.select.value],
      from: this.datePipe.transform(this.startDate, "yyyy-MM-dd"),
      to: this.datePipe.transform(this.endDate, "yyyy-MM-dd"),
    };
    let merchants = "";
    this.select.value.forEach((element, index) => {
      merchants +=
        this.merchantsList.find((res) => {
          return res.id == element;
        }).name + (index != this.select.value.length - 1 ? ", " : "");
    });
    this.financeReports.unshift({
      report_type: this.type,
      from: this.startDate,
      to: this.endDate,
      merchants_name: merchants,
      generated_at: new Date(),
      status: "processing",
    });
    // this.financeReports.reverse();
    this.financeService.generateReports(body).subscribe((res: any) => {
      // this.financeReports.reverse();
      this.financeReports[0].status = "ready";
      this.financeReports[0].file_path = res.data.file_path;
      // this.financeReports.reverse();
    });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  setTab(tab) {
    this.selectedTab = tab;
    localStorage.setItem("selectedTab", tab);
  }
}
