import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { sameValueAs } from 'src/app/shared/validators/same-password';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private translate: TranslateService,
              private userService: UserService,
              private toastrService: ToastrService,
              private router: Router
              ) { } 
  registerForm: FormGroup;
  lang;
  isSocial: boolean = false;
  provider: any;
  providerId: any;
  ngOnInit() { 
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)
    this.initRegisterForm();
    this.getSocialUser();
  }


  initRegisterForm() {
    this.registerForm = new FormGroup({
      // name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),

      fname: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
      lname: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
      mobile: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      retypePassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      provider: new FormControl(''),
      provider_id: new FormControl('')
    }, { validators: sameValueAs })
  }

  getSocialUser(){
    this.userService.socialUser.subscribe(
      (res: any)=>{
        this.isSocial = true;
        console.log('REEEE', res);
        console.log('is Social:-  ', this.isSocial);
        this.provider = res.provider;
        this.providerId = res.provider_id;
        this.registerForm.patchValue({
          fname: res.first_name,
          lname: res.last_name,
          email: res.email,
          provider: res.provider,
          provider_id: res.provider_id
        });
      }
    );
  }

  register() {
    let userObject:any = {
      name: `${this.registerForm.value.fname} ${this.registerForm.value.lname}`,
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      mobile: this.registerForm.value.mobile,
    }

    if(localStorage.getItem('cartToken')){
      userObject.cart_token = localStorage.getItem('cartToken');
    }

    if(!this.isSocial){
      this.userService.register(userObject).subscribe((res: any) => {
        console.log('The register', res);
        if(this.lang == 'ar'){
          this.toastrService.success(res.message)
        }else{
          this.toastrService.success(res.message)
        }
        let routs:any[] = this.router.url.split('/');
        this.router.navigate([routs[1] + '/login', this.lang]);
      },error=>{
        if(error.error.errors.name){
          this.toastrService.error(error.error.errors.name[0]); // toasr services
          }
          else if (error.error.errors.password){
          this.toastrService.error(error.error.errors.password[0]); // toasr services
          }
          else if (error.error.errors.mobile){
            this.toastrService.error(error.error.errors.mobile[0]); // toasr services
            }
            else if (error.error.errors.email){
              this.toastrService.error(error.error.errors.email[0]); // toasr services
              }
      })
    }else{
      userObject.first_name = this.registerForm.value.fname;
      userObject.last_name = this.registerForm.value.lname
      userObject.provider = this.provider;
      userObject.provider_id = this.providerId;
      userObject.password_confirmation = this.registerForm.value.retypePassword;
      this.userService.registerUpdated(userObject).subscribe((res:any) => {
        console.log('The register', res);
        if(this.lang == 'ar'){
          this.toastrService.success(res.message)
        }else{
          this.toastrService.success(res.message)
        }
        let routs:any[] = this.router.url.split('/');
        this.router.navigate([routs[1] + '/verify-phone', this.lang], {queryParams:{phone: res['user'].mobile}});
      },
      error=>{
        if(error.error.errors.name){
          this.toastrService.error(error.error.errors.name[0]); // toasr services
          }
          else if (error.error.errors.password){
          this.toastrService.error(error.error.errors.password[0]); // toasr services
          }
          else if (error.error.errors.mobile){
            this.toastrService.error(error.error.errors.mobile[0]); // toasr services
            }
            else if (error.error.errors.email){
              this.toastrService.error(error.error.errors.email[0]); // toasr services
              }
      });
    }
  }

  gotoHome() {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/', this.lang]);
  }
  
}
