import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  
  today: number = Date.now();
  
  constructor(public translate: TranslateService) { }
  lang;
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang);
  }

}
