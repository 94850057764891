<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">

            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'category'|translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->

<!-- collection banner -->
<section class="banner-padding banner-furniture" [ngClass]="catClass">

    <div class="container-fluid align-items-center m-auto">
        <div class="row partition3">
            <div *ngFor="let cat of categories" class="col-md-3">
                <a (click)="navigateCategory(cat)">
                    <div class="collection-banner">


                        <img
                            *ngIf="cat.name.en != 'Games'" 
                            src="assets/images/svgs/{{cat.name.en}}.svg"
                            [style.backgroundColor]="cat?.color" 
                            alt="" 
                            class="img-fluid">

                        <img 
                            *ngIf="cat.name.en == 'Games'"
                            [src]="cat?.header_image[0].default"
                            [style.backgroundColor]="cat?.color" 
                            alt="" 
                            class="img-fluid">
                        <div class="p-3">
                            <div class="row">
                                <div class="col-3">
                                    <img [src]="(cat?.home_image != null)?cat?.home_image[0]?.default:'assets/images/sahlalogo.png'" alt=""
                                        class="img-fluid sub-img">
                                </div>
                                <div class="col-9">
                                    <div class="catname">
                                        <div>
                                            <h2 class="mb-0" *ngIf="lang == 'en'">{{cat?.name?.en}}</h2>
                                            <h2 class="mb-0" *ngIf="lang == 'ar'">{{cat?.name?.ar}}</h2>
                                        </div>
                                    </div>
                                    <span class="see-more mb-0">See more</span>
                                </div>
                            </div>

                            <!-- <div class="hover-col"></div> -->
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<!-- collection banner end  -->

<section class="top-slider">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3>
                    {{'Top cashbacks Stores' | translate}}
                </h3>

                <!-- Start owl slider -->

                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let merchant of topMerchants">
                        <div (click)="gotoMerchant(merchant?.id)">
                            <img [src]="merchant?.cover_image[0]?.default? merchant?.cover_image[0]?.default : 'assets/images/sahlalogo.png'" alt="">
                            <h4 class="text-center font-weight-bold mt-3">{{merchant?.name}}</h4>
                        </div>
                    </ng-template>
                    <!-- <ng-template carouselSlide>
                        <img src="./assets/images/default/coffee-bg.jpg" alt="">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="./assets/images/default/coffee-bg.jpg" alt="">
                    </ng-template> -->
                </owl-carousel-o>

                <!-- End owl slider -->
            </div>
        </div>
    </div>
</section>

<ngx-spinner color="#f95733"></ngx-spinner>