import { Component, OnInit } from "@angular/core";

import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-revenue-hour-report",
  templateUrl: "./revenue-hour-report.component.html",
  styleUrls: ["./revenue-hour-report.component.scss"],
})
export class RevenueHourReportComponent implements OnInit {
  lang;
  merchantId: number;
  config: any;
  reports: any[] = [];
  page: number = 1;
  perPage: any = 0;
  length: any = 0;
  hasPagination: boolean = false;
  isLoading: boolean = false;

  filterForm: FormGroup;
  Hide: boolean = true;

  dataSource: Object;
  selectedID = 1;
  title: string;
  id: number;
  name: string;
  type: string;
  reportType: string;
  merchantsList: any;
  merchant: any;
  idList: number[];
  date;
  showDate = new Date();
  view: any[] = [1000, 600];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  timeline: boolean = true;
  colorScheme = {
    domain: ["#0a4870", "#3b0033"],
  };
  multi;
  downloadreportPDF: any;
  downloadreport: any;

  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
    Object.assign(this, { multi: this.multi });
  }
  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.merchantId = +localStorage.getItem("merchantId");
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.translate.use(this.lang);
    this.getList();
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.idList = [this.id];
    this.initReports();
  }
  initReports() {
    this.getReports();
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.length,
    };
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.name = this.merchant.name;
          this.idList = [this.id];
          this.initReports();
        }
      });
  }
  getReports() {
    if (this.selectedID == 1) {
      this.date = new Date();
      this.date = this.datePipe.transform(this.date, "YYYY-MM-dd");
    } else if (this.selectedID == 2) {
      this.date = new Date();
      this.date.setDate(new Date().getDate() - 1);
      this.date = this.datePipe.transform(this.date, "YYYY-MM-dd");
    }
    let body = {
      type: this.reportType,
      date: this.date,
      merchants: this.idList,
    };
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.multi = null;
    this.isLoading = true;
    this.http
      .post("report/revenue-hour-report", body, {}, headers)
      .subscribe((res: any) => {
        this.reports = res.data.orders;
        this.isLoading = false;
        this.downloadreportPDF = res["data"].pdf_download_file;
        this.downloadreport = res["data"].download_file;
        this.setData();
      });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }

  printAll() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }
  onDateChange(e: Date) {
    this.date = this.datePipe.transform(e, "YYYY-MM-dd");
    this.getReports();
  }
  reset() {
    this.reportType = null;
    this.date = new Date();
    this.date = this.datePipe.transform(this.date, "YYYY-MM-dd");
    this.selectedID = 1;
    this.getReports();
  }
  setData() {
    let vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    let vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    if (vw <= 500) vw = vw - 50;
    else vw = vw - 100;
    this.view = [vw, vh - 300];
    console.log(vw, vh);
    this.multi = [
      {
        name: this.lang == "en" ? "Orders" : "الطلبات ",
        series: [],
      },
      {
        name: this.lang == "en" ? "Revenue" : "إيرادات ",
        series: [],
      },
    ];
    for (let index = 1; index <= 24; index++) {
      this.multi[0].series.push({
        name: index.toString(),
        value: 0,
      });
      this.multi[1].series.push({
        name: index.toString(),
        value: 0,
      });
    }
    Object.keys(this.reports).forEach((res) => {
      this.multi[0].series.find((key) => {
        return key.name == res.toString();
      }).value =
        this.reports[res].istore_count + this.reports[res].online_count;
      this.multi[1].series.find((key) => {
        return key.name == res.toString();
      }).value =
        this.reports[res].istore_total + this.reports[res].online_total;
    });
  }
  changeSelection(event: any) {
    if (this.selectedID != 3) this.getReports();
  }
}
