import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformationRoutingModule } from './information-routing.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from '../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AboutUsComponent, FeedbackComponent, TermsConditionComponent, PrivacyPolicyComponent, FaqComponent],
  imports: [
    CommonModule,
    InformationRoutingModule,
    SlickCarouselModule,
    SharedModule,
    NgxSpinnerModule,

  ],
  exports:[
    SharedModule
  ]
})
export class InformationModule { }
