<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Monthly Report" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="dates">
            <mat-form-field appearance="outline">
              <mat-label>{{ "Choose Type" | translate | titlecase }}</mat-label>
              <mat-select [(ngModel)]="status" (selectionChange)="getData()">
                <mat-option selected disabled [value]="null">
                  {{ "Choose Type" | translate }}
                </mat-option>
                <mat-option [value]="'online'">
                  {{ "onlineOrders" | translate }}
                </mat-option>
                <mat-option [value]="'instore'">{{
                  "instore" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "Choose Year" | translate | titlecase }}</mat-label>
              <mat-select [(ngModel)]="year" (selectionChange)="getData()">
                <mat-option *ngFor="let item of yearsList" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div></div>
          </div>
        </div>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >
            <div class="btns">
              <button (click)="reset()" class="btn btn-success mx-2">
                {{ "Reset" | translate }}
              </button>
              <button
                (click)="printAll()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>
        <mat-spinner *ngIf="!isLoading"></mat-spinner>

        <div class="row" *ngIf="isLoading">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card">
              <h2>{{ "Year / Month" | translate }}</h2>
            </div>

            <div class="card" *ngFor="let item of dates">
              <div class="row">
                <div class="col-md-2 col-sm-6 col-xs-6">
                  <h5 class="year">{{ item }}</h5>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6">
                  <h5>{{ "Total Quantity In Store" | translate }}</h5>
                  <p>
                    {{
                      reports[item].istore_count
                        ? reports[item].istore_count
                        : 0
                    }}
                  </p>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6">
                  <h5>{{ "Total Quantity Online" | translate }}</h5>
                  <p>
                    {{
                      reports[item].online_count
                        ? reports[item].online_count
                        : 0
                    }}
                  </p>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6">
                  <h5>{{ "Total Amount In Store" | translate }}</h5>
                  <p>
                    {{
                      reports[item].istore_total
                        ? reports[item].istore_total
                        : 0
                    }}
                    {{ "EGP" | translate }}
                  </p>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6">
                  <h5>{{ "Total Amount Online" | translate }}</h5>
                  <p>
                    {{
                      reports[item].online_total
                        ? reports[item].online_total
                        : 0
                    }}
                    {{ "EGP" | translate }}
                  </p>
                </div>

                <div class="col-md-2 col-sm-6 col-xs-6">
                  <!-- <button
                    class="download-btn"
                    (click)="downloadFiles(reports[item])"
                  >
                    {{ "Download" | translate }}
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
