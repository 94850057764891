import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;

@Component({
  selector: 'app-slider-five',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderFiveComponent implements OnInit {

  constructor(public translate: TranslateService) { }
  lang;
  @Input() slides;
  ngOnChanges()
  {
    console.log("imageSlides => ",this.slides)
  }
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    console.log("Sliddddes", this.slides);

  }
  changeLang(lang) {
      if(lang == 'en') {
        this.lang = 'ar'
        this.translate.use('ar');
        localStorage.setItem("lang_ref",'ar')
        window.location.reload()
      }
      else {
        this.lang = 'en';
        $("body").removeClass('rtl');
        this.translate.use('en');
        localStorage.setItem("lang_ref",'en')
        // console.log("language => ",this.translate.getDefaultLang())
        window.location.reload()
      }
  }

  // Slick slider config
  public sliderConfig: any = {
    autoplay: true,
    autoplaySpeed: 3000
  };

}
