<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>
              {{ "Revenue by hour" | translate }}
            </h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="seperator"></div>

        <form>
          <div class="row">
            <div class="col-md-3 res">
              <mat-form-field
                appearance="outline"
                style="background-color: white"
                ><mat-label>{{ "Select Type" | translate }}</mat-label>
                <mat-select
                  [(value)]="reportType"
                  (selectionChange)="getReports()"
                >
                  <mat-option value="online">
                    {{ "online_order" | translate }}
                  </mat-option>
                  <mat-option value="instore">
                    {{ "instore" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 res">
              <mat-form-field
                appearance="outline"
                style="background-color: white"
              >
                <mat-select
                  (selectionChange)="changeSelection($event)"
                  [(ngModel)]="selectedID"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option [value]="1">
                    {{ "Today" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "Yesterday" | translate }}
                  </mat-option>

                  <mat-option [value]="3">
                    {{ "Custom" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 res" *ngIf="selectedID == 3">
              <div>
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <mat-label>{{ "Choose date" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    (dateChange)="onDateChange($event.value)"
                    [(ngModel)]="showDate"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="month"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >
            <div class="btns">
              <button (click)="reset()" class="btn btn-success">
                {{ "Reset" | translate }}
              </button>
              <button
                (click)="printAll()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>

        <div class="row">
          <div class="card" *ngIf="multi?.length == 0 && isLoading">
            <div class="card-body">
              {{ "NO_ORDERS" | translate }}
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-xs-12">
            <ngx-charts-line-chart
              [view]="view"
              *ngIf="multi"
              [scheme]="colorScheme"
              [legend]="legend"
              [showXAxisLabel]="true"
              [xAxis]="true"
              [yAxis]="true"
              [xAxisLabel]="'time' | translate"
              [timeline]="timeline"
              [legendTitle]="''"
              [results]="multi"
            >
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
