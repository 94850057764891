<!-- brand filter start -->
<div class="collection-collapse-block open">
  <h3 class="collapse-block-title">{{'brand'|translate}}</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let filter of tagsFilters">
        <input type="checkbox" class="custom-control-input" [value]="filter.brand" (change)=checkedFilter($event) id="{{filter.brand}}">
        <label class="custom-control-label" for="{{filter.brand}}">{{filter.brand}}</label>
      </div>
    </div> 
  </div>
</div>
