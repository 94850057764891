import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-pass6',
  templateUrl: './forget-pass6.component.html',
  styleUrls: ['./forget-pass6.component.css']
})
export class ForgetPass6Component implements OnInit {

  forgetPasswordForm: FormGroup;
  newPasswordForm: FormGroup;
  email_check:Boolean = false;
  email;
  code;
  lang;
  constructor(private userService: UserService,
     private router: Router, private toast: ToastrService) { }

  ngOnInit() {
    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999);
    this.lang = localStorage.getItem('lang_ref');
    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)
    this.initForgetPasswordForm();
    this.initNewPasswordForm();
  }

  initForgetPasswordForm() {
    this.forgetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }
  initNewPasswordForm()
  {
    this.newPasswordForm = new FormGroup({
      password: new FormControl('',Validators.compose([Validators.required])),
      password_confirmation: new FormControl('',Validators.compose([Validators.required])),
      token: new FormControl('',Validators.compose([Validators.required])),
    })
  }

  forgetPassword() {
    this.userService.forgetPassword(this.forgetPasswordForm.value).subscribe(res => {
      // this.userService.handleResponse(res);
      if(this.lang == 'en'){
        this.toast.success("Email with code is sent");
      }else{
        this.toast.success("تم ارسال الكود على بريدك الالكترونى");
      }
       // toasr services
      this.email_check = true;
      // this.router.navigate(['/login']);

    }, error => {
        // console.log(error.message);
      if(error.error.errors.email){
        this.toast.error(error.error.errors.email[0]); // toasr services
        }
    }
    )
  }

  submitCode()
  {
    // this.userService.
    console.log("code => ",this.code);
  }
  changePassword(newPassword)
  {
    if(newPassword.new_password != newPassword.confirm_new_password){
      if(this.lang == 'en'){
        this.toast.error("2 passwords not matched");
      }else{
        this.toast.error("الرقم السرى غير متطابق");
      }
    }
    else {
      console.log("reset password=> ", {...newPassword,...this.forgetPasswordForm.value})
        this.userService.resetPasword({...newPassword,...this.forgetPasswordForm.value}).subscribe((response:any)=>{
          console.log("response> ",response);
          if(response.status == "updated"){
            if(this.lang == 'en'){
              this.toast.success("Password Successfully Changed");
            }else{
              this.toast.success("تم تغيير الرقم السرى بنجاح");
            }
            
            this.router.navigate(['login']);
          }
        },error=>{
          console.log("error=> ",error.error)
          if(error.error.errors.password.length >0) {
            error.error.errors.password.forEach(error=>{
              this.toast.error(error);
            })
          }
          if(error.error.errors.token.length >0) {
            error.error.errors.token.forEach(error=>{
              this.toast.error(error);
            })
          }
        })
    }
  }


}
