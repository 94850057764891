import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-in-store',
  templateUrl: './in-store.component.html',
  styleUrls: ['./in-store.component.css']
})
export class InStoreComponent implements OnInit {

  id;
  lang;
  Merchants: any[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClientService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);

    this.route.queryParams.subscribe(
      res=>{
        this.id = res['id'];
        this.getMerchantOfBrands(this.id);
      }
    );
  }

  getMerchantOfBrands(id){
    let param = {'brand': id};
    this.http.get('merchants', param).subscribe(
      res=>{
        this.Merchants = res['merchants'].data;
        console.log('Res', this.Merchants);
      }
    );
  }

  openPlace(lat, long){
    window.open("https://www.google.com/maps/place/" + lat + "N" + long + "E", '_blank');
  }

}
