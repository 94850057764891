<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
            <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <h2>
                    {{'Print Orders' | translate}}
                </h2>

                <button 
                    printTitle="MyTitle"
                    printSectionId="print-section" 
                    ngxPrint
                    (click)="print()">
                    Download
                </button>

                <div class="row" id="element">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <img src="assets/images/sahlalogo.png" width="100" height="100" />
                            </div>
                            <div class="col-md-7"></div>
                            <div class="col-md-2">
                                <p class="align-middle" style="margin-top: 60px;">Penta Value</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <app-table [reports]="orders" [fields]="displayedColumns" [length]="totalCount" [perPage]="perPage"
                        [config]="config" [currentPage]="page" [hasPagination]="hasPagination"></app-table>
                    </div>
                    <div class="footer" style="margin:30px auto 0px; width: 22%;">
                        <a href="https://pentavalue.com/en" class="text-center" style="text-align: center;">Developed By
                            PentaValue</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>