import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { Product } from 'src/app/shared/classes/product';
import { AboutService } from 'src/app/shared/services/about.service';
import { AddingMetaService } from 'src/app/shared/services/adding-meta.service';
import { AllproductsService } from 'src/app/shared/services/allproducts.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { ProductsService } from 'src/app/shared/services/products.service';

@Component({
  selector: 'app-main9',
  templateUrl: './main9.component.html',
  styleUrls: ['./main9.component.scss']
})
export class Main9Component implements OnInit {

  allproducts: any[] = [];
  beforeSearch:any[] = [];
  responseProduct;
  totalItems: number = 0;
  showMenu: boolean = true;
  public counter: number = 1;
  closeResult = '';
  // cart
  public shoppingCartItems:any[] = [];
  lang: string;
  ContactData: Object;
  email: any;
  phone: any;
  address_ar: any;
  updown: boolean = false;
  catproducts: any = [];
  result: any;
  total: any;
  subTotal: any;
  cashback: any;
  deliveryfees: any;

  reviews:any[] = [];
  rates:any[] = [];
  reviewsCount:any;
  product_id;
  fax;
  categories: any[];
  // search
  searchQuery: string;
  searchRes: any[] = [];
  searchSubscription: any;
  search_show;
  main_search_show;
  merchantId: number;
  merchantData: any;
  theProduct:any;
  customeFields:any[] = [];
  fields:any[] = [];
  isLoading: boolean = false;

  constructor(private categoriesService: CategoriesService,
    private router: Router,
    private allproductservice: AllproductsService,
    private cartService: CartService,
    private productsService: ProductsService,
    public translate: TranslateService,
    private metaService2: AddingMetaService,
    private lookupService: LookupService,
    private modalService: NgbModal,
    private toaster:ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private viewportScroller: ViewportScroller,) { }




  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.spinner.show();
    this.route.queryParams.subscribe(res => {
      this.merchantId = +res['id'];
    })
    // this.getProductsBycat(catid)
    // ////////////////////////////////////(-----init cart------)//////////////////////////////////////
    this.getCartItems();
    this.cartService.cartUpdated.subscribe(res => {
      this.getCartItems();
    });
    this.getMerchantDetails();
    this.getMerchantReviews();
    // this.getFields();
    // ////////////////////////////////////(-----End cart------)//////////////////////////////////////
    this.lookupService.getMetaData('category').subscribe((res: any) => {
      let metaObj = res;
      this.metaService2.updateMeta({
        title: metaObj[0]?.meta_data_seo['ar'],
        description: metaObj[0]?.description_seo['ar'],
        url: window.location.href
      })
    },
    error=>{
      console.log(error);
    })

    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)
  }


  getMerchantDetails() {
    this.allproductservice.getMerchantDetails(this.merchantId).subscribe(
      res => {
        this.merchantData = res['merchant'];
        console.log('merchant Data:- ', this.merchantData);
        this.categories = this.merchantData?.categories;
        this.allproducts = res['products'];
        this.beforeSearch = res['products'];
        console.log('all Products', this.allproducts);
        this.spinner.hide();
      }
    );
  }

  getMerchantReviews(){
    this.allproductservice.getMerchantReviews(this.merchantId).subscribe(
      res=>{
        this.rates = res['data'].rates;
        this.reviews = res['data'].reviews;
        this.reviewsCount = res['data'].num_of_reviews;
      }
    );
  }
  // ////////////////////////////////////(-----start cart------)//////////////////////////////////////

  getCartItems() {
    this.isLoading = true;
    this.cartService.getItems().subscribe(
      (res:any) => {

        if(res['cart'].length > 0 || res['cart']!= {}){
          this.shoppingCartItems = res['cart'].product_items;
          this.total = res['cart'].total;
          this.subTotal = res['cart'].sub_total;
          this.cashback = res['cart'].cashback;
          this.deliveryfees = res['cart'].delivery_fees;
          console.log('TTTTTotal', this.shoppingCartItems);
          this.isLoading = false;
        }else{
          this.shoppingCartItems = [];
          console.log('shopping ', this.shoppingCartItems)
        }
        
      }
    );
  }

  // Remove cart items
  public removeItem(item: any) {
    this.isLoading = true;
    this.cartService.removeFromCart(item.id).subscribe(
      res=>{
        this.toaster.success(res['message']);
        this.cartService.cartUpdated.emit();
        this.getCartItems();
      }
    );
    // this.getCartItems();
  }
  // ////////////////////////////////////(-----End cart------)//////////////////////////////////////

  navigateCategory(cat) {
    if (cat.is_leaf || cat.is_leaf == null) {
      let query = cat.level == 1 ? "categories" : (cat.level == 2 ? 'subCategories' : "subSubCategories")
      this.router.navigate(['category'], {
        queryParams: {
          [query]: cat.id
        }
      })
    } else {
      let query = cat.level == 1 ? "cat_id" : (cat.level == 2 ? "sub_cat_id" : "sub_sub_cat_id");
      this.router.navigate(['categories'], {
        queryParams: {
          [query]: cat.id
        }
      })
    }
  }

  search(query: string) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (query) {
      this.searchSubscription = this.productsService.searchProducts(query).subscribe(res => {
        this.searchRes = res['data'];
      });
    }
    else {
      this.searchRes = [];
      // return;
    }
  }

  searchProducts(){
    console.log(this.searchQuery);
    this.allproducts = this.beforeSearch.filter(res => res.title.includes(this.searchQuery)
    );

    console.log("RESULT", this.allproducts);
  }

  openProduct(event, productID) {
    event.stopPropagation();
    this.searchQuery = '';
    this.searchRes = [];
    this.router.navigate(['product', productID]);
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
}

  // Add to cart
  public addToCart(product: Product, quantity: number = 1) {
    this.cartService.addToCart(product, quantity, this.fields);
    console.log("product===>", product);
    this.getCartItems();
    this.fields = [];
    this.modalService.dismissAll();
  }

  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    quantity = product.quantity + 1;
    this.cartService.updateCartQuantity(product, quantity, product.id).subscribe(
      res => {
        // this.toasterService.success(res['message']);
        this.getCartItems();
      },
      error => {
        console.log(error);
      }
    )

  }

  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    quantity = product.quantity - 1;
    this.cartService.updateCartQuantity(product, quantity, product.id).subscribe(
      res => {
        // this.toasterService.success(res['message']);
        this.getCartItems();
      },
      error => {
        console.log(error);
      }
    )
  }

  public increment1() {
    this.counter += 1;
  }

  public decrement1() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  gotoCheckout(){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/payments/checkout', this.lang]);
  }

  open(content, product) {
    this.theProduct = product;
    this.customeFields = product.custom_fields
    console.log('The', this.theProduct);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // getFields(){
  //   this.productsService.getCustomFields().subscribe(
  //     res=>{
  //       this.customeFields = res['fields'];
  //     }
  //   );
  // }

  getCheckboxValues(ev, field, id) {

    console.log(field)
    let value;
    value = field.price_value

    let obj:any = {};
    obj.product_field_id = id;
    obj.selected_values = [{"field_option_id":field.field_option_id, "value":value}];
    if(ev.target.checked){
      this.fields.push(obj);
    }else {
      let removeIndex = this.fields.findIndex(itm => itm.selected_values[0].field_option_id===obj.selected_values[0].field_option_id);

      if(removeIndex !== -1)
        this.fields.splice(removeIndex,1);
    }
    console.log(this.fields);
  }

  handleChange(event, field){
    console.log(event.target.value);
    console.log(field);
    let value;
    field.map.forEach(
      element=>{
        if(element.field_option_id == event.target.value){
          value = element.price_value
        }
      }
    );
    let obj:any = {};
    obj.product_field_id = field.id;
    obj.selected_values = [{"field_option_id":event.target.value, "value":value}];
    if(this.fields.length > 0){
      this.fields.forEach(element=>{
        if(element.product_field_id == field.id){
          
            let index = this.fields.indexOf(element);
            console.log('index', index)
            this.fields.splice(index, 1);
            this.fields.push(obj);
          
        }else{
          this.fields.push(obj);
        }
      });
    }else{
      this.fields.push(obj);
    }
    
    console.log('Fields', this.fields);
  }

  gotoBranches(id){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/instore', this.lang], {queryParams:{id:id}});
  }

}
