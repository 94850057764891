import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { SharedModule } from '../shared/shared.module';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import { Login6Component } from './pages/auth_six/login6/login6.component';
import { Signup6Component } from './pages/auth_six/signup6/signup6.component';
import { ForgetPass6Component } from './pages/auth_six/forget-pass6/forget-pass6.component';
import { VerifyPhoneComponent } from './pages/verify-phone/verify-phone.component';


@NgModule({
 
  imports: [
    CommonModule, 
    AuthenticationRoutingModule,
    SharedModule
  ],
  declarations: [
      LoginComponent,
      // TestComponent,
      ChangePassComponent,
      RegisterComponent,
      ForgetPasswordComponent,
      Login6Component,
      Signup6Component,
      ForgetPass6Component,
      VerifyPhoneComponent,
    ],

  exports:[
    // TestComponent
    ChangePassComponent
  ]
})
export class AuthenticationModule { }
