<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>
    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Performance Report" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="seperator"></div>

        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-md-3 res">
              <mat-form-field
                appearance="outline"
                style="background-color: white"
              >
                <!-- <mat-label>{{'select status' | translate}}</mat-label> -->
                <mat-select
                  (selectionChange)="changeSelection($event)"
                  [(ngModel)]="selectedID"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option [value]="1">
                    {{ "last 7 days" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "last 30 days" | translate }}
                  </mat-option>

                  <mat-option [value]="3">
                    {{ "Current month" | translate }}
                  </mat-option>
                  <mat-option [value]="4">
                    {{ "Previous month" | translate }}
                  </mat-option>
                  <mat-option [value]="5">
                    {{ "Last 3 months" | translate }}
                  </mat-option>

                  <mat-option [value]="6" (click)="ShowDatePicker()">
                    {{ "Custom" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3 res">
              <div class="" *ngIf="Hide == false">
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <mat-label>{{ "Choose date" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="date"
                    [matDatepicker]="picker"
                    (dateChange)="getStartDate('change', $event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3 res">
              <div class="" *ngIf="Hide == false">
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <mat-label>{{ "Choose end date" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="endDate"
                    [matDatepicker]="picker"
                    (dateChange)="getEndDate('change', $event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >

            <div class="btns">
              <button (click)="reset()" class="btn btn-success">
                {{ "Reset" | translate }}
              </button>

              <button
                (click)="printAll()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>

        <mat-spinner *ngIf="isLoading && !reports"></mat-spinner>
        <!-- <app-table 
              [reports]="reports" 
              [fields]="displayedColumns" 
              [length]="length" 
              [perPage]="perPage"
              [hasPagination]="hasPagination"></app-table> -->

        <div class="text-center" *ngIf="reports?.length == 0 && !isLoading">
          {{ "NO_ORDERS" | translate }}
        </div>
        <!-- <mat-spinner *ngIf="!isLoading"></mat-spinner> -->
        <div
          style="overflow-x: auto"
          id="element"
          *ngIf="!isLoading && reports?.length > 0"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ "date" | translate }}</th>
                <th scope="col">{{ "orders" | translate }}</th>
                <th scope="col">{{ "Successed" | translate }}</th>
                <th scope="col">{{ "Cancelled" | translate }}</th>
                <th scope="col">{{ "sales" | translate }}</th>
                <th scope="col">{{ "Cashback" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of reports">
                <td>{{ item.date }}</td>
                <td>{{ item.orders_count }}</td>
                <td>{{ item.successful_orders_count }}</td>
                <td>{{ item.failed_orders_count }}</td>
                <td>{{ item.total_sales - item.cancellations_amount }}</td>
                <td>
                  {{
                    round(item.total_cashback - item.total_cancelation_cashback)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="text-center">
                {{'NO_ORDERS' | translate}}
            </div> -->

        <!-- <div class="row">
              <div class="w-100 pagination mt-3 text-center" *ngIf="orders?.length > 0">
                <mat-paginator 
                  class="w-100"
                  (page)="handlePageEvent($event)"
                  [length]="totalCount"
                  [pageSize]="perPage"
                  [pageSizeOptions]="perPage">
                </mat-paginator>
              </div>
            </div> -->
      </div>
    </div>
  </div>
</div>
