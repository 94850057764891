import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
declare var $: any;

@Component({
  selector: 'app-categories6',
  templateUrl: './categories6.component.html',
  styleUrls: ['./categories6.component.css']
})
export class Categories6Component implements OnInit {

  categories: any[];
  lang:string;

  constructor(
    private categoriesService: CategoriesService, 
    private translate: TranslateService,
    private router: Router) { }
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang); 
    this.lang = this.translate.currentLang;
    // $.getScript('assets/js/menu.js');
     /* this.menuItems = MENUITEMS.filter(menuItem => menuItem); */
     this.categoriesService.getCategories().subscribe((res: any[]) => {
       this.categories = res['categories'];

      //  console.log(this.categories)
     })

  }

  gotoCategories(id: number){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang], {queryParams:{'categories': id}});
  }
  

}
