import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
declare var $: any;
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  ordersList = [1]
  theOrder: any;
  lang;
  orderStatuses: any[] =[];
  status:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: CheckoutService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang);
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999);

    this.getOrderStatuses();

    this.route.queryParams.subscribe(
      res=>{
        console.log("res", res['id'])
        this.getOrder(+res['id']);
      }
    );

  }

  showOrders(i) {
    console.log("ioiioio",i)
    let show  = $(`#orders_list_body_${i}`).attr('show');
    if(show == 'false') {
      $(`#orders_list_body_${i}`).attr("show","true")
      $(`#orders_list_body_${i}`).removeClass('bounceOutUp');

      $(`#orders_list_body_${i}`).addClass('animated bounceInDown');
      $(`#orders_list_body_${i}`).removeClass('display--none');
      return;
    }
    $(`#orders_list_body_${i}`).attr("show","false")
    $(`#orders_list_body_${i}`).removeClass('bounceInDown');
    $(`#orders_list_body_${i}`).addClass('bounceOutUp');
    setTimeout(function(){
      $(`#orders_list_body_${i}`).addClass('display--none');
    },1000)

    
    console.log(i)
   
  }

  getOrder(id: number){
    this.ordersService.getOrderById(id).subscribe(
      res=>{
        console.log("The Order", res);
        this.theOrder = res;
        this.orderStatuses.forEach(ele=>{
          if(ele.id == this.theOrder.order_status_id){
            this.status = ele.title.ar;
          }
        });
      }
    );
  }

  getOrderStatuses(){
    this.ordersService.getOrderStatuses().subscribe(
      (res: any)=>{
        this.orderStatuses = res;
      }
    );
  }


}
