import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { Menu, MENUITEMS } from './navbar-items';

@Component({
  selector: 'app-navbar6',
  templateUrl: './navbar6.component.html',
  styleUrls: ['./navbar6.component.css']
})
export class Navbar6Component implements OnInit {

  public menuItems: Menu[];
  public toggleNavBar : boolean = false;

  categories: any[];
  lang:string;
  

  constructor(private categoriesService: CategoriesService,
              private translate: TranslateService,
              private router:Router) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
    // this.categoriesService.getCategories().subscribe((res: any[]) => {
    //   this.categories = res['categories'];
    //   console.log(this.categories)
    //   console.log("categoriess",this.categories);
    //   console.log("Categories Length", res['categories'].length)
    // })
    this.getData();

  }

   //  start categories
   getData() {
    this.categoriesService.getCategories().subscribe((result: any[]) => {
      this.categories = result['categories'];
      for (let index = 0; index < result['categories'].length; index++) {
        const category = result['categories'][index];
        const dataToPush = {
          title: category.name.En,
          // type: category.sub_categories.length > 0 ? "sub" : "link",
          type:  "link",

         
        };
        if (dataToPush.type === "link") {
          dataToPush["path"] = `/category/${category.id}`;
        }
        // this.menuItems.push(dataToPush);
        // console.log("menu items",this.menuItems)
      }
      // setTimeout(this.intializeSmartMenue, 1000);
    });
  }

  toggleNav() {
    this.toggleNavBar = !this.toggleNavBar
  }

  
  navigateCategory(cat) {
    let routs:any[] = this.router.url.split('/');
    if (cat.is_leaf || cat.is_leaf == null) {
      let query = cat.level == 1 ? "categories" : (cat.level == 2 ? 'subCategories' : "subSubCategories")
      this.router.navigate([routs[1] + '/category', this.lang], {
        queryParams: {
          [query]: cat.id
        }
      })
    } else {
      let query = cat.level == 1 ? "cat_id" : (cat.level == 2 ?"sub_cat_id":"sub_sub_cat_id");
      this.router.navigate([routs[1] + '/categories', this.lang], {
        queryParams: {
          [query]: cat.id
        }
      })
    }
  }
}
