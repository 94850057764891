<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 text-left">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a (click)="gotoHome()">{{ "home" | translate }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "verifyOTP" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
</div> -->
<!-- breadcrumb End -->
<!-- section start -->
<section class="pwd-page">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/images/sahlalogo.png" alt="Sahla Logo" />
            </div>

            <div class="col-lg-6 offset-lg-3">
                <h2>{{ "verifytransaction" | translate }}</h2>
                <form [formGroup]="confirmTransaction" class="theme-form">
                    <div class="form-row">

                        <!-- <div class="col-md-12">
                            <input formControlName="transaction_id" type="text" class="form-control" id="email"
                                placeholder="{{ 'Transaction number' | translate }}" />


                            <span 
                                *ngIf="confirmTransaction.controls['transaction_id'].touched && confirmTransaction.controls['transaction_id']?.errors?.required"
                                class="error-msg">
                                {{ "transactionIdReqMes" | translate }}
                            </span>
                        </div> -->

                        <div class="col-md-12">
                            <input formControlName="amount" type="text" class="form-control" id="price"
                                placeholder="{{ 'Transaction total price' | translate }}" />


                            <span 
                                *ngIf="confirmTransaction.controls['amount'].touched && confirmTransaction.controls['amount']?.errors?.required"
                                class="error-msg">
                                {{ "transactionAmountReqMes" | translate }}
                            </span>
                        </div>
                        <button [disabled]="!confirmTransaction.valid" (click)="verifyOtpCode()"
                            class="btn btn-solid form-control">
                            {{ "submit" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->