<!-- Tab product -->
<!-- <div class="title1 section-t-space">

	<h4>{{'exclusiveproducts'|translate}}</h4>
	<h2 class="title-inner1">{{'specialproducts'|translate}}</h2>
</div> -->
<section class="section-b-space p-t-0">
	<div class="container">
		<div class="row m-auto">
			<div *ngIf="homeData?.products" class="col-md-12 col-sm-12 col-xs-12">
				<h2  class="section-title">
					<span>

						{{'recommendedproducts'|translate}}
					</span>
				</h2>
				<div id="tab-4" class="tab-content active default">
					<div class="no-slider">
						<!-- <app-product-slider-five [products]="homeData?.products"></app-product-slider-five> -->
						<app-special-products-five [products]="homeData?.products"></app-special-products-five>

					</div>
				</div>
			</div>
			<div *ngIf="homeData?.products" class="col-md-12 col-sm-12 col-xs-12">
				<h2 class="section-title">
					<span>

						{{'arrivalproducts'|translate}}
					</span>
				</h2>
				<div id="tab-5" class="tab-content">
					<div class=" no-slider">
					 <!-- <app-product [products]="homeData?.products"></app-product>  -->
					 <app-special-products-five [products]="prods"></app-special-products-five>
					</div>
				</div>
			</div>
			<!-- <div *ngIf="homeData?.offers.length > 0" class="col-md-12 col-sm-12 col-xs-12">
				<h2 class="title-inner1 text-center">{{'offersproducts'|translate}}</h2>
				<div id="tab-6" class="tab-content">
					<div class=" no-slider">
						<app-special-products-five [products]="homeData?.offers"></app-special-products-five>
						<app-collection-banner-five [offers]="homeData?.offers" style="width:100%"></app-collection-banner-five>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</section>
<!-- Tab product end -->
