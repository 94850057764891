<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
      <div class="row">
        <div class="col-sm-6 text-left">
            <nav aria-label="breadcrumb" class="theme-breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{'Profile' | translate}}</li>
                </ol>
            </nav>
        </div>
          
      </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->

<!-- <section class="section-b-space" [ngClass]="profileClass">
  <div class="container">
      <div class="row">
          <div class="col-lg-3">
              <div class="account-sidebar">
                  <a class="popup-btn">
                     {{'Myaccount' |translate}}
                     
                  </a>
              </div>
              <div class="dashboard-left">
                  <div class="collection-mobile-back">
                      <span class="filter-back">
                          <i class="fa fa-angle-left" aria-hidden="true"></i>
                          {{'back'|translate}}
                      </span>
                  </div>
                  <div class="block-content">
                      <ul> 
                          <li class="active">
                              <a (clcik)="gotoProfile()">{{'Accountinfo'| translate}}</a></li>
                          <li><a (click)="gotoOrders()">{{'orders'| translate}}</a></li>
                          <li><a (click)="gotoEditProfile()"> {{'updateyouraccount'| translate}}</a></li>
                          <li><a (click)="gotoChangePassword()">{{'changepassword'| translate}}</a></li>
                          <li *ngIf="type == 'customer'"><a (click)="gotoAddress()">{{'MyAddress'| translate}}</a></li>
                          <li *ngIf="type == 'customer'"><a (click)="gotoWallet()">{{'Wallet'| translate}}</a></li>
                          <li *ngIf="type == 'customer'"><a (click)="gotoPaymet()">{{'Payment Methods'| translate}}</a></li>
                          <li class="last"><a  (click)="logout()">{{'logout'|translate}}</a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-lg-9">
              <div class="dashboard-right">
                  <div class="dashboard">
                      <div class="box-account box-info">
                          <div class="box-head">
                              <h2>
                                  {{'Accountinfo'| translate}}
                                </h2>
                          </div>
                          <div class="row">
                              <div class="col-sm-12">
                                  <div class="box">
                                      <div class="box-title">
                                          <h3>
                                              {{'Contactinfo'| translate}}
                                          </h3>
                                          <button (click)="gotoEditProfile()" class="edit_btn">
                                            {{'updateyouraccount'| translate}}
                                          </button>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="box-content">
                                                <ul
                                                    class="js-uploader__file-list uploader__file-list">
                                                    <li 
                                                        class=" js-uploader__file-list uploader__file-list">
                                                        <span
                                                            class="uploader__file-list__thumbnail">
                                                            <img  *ngIf="image != null" class="thumbnail" id="img_"
                                                                src={{image}}>
                                                            <img *ngIf="image == null" class="thumbnail" id="img_"
                                                                src="https://www.kindpng.com/picc/m/451-4517876_default-profile-hd-png-download.png">
                                                        </span>
                                                     
                                                    </li>
                                                </ul>
                                                <h6><span class="font-bold">{{'Name' | translate}}: </span>{{name}}</h6>
                                                <h6><span class="font-bold">{{'email' | translate}}: </span>{{email}}</h6>
                                                <h6><span class="font-bold">{{'phoneno' | translate}}: </span>{{mobile}}</h6>
                                                <h6 *ngIf="address"><span class="font-bold">{{'address' | translate}}: </span>{{address}}</h6>
                                                <h6 *ngIf="gender">
                                                    <span class="font-bold">{{'gender' | translate}}: </span>
                                                    <span *ngIf="gender == 'm'">{{'Male' | translate}}</span>
                                                    <span *ngIf="gender == 'f'">{{'Female' | translate}}</span>
                                                </h6>
                                                <h6 *ngIf="birthdate"><span class="font-bold">{{'birthdate' | translate}}: </span>{{birthdate | date}}</h6>
                                            </div>
                                          </div>
                                          
                                      </div>
                                      
                                  </div>
                              </div>
                          </div>
                          <div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</section> -->

<div class="container">
    <mat-tab-group dynamicHeight (selectedTabChange)="getSelectedTab($event)">
        <mat-tab label="{{'Accountinfo'| translate}}">
            
            <div class="box-account box-info">
                <div class="box-head">
                    <h2>
                        {{'Accountinfo'| translate}}
                      </h2>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="box">
                            <div class="box-title">
                                <h3>
                                    {{'Contactinfo'| translate}}
                                </h3>
                                <!-- <button (click)="gotoEditProfile()" class="edit_btn">
                                  {{'updateyouraccount'| translate}}
                                </button> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12">
                                  <div class="box-content">
                                      <ul
                                          class="js-uploader__file-list uploader__file-list">
                                          <li 
                                              class=" js-uploader__file-list uploader__file-list">
                                              <span
                                                  class="uploader__file-list__thumbnail">
                                                  <img  *ngIf="image != null" class="thumbnail" id="img_"
                                                      src={{image}}>
                                                  <img *ngIf="image == null" class="thumbnail" id="img_"
                                                      src="https://www.kindpng.com/picc/m/451-4517876_default-profile-hd-png-download.png">
                                              </span>
                                           
                                          </li>
                                      </ul>
                                      <h6><span class="font-bold">{{'Name' | translate}}: </span>{{name}}</h6>
                                      <h6><span class="font-bold">{{'email' | translate}}: </span>{{email}}</h6>
                                      <h6><span class="font-bold">{{'phoneno' | translate}}: </span>{{mobile}}</h6>
                                      <h6 *ngIf="address"><span class="font-bold">{{'address' | translate}}: </span>{{address}}</h6>
                                      <h6 *ngIf="gender">
                                          <span class="font-bold">{{'gender' | translate}}: </span>
                                          <span *ngIf="gender == 'm'">{{'Male' | translate}}</span>
                                          <span *ngIf="gender == 'f'">{{'Female' | translate}}</span>
                                      </h6>
                                      <h6 *ngIf="birthdate"><span class="font-bold">{{'birthdate' | translate}}: </span>{{birthdate | date}}</h6>
                                  </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
          
        </mat-tab>
        <mat-tab label="{{'orders'| translate}}">
            <app-orders></app-orders>
        </mat-tab>
    
        <mat-tab label="{{'updateyouraccount'| translate}}">
            <app-profile-update></app-profile-update>
       
        </mat-tab>
    
        <mat-tab label="  {{'changepassword'| translate}}">
           <app-change-pass></app-change-pass>
        </mat-tab>
    
        <mat-tab label="{{'MyAddress'| translate}}">
            <app-address></app-address>
        </mat-tab>

        <mat-tab label="{{'Wallet'| translate}}">
            <app-wallet-page></app-wallet-page>
        </mat-tab>

        <mat-tab label="{{'Payment Methods'| translate}}">
            <app-payment-methods></app-payment-methods>
        </mat-tab>
        
      </mat-tab-group>
</div>