import { Component, DoCheck, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';
import { DOCUMENT } from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { ChangeDialogComponent } from '../change-dialog/change-dialog.component';

@Component({
  selector: 'app-header-talabt',
  templateUrl: './header-talabt.component.html',
  styleUrls: ['./header-talabt.component.scss']
})
export class HeaderTalabtComponent implements OnInit, DoCheck {
  lang: string;
  routs:any[];
  token;
  notifications;
  notifs;
  notification_count;
  adminNotification;
  isLogged: boolean = false;
  fromLocal:  string = '';
  id;
  merchants:any[] = [];
  showMenu: boolean = true;
  defaultAddress:any;
  searchQuery: string;
  query:string = '';
  searchSubscription: any;
  areaName: any;
  url:any;
  constructor(
    public translate: TranslateService,
    private router: Router,
    public notifiservice: NotificationsService,
    private toastrService: ToastrService,
    private userService: UserService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document) { }
  ngDoCheck(): void {
    this.url = this.router.url.split('/');
  }


  openDialog() {
    const dialogRef = this.dialog.open(ChangeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  @HostListener('window:scroll', [])
  
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('home-header').classList.add('sticky-header');
    } else {
      document.getElementById('home-header').classList.remove('sticky-header');

    }
  }


  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
  
    this.url = this.router.url.split('/')
    console.log('sssssssssssssssssssssssssssssssssss', this.url)

    this.url = this.router.url.split('/');
    console.log("UUURRRLLL", this.url);

    this.userService.hasDefaultAddress.subscribe(
      res=>{
        console.log("THE Response is:- ", res);
        if(res == true){
          this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
          console.log("DEFAULT", this.defaultAddress);
          this.id = this.defaultAddress.area.id;
          this.areaName = this.defaultAddress.area.name;
        }else{
          this.id = +localStorage.getItem('area_id');
          this.areaName = JSON.parse(localStorage.getItem('area_name'));
        }
      }
    );

    this.userService.areaChanged.subscribe(
      res=>{
        if(res == true){
          this.areaName = JSON.parse(localStorage.getItem('area_name'));
        }
      }
    );
    if(localStorage.getItem('userObject')){
      this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
    }
    if(this.defaultAddress != null){
      this.id = this.defaultAddress.area.id;
      this.areaName = this.defaultAddress.area.name;
    }else{
      this.id = +localStorage.getItem('area_id');
      this.areaName = JSON.parse(localStorage.getItem('area_name'));
      // this.route.queryParams.subscribe(
      //   res=>{
      //     this.id = res['areas'];
      //   }
      // );
    }

    this.userService.isLogged.subscribe(
      res=>{
        this.isLogged = res;
        console.log('Thr Res', res);
        if(res == true){
          localStorage.setItem('isLogged', 'true');
        }else{
          localStorage.setItem('isLogged', 'false');
          this.fromLocal = 'false';
        }
      }
    );
    this.fromLocal = localStorage.getItem('isLogged')
    this.token = localStorage.getItem('token');

    if (this.token != null) {
      this.notifiservice.getNotification().subscribe((res: any) => {
        this.notifications = res;
        this.notifs = res.data;
        this.notifs = this.notifs.reverse();
        this.notification_count = this.notifications.data.length;
        console.log("The Notifications =======>", this.notifs)
      });

      this.notifiservice.getAdminNotification().subscribe( (res: any)=> {
        this.adminNotification = res.reverse();
        console.log('Admin Notification', this.adminNotification)
      });
    }
  }

  changeLang(lang) {
    console.log(lang)
      if(lang == 'en') {
        this.lang = 'ar'
        $("body").addClass('rtl');
        this.translate.use('ar');
        localStorage.setItem("lang_ref",'ar')
        window.location.reload()
      }
      else {
        this.lang = 'en';
        $("body").removeClass('rtl');
        $("body").addClass('ltr');
        this.translate.use('en');
        localStorage.setItem("lang_ref",'en')
        // console.log("language => ",this.translate.getDefaultLang())
        window.location.reload()
      }

      console.log("final lang => ",this.lang)

  }
  search(query){
    if(this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if(query){
      console.log('Query', query);
      this.userService.search(query, this.id).subscribe(
        res=>{
          console.log('RES', res);
          this.merchants = res['merchants'].data;
        }
      );
    }else{
      this.merchants = [];
    }
  }

  menuHide(event) {
    this.showMenu = false;
  }

  menuShow() {
    this.showMenu = true;
  }

  gotoHome(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/search', this.lang]);
  }

  gotoHome2(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/', this.lang]);
  }

  gotoCategories(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/categories', this.lang]);
  }

  gotoInstore(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/instore', this.lang]);
  }

  gotoOrders(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/orders', this.lang]);
  }

  gotoMerchant(event, id){
    event.stopPropagation();
    this.searchQuery = '';
    this.merchants = [];
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/merchant-details', this.lang], {queryParams:{'id': id}});
  }

  gotoContact(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/contact', this.lang]);
  }

  deleteNotification(id) {

    this.notifiservice.deleteNotification(id).subscribe(res => {
      if (this.lang == 'en') {
        this.toastrService.success("This Notification Has Been Deleted Successfully"); // toasr services
      }
      else if (this.lang == 'ar') {
        this.toastrService.success("تم مسح هذا الاشعار بنجاح"); // toasr services
      }
      window.location.reload();

    })
  }

  gotoSearch(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/', this.lang]);
  }
}
