<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3 header">
        <div class="header-content">
          <mat-icon (click)="back()" *ngIf="lang == 'en'">arrow_back</mat-icon>
          <mat-icon (click)="back()" *ngIf="lang == 'ar'"
            >arrow_forward</mat-icon
          >
          <div>
            <h2>
              {{ "menuoperation" | translate }}
            </h2>
            <h3>
              {{ merchantName }}
            </h3>
          </div>
        </div>
        <div>
          <button
            mat-raised-button
            class="save"
            (click)="editCat.isDraft ? editDraft() : addProduct()"
          >
            {{ "save" | translate }}
          </button>
        </div>
      </div>
      <div class="mx-3 px-3 my-3">
        <mat-tab-group animationDuration="0ms">
          <mat-tab [label]="'Item Details' | translate">
            <div class="row">
              <div class="col-2">
                <label> {{ "Item Image" | translate }} </label>
              </div>
              <div class="col-10">
                <div class="image-upload" (click)="file.click()">
                  <svg
                    class="webapp_global-MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"
                    ></path>
                  </svg>
                  <p *ngIf="!image_url">{{ "Add item image" | translate }}</p>
                  <span *ngIf="!image_url">
                    {{
                      "Image Size: 400 x 300 px / Image formats: jpg, png / 5MB Max."
                        | translate
                    }}
                  </span>
                  <p *ngIf="image_url">
                    {{
                      this.image_url?.name
                        ? this.image_url?.name
                        : this.image_url
                    }}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-2">
                <label> {{ "Item Name" | translate }} </label>
              </div>
              <div class="col-10 input-names">
                <mat-form-field appearance="outline">
                  <mat-label>Engilsh</mat-label>
                  <input matInput [(ngModel)]="editCat.title_en" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>عربي</mat-label>
                  <input matInput [(ngModel)]="editCat.title_ar" />
                </mat-form-field>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-2">
                <label> {{ "Item Description" | translate }} </label>
              </div>
              <div class="col-10 desc">
                <mat-form-field appearance="outline">
                  <mat-label>English</mat-label>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5"
                    [(ngModel)]="editCat.content_en"
                  ></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>عربي</mat-label>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5"
                    [(ngModel)]="editCat.content_ar"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-2">
                <label> {{ "PRICE" | translate }} </label>
              </div>

              <div class="col-10 price_wrapper">
                <div class="price">
                  <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="editCat.price" type="number" />
                  </mat-form-field>
                  <label>{{ "EGP" | translate }}</label>
                </div>
                <mat-slide-toggle>{{
                  "Price on selection" | translate
                }}</mat-slide-toggle>
              </div>


            </div>

            <div class="row">
             

              <div class="col-12 price_wrapper">
                <div class="price">
                  <input type="checkbox" [(ngModel)]="editCat.is_draft" />
                  <label style="padding: 8px 10px 0px 10px;"> Is Draft </label>

                  <!-- <input  [(ngModel)]="editCat.is_draft" type="radio" (click)="Draft($event)"> -->
                  <!-- <mat-form-field appearance="outline">
                     <mat-radio-button (click)="Draft($event)"></mat-radio-button>
                     <input matInput [(ngModel)]="editCat.is_draft" value="1" style="display: none;" />

                  </mat-form-field> -->

                </div>
               
              </div>
            </div>

          </mat-tab>
          <mat-tab [label]="'Item Choices' | translate">
            <div class="btn_wrapper">
              <button
                mat-raised-button
                class="add_btn"
                (click)="custom_fields.push({ choices: [] })"
              >
                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                </svg>
                <span class="plugin-muiv4-MuiButton-label">
                  {{ "ADD NEW CHOICE CATEGORY" | translate }}</span
                >
              </button>
            </div>
            <div *ngFor="let item of custom_fields; let i = index">
              <mat-card>
                <mat-card-title>{{
                  "Category Name" | translate
                }}</mat-card-title>
                <hr />
                <div class="alert alert-primary" role="alert">
                  {{
                    "Choose your choice category name from suggestions as you type to make your menu changes faster. These match menu guidelines and won’t need review."
                      | translate
                  }}
                </div>
                <div class="row">
                  <div class="col-2">
                    <label> {{ "Choice Category" | translate }} </label>
                  </div>
                  <div class="col-9">
                    <div class="input-names">
                      <mat-form-field appearance="outline">
                        <mat-label>Engilsh</mat-label>
                        <input matInput [(ngModel)]="item.name_en" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>عربي</mat-label>
                        <input matInput [(ngModel)]="item.name_ar" />
                      </mat-form-field>
                    </div>
                    <div class="row input-names">
                      <div class="col">
                        <h4>{{ "Min" | translate }}</h4>
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="item.min_qty">
                            <mat-option value="0">0</mat-option>
                            <mat-option value="1">1</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <h4>{{ "Max" | translate }}</h4>
                        <mat-form-field
                          appearance="outline"
                          [(ngModel)]="item.max_qty"
                        >
                          <mat-select>
                            <mat-option value="0">0</mat-option>
                            <mat-option value="1">1</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col">
                        <mat-icon
                          [matTooltip]="
                            'Min should be Higher than Max' | translate
                          "
                          >info</mat-icon
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <mat-icon (click)="deleteCat(i)" class="delete"
                      >delete</mat-icon
                    >
                  </div>
                  <mat-card-actions class="btn_wrapper">
                    <button
                      mat-raised-button
                      class="add_btn"
                      (click)="item?.choices.push({})"
                    >
                      <svg
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                      </svg>
                      <span class="plugin-muiv4-MuiButton-label">
                        {{ "ADD NEW CHOICE" | translate }}
                      </span>
                    </button>
                  </mat-card-actions>
                </div>
                <br *ngIf="item?.choices?.length > 0" />
                <div *ngFor="let it of item.choices; let j = index">
                  <mat-card>
                    <mat-card-title>{{
                      "Choice Name" | translate
                    }}</mat-card-title>
                    <div class="row">
                      <div class="col-2">
                        <label> {{ "Choice" | translate }} </label>
                      </div>
                      <div class="col-9">
                        <div class="input-names">
                          <mat-form-field appearance="outline">
                            <mat-label>Engilsh</mat-label>
                            <input matInput [(ngModel)]="it.name_en" />
                          </mat-form-field>
                          <mat-form-field appearance="outline">
                            <mat-label>عربي</mat-label>
                            <input matInput [(ngModel)]="it.name_ar" />
                          </mat-form-field>
                        </div>
                        <div class="row input-names">
                          <div class="col">
                            <div class="price">
                              <mat-form-field appearance="outline">
                                <input
                                  matInput
                                  type="number"
                                  [(ngModel)]="it.price"
                                />
                              </mat-form-field>
                              <label>{{ "EGP" | translate }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1">
                        <mat-icon (click)="deleteProd(i, j)">delete</mat-icon>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </mat-card>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
<input
  (change)="handleReaderLoaded($event.target.files)"
  hidden
  type="file"
  #file
  placeholder="Upload"
  id="profile_img"
/>
