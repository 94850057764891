<div class="wallet-cash_ballance">
    <div class="wallet-row justify-content-between">
        <p class="text-bold ">{{'Wallet Balance' | translate}} </p>
        <button class="btn btn-primary" (click)="open(content, 'redeem')">
            {{'Redeem' | translate}}
        </button>

        <button class="btn btn-primary" (click)="open(content, 'collect'); collect()">
            {{'Collect' | translate}}
        </button>
    </div>
    <div class="wallet-row mb-4">
        <img src="./assets/images/wallet.png" alt="wallet">
        <h2 class="h2-text">{{'EGP' | translate}} {{totalBalance}}</h2>
    </div>
    <!-- <p>Including {{'EGP' | translate}} 0 {{'Cashback' | translate}}</p> -->
    <p class="text-muted">{{'To cashout you must earn at least EGP 100' | translate}}</p>
</div>


<div class="sec-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 m-auto">
                <div class="wallet-body">
                    <div class="transactions-header">
                        <p class="text-bold">{{'Transactions' | translate}}</p>
                    </div>
                    <div *ngIf="transactions?.length > 0">
                        <div class="transaction-row" *ngFor="let transaction of transactions">
                            <div class="flex-div">
                                <div class="img-div">
                                    <img src="./assets/images/wallet.png" alt="">
                                    <p>Bonus Cashback</p>
                                </div>
                                <div>
                                    <p>{{'Transaction' | translate}}: #{{transaction?.id}}</p>
                                    <p>{{'Merchant' | translate}}: {{transaction?.merchant?.name}}</p>
                                    <p class="text-muted">{{transaction?.created_at | date:'short'}}</p>
                                    <p *ngIf="transaction?.type == 'credit'">{{'Reward' | translate}} <span style="color: lightblue;">{{transaction?.wallet_amount}} {{'EGP' | translate}}</span></p>
                                    <p *ngIf="transaction?.type == 'debit'">{{'Redeem' | translate}} <span style="color: lightcoral;">{{transaction?.wallet_amount}} {{'EGP' | translate}}</span></p>
                                </div>
                            </div>
                            <div class="text-right">
                                <p class="text-green">{{transaction?.total_amount}} {{'EGP' | translate}}</p>
                                <p class="text-muted">{{transaction?.latest_status?.status}}</p>
                            </div>
                        </div>
                        <div></div>
                    </div>

                    <div *ngIf="transactions?.length == 0">
                        {{'No Transactions' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 *ngIf="isCollect" class="modal-title" id="modal-basic-title">{{'Collect'|translate}}</h4>
        <h4 *ngIf="isRedeem" class="modal-title" id="modal-basic-title">{{'Redeem'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="isCollect">
            <p>{{'Please disclose Your OTP to the cashier' | translate}}</p>
            <p *ngIf="otp || otp > 0" class="otp">{{otp}}</p>
            <button class="btn btn-primary" (click)="close()">{{'Done' | translate}}</button>
        </div>

        <div *ngIf="isRedeem">
            <h4 class="text-center">Redeem</h4>
            <p>Enter the amount you want to redeem</p>
            <input type="text" [(ngModel)]="amount" placeholder="Enter the Amount" class="form-control"/>
            <button class="btn btn-primary" type="button" (click)="redeem(amount)">{{'Redeem' | translate}}</button>

            <div *ngIf="redeemOtp || redeemOtp > 0">
                <p>{{'Please disclose Your OTP to the cashier' | translate}}</p>
                <p class="otp">{{redeemOtp}}</p>
                <button class="btn btn-primary" (click)="close()">{{'Done' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="saveAmount()" style="border-radius: 20px;">{{'DONATE NOW'|translate}}</button> -->
    </div>
</ng-template>