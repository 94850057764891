import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { HttpLoaderFactory } from '../app.module';
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { AgeVerificationComponent } from "./product/widgets/age-verification/age-verification.component";
import { AgmCoreModule } from "@agm/core";
import { BarRatingModule } from "ngx-bar-rating";
import { BlogDonationComponent } from "./New_Components/blog-donation/blog-donation.component";
import { BrandComponent } from "./product/collection/filter/brand/brand.component";
import { Cart6Component } from "./product/cart6/cart6.component";
import { CartComponent } from "./product/cart/cart.component";
import { Categories6Component } from "./footer/widgets/categories6/categories6.component";
import { CategoriesComponent } from "./product/widgets/categories/categories.component";
import { CategoriesFooterComponent } from "./footer/widgets/categories/categories.component";
import { ChangeDialogComponent } from "./header/change-dialog/change-dialog.component";
import { CheckoutComponent } from "./product/checkout/checkout.component";
import { CollectionLeftSidebarComponent } from "./product/collection/collection-left-sidebar/collection-left-sidebar.component";
import { CollectionNoSidebarComponent } from "./product/collection/collection-no-sidebar/collection-no-sidebar.component";
import { CollectionRightSidebarComponent } from "./product/collection/collection-right-sidebar/collection-right-sidebar.component";
import { ColorComponent } from "./product/collection/filter/color/color.component";
import { CommonModule } from "@angular/common";
import { CopyrightComponent } from "./footer/widgets/copyright/copyright.component";
import { EhdaaComponent } from "./New_Components/ehdaa/ehdaa.component";
import { ExitPopupComponent } from "./product/widgets/exit-popup/exit-popup.component";
// New Components
import { FastDonationComponent } from "./New_Components/fast-donation/fast-donation.component";
import { FooterOneComponent } from "./footer/footer-one/footer-one.component";
import { GoogleMapComponent } from "./New_Components/google-map/google-map.component";
import { HeaderTalabtComponent } from "./header/header-talabt/header-talabt.component";
import { HttpClientInterceptor } from "./services/http-client.interceptor";
import { HttpClientService } from "./services/http-client.service";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { InformationComponent } from "./footer/widgets/information/information.component";
import { InstagramService } from "./services/instagram.service";
import { LandingFixService } from "../shared/services/landing-fix.service";
import { LeftMenuComponent } from "./header/widgets/left-menu/left-menu.component";
import { LeftSidebarComponent } from "./header/left-sidebar/left-sidebar.component";
import { LookupService } from "./services/lookup.service";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { ModalCartComponent } from "./product/widgets/modal-cart/modal-cart.component";
import { Navbar11Component } from "./header/widgets/navbar11/navbar11.component";
import { Navbar2Component } from "./header/widgets/navbar2/navbar2.component";
import { Navbar3Component } from "./header/widgets/navbar3/navbar3.component";
import { Navbar4Component } from "./header/widgets/navbar4/navbar4.component";
import { Navbar5Component } from "./header/widgets/navbar5/navbar5.component";
import { Navbar6Component } from "./header/widgets/navbar6/navbar6.component";
import { Navbar7Component } from "./header/widgets/navbar7/navbar7.component";
import { NavbarComponent } from "./header/widgets/navbar/navbar.component";
import { NewProductComponent } from "./product/widgets/new-product/new-product.component";
import { NewProductTypeComponent } from "./New_Components/new-product-type/new-product-type.component";
import { NgModule } from "@angular/core";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { NgxImgZoomModule } from "ngx-img-zoom";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxPayPalModule } from "ngx-paypal";
import { NgxSpinnerModule } from "ngx-spinner";
import { NoRightClickDirective } from "./header/header-talabt/no-right-click.directive";
// Pipes
import { OrderByPipe } from "./pipes/order-by.pipe";
import { OrderService } from "./services/order.service";
import { PaginationService } from "./classes/paginate";
import { PermenantProjectsComponent } from "./New_Components/permenant-projects/permenant-projects.component";
import { PriceComponent } from "./product/collection/filter/price/price.component";
import { PrintPopupComponent } from "./New_Components/print-popup/print-popup.component";
import { ProductCompareComponent } from "./product/product-compare/product-compare.component";
import { ProductComponent } from "./product/product.component";
import { ProductLeftSidebarComponent } from "./product/product-details/product-left-sidebar/product-left-sidebar.component";
import { ProductVerticalTabComponent } from "./product/product-details/product-vertical-tab/product-vertical-tab.component";
import { ProductsService } from "./services/products.service";
import { ProjectsComponent } from "./New_Components/projects/projects.component";
import { QuickViewComponent } from "./product/widgets/quick-view/quick-view.component";
import { RangeSliderModule } from "ngx-rangeslider-component";
import { RatingComponent } from "./product/collection/filter/rating/rating.component";
import { RatingModule } from "ng-starrating";
import { RelatedProductsComponent } from "./product/product-details/related-products/related-products.component";
import { ReviewsComponent } from "./reviews/reviews.component";
import { RouterModule } from "@angular/router";
import { SearchComponent } from "./product/search/search.component";
import { SettingsComponent } from "./header/widgets/settings/settings.component";
import { SettingsGiftsComponent } from "./header/widgets/settings -gifts/settingsGifts.component";
import { ShoppingCartComponent } from "./product/shopping-cart/shopping-cart.component";
import { SideNav2Component } from "./New_Components/side-nav-2/side-nav.component";
import { SideNavComponent } from "./New_Components/side-nav/side-nav.component";
import { SidebarComponent } from "./product/product-details/sidebar/sidebar.component";
// import { ShopRoutingModule } from '../shop/shop-routing.module';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SocialComponent } from "./footer/widgets/social/social.component";
import { SuccessComponent } from "./product/success/success.component";
import { SupplierFilterComponent } from "./product/collection/filter/supplier-filter/supplier-filter.component";
import { TableComponent } from "./New_Components/table/table.component";
import { TopbarOneComponent } from "./header/widgets/topbar/topbar-one/topbar-one.component";
import { TopbarSixComponent } from "./header/widgets/topbar/topbar-six/topbar-six.component";
import { TopbarThreeComponent } from "./header/widgets/topbar/topbar-three/topbar-three.component";
import { TopbarTwoComponent } from "./header/widgets/topbar/topbar-two/topbar-two.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { WhyWeChooseComponent } from "./footer/widgets/why-we-choose/why-we-choose.component";
import { WishlistComponent } from "./product/wishlist/wishlist.component";
import { WishlistService } from "./services/wishlist.service";
import { Zakah1Component } from "./New_Components/zakah1/zakah1.component";
import { Zakah2Component } from "./New_Components/zakah2/zakah2.component";
import { Zakah3Component } from "./New_Components/zakah3/zakah3.component";

// components

// import { SettingsGiftsComponent } from './header/widgets/settings -gifts/settings.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  exports: [
    Navbar11Component,
    CommonModule,
    TranslateModule,
    SideNav2Component,
    FormsModule,
    ReactiveFormsModule,
    LeftSidebarComponent,
    FooterOneComponent,
    OrderByPipe,
    NgxSpinnerModule,
    ProductComponent,
    CollectionLeftSidebarComponent,
    CollectionRightSidebarComponent,
    CollectionNoSidebarComponent,
    ColorComponent,
    BrandComponent,
    PriceComponent,
    ProductLeftSidebarComponent,

    ProductVerticalTabComponent,
    RelatedProductsComponent,
    SidebarComponent,
    CategoriesFooterComponent,
    QuickViewComponent,
    ModalCartComponent,
    NewProductComponent,
    SearchComponent,
    ProductCompareComponent,
    WishlistComponent,
    CartComponent,
    CheckoutComponent,
    SuccessComponent,
    ExitPopupComponent,
    AgeVerificationComponent,
    CategoriesComponent,
    SupplierFilterComponent,
    RatingComponent,
    FastDonationComponent,
    NewProductTypeComponent,
    EhdaaComponent,
    Zakah1Component,
    Zakah2Component,
    Zakah3Component,
    PermenantProjectsComponent,
    BlogDonationComponent,
    GoogleMapComponent,
    ProjectsComponent,
    AgmCoreModule,
    SocialComponent,
    WhyWeChooseComponent,

    HeaderTalabtComponent,
    SettingsComponent,
    SettingsGiftsComponent,
    TableComponent,
    SideNavComponent,

    NoRightClickDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    BarRatingModule,
    MatRadioModule,
    RangeSliderModule,
    InfiniteScrollModule,
    NgxPayPalModule,
    MatIconModule,
    NgxImgZoomModule,
    RatingModule,
    NgxPaginationModule,
    NgxImageZoomModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAnTqVhf0Kkgr7PDx5EBzhwokn9wnboE38",
    }),
  ],

  declarations: [
    LeftSidebarComponent,
    FooterOneComponent,
    SideNav2Component,
    OrderByPipe,
    NavbarComponent,
    Navbar6Component,
    SettingsComponent,
    SettingsGiftsComponent,
    LeftMenuComponent,
    TopbarOneComponent,
    TopbarTwoComponent,
    InformationComponent,
    CategoriesFooterComponent,
    WhyWeChooseComponent,
    CopyrightComponent,
    SocialComponent,
    CategoriesComponent,
    ProductComponent,
    CollectionLeftSidebarComponent,
    CollectionRightSidebarComponent,
    CollectionNoSidebarComponent,
    ColorComponent,
    BrandComponent,
    PriceComponent,
    ProductLeftSidebarComponent,
    ProductVerticalTabComponent,
    RelatedProductsComponent,
    SidebarComponent,
    CategoriesFooterComponent,
    QuickViewComponent,
    ModalCartComponent,
    NewProductComponent,
    SearchComponent,
    ProductCompareComponent,
    WishlistComponent,
    CartComponent,
    CheckoutComponent,
    SuccessComponent,
    ExitPopupComponent,
    AgeVerificationComponent,
    SupplierFilterComponent,
    RatingComponent,
    ReviewsComponent,
    FastDonationComponent,
    NewProductTypeComponent,
    Zakah1Component,
    Zakah2Component,
    Zakah3Component,
    EhdaaComponent,
    PermenantProjectsComponent,
    BlogDonationComponent,
    GoogleMapComponent,
    ProjectsComponent,
    TopbarSixComponent,
    ShoppingCartComponent,
    TopbarThreeComponent,
    Navbar2Component,
    Navbar3Component,
    Navbar4Component,
    Navbar5Component,
    Navbar11Component,
    HeaderTalabtComponent,
    Categories6Component,
    Cart6Component,
    Navbar7Component,
    TableComponent,
    SideNavComponent,
    ChangeDialogComponent,
    NoRightClickDirective,
    PrintPopupComponent,
  ],

  providers: [
    WINDOW_PROVIDERS,
    LandingFixService,
    InstagramService,
    ProductsService,
    WishlistService,
    OrderService,
    PaginationService,
    HttpClientService,
    LookupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
