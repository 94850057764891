import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../shared/services/products.service';
import { ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  reviews:any[] =[]
  product_id;


  constructor(private route: ActivatedRoute,
    public productsService: ProductsService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.product_id = params.id;
      // console.log("The Product Id", this.product_id)
      this.getReviews(this.product_id);
    })
    this.productsService.reviewAdded.subscribe(
      res=>{
        this.productsService.getreview(this.product_id).subscribe((res:any)=>{
          this.reviews = res['reviews'];
          console.log("reviews => ",this.reviews)
        });
      }
    );
    
  }

  getReviews(product_id){
    this.productsService.getreview(product_id).subscribe((res:any)=>{
      this.reviews = res['reviews'];
      console.log("reviews => ",this.reviews)
    });
  }
}
