import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  lang;
  method;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);

    this.method = localStorage.getItem('paymentMethod');
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

  gotoProfile() {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/profile', this.lang],{queryParams:{ref:'profile'}})
  }

  saveChanges(){
    console.log('method', this.method);
    localStorage.setItem('paymentMethod', this.method);
    if(this.lang == 'ar'){
      this.toaster.success(this.method + " هى الان وسيلة الدفع الأساسية");
    }else{
      this.toaster.success(this.method + " is Now Your Default Payment Method");
    }
  }

}
