<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="row">
          <div class="col-md-10">
            <h2>
              {{ "Edit Opening Times" | translate }}
            </h2>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" (click)="save()">
              {{ "save" | translate }}
            </button>
          </div>
        </div>
        <div class="big-border">
          <div class="card" *ngFor="let day of days; let i = index">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <h3>{{ day.day | translate }}</h3>
                </div>
                <div class="col-md-4">
                  <label class="text-r">{{
                    "opens at" | translate | titlecase
                  }}</label>
                  <select class="form-control" [(ngModel)]="day.opening_time">
                    <option *ngFor="let t of time" [value]="t.key">
                      {{ t.text }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="text-r">{{
                    "closes at" | translate | titlecase
                  }}</label>
                  <select class="form-control" [(ngModel)]="day.closing_time">
                    <option *ngFor="let t of time" [value]="t.key">
                      {{ t.text }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <mat-slide-toggle
                    [checked]="day?.is_opened == 1 ? true : false"
                    (change)="updateDay($event, i)"
                  ></mat-slide-toggle>
                  <button
                    *ngIf="i == 0"
                    class="btn btn-link"
                    (click)="copyToAllDays(day.opening_time, day.closing_time)"
                  >
                    {{ "Copy to all Days" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
