  <!-- section start -->
  <section class="cart-section section-b-space">
    <div class="container">
      <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems.length">
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>{{'Cart is Empty'| translate}}</strong></h3>
        <!-- <h4>Add something to make me happy :)</h4> -->
      </div>
      <div class="row" *ngIf='shoppingCartItems.length'>
        <div class="col-sm-12">
          <div class="row" *ngFor="let item of shoppingCartItems;index as i">
            <div class="col-md-12 col-sm-12 col-xs-12 cart_container_new">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <i class="fa fa-times cart-remove-product" (click)="deleteItemFromCart(item.id)" aria-hidden="true"></i>
                </div>
                <div class="col-md-1 col-sm-2 col-xs-12">
                  <img class="" [src]="item?.itemable?.mediaCollection[0]?.default" alt="">
                </div>
                <div class="col-md-12 col-sm-10 col-xs-12">
                  <a [routerLink]="['/product', item.id]" class="cart-product-name">{{ lang == 'ar' ? item?.itemable?.title['ar'] : item?.itemable?.title['En'] }}</a>
                  <br>
                  <p>
                    <!-- <span>{{'size'|translate}}: 12</span> -->
                    <span>
                      <div class="qty-box cart-qty-box">
                        <div class="input-group">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" (click)="updateCartQuantity(item.id,-1)" data-type="minus" data-field="">
                            <i class="ti-angle-left"></i>
                          </button>
                        </span>
                          <input type="text" name="quantity" disabled class="form-control input-number" [(ngModel)]="item.quantity">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" (click)="updateCartQuantity(item.id,1)" data-type="plus" data-field="">
                            <i class="ti-angle-right"></i>
                          </button>
                        </span>
                        </div>
                        <h4 class="cart-product-total">{{'total'|translate}}: {{ item?.itemable?.price * item?.quantity }} {{'SAR' |translate}}</h4>
                      </div>
                    </span>
                  </p>
  
                    <hr>
                  <!-- <h4 >{{item.price}}<del>{{item?.salePrice}} </del> {{'SAR' |translate}} </h4> -->
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'en'">
                  <textarea class="form-control cart-product-requirements" placeholder="Add special requirements"></textarea>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'ar'">
                  <textarea class="form-control cart-product-requirements" placeholder="أضف متطلبات خاصة"></textarea>
                </div>
              </div>
  
            </div>
          </div>
          <table class="table cart-table table-responsive-md">
            <tfoot>
              <tr>
                <td>{{'total'|translate}}</td>
                <td>
                  <h2> {{ cart?.total_price }} {{'SAR' |translate}}</h2>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="row cart-buttons" *ngIf='shoppingCartItems.length'>
        <div class="col-6">
          <a [routerLink]="'../category'" class="btn btn-solid">{{'continueShopping'|translate}}</a>
        </div>
        <div class="col-6">
          <a [routerLink]="'/payments/checkout'" class="btn btn-solid">{{'checkout'|translate}}</a>
        </div>
      </div>
    </div>
  </section>
  <!--section end-->
  