<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 m-auto" style="margin-top: 30px !important;">
                <h3>{{'merchantlogin'|translate}}</h3>
                <div class="theme-card">
                    <form [formGroup]="loginForm" class="theme-form">
                        <div class="form-group">
                            <label for="email">{{'email'|translate}}</label>
                            <input formControlName="name" type="text"
                                class="form-control {{loginForm.controls['name'].touched && loginForm.controls['name'].errors ? 'error-control' : ''}}"
                                id="email" placeholder="{{'email' | translate}}">
                            <span
                                *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.required"
                                class="error-msg">
                                {{'emailReqMes'|translate}}
                            </span>
                            <span *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.email"
                                class="error-msg">
                                {{'emailmustbe'|translate}}.
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="review">{{'Password'|translate}}</label>

                            <div class="input-group">

                               <input formControlName="password" [type]="password"
                                class="form-control {{loginForm.controls['password'].touched && loginForm.controls['password'].errors ? 'error-control' : ''}}"
                                id="password" placeholder="{{'Password'|translate}}" style="border-radius: 10px 0px 0px 10px !important;">


                                <div class="input-group-append">
                                    <span class="input-group-text" style="height: 53px;border-radius: 0px 8px 8px 0px;">
                                        <i
                                        class="fa"
                                        [ngClass]="{
                                          'fa-eye-slash': !show,
                                          'fa-eye': show
                                        }"
                                        (click)="toggleFieldTextType()"
                                      ></i>
                                    </span>
                                </div>

                            </div>    

                            <span
                                *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.required"
                                class="error-msg">
                                {{'passwordReqMes'|translate}}
                            </span>
                            <span
                                *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.minlength"
                                class="error-msg">
                                {{'passwordMinLengMes'|translate}}.
                            </span>
                        </div>
                        <!-- <a class="forget-password" (click)="gotoFrogetPassword()">{{'forget-password'|translate}}</a><br> -->
                        <button [disabled]="!loginForm.valid" (click)="login()"
                            class="btn btn-solid margin-t xbutton form-control">{{'login'|translate}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>