import { Component, OnInit, ViewChild } from "@angular/core";

import { DatePipe } from "@angular/common";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-otp-cashier",
  templateUrl: "./otp-cashier.component.html",
  styleUrls: ["./otp-cashier.component.css"],
})
export class OtpCashierComponent implements OnInit {
  [x: string]: any;
  name;
  type;
  merchantsList;
  lang;
  reports: any[] = [];
  merchants_array: any[] = [];
  brandreports;
  config: any;
  page: number = 1;
  perPage: any;
  length: any;
  isLoading: boolean = false;
  @ViewChild("TablePaginator", { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    "id",
    "date",
    "transaction_amount",
    "type",
    "total_amount",
  ];
  visible: boolean = false;
  currentCriteria: any = {
    type: "",
    date: "",
    start_time: "",
    end_time: "",
  };
  start;
  end;
  totalReward: number = 0;
  totalRedeem: number = 0;

  Hide: boolean = true;
  selectedID = 1;
  end_date: any;
  start_date: string;
  downloadreport: string;

  Branch_Name;
  id: any;
  merchant: any;
  date: any = 0;
  idList: any[];

  constructor(
    private translate: TranslateService,
    private http: HttpClientService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.isLoading = false;
    this.getList();
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.initReports();
  }
  initReports() {
    this.isLoading = false;
    this.initForm();
    this.getEndDayReport();
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.length,
    };
    localStorage.getItem("Brand_Merchants_array");
    this.merchants_array = JSON.parse(
      localStorage.getItem("Brand_Merchants_array")
    );
    this.Branch_Name = this.merchants_array[0].name;
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.idList = [this.id];
          this.name = this.merchant.name;
          this.initReports();
        }
      });
  }
  initForm() {
    // this.filterForm = new FormGroup({
    //   date: new FormControl(""),
    //   // status: new FormControl(""),
    //   // startDate: new FormControl(""),
    //   // endDate: new FormControl(""),
    // });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  reset() {
    this.selectedID = 1;
    // this.filterForm.reset();
    this.data = null;

    this.currentCriteria = {};
    // this.getReports(this.page, this.currentCriteria);
    this.getEndDayReport();
  }

  getEndDayReport() {
    let body = {
      date_flag: this.selectedID,
      date: this.start_date,
      merchants: this.idList,
    };
    this.isLoading = false;
    this.http.post("report/end-of-day", body, []).subscribe((res) => {
      this.reports = res["data"].merchants;
      this.brandreports = res["data"];
      console.log(res);
      this.downloadreport = res["data"].download_file;
      this.downloadreportPDF = res["data"].pdf_download_file;
      this.perPage = res["data"].per_page;
      this.length = res["data"].total;
      this.isLoading = true;

      console.log(
        "End of day report download api link :----",
        this.downloadreport
      );

      this.updateConfig();
    });
  }

  onChangePage(event) {
    this.config.currentPage = event;
    console.log("Event", event);
    this.page = event;
    this.getEndDayReport();
    this.updateConfig();
  }

  updateConfig() {
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.length,
    };
  }

  onChange(event) {
    console.log("Event", event.target.value);
    let body = { type: event.target.value };
    console.log(body);
    this.currentCriteria["type"] = event.target.value;
    // this.getReports(this.page, this.currentCriteria);
    this.getEndDayReport();
  }

  onChangeName(event) {
    console.log("Event", event.target.value);
    let body = { user: event.target.value };
    console.log(body);
    // this.getOrders(1, body);
  }

  // getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
  //   let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
  //   let body = {'date': date};
  //   console.log(body);
  //   this.currentCriteria['date'] = date;
  //   this.getReports(this.page, this.currentCriteria);
  //   this.getEndDayReport(this.page, this.currentCriteria);
  // }

  onItemSelect(item: any) {
    console.log("iteeeeem", item);
    let body = { user: item.id };
    console.log(body);
    // this.getReports(this.page, this.currentCriteria);
    this.getEndDayReport();
  }

  handlePageEvent(event) {
    console.log(event);
    // this.getReports(event.pageIndex + 1);
    this.getEndDayReport();
    this.updateConfig();
  }

  changedTime(event: any, pos) {
    let body = {};
    event = event.toString().slice(0, -3);
    let x: any[] = event.split(":");
    let first = +x[0];
    if (first <= 9) {
      event = "0" + event;
    }

    // console.log(this.datePipe.transform(event,'h:mm'))
    if (pos == "start") {
      body = { start_time: event };
      this.currentCriteria["start_time"] = event;
      // this.getReports(this.page, this.currentCriteria);
      this.getEndDayReport();
    } else {
      body = { end_time: event };
      this.currentCriteria["end_time"] = event;
      // this.getReports(this.page, body);
      this.getEndDayReport();
    }
    console.log("time Changes", this.currentCriteria);
  }
  gotoReport(report) {
    localStorage.setItem("transaction", JSON.stringify(report));
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/print-receipt", this.lang]);
  }

  printAll() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }

  ShowDatePicker() {
    this.Hide = false;
    console.log("Date picker:---", this.Hide);
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.start_date = date;
    this.getEndDayReport();
  }

  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.end_date = date;
    console.log("The End date value:-----", this.end_date);
    this.getEndDayReport();
  }

  changeSelection(event: any) {
    this.selectedID = event.source.selected.value;
    if (this.selectedID != 3) this.getEndDayReport();
  }
}
