<!-- 1st Level Menu -->
<!-- Sample menu definition -->
<ul id="sub-menu" class="sm pixelstrap sm-vertical">
  <li *ngFor="let category of categories">
    <ng-container *ngIf="!category['categories'].length">
      <a (click)="navigateCategory(category)">
        {{lang == 'ar' ? category.title['ar'] : category.title['En']}}
      </a>
    </ng-container>
   <ng-container *ngIf="category['categories'].length">
     <a href="javascript:void(0)"> {{lang == 'ar' ? category.title['ar'] : category.title['En']}} </a>
    <ul>
      <li *ngFor="let subCategory of category['categories']">
        <a  (click)="navigateCategory(subCategory)">
          {{lang == 'ar' ? subCategory.title['ar'] : subCategory.title['En']}}
        </a>
      </li>
    </ul>
   </ng-container>
  </li>
</ul>
