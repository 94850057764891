import { Component, OnInit } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";

import { CartService } from "src/app/shared/services/cart.service";
import { CheckoutService } from "src/app/shared/services/checkout.service";
import { FirebaseMessaging } from "src/app/shared/services/Firebase-messageing/firebase-messaging.service";
import { Product } from "../../../../classes/product";
import { ProductsService } from "../../../../../shared/services/products.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";
import { WishlistService } from "../../../../services/wishlist.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-topbar-six",
  templateUrl: "./topbar-six.component.html",
  styleUrls: ["./topbar-six.component.css"],
})
export class TopbarSixComponent implements OnInit {
  lang: string;
  check_login: string = "";
  closeResult = "";
  amount: number;
  baseURL = environment.base;
  loading: Boolean = false;

  constructor(
    public productsService: ProductsService,
    public translate: TranslateService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private checkoutService: CheckoutService,
    private cartService: CartService,
    private wishlistService: WishlistService,
    private userService: UserService,
    private firebaseMessaging: FirebaseMessaging // private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.check_login = localStorage.getItem("token");
    console.log("The Token", this.check_login);
  }

  logout() {
    this.firebaseMessaging.deleteFirebaseToken();
    this.check_login = null;
    localStorage.removeItem("token");
    localStorage.removeItem("wishlistItem");
    localStorage.removeItem("userObject");
    this.wishlistService.wishlistProducts.next([]);
    console.log("local storage is cleared");
    let msg: string = "";
    this.translate.get("You Have Been Logout").subscribe((res) => {
      msg = res;
    });
    this.cartService.emptyCart();
    this.toastrService.success(msg);

    this.userService.isLogged.next(false);
    localStorage.setItem("isLogged", "false");
  }

  changeLang(lang) {
    console.log(lang);
    if (lang == "en") {
      this.lang = "ar";
      $("body").addClass("rtl");
      this.translate.use("ar");
      localStorage.setItem("lang_ref", "ar");
      window.location.reload();
    } else {
      this.lang = "en";
      $("body").removeClass("rtl");
      $("body").addClass("ltr");
      this.translate.use("en");
      localStorage.setItem("lang_ref", "en");
      // console.log("language => ",this.translate.getDefaultLang())
      window.location.reload();
    }

    console.log("final lang => ", this.lang);
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  saveAmount() {
    this.loading = true;
    console.log("amount=> ", this.amount);
    this.checkoutService.donation(this.amount).subscribe(
      (response: any) => {
        this.loading = false;
        var paymentUrl = this.baseURL + "/paymentRequest";
        var fr =
          '<form action="' +
          paymentUrl +
          '" method="post">' +
          '<input type="hidden" name="checkoutId" value="' +
          response.checkoutId +
          '" />' +
          '<input type="hidden" name="responseUrl" value="' +
          response.responseUrl +
          '" />' +
          '<input type="hidden" name="paymentMethods" value="' +
          response.paymentMethods +
          '" />' +
          "</form>";
        var form = $(fr);
        $("body").append(form);
        form.submit();
      },
      (error) => {
        console.log("error=> ", error);
        if (error.status == 401) {
          this.toastrService.error(`${this.translate.instant("LOGINMSG")}`); // toasr services
        }
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
