<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                        <li *ngIf="isFromProfile" class="breadcrumb-item"><a
                            (click)="gotoProfile()">{{'Profile'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'Order_List' |translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div> -->
<!-- breadcrumb End -->
<section class="section-b-space" [ngClass]="orderClass">
    <div class="container padding-cls">
        <div class="checkout-page">
            <h3>{{'Cashback Accumulated' | translate}}</h3>
            <p><b>{{totalBalance}} {{'EGP' | translate}}</b></p>
            <!--   -->
            <div *ngIf="emptyList== true" class="alert alert-warning empty-orders" role="alert">
                <h5>{{'NO_ORDERS'|translate}}</h5>
            </div>

        
           <div class="checkout-form">
                <div *ngFor="let item of ordersList; index as i" class="row" (click)="showOrders(i)">
                    <div class="col-lg-12 col-sm-12 col-xs-12 order-container margin-bottom-small">
                        <h4><a (click)="openDetails(item.id)">
                                <span *ngIf="item.order_status == null" class="status">{{'NOSTATUS'|translate}}</span>
                                <span class="status" *ngIf="item.order_status == 'accepted'">
                                    {{'pending' | translate}}
                                </span>
                                <span *ngIf="item.order_status !=null && item.order_status != 'accepted' && lang =='en'"
                                    class="status">{{item.order_status}}</span>
                                <span *ngIf="item.order_status !=null && item.order_status != 'accepted' && lang =='ar'"
                                    class="status">{{item.order_status}}</span>
                                {{'ORDER_NUM'|translate}} #{{item.id}}</a> </h4>
                        <p class="no-margin-bottom"><span>{{item.created_at | date}}</span><i class="fa fa-sort-desc"
                                aria-hidden="true"></i></p>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-small display--none"
                        id="orders_list_body_{{i}}" style="padding-left: 0px;padding-right: 0px;" show="false">
                        <div class="order-container">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 order-item-container">
                                    <div *ngFor="let product of item.products | paginate: config" class="row order-item">
                                        <div class="col-md-2 col-sm-2 col-xs-12 ">

                                            <img class="order-img" [src]="product?.product?.mediaCollection[0]?.default">
                                        </div>
                                        <div class="col-md-10 col-sm-10 col-xs-12">
                                            <h4>
                                                <span *ngIf="lang =='ar'"
                                                    style="font-weight: bold;">{{product?.product?.title?.ar}}
                                                    x{{product?.quantity}}</span>
                                                <span *ngIf="lang =='en'"
                                                    style="font-weight: bold;">{{product?.product?.title?.en}}
                                                    x{{product?.quantity}}</span>
                                                <span>
                                                    <star-rating [value]="product?.product?.avg_rank" totalstars="5"
                                                        checkedcolor="orange" uncheckedcolor="black" size="24px"
                                                        readonly="true"></star-rating>
                                                </span>
                                            </h4>
                                            <p><span>{{"Order" | translate}} #{{item?.id}}</span> </p>
                                            <p><span>{{"Merchant" | translate}}: {{item?.merchant?.name}}</span> </p>
                                            <p><span>{{'price' | translate}}: {{product?.product?.price}} {{'EGP' | translate}}</span></p>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm=12 col-xs-12">
                                    <hr>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <p class="font--600  text-center" style="margin-bottom: 6px; font-size: 16px;">
                                        {{"total" | translate}}: <span>{{item?.total}}</span></p>
                                    <button class="btn reorder-btn m-2" (click)="reOrderProduct(item)">{{'Reorder' |
                                        translate}}</button>

                                    <!-- <button (click)="open(content);" *ngIf="item.order_status == 'delivered'"
                                        class="btn reorder-btn btn-outlined m-2">{{'Rate' | translate}}</button> -->

                                    <button (click)="submitCancel(item?.id)" *ngIf="item.order_status != 'delivered' && item.order_status != 'cancelled'"
                                        class="btn reorder-btn btn-outlined m-2">{{'Cancel Order' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="ordersList?.length == 0">
                    {{'NO_ORDERS' | translate}}
                </div>
                <div class="row pagination mt-3 text-center" *ngIf="ordersList?.length > 0">
                    <pagination-controls responsive="true" (pageChange)="onChangePage($event)"></pagination-controls>
                  </div>
            </div> 
        
        </div>

        <ng-template #content let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">{{'Rate'|translate}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-outline-dark" (click)="saveAmount()" style="border-radius: 20px;">{{'DONATE NOW'|translate}}</button> -->
            </div>
        </ng-template>

    </div>
</section>

<ngx-spinner color="#f95733"></ngx-spinner>