import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  shoppingCartItems: any[] = [];
  cart: any;
  constructor(
    private shoppingCart: ShoppingCartService
  ) { }

  ngOnInit(): void {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999);

    this.getShoppingCartItems();
  }

  getShoppingCartItems() {
    this.shoppingCart.getMyCartItems().subscribe(
      res=>{
        console.log(res);
        this.cart = res['cart'];
        this.shoppingCartItems = res['cart']['product_items'];
        console.log("Cart Items", this.shoppingCartItems)
      },
      error=>{
        console.log(error);
      }
    );
  }

  addItem(data: any) {
    this.shoppingCart.addItemToCart(data).subscribe(
      res=>{
        console.log(res);
      },
      error=>{
        console.log(error);
      }
    );
  }

  updateCartQuantity(id:number, quantity: number){
    let qnt;
    this.shoppingCartItems.forEach(item=>{
      if(item.id == id){
        qnt = item.quantity + quantity;
      }
    });
    if(qnt > 0){
      let data = {
        "quantity": qnt,
        "_method": "put"
      }
      this.shoppingCart.updateCartQuantity(id, data).subscribe(
        res=>{
          console.log(res);
          this.getShoppingCartItems();
        },
        error=>{
          console.log(error);
        }
      );
    }else{
      this.deleteItemFromCart(id);
    }
    
  }

  deleteItemFromCart(id: number) {
    this.shoppingCart.deleteItemFromCart(id).subscribe(
      res=>{
        console.log(res);
        this.getShoppingCartItems();
      },
      error=>{
        console.log(error);
      }
    );
  }

}
