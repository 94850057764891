<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <!-- <div class="col-sm-6">
        <div class="page-title">
          <h2>{{'product'|translate}}</h2>
        </div>
      </div> -->
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
            <li class="breadcrumb-item" aria-current="page"><a (click)="gotoProducts()">{{'product'|translate}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="lang== 'en'">{{product?.title.en}}</li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="lang== 'ar'">{{product?.title.ar}}</li>

          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="section-b-space" [ngClass]="productClass">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">

        <div class="col-sm-3 collection-filter">
          <product-details-sidebar></product-details-sidebar>
        </div>

        <div class="col-lg-9 col-sm-12 col-xs-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-5 responsive-btn">
                  <span class="filter-btn" (click)="mobileSidebar()"><i class="fa fa-bars" aria-hidden="true"></i>
                    sidebar</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <ngx-slick-carousel class="product-slick"  id="p-zoom" #slickModal="slick-carousel"
                  [config]="slideConfig">
                  <div ngxSlickItem *ngFor="let image of product?.mediaCollection">
                    <div style="border-radius: 20px !important;">
                      <!-- <ngx-img-zoom [previewImageSrc]="image['large']"  [zoomImageSrc]="image['large']" [imgStyle]="'width:85%; height:100%;'"
                       [resultStyle]="'background-color:white;width:400px;height:450px;background-repeat: no-repeat; z-index: 2; position:absolute;background-size: 1000px 1500px;
                       -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 0;left: 100%;'" [lensStyle]="'width:100px; height:100px'" [containerStyle]="'position: relative;'" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
                      </ngx-img-zoom> -->
                      <lib-ngx-image-zoom style="border-radius: 20px !important;" [thumbImage]="image.default" [fullImage]="image.large"></lib-ngx-image-zoom>
                    </div>
                  </div>
                </ngx-slick-carousel>
                <div class="row">
                  <div class="col-12 p-0">
                    <ngx-slick-carousel class="slider-nav" #slickModal="slick-carousel" [config]="slideNavConfig">
                      <div ngxSlickItem *ngFor="let image of product?.mediaCollection">
                        <div>
                          <img [src]="image['large']" alt="" class="img-fluid">
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 rtl-text">
                <div class="product-right">
                  <h2>{{lang == 'ar' ?  product?.title['ar'] : product?.title['en']}}</h2>
                  <h4 *ngIf="!productsService?.catalogMode && product?.discounts.length">
                    <del>{{product?.price}} {{'SAR'|translate}}</del>
                    <span>{{product?.discounts[0].value}}% off</span>
                  </h4>
                  <span>
                    <h3 *ngIf="!productsService?.catalogMode">{{product?.discounts.length? (product?.price - (product?.price * product?.discounts[0].value)/100):product?.price }} {{'SAR'|translate}}
                      <div>
                       <span *ngIf="product?.tax_included == 0" class="tax-style"> {{'taxincluded' | translate}}</span>
                       <span *ngIf="product?.tax_included == 1" class="tax-style"> {{'taxnotincluded' | translate}}</span>
                      </div>
                    </h3>
                  </span>
                  <!-- <span *ngIf="addTax.value == '0'">
                    <h3 *ngIf="!productsService?.catalogMode">{{product?.price }} {{'SAR'|translate}}</h3>
                  </span> -->
                  <!-- <div>
                   <h4 *ngIf="lang == 'en'">{{'soldby'|translate}} : <span [routerLink]="['/suppliers',2,'Samsung']" style="cursor: pointer;    padding-left: 0 !important;">Samsung</span>
                   </h4>
                   <h4 *ngIf="lang == 'ar'">{{'soldby'|translate}} : <span [routerLink]="['/suppliers',2,'Samsung']" style="cursor: pointer;    padding-left: 0 !important;">سامسونج</span>
                   </h4>

                 </div>  -->
                 <span *ngIf="product?.delivery_time != null">
                   {{'deliveryTime' | translate}}: {{product?.delivery_time}} {{ 'days' |translate}}
                 </span>
                  <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                 <span>
                  {{'stockstatus' | translate}}:- {{product?.stock_status_text}}
                 </span>
                  <ul class="" *ngIf="product?.variants && !productsService?.catalogMode" style="margin-bottom: 12px;">
                    <li class="color-margin-item-new" (click)="slickModal.slickGoTo(i)"
                      *ngFor="let variant of product?.variants let i = index"><span
                        class="color-tag-new">{{variant.color}}</span></li>
                  </ul>
                  <div class="pro_inventory"
                    *ngIf="product?.stock != 0 && product?.stock <= 5 && !productsService?.catalogMode">
                    <p class="active">
                      {{'Hurry'|translate}} {{product?.stock}} {{'product'|translate}} {{'stock'|translate}}.

                    </p>
                    <div class="inventory-scroll nostripes active">
                      <span style="width: 95%;"></span>
                    </div>
                  </div>
                  <div class="product-description border-product" *ngIf="!productsService?.catalogMode">
                    <h6 class="product-title size-text" *ngIf="product?.size">
                      {{'selectSize'|translate}}
                      <!-- <span><a href="">size chart</a></span> -->
                    </h6>
                    <div class="size-box" *ngIf="product?.size">
                      <ul>
                        <li [ngClass]="{'active': selectedSize == size}" *ngFor="let size of sizes">
                          <a *ngIf="lang== 'en'" [routerLink]="" (click)="changeSizeVariant(size)">{{size.en}}</a>
                          <a *ngIf="lang== 'ar'" [routerLink]="" (click)="changeSizeVariant(size)">{{size.ar}}</a>
                        </li>
                      </ul>
                    </div>
                    <h5 class="avalibility" *ngIf="counter <= product?.stock"><span>{{'stock'|translate}}</span></h5>
                    <h5 class="avalibility" *ngIf="counter > product?.stock"><span>{{'outstock'|translate}}</span></h5>
                    <h6 class="product-title">{{'quantity'|translate}}</h6>
                    <div class="qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <button type="button" [disabled]="product?.stock_status_id == 3" (click)="decrement()" class="btn quantity-left-minus" data-type="minus"
                            data-field="">
                            <i class="ti-angle-left"></i>
                          </button>
                        </span>
                        <input type="text" name="quantity" disabled class="form-control input-number"
                          value="{{counter}}">
                        <span class="input-group-prepend">
                          <button type="button" [disabled]="product?.stock_status_id == 3" (click)="increment()" class="btn quantity-right-plus" data-type="plus"
                            data-field="">
                            <i class="ti-angle-right"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="product-buttons" *ngIf="!productsService?.catalogMode">
                    <a (click)="addToCart(product, counter)" class="btn btn-solid"
                      [class.disabled]="counter > product?.stock"
                      [class.disabled]="product?.stock_status_id == 3"
                      >{{'cartbtn'|translate}}</a>
                    <a (click)="buyNow(product, counter)" class="btn btn-solid"
                      [class.disabled]="counter > product?.stock"
                      [class.disabled]="product?.stock_status_id == 3"
                      >{{'buybtn'|translate}}</a>
                  </div>
                  <div class="border-product">
                    <h6 class="product-title">{{'productdetails'|translate}}</h6>
                    <!-- <p>{{'DESC_ABOUT'|translate}}</p> -->
                    <p *ngIf="lang == 'en'">{{product?.content.en }}</p>
                    <p *ngIf="lang == 'ar'">{{product?.content.ar }}</p>
                  </div>
                  <div class="border-product">
                    <h6 class="product-title">{{'share'|translate}}</h6>
                    <div class="product-icon">
                      <ul class="product-social">
                        <li><a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://plus.google.com/discover" target="_blank"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.whatsapp.com/" target="_blank"><i class="fa fa-whatsapp"></i></a></li>
                        <li><a href="https://telegram.org/" target="_blank"><i class="fa fa-telegram"></i></a></li>
                      </ul>
                      <form class="d-inline-block" *ngIf="!productsService?.catalogMode">
                        <button class="wishlist-btn" (click)="addToWishlist(product)"><i class="fa fa-heart"></i><span
                            class="title-font">{{'wishbtn'|translate}}</span></button>
                      </form>
                    </div>
                  </div>
                  <div class="border-product" *ngIf="!productsService?.catalogMode">
                    <!-- <h6 class="product-title">Time Reminder</h6> -->
                    <!-- <div class="timer">
                      <p id="timer">
                        <span>25
            <span class="padding-l">:</span>
                        <span class="timer-cal">Days</span>
                        </span>
                        <span>22
            <span class="padding-l">:</span>
                        <span class="timer-cal">Hrs</span>
                        </span>
                        <span>13
            <span class="padding-l">:</span>
                        <span class="timer-cal">Min</span>
                        </span>
                        <span>57
            <span class="timer-cal">Sec</span>
                        </span>
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">
                  <!-- <li class="nav-item">
                    <a class="nav-link active" id="top-home-tab" data-toggle="tab" href="#top-home" role="tab" aria-selected="true">
                      <i class="icofont icofont-ui-home"></i>{{'Description'|translate}}</a>
                    <div class="material-border"></div>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link active" id="profile-top-tab" data-toggle="tab" href="#top-profile" role="tab"
                      aria-selected="false"><i class="icofont icofont-man-in-glasses"></i>{{'Details'|translate}}</a>
                    <div class="material-border"></div>
                  </li>

                  <!-- <li class="nav-item">
                    <a class="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-selected="false">
                    <i class="icofont icofont-contacts"></i>{{'Video'|translate}}</a>
                    <div class="material-border"></div>
                  </li> -->
                  <!-- <li class="nav-item">
                    <a class="nav-link" id="warranty-top-tab" data-toggle="tab" href="#top-warranty" role="tab" aria-selected="false">
                    <i class="icofont icofont-contacts"></i>{{'Warranty'|translate}} </a>
                    <div class="material-border"></div>
                  </li> -->

                  <li class="nav-item">
                    <a class="nav-link " id="rate-top-tab" data-toggle="tab" href="#top-rate" role="tab"
                      aria-selected="false">
                      <i class="icofont icofont-contacts"></i>{{'Review'|translate}}</a>
                    <div class="material-border"></div>
                  </li>
                  <!-- <li class="nav-item">
                    <a class="nav-link" id="review-top-tab" data-toggle="tab" href="#top-review" role="tab" aria-selected="false">
                        <i class="icofont icofont-contacts"></i>{{'Write Review'|translate}}</a>
                    <div class="material-border"></div>
                  </li> -->

                </ul>
                <div class="tab-content nav-material" id="top-tabContent">
                  <!-- <div class="tab-pane fade show active" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                    <p>{{'infofooter'|translate}}</p>
                    <p>{{'infofooter'|translate}}</p>
                  </div> -->
                  <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                    aria-labelledby="profile-top-tab">
                    <!-- <p>{{'DESC'|translate}}</p> -->
                    <div class="single-product-tables">
                      <table style="width:100%">
                        <!-- <tbody *ngIf="lang == 'en'">
                          <tr>
                            <td>Febric</td>
                            <td>Chiffon</td>
                          </tr>
                          <tr>
                            <td>Color</td>
                            <td>Red</td>
                          </tr>
                          <tr>
                            <td>Material</td>
                            <td>Crepe printed</td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="lang == 'ar'">
                          <tr>
                            <td>القماش</td>
                            <td>شيفون</td>
                          </tr>
                          <tr>
                            <td>اللون</td>
                            <td>احمر</td>
                          </tr>
                          <tr>
                            <td>الخامه </td>
                            <td> كريب مطبوع</td>
                          </tr>
                        </tbody> -->
                        <tbody>
                          <p *ngIf="lang == 'en'" [innerHtml]="product?.content.en"></p>
                          <p *ngIf="lang == 'ar'" [innerHtml]="product?.content.ar"></p>

                        </tbody>
                      </table>
                      <!-- <table>
                        <tbody *ngIf="lang == 'en'">
                          <tr>
                            <td>Length</td>
                            <td>50 Inches</td>
                          </tr>
                          <tr>
                            <td>Size</td>
                            <td>S, M, L </td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="lang == 'ar'">
                          <tr>
                            <td>الطول</td>
                            <td>50 انش</td>
                          </tr>
                          <tr>
                            <td>المقاسات</td>
                            <td>صغير و متوسط و كبير </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </div>

                  <!-- <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                    <div class="mt-3 text-center">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                  </div> -->

                  <!-- <div class="tab-pane fade" id="top-warranty" role="tabpanel" aria-labelledby="warranty-top-tab">
                    <div class="mt-3">
                      <p>
                        {{'infofooter'|translate}}                      </p>
                    </div>
                  </div> -->

                  <div class="tab-pane fade" id="top-rate" role="tabpanel" aria-labelledby="rate-top-tab">
                    <div class="mt-3 text-center">
                      <form class="theme-form" [formGroup]="ReviewForm">
                        <div class="form-row">

                          <div class="col-md-12 text-left">
                            <div class="media">
                              <label>{{'rating'|translate}}</label>
                              <div class="media-body ml-3">

                                <star-rating value="0" totalstars="5" checkedcolor="orange" uncheckedcolor="gray"
                                  size="20px" readonly="false" (rate)="onRate($event)"></star-rating>

                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 text-left">
                            <label for="exampleFormControlTextarea1">{{'Review Details'|translate}}</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"
                              formControlName="comment"></textarea>

                          </div>

                          <div class="col-md-12">
                            <button class="btn btn-solid" [disabled]="!ReviewForm.valid" type="button"
                              (click)="reviewsubmit()">{{'Submit YOur Review'|translate}}</button>
                          </div>

                        </div>
                      </form>
                      <!-- <app-comments-shared></app-comments-shared> -->
                      <app-reviews></app-reviews>
                    </div>
                  </div>

                  <!-- <div class="tab-pane fade" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">

                  </div> -->
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section ends -->


<app-related-products></app-related-products>
<div class="col-sm-12  no-found" style="text-align: center;">
  <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
  <h3 style="margin-bottom: 120px;"> {{'noproduct'|translate}} </h3>
</div>

<!-- Modal size chart -->
<div class="modal fade" id="sizemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ lang == 'ar' ? product?.title['ar'] :  product?.title['en']}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="assets/images/size-chart.jpg" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div>