


<section class="p-t-0">
  
                <ngx-slick-carousel class="product-5 product-m no-arrow" #slickModal="slick-carousel" [config]="productSlideConfig">
                    <div ngxSlickItem class="product-box" *ngFor="let product of products">
                        <div class="container">

                        <app-product [product]="product"></app-product> 
    </div>
                        
                    </div>
                </ngx-slick-carousel>
      
</section>