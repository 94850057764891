import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {UserService} from './user.service';
import { Router } from '@angular/router';
import { HttpClientService } from './http-client.service';


// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("wishlistItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  lang;
  token;

  // wishlist array
  public wishlistProducts: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  // Initialize 
  constructor(
    private toastrService: ToastrService ,
    private translate: TranslateService,
    private userservice:UserService,
    private router:Router,
    private httpClient: HttpClientService) { 
      this.wishlistProducts.subscribe(products => products = products);
      this.token = localStorage.getItem('token');
      this.lang = localStorage.getItem('lang_ref');
  }

  // Get  wishlist Products
  public getProducts() {
    return this.httpClient.get('favourites', []);
  }

  // If item is aleready added In wishlist
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.id);
    return item !== undefined;
  }

  // Add to wishlist
  public addToWishlist(product: Product) {
    var item: Product | boolean = false;
    if(this.token != null){
      this.httpClient.post('favourites/' + product.id, {},[]).subscribe(
        res=>{
          return this.toastrService.success(res[0]);
        },
        error=>{
          console.log(error);
          if(this.lang == 'en'){
            this.toastrService.error('This product has been added before to the favourites');
          }else{
            this.toastrService.error('هذا المنتج تمت اضافته من قبل الى المفضلة');
          }
          
        }
      );
    }
    else if (this.token == null){
      if(this.lang == 'en'){
        this.toastrService.error('Please Sign In');
      }else{
        this.toastrService.error('من فضلك قم بتسجيل الدخول');
      }
      let routs:any[] = this.router.url.split('/');
      this.router.navigate([routs[1] + '/login', this.lang]);
    }
      // localStorage.setItem("wishlistItem", JSON.stringify(products)); 
      // return item;
  }

  // Removed Product
  public removeFromWishlist(product: Product) {
    return this.httpClient.delete('favourites/' + product.id, []);
  }
  
  public emptyWishList(){
    this.wishlistProducts.next([]);
    localStorage.removeItem('wishlistItem');
  }

}
