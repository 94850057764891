<div class="row align-items-center mx-0">
  <!-- <div class="col-lg-4">
      <div class="">
        <ul>
          <li>{{ 'topbar-title' | translate }}</li>
        </ul>
      </div>
    </div> -->
  <div class="topbar-custom-header" *ngIf="!productsService?.catalogMode">
    <ul class="header-dropdown lang-ul">


      <div class="auth-btns" *ngIf="!isLogged">
        <a (click)="navigateToLogin()" data-lng="en">
          {{ 'login' | translate }}
        </a>

        <a (click)="navigateToRegister()" data-lng="en">
          {{ 'register' | translate }}
        </a>

      </div>


      <li class="onhover-dropdown" *ngIf="isLogged">
        <!-- <i class="fa fa-user" aria-hidden="true"></i> -->



        <div class="d-flex align-items-center">
          <div>
            <img src="./assets/images/usr.jpg" alt="">

          </div>
          <span class="txt" *ngIf="isLogged">
            {{ 'my-account' | translate }}
            <!-- {{'hello' | translate}} 
              <span class="d-block">
                {{user?.name}}
              </span> -->

          </span>
        </div>

       


        <ul class="onhover-show-div">

          <!-- <li *ngIf="!isLogged">
              <a (click)="navigateToLogin()" data-lng="en"  >
                  {{ 'login' | translate }}
              </a>
            </li>

            <li *ngIf="!isLogged">
              <a (click)="navigateToRegister()" data-lng="en"  >
                  {{ 'register' | translate }}
              </a>
            </li> -->

          <li (click)="navigateToProfile()" profile *ngIf="isLogged">
            <a data-lng="es">
              {{ 'Profile' | translate }}
            </a>
          </li>
          <li *ngIf="isLogged">
            <a data-lng="es" (click)=" logout()">
              {{ 'logout' | translate }}
            </a>
          </li>
        </ul>
      </li>


      <li class="flag-items">
        <a (click)="changeLang('en')" *ngIf="lang == 'en'"><img class="topbar-flag" src="./assets/images/ar.png"> <span
            class="txt">AR</span></a>
        <a (click)="changeLang('ar')" *ngIf="lang == 'ar'"><img class="topbar-flag" src="./assets/images/en.webp"> <span
            class="txt">En</span></a>
      </li>
    </ul>
  </div>
</div>

<ngx-spinner color="#f95733"></ngx-spinner>