<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
            <li class="breadcrumb-item active">{{'cart'|translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="cart-section section-b-space" [ngClass]="cartClass">
  <div class="container">
    <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems?.length">
      <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
      <h3><strong>{{'Cart is Empty'| translate}}</strong></h3>
      <!-- <h4>Add something to make me happy :)</h4> -->
    </div>
    <div class="row" *ngIf='shoppingCartItems?.length'>
      <div class="col-sm-12">
      
        <div class="row" *ngFor="let item of shoppingCartItems;index as i">
          
          <div class="col-md-12 col-sm-12 col-xs-12 cart_container_new">
          
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <i class="fa fa-times cart-remove-product" (click)="removeItem(item)" aria-hidden="true"></i>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12">
                <img class="" [src]="item.itemable.mediaCollection[0]?.default" alt="">
                <!-- <a   class="cart-product-name">{{ lang == 'ar' ? item.itemable.title['ar'] : item.itemable.title['en'] }}</a>
                <br>
                <span>{{merchantName}}</span> -->
              </div>
              <div class="col-md-10 col-sm-10 col-xs-12">
                <a   class="cart-product-name">{{ lang == 'ar' ? item.itemable.title['ar'] : item.itemable.title['en'] }}</a>
                <br>
                <span>{{merchantName}}</span>
                <div>
                  <!-- <span>{{'size'|translate}}: 12</span> -->
                  <span>
                    <div class="qty-box cart-qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                        <button type="button" class="btn quantity-left-minus" (click)="decrement(item)" data-type="minus" data-field="">
                          <!-- <i class="ti-angle-left"></i> -->
                          <i class="ti-angle-left"></i>
                        </button>
                      </span>
                        <input type="text" name="quantity" disabled class="form-control input-number" [(ngModel)]="item.quantity">
                        <span class="input-group-prepend">
                        <button type="button" class="btn quantity-right-plus" (click)="increment(item)" data-type="plus" data-field="">
                          <i class="ti-angle-right"></i>
                        </button>
                      </span>
                      </div>

                      <div *ngFor="let field of item.selected_fields">
                        {{field?.name}}: {{field?.option}} & {{'price'| translate}}: {{field?.value}} {{'EGP' | translate}}
                      </div>
                      <div class="d-flex flex-wrap justify-content-between w-100 mt-3">

                        <h4 class="cart-product-total">{{'total'|translate}}: {{item.itemable.price}}<del>{{item?.salePrice}} </del> {{'EGP' |translate}} </h4>
                        
                        <!-- <h4 class="cart-product-total">
                          {{'total'|translate}}: {{ item.itemable.price * item.quantity }} {{'EGP' |translate}}
                        </h4> -->

                      </div>
 
                    </div>
                  </span>
                </div>
               
              </div>

            </div>
            
            <div class="row justify-content-end">

              <div class="col-md-10 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'en'">
                <textarea class="form-control cart-product-requirements" placeholder="Add special requirements"></textarea>
              </div>
              <div class="col-md-10 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'ar'">
                <textarea class="form-control cart-product-requirements" placeholder="أضف متطلبات خاصة"></textarea>
              </div>
            </div>

          </div>
        </div>
        <table class="table cart-table table-responsive-md">
          <tfoot class="total-footer">
            <tr>
              <td>{{'total'|translate}}</td>
              <td>
                <h2> {{ totalPrice }} {{'EGP' |translate}}</h2>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row cart-buttons" *ngIf='shoppingCartItems?.length'>
      <div class="col-6">
        <a (click)="gotoCategory()" class="btn btn-solid">{{'continueShopping'|translate}}</a>
      </div>
      <div class="col-6">
        <a (click)="gotoCheckout()" class="btn btn-solid">{{'checkout'|translate}}</a>
      </div>
    </div>
  </div>
</section>
<!--section end-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'deleteItem' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{'Are You Sure You Want to Delete this Item?' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="save()">{{'submit' | translate}}</button>
  </div>
</ng-template>
<ngx-spinner color="#f95733"></ngx-spinner>