<div class="row main-menu">
    <div class="col-sm-6 col-12">
        <div class="header-contact">
            <ul>
                <li>Welcome to Remaad</li>
                <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us:  123 - 456 - 7890</li>
            </ul>
        </div>
        <div class="menu-left">
            <div class="navbar">
                <a href="javascript:void(0)" (click)="openNav()">
                    <div class="bar-style"> <i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
                </a>
            </div>
            <div class="brand-logo mobile-logo">
                <a [routerLink]="'/home/one'"> <img src="assets/images/icon/pentavalue-logo-2.png" class="img-fluid" alt=""></a>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="menu-right pull-right">
            <ul class="header-dropdown">
                <li class="mobile-wishlist">
                	<a [routerLink]="'/favourite'">
                		<i class="fa fa-heart" aria-hidden="true"></i>
                		<img src="assets/images/icon/like.png" alt=""> 
                	</a>
                </li>
                <li class="onhover-dropdown mobile-account">
                    <img src="assets/images/icon/users.png" alt="">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <ul class="onhover-show-div">
                        <li>
                            <a [routerLink]="'/authentication/login'" data-lng="en">
                                Login
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="'/home/one'" data-lng="es">
                                Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div>
                <app-header-widgets></app-header-widgets>
            </div>
        </div>
    </div>
</div>