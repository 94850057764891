<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav2></app-side-nav2>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3 header">
        <div class="header-content">
          <!-- <mat-icon [routerLink]="['/otphome/menu', lang]" *ngIf="lang == 'en'"
            >arrow_back</mat-icon
          >
          <mat-icon [routerLink]="['/otphome/menu', lang]" *ngIf="lang == 'ar'"
            >arrow_forward</mat-icon
          > -->
          <div>
            <h2>
              {{ "product requests" | translate }}
            </h2>
            <h3>
              {{ BrandName }}
            </h3>
          </div>
        </div>

        <div>
          <mat-form-field>
            <mat-select (selectionChange)="onbrandchange($event)">
              <mat-option
                class="cuurent-status"
                *ngFor="let m of merchantsList"
                [value]="m"
              >
                {{ m.title | titlecase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div>
        <div
          id="parentDiv"
          scrollSpy
          [spiedTags]="['DIV']"
          class="wrapper"
          style="overflow-y: scroll"
          *ngIf="products_requests?.length > 0"
        >
          <!-- <div class="toggle" *ngIf="products.length == 0">
          <mat-slide-toggle (change)="updateProds($event)">
            {{ "Show only unavailable" | translate }} ({{
              products.length
            }}
            {{ "items" | translate }})</mat-slide-toggle
          >
        </div> -->

          <div *ngIf="products_requests?.length > 0">
            <div *ngFor="let product of products_requests">
              <mat-card>
                <!-- <mat-card-title>
              {{ subcategory.name | translate | titlecase }}
            </mat-card-title> -->

                <mat-card-content>
                  <div class="badge">
                    <p
                      [ngClass]="{
                        update: product.product?.id ? true : false,
                        new: product.product?.id ? false : true
                      }"
                    >
                      {{ product.product?.id ? "Update" : "New" }}
                    </p>
                  </div>
                  <div class="product">
                    <img
                      class="product-img"
                      src="{{
                        product?.mediaCollection?.length > 0
                          ? product?.mediaCollection[0]?.default
                            ? product?.mediaCollection[0]?.default
                            : product?.mediaCollection[0]
                          : product.product.mediaCollection?.length > 0
                          ? product.product.mediaCollection[0]?.default
                            ? product.product.mediaCollection[0]?.default
                            : product.product.mediaCollection[0]
                          : ''
                      }}"
                      [alt]="product.product.title_en"
                    />

                    <label *ngIf="lang == 'en'" class="product-name">{{
                      product?.product?.title_en
                    }}</label>
                    <label *ngIf="lang == 'ar'" class="product-name">{{
                      product?.product?.title_ar
                    }}</label>

                    <label *ngIf="lang == 'en'" class="product-content">{{
                      product?.product?.content_en
                    }}</label>
                    <label *ngIf="lang == 'ar'" class="product-content">{{
                      product?.product?.content_ar
                    }}</label>

                    <label *ngIf="lang == 'en'" class="price"
                      >{{ product?.product?.price }}
                      <span>{{ product?.product?.currency?.en }}</span>
                    </label>
                    <label *ngIf="lang == 'ar'" class="price"
                      >{{ product?.product?.price }}
                      <span>{{ product?.product?.currency?.ar }}</span>
                    </label>

                    <mat-form-field>
                      <mat-select
                        (selectionChange)="updateStatus($event, product?.id)"
                      >
                        <mat-option value="approved">
                          {{ "Approve" | translate }}
                        </mat-option>
                        <mat-option value="rejected">
                          {{ "Decline" | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div style="margin-inline-start: 1rem">
                      <button
                        mat-raised-button
                        class="btn btn-primary"
                        (click)="prodDetails(product)"
                      >
                        View
                      </button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
