import { Component, OnInit } from '@angular/core';
import { LandingFixService } from '../../services/landing-fix.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  lang;

  constructor(private fix: LandingFixService,
    private translate: TranslateService,) { }

  ngOnInit() {
  
    $.getScript('assets/js/menu.js');
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
  }

  closeNav() {
     this.fix.removeNavFix();
  }

}
