<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home' | translate}}</a></li>
                        <li class="breadcrumb-item"><a (click)="gotoProfile()">{{'Profile'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'Payment Methods' | translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

</div> -->
<!-- breadcrumb End -->
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>{{'payment_methods' | translate}}</h3>
                <div class="theme-card">
                    <div class="row">
                        <div class="col-md-6">
                            <h2><input type="radio" value="cash" [(ngModel)]="method" name="payment"/> {{'cod' | translate}}</h2>
                        </div>
                        <div class="col-md-6">
                            <h2><input type="radio" value="wallet" [(ngModel)]="method" name="payment"/> {{'Sahla Wallet' | translate}}</h2>
                        </div>

                        <div class="col-md-12">
                            <button class="btn btn-success" (click)="saveChanges()">{{'savechanges' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->