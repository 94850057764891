<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home' | translate}}</a></li>
                        <li class="breadcrumb-item"><a (click)="gotoProfile()">{{'Profile'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'Update Profile' | translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

</div> -->
<!-- breadcrumb End -->
<!-- section start -->
<section class="register-page section-b-space" [ngClass]="updateClass">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>{{'Update Profile' | translate}}</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="profileForm">
                        <div id="_1_step" class="animated ">

                            <div class="form-row">

                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="email">{{'First Name'|translate}}<span class="color--red">*</span></label>
                                    <input type="text" formControlName="first_name" class="form-control" id="fname"
                                        placeholder="{{'First Name' | translate}}" required="">
                                        <span
                                        *ngIf="profileForm.controls['first_name'].dirty && profileForm.controls['first_name']?.errors?.required"
                                        class="error-msg">
                                        {{'nameReq'|translate}}
                                    </span>

                                    <span
                                        *ngIf="profileForm.controls['first_name'].dirty && profileForm.controls['first_name']?.errors?.invalidName"
                                        class="error-msg">
                                        {{'specialChars' | translate}}
                                    </span>
                                </div>

                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="last">{{'Last Name'|translate}}<span class="color--red">*</span></label>
                                    <input type="text" formControlName="last_name" class="form-control" id="fname"
                                        placeholder="{{'Last Name' | translate}}">
                                        <span
                                        *ngIf="profileForm.controls['last_name'].dirty && profileForm.controls['last_name']?.errors?.required"
                                        class="error-msg">
                                        {{'nameReq'|translate}}
                                    </span>

                                    <span
                                        *ngIf="profileForm.controls['last_name'].dirty && profileForm.controls['last_name']?.errors?.invalidName"
                                        class="error-msg">
                                        {{'specialChars' | translate}}
                                    </span>
                                </div>

                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="email">{{'phoneno'|translate}}<span class="color--red">*</span></label>
                                    
                                    <input formControlName="mobile" type="tel"
                                        class="form-control {{profileForm.controls['mobile'].touched && profileForm.controls['mobile'].errors ? 'error-control' : ''}}"
                                        id="mobile" placeholder="{{'phoneno' | translate}}" required=""
                                        value={{mobile}}>
                                    <span
                                        *ngIf="profileForm.controls['mobile'].touched && profileForm.controls['mobile']?.errors?.required"
                                        class="error-msg">
                                        {{'phoneReqMes'|translate}}
                                    </span>
                                    <span
                                        *ngIf="profileForm.controls['mobile'].touched && profileForm.controls['mobile']?.errors?.pattern"
                                        class="error-msg">
                                        {{'phonePatMes'|translate}}.
                                    </span>
                                </div>
                            <!-- </div>

                            <div class="form-row margin-top"> -->

                                <div class="col-md-6 col-sm-6 col-xs-12">
                                    <label for="email">{{'email'|translate}}<span class="color--red">*</span></label>
                                    <input type="email" formControlName="email" class="form-control" id="birtg"
                                        placeholder="{{'email' | translate}}" required="" value={{email}}>

                                    <span
                                        *ngIf="profileForm.controls['email'].touched && profileForm.controls['email']?.errors?.required"
                                        class="error-msg">
                                        {{'emailReqMes'|translate}}
                                    </span>
                                    <span
                                        *ngIf="profileForm.controls['email'].touched && profileForm.controls['email']?.errors?.pattern"
                                        class="error-msg">
                                        {{'emailmustbe'|translate}}.
                                    </span>
                                </div>

                                <!-- <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label>{{'address'|translate}}<span class="color--red">*</span></label>
                                    <input type="text" formControlName="address" class="form-control"
                                        placeholder="{{'address' | translate}}" required=""
                                        value={{address}}>

                                    <span
                                        *ngIf="profileForm.controls['address'].touched && profileForm.controls['address']?.errors?.required"
                                        class="error-msg">
                                        {{'addressReqMes'|translate}}
                                    </span>
                                </div> -->

                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="email">{{'Birthdate'|translate}}<span class="color--red">*</span></label>
                                    <input type="date" formControlName="birth_date" class="form-control" id="fname" >
                                        <span
                                        *ngIf="profileForm.controls['birth_date'].dirty && profileForm.controls['birth_date']?.errors?.required"
                                        class="error-msg">
                                        {{'birthdateReq'|translate}}
                                    </span>
                                </div>

                                 <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="email">{{'Gender'|translate}}<span class="color--red">*</span></label>
                                    <select (change)="selectGender($event)" formControlName="gender" class="form-control">
                                        <option value='m' [selected]="gender == 'm'">{{'Male' | translate}}</option>
                                        <option value='f' [selected]="gender == 'f'">{{'Female' | translate}}</option>
                                    </select>
                                        <span
                                        *ngIf="profileForm.controls['gender'].dirty && profileForm.controls['gender']?.errors?.required"
                                        class="error-msg">
                                        {{'genderReq'|translate}}
                                    </span>
                                </div>

                            </div>

                            <div class="form-row margin-top">

                                <div class="col-md-6">
                                    <label>{{'Profile Photo'|translate}}</label>
                                    <br />
                                    <br />
                                    <label for="profile_img" class="btn btn-outlined x"> {{"upload" | translate}}<i
                                            class="fa fa-upload mr-1"></i></label>
                                    <div class="col"><span>{{filename}}</span></div>
                                    <input (change)="readImage($event)" style="visibility:hidden;" type="file"
                                        placeholder="Upload" id="profile_img" accept="image/x-png,image/gif,image/jpeg">
                                    <ul *ngIf="image_url" class="js-uploader__file-list uploader__file-list">
                                        <li class="js-uploader__file-list uploader__file-list">
                                            <span class="uploader__file-list__thumbnail">
                                                <img id="profileImg" class="thumbnail" id="img_" [src]=' thumbnail?thumbnail : image_url'
                                                    (click)="openImg()">
                                            </span>
                                            <span class="uploader__file-list__button" id="delete">
                                                <a id="close" (click)="closebtn()"
                                                    class="uploader__icon-button cursor--pointer">
                                                    <i class="fa fa-times"></i>
                                                </a>
                                            </span>

                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12">
                                </div>
                            </div>
                        </div>


                        <div class="row update-btn">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <button class="btn btn-solid color--white" type="button" (click)="update()" [disabled]="!profileForm.valid">
                                    {{'Update Profile' | translate}}</button>
                            </div>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->