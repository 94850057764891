import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AboutService } from '../../../shared/services/about.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
declare var google;


declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  lang;
  ContactData;

  address_en;
  address_ar: string = '';

  email;
  phone: string;
  fax;

  contactForm: FormGroup;
  newStructure = [
    {
      "text": "Address",
      "icon": "fa fa-map-marker",
      "value": "1 nasrCity -Cairo"
    },
    {
      "text": "email",
      "icon": "fa fa-envelope-o",
      "value": "hagar@pentavalue.com"
    },

  ]

  geocoder: any;
  public location: Location = {
    lat: 26.389890,
    lng: 50.172114,
    marker: {
      lat: 26.389890,
      lng: 50.172114,
      draggable: true
    },
    zoom: 5
  };
  location_name: string = '';
  contactClass: string = '';
  @ViewChild(AgmMap, { static: true }) map: AgmMap;

  constructor(private translate: TranslateService,
    private service: AboutService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    public mapsApiLoader: MapsAPILoader,
    private router: Router

  ) {

    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });

  }



  ngOnInit(): void {
    this.contactClass = this.router.url.toString().split('/')[1];
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)
    this.spinner.show();

    this.service.getStoreInfo().subscribe(res => {
      this.spinner.hide();

      this.ContactData = res;
      console.log("contactUs=> ", res)

      this.address_en = this.ContactData[7]?.content.en;
      this.address_ar = this.ContactData[7]?.content.ar;

      this.email = this.ContactData[5]?.content.en;
      this.phone = this.ContactData[6]?.content.en;
      this.fax = this.ContactData[3]?.content.en;
      // let result = this.phone.replace('<>', '');
      // this.phone = result;
      // console.log('Logger',this.address_ar.slice(0,3));
      // this.location_name = this.address_ar.replace('<p>','').replace('&nbsp;</p>', '');

    }, error => {
      this.spinner.hide();
      if (this.lang == 'en') {
        this.toastrService.error("PLease Check your Internet Connection,and Try Again")
      } else {
        this.toastrService.error("من فضلك تأكد من وجود انترنت وحاول مرة أخرى")
      }

    })
    this.initForm();
    this.updateOnMap();
  }

  initForm() {
    this.contactForm = new FormGroup({
      first_name: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
      last_name: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^01[0-2]\d{1,8}$/)]),
      message: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z0-9 ]+$[a-zA-Z]*')]),

    })
  }

  public contactusmessage() {

    this.service.contactus(this.contactForm.value).subscribe(res => {
      // if (this.lang == 'en') {
      //   this.toastrService.success("Your Message Has Been Submitted Successfully"); // toasr services
      // } else {
        this.toastrService.success(res['message']); // toasr services
      // }

      this.contactForm.reset();
    }, error => {
      // console.log(error.error.errors)
      this.toastrService.error(error.error.errors); // toasr services

    })
  }

  updateOnMap() {
    let full_address: string = this.location.address_level_1 || ""
    if (this.location.address_level_2) { full_address = full_address + " " + this.location.address_level_2; }
    if (this.location.address_state) { full_address = full_address + " " + this.location.address_state; }
    if (this.location.address_country) { full_address = full_address + " " + this.location.address_country; }

    
    this.findLocation(this.location_name);
  }

  findLocation(address) {
    if (!this.geocoder) { this.geocoder = new google.maps.Geocoder(); }
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types;

          if (types.indexOf('locality') !== -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name;
          }
          if (types.indexOf('country') !== -1) {
            this.location.address_country = results[0].address_components[i].long_name;
          }
          if (types.indexOf('postal_code') !== -1) {
            this.location.address_zip = results[0].address_components[i].long_name;
          }
          if (types.indexOf('administrative_area_level_1') !== -1) {
            this.location.address_state = results[0].address_components[i].long_name;
          }
        }

        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.marker.lat = results[0].geometry.location.lat();
          this.location.marker.lng = results[0].geometry.location.lng();
          this.location.marker.draggable = true;
          this.location.viewport = results[0].geometry.viewport;
        }

        this.map.triggerResize();
      } else {
        // alert("Sorry, this search produced no results.");
      }
    });
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
