<nav class="nav-bar" >
  <div class="site-nav-dropdown_inner gridItem  menubar-section">
    <div class="menu-tool">
      <ul class="main-nav">
        <li class="top-level-link">
          <a [routerLink]="['/home_eleven', lang]"><span>{{'HOME'|translate}}</span></a>      
        </li> 
        
        <li class="top-level-link">
          <a class="mega-menu" [routerLink]="['/home_eleven/categories', lang]"><span>{{'Categories'|translate}}</span></a>
          <div class="sub-menu-block site-nav-dropdown kkl " 
          [style.text-align]="lang=='ar'?'right':'left'"
          >
            <div class="container   style_2">
              <div class="col-1u parent-mega-menu"  [style.float]="lang=='en'?'left':'right'">
                <div class="inner col-xs-12 col-sm-4" *ngFor="let cat of categories" >
                  <div  *ngIf="lang == 'en'" (click)="navigateCategory(cat)" class="hover " style="margin-bottom: 15px;" [style.text-align]="lang=='en'?'left':'right'">
                   {{cat?.name?.en}}
                  </div>

                  <div *ngIf="lang == 'ar'" (click)="navigateCategory(cat)" class="hover " style="margin-bottom: 15px;" [style.text-align]="lang=='en'?'left':'right'">
                    {{cat?.name?.ar}}
                  </div>
                </div>
               
              </div>

              <div class="col-2u"  [style.float]="lang=='en'?'right':'left'">
        
                <div class="col-left col-sm-6" style="float: left;">
                  <a  title="">
                    <img [src]="categories[0]?.home_image[0]?.default" width="225" height="206" alt="">
                  </a>
                  <a  title="">
                    <img [src]="categories[1]?.home_image[0]?.default" width="225" height="206" alt="">
                  </a>
                </div>
                
                
                <div class="col-right col-sm-6">
                  <a  title="">
                    <img [src]="categories[2]?.home_image[0]?.default" width="230" height="417" alt="">
                  </a>
                </div>
                    
        
              </div>
           
            </div>
          </div>
        </li>
        <li class="top-level-link">
          <a [routerLink]="['/home_eleven/products', lang]"><span>{{'PRODUCTS'|translate}}</span></a>    
        </li>
        <li class="top-level-link">
          <a [routerLink]="['/home_eleven/about-us', lang]"><span>{{'about-us'|translate}}</span></a>
         
        </li>
        <li class="top-level-link">
          <a class="mega-menu" [routerLink]="['/home_eleven/contact', lang]"><span>{{'contact'|translate}}</span></a> 
          <div class="sub-menu-block">
            <div class="row">
              <div class="col-md-4 col-lg-4 col-sm-4">
                <h2 class="sub-menu-head">{{'address'|translate}}</h2>
                <ul class="sub-menu-lists">
                  <li class="inner" *ngIf="lang == 'en'" [innerHTML]="address_en"></li>
                  <li class="inner" *ngIf="lang == 'ar'" [innerHTML]="address_ar"></li>

                </ul>           
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4">
                <h2 class="sub-menu-head">{{'email'|translate}}</h2>
                <ul class="sub-menu-lists">
                  <li class="inner" [innerHtml]="email"></li>
                </ul>
              </div>
              <div class="col-md-4 col-lg-4 col-sm-4">
                <h2 class="sub-menu-head">{{'storefax'|translate}}</h2>
                <ul class="sub-menu-lists">
                  <li class="inner" [innerHtml]="phone"></li>
               
                </ul>

               
              </div>

              <div class="featured-content" [style.float]="lang=='en'?' left':'right'" >       
                  
                <a class="two" [routerLink]="['/home_eleven/contact']"  style="padding:0;color:#685f52;background:none;border-bottom:2px solid #685f52;">{{'Details' |translate}}</a>    
                   
              </div>
            </div>
            
            <!-- <div class="row banners-area">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
              </div>
            </div> -->
            
          </div>     
        </li>
      </ul> 
    </div>
  </div>
</nav> 
<!-- //////////////////////////////////////////////////////////////////////////////////////// -->

<!-- <nav role="navigation">
   
  <a href="javascript:void(0);" class="ic menu" tabindex="1">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </a>
  <a href="javascript:void(0);" class="ic close"></a>
  <ul class="main-nav">
    <li class="top-level-link">
      <a><span>Home</span></a>      
    </li> 
    
    <li class="top-level-link">
      <a class="mega-menu"><span>Products</span></a>
      <div class="sub-menu-block">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Clothing</h2>
            <ul class="sub-menu-lists">
              <li><a>Mens</a></li>
              <li><a>Womens</a></li>
              <li><a>Kids</a></li>
              <li><a>New Born</a></li>
              <li><a>View All</a></li>
            </ul>           
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Handbags</h2>
            <ul class="sub-menu-lists">
              <li><a>Wallets</a></li>
              <li><a>Athletic bag</a></li>
              <li><a>Backpack</a></li>
              <li><a>Bucket Bag</a></li>
              <li><a>View All</a></li>
            </ul>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Shoes</h2>
            <ul class="sub-menu-lists">
              <li><a>Mens</a></li>
              <li><a>Womens</a></li>
              <li><a>Kids</a></li>
              <li><a>View All</a></li>
            </ul>
          </div>
        </div>
        
        <div class="row banners-area">
          <div class="col-md-6 col-lg-6 col-sm-6">
            <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
          </div>
          <div class="col-md-6 col-lg-6 col-sm-6">
            <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
          </div>
        </div>
        
      </div>
    </li>
    <li class="top-level-link">
      <a><span>Services</span></a>    
    </li>
    <li class="top-level-link">
      <a class="mega-menu"><span>About</span></a>
      <div class="sub-menu-block">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Company</h2>
            <ul class="sub-menu-lists">
              <li><a>About</a></li>
              <li><a>Mission</a></li>
              <li><a>Community</a></li>
              <li><a>Team</a></li>
            </ul>           
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Media</h2>
            <ul class="sub-menu-lists">
              <li><a>News</a></li>
              <li><a>Events</a></li>
              <li><a>Blog</a></li>
            </ul>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4">
            <h2 class="sub-menu-head">Careers</h2>
            <ul class="sub-menu-lists">
              <li><a>New Opportunities</a></li>
              <li><a>Life @ Company</a></li>
              <li><a>Why Join Us?</a></li>
            </ul>
          </div>
        </div>
        
        <div class="row banners-area">
          <div class="col-md-6 col-lg-6 col-sm-6">
            <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
          </div>
          <div class="col-md-6 col-lg-6 col-sm-6">
            <img src="http://devitems.com/tf/teemo-preview/teemo/img/banner/banner-menu1.jpg" width="100%;">
          </div>
        </div>
        
      </div>
    </li>
    <li class="top-level-link">
      <a><span>Contact</span></a>      
    </li>
  </ul> 
</nav> -->

