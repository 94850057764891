<nav class="navbar navbar-expand-lg navbar-light mobile-nav">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent2"
    aria-controls="navbarSupportedContent2"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent2">
    <ul class="navbar-nav mr-auto">

      
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../general-analysis', lang]">
          <img
            class="src-icon"
            src="assets/images/sahla-icons/clipboard.png"
            alt=""
          />
          {{ "General Analysis" | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../requests', lang]">
          <i
            class="fas fa-phone-square src-icon side-nav-icon"
            style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
          ></i>
  
          {{ "Support Request" | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../search-customer', lang]">
         <i class="fas fa-search" style="font-size: 23px;margin: auto;display: block;"></i>
          {{ "Search Customer" | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../menu-availability', lang]">
         <i class="fa fa-cutlery" style="font-size: 23px;margin: auto;display: block;"></i>
          {{ "product requests" | translate }}
        </a>
      </li>

    </ul>
  </div>
</nav>

<div class="side-nav">
  <ul>

   

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../general-analysis', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/clipboard.png"
          alt=""
        />

        {{ "General Analysis" | translate }}</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../requests', lang]">
        <i
          class="fas fa-phone-square src-icon side-nav-icon"
          style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
        ></i>

        {{ "Support Request" | translate }}</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../search-customer', lang]">
        <i class="fas fa-search" style="font-size: 23px;margin: auto;display: block;"></i>
        {{ "Search Customer" | translate }}</a
      >
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../menu-availability', lang]">
       <i class="fa fa-cutlery" style="font-size: 23px;margin: auto;display: block;"></i>
        {{ "product requests" | translate }}
      </a>
    </li>

  </ul>
</div>
