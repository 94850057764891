import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Observer } from "rxjs";

import { CartService } from "src/app/shared/services/cart.service";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { FirebaseMessaging } from "src/app/shared/services/Firebase-messageing/firebase-messaging.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";
import { WishlistService } from "src/app/shared/services/wishlist.service";
import { nameValidator } from "./name.validator";

declare var $: any;
@Component({
  selector: "app-profile-update",
  templateUrl: "./profile-update.component.html",
  styleUrls: ["./profile-update.component.scss"],
})
export class ProfileUpdateComponent implements OnInit {
  profile_img = "";
  filename: any;
  uploaded_file: boolean;

  config = {
    displayKey: "text",
    search: true,
    height: "auto",
    placeholder: "Select",
    limitTo: 1,
    moreText: "more",
    noResultsFound: "No results found!",
    searchPlaceholder: "Search", // label thats displayed in search input,
    searchOnKey: "text",
  };

  lang;

  profileForm: FormGroup;

  profileData;
  first_name: any;
  last_name: any;
  mobile: any;
  email: any;
  address: any;
  image_url: any;
  thumbnail: any;
  imageName: string = "";
  isChanged: boolean = false;
  check_login: string = "";
  updateClass: string = "";
  gender;
  birthDate;

  constructor(
    private translate: TranslateService,
    private userservice: UserService,
    private toastrService: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private datePipe: DatePipe,
    private firebaseMessaging: FirebaseMessaging
  ) {}
  ngOnInit() {
    this.updateClass = this.router.url.toString().split("/")[1];
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    setTimeout(function () {
      $(".loader-wrapper").fadeOut("slow", function () {
        $(this).remove();
      });
    }, 1999);

    this.userservice.viewprofile().subscribe(
      (res: any) => {
        this.profileData = res;
        this.first_name = res.first_name;
        this.last_name = res.last_name;
        this.mobile = res.mobile;
        this.email = res.email;
        this.gender = res.gender;
        this.image_url = res.image_url;
        this.birthDate = res.birth_date;
        console.log("Profile Data", this.profileData);

        this.profileForm.patchValue({ first_name: this.first_name });
        this.profileForm.patchValue({ last_name: this.last_name });
        this.profileForm.patchValue({ mobile: this.mobile });
        this.profileForm.patchValue({ email: this.email });
        this.profileForm.patchValue({ gender: this.gender });
        this.profileForm.patchValue({ image: this.image_url });
        this.profileForm.patchValue({
          birth_date: this.datePipe.transform(this.birthDate, "yyyy-MM-dd"),
        });
      },
      (error) => {
        this.toastrService.error(error.error.message); // toasr services
      }
    );

    this.initupdateForm();
  }

  initupdateForm() {
    this.profileForm = new FormGroup({
      first_name: new FormControl("", [
        Validators.required,
        nameValidator,
        Validators.pattern("^[\u0621-\u064Aa-zA-Z0-9 ]+$[a-zA-Z]*"),
      ]),
      last_name: new FormControl("", [
        Validators.required,
        nameValidator,
        Validators.pattern("^[\u0621-\u064Aa-zA-Z0-9 ]+$[a-zA-Z]*"),
      ]),
      mobile: new FormControl("", [
        Validators.required,
        Validators.pattern(/^01[0-2,5]\d{1,8}$/),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      image: new FormControl(),
      gender: new FormControl(),
      birth_date: new FormControl(),
    });
  }

  logout() {
    this.firebaseMessaging.deleteFirebaseToken();
    this.check_login = null;
    localStorage.removeItem("token");
    localStorage.removeItem("wishlistItem");
    localStorage.removeItem("userObject");

    this.wishlistService.wishlistProducts.next([]);
    console.log("local storage is cleared");
    let msg: string = "";
    this.translate.get("You Have Been Logout").subscribe((res) => {
      msg = res;
    });
    this.cartService.emptyCart();
    this.toastrService.success(msg);

    this.router.navigate(["login", this.lang]);
    if (this.lang == "en") {
      this.toastrService.success("Please Verify Your Account Again");
    } else {
      this.toastrService.success("من فضلك قم بتفعيل الحساب مرة أخرى");
    }
  }

  update() {
    let routs: any[] = this.router.url.split("/");
    let value: any = this.profileForm.value;
    let formData = new FormData();
    if (this.isChanged) {
      formData.append("image", this.image_url);
    }

    formData.append("name", this.profileForm.value.name);
    if (this.profileForm.value.email != this.email) {
      formData.append("email", this.profileForm.value.email);
    }

    formData.append("address", this.profileForm.value.address);
    if (this.profileForm.value.mobile != this.mobile) {
      formData.append("mobile", this.profileForm.value.mobile);
    }

    formData.append("image", this.image_url);
    // formData.append('formdata',JSON.stringify(value));
    console.log("Form Values", formData.get("address"));

    console.log("Data to be Sent:- ", this.profileForm.value);
    this.userservice.updateprofile(this.profileForm.value).subscribe(
      (res) => {
        if (this.lang == "en") {
          this.toastrService.success(
            "Your Profile Has Been Updated Successfully"
          ); // toasr services;
        } else {
          this.toastrService.success("تم تعديل الحساب بنجاح"); // toasr services;
        }

        this.router.navigate([routs[1] + "/profile", this.lang]);
        if (this.profileForm.value.email != this.email) {
          this.logout();
        }
      },
      (error) => {
        console.log(error.error.errors);
        if (error.error.errors.name) {
          this.toastrService.error(error.error.errors.name[0]); // toasr services
        } else if (error.error.errors.mobile) {
          this.toastrService.error(error.error.errors.mobile[0]); // toasr services
        } else if (error.error.errors.address) {
          this.toastrService.error(error.error.errors.address[0]); // toasr services
        } else if (error.error.errors.email) {
          this.toastrService.error(error.error.errors.email[0]); // toasr services
        } else if (error.error.errors.image) {
          this.toastrService.error(error.error.errors.image[0]); // toasr services
        }
      }
    );
  }

  readImage(event) {
    var files = event.target.files[0];
    // this.name = event.target.files[0].name;
    console.log("The File is ======>", files);
    this.filename = event.target.files[0].name;
    this.uploaded_file = true;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);

    this.image_url = files;
    this.isChanged = true;
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    let url = btoa(binaryString);

    // console.log(this.image_url )
    if (url) {
      let objectURL = "data:image/jpeg;base64," + url;
      this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }

  closebtn(index) {
    this.image_url = "";
    $("#image_url").val("");
  }

  openImg() {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        this.image_url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  selectionChanged(event) {}

  gotoHome() {
    let routs: any[] = this.router.url.split("/");
    console.log(routs);
    this.router.navigate([routs[1] + "/", this.lang]);
  }

  gotoProfile() {
    let routs: any[] = this.router.url.split("/");
    console.log(routs);
    this.router.navigate([routs[1] + "/profile", this.lang]);
  }

  selectGender(event) {
    console.log(event.target.value);
  }
}
