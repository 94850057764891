import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AboutService } from '../../../shared/services/about.service';
declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  lang;
  aboutData;
  About_Title;
  About_Content_EN;
  About_Content_AR;
  aboutCssClass: string = '';
  About_Image;
  socialLinks: any[] = [];

  constructor(
    private translate: TranslateService, 
    private service: AboutService,
    private router: Router) { }
  ngOnInit() {
    console.log("current url, ",this.router.url.toString().split('/'));
    this.aboutCssClass = this.router.url.toString().split('/')[1];
    console.log("the Current class is:- ", this.aboutCssClass);
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)

    this.service.getStoreInfo().subscribe((res: any) => {

      this.aboutData = res;
      // console.log("About Image =====>", res[0].media_collection[0].preview)
      this.About_Content_EN = res[0].content.en;
      this.About_Content_AR = res[0].content.ar;
      this.About_Image = res[0]?.media_collection[0]?.preview;
      console.log("this.About_Content_EN=> ", this.About_Content_EN);
      console.log(" this.About_Content_AR => ", this.About_Content_AR);
    });

    this.service.getSociallinks().subscribe(
      (res: any)=>{
        this.socialLinks = res;
      }
    );

  }

  // Testimonial Carousel
  public testimonial = [{
    image: 'assets/images/default/shop.jpg',
    name: 'Mark jkcno',
    designation: 'Designer',
    description: 'you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings.',
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'Adegoke Yusuff',
    designation: 'Content Writer',
    description: 'you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings.',
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'John Shipmen',
    designation: 'Lead Developer',
    description: 'you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings.',
  }]

  // Teastimonial Slick slider config
  public testimonialSliderConfig = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Team 
  public team = [{
    image: 'assets/images/default/shop.jpg',
    name: 'Mark jkcno',
    designation: 'Designer'
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'Adegoke Yusuff',
    designation: 'Content Writer'
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'John Shipmen',
    designation: 'Lead Developer'
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'Hileri Keol',
    designation: 'CEO & Founder at Company'
  }, {
    image: 'assets/images/default/shop.jpg',
    name: 'John Shipmen',
    designation: 'Lead Developer'
  }]

  // Team Slick slider config
  public teamSliderConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

}
