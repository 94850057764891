<div class="full-height">
    <div class="container">
    <div class="row">
        
        <div class="col-md-12">
        <h2 class="d-block">{{'MyAddress' | translate}}</h2>

            <button class="btn btn-primary" (click)="AddNew()">Add <i class="fa fa-plus"></i></button>
            <div class="col-md-6 address" *ngFor="let address of myAddresses">
                <div class="row">
                    <div class="col-md-10">
                        <p>{{address?.address_type}}</p>
                        <p>{{address?.street_name}}, {{address?.apartment_number}}</p>
                    </div>

                    <div class="col-md-2">
                        <button class="btn btn-primary" (click)="gotoEdit(address, address?.id)"><i class="fa fa-pencil"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>