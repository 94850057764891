import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private httpClient: HttpClientService,
    private httpClientM: HttpClient

  ) { }

  getcities() {
    return this.httpClient.get('cities', []);

  }

  postOrder(orderData, products) {
    orderData['products'] = products;
    console.log("orderData => ", orderData)
    return this.httpClient.post('orders', orderData, {});
  }

  postOrder2(products) {
    return this.httpClient.post('orders', products, {});
  }



  calcOrderPrice(cartItems) {

    let itemsToCalc: any[] = [];

    cartItems.forEach(element => {
      itemsToCalc.push({
        id: element.id,
        quantity: element.quantity
      })
      return this.httpClient.post('orders/calculate', itemsToCalc, null);
    });

  }

  listUserOrders(page:number) {
    return this.httpClient.get('orders', {'page':page});
  }

  donation(amount) {
    return this.httpClient.post('direct-payment', { amount: amount }, {})
  }

  getOrderById(id: number) {
    return this.httpClient.get('orders/' + id, []);
  }

  getOrderStatuses(){
    return this.httpClient.get('lookups/order-statuses', []);
  }
}
