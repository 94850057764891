import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpClientService: HttpClientService) { }


  getHomeData() {
    return this.httpClientService.get('home', []);
  }


  getCategories() {
    return this.httpClientService.get('categories', []);
  }
  getSliderImages()
  {
    return this.httpClientService.get('slider-images',[]);
  }
  getConfig() {
    return this.httpClientService.get('global-settings',[]);
  }

  getBsetSeller(){
    return this.httpClientService.get('products/best-seller/5', []);
  }

  getAreas() {
    return this.httpClientService.get('areas', []);
  }

  getAreasByCityId(id) {
    return this.httpClientService.get('areas', {'city_id': id});
  }
  
  getAreaByCoords(lat, long){
    let body = {
      lat: lat,
      long: long
    }
    return this.httpClientService.post('get-user-area-by-polygon', body, []);
  }
}
