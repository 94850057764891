<h1 mat-dialog-title>{{ "Add item image" | translate }}</h1>
<div *ngIf="!imageChangedEvent && !data.default">
  <div mat-dialog-content class="content">
    <div class="image-browser">
      <div (click)="image.click()">
        <input
          type="file"
          (change)="fileChangeEvent($event)"
          hidden
          #image
          accept="image/png, image/gif, image/jpeg"
        />
        <div class="image-upload">
          <svg
            class="webapp_global-MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"
            ></path>
          </svg>
          <p>{{ "Add item image" | translate }}</p>
          <span>
            {{
              "Image Size: 400 x 300 px / Image formats: jpg, png / 5MB Max."
                | translate
            }}
          </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="guidelines">
      <div class="image-text">
        <img src="../../../../assets/images/unsplash_kcA-c3f_3FE.png" />
        <div class="type do">
          <mat-icon>done</mat-icon>
          <span> {{ "Do" | translate }}</span>
        </div>
        <p>
          {{
            "Images should be high-quality and preferably from a wide-angle"
              | translate
          }}
        </p>
      </div>
      <div class="image-text">
        <img src="../../../../assets/images/unsplash_-Uca3SGlOag.png" />
        <div class="type do">
          <mat-icon>done</mat-icon>
          <span> {{ "Do" | translate }}</span>
        </div>
        <p>
          {{
            "Hands can be shown in the image but need to be clean and styled"
              | translate
          }}
        </p>
      </div>
      <div class="image-text">
        <img src="../../../../assets/images/unsplash_MqT0asuoIcU.png" />
        <div class="type dont">
          <mat-icon>close</mat-icon>
          <span> {{ "Don't" | translate }}</span>
        </div>
        <p>{{ "Item must be centered in the image" | translate }}</p>
      </div>
      <div class="image-text">
        <img src="../../../../assets/images/Group 65273.png" />
        <div class="type dont">
          <mat-icon>close</mat-icon>
          <span> {{ "Don't" | translate }}</span>
        </div>
        <p>{{ "Do not display text or watermarks" | translate }}</p>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>{{ "close" | translate }}</button>
    </div>
  </div>
</div>
<div
  mat-dialog-content
  class="content"
  *ngIf="imageChangedEvent || data.default"
>
  <div class="content-image">
    <div class="col-9">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageURL]="data.default"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="4 / 3"
        [resizeToWidth]="256"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
      >
      </image-cropper>
      <div class="row">
        <div class="text-center col-9">
          <img [src]="croppedImage" />
        </div>
      </div>
      <div class="row text-center actions">
        <div class="col-md-1">
          <mat-icon (click)="rotateLeft()">undo</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="rotateRight()">redo</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="flipHorizontal()" style="transform: rotateZ(90deg)"
            >vertical_align_center</mat-icon
          >
        </div>
        <div class="col-md-1">
          <mat-icon (click)="flipVertical()">vertical_align_center</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="resetImage()">restart_alt</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="zoomIn()">zoom_in</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="zoomOut()">zoom_out</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="toggleContainWithinAspectRatio()"
            >aspect_ratio</mat-icon
          >
        </div>
        <div class="col-md-1">
          <mat-icon (click)="resetImage()">restart_alt</mat-icon>
        </div>
        <div class="col-md-1">
          <mat-icon (click)="clear()">clear</mat-icon>
        </div>
      </div>
    </div>
    <div class="guidelines col-3">
      <h2>{{ "Guidelines" | translate }}</h2>
      <ul>
        <li>
          <p class="do">
            <mat-icon>done</mat-icon>
            {{ "Do" | translate }}
          </p>
          <p>
            {{
              "Images should be high-quality and preferably from a wide-angle"
                | translate
            }}
          </p>
        </li>
        <li>
          <p class="do">
            <mat-icon>done</mat-icon>
            {{ "Do" | translate }}
          </p>
          <p>
            {{
              "Hands can be shown in the image but need to be clean and styled"
                | translate
            }}
          </p>
        </li>
        <li>
          <p class="dont">
            <mat-icon>close</mat-icon>
            {{ "Don't" | translate }}
          </p>
          <p>{{ "Item must be centered in the image" | translate }}</p>
        </li>
        <li>
          <p class="dont">
            <mat-icon>close</mat-icon>
            {{ "Don't" | translate }}
          </p>
          <p>{{ "Do not display text or watermarks" | translate }}</p>
        </li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ "close" | translate }}</button>
    <button mat-raised-button>
      {{ "save" | translate }}
    </button>
  </div>
</div>
