import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-otp-active-menu',
  templateUrl: './otp-active-menu.component.html',
  styleUrls: ['./otp-active-menu.component.css']
})
export class OtpActiveMenuComponent implements OnInit {

  lang;
  categories: any[] = [];
  products: any[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  id: number;
  sidebarShow: boolean = false;
  constructor(
    private categoryService: CategoriesService,
    private translateService: TranslateService,
    private http: HttpClientService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.id = +localStorage.getItem('merchantId');
    this.getCategories();
  }

  getCategories(){
    this.http.get('merchants/' + this.id, {}).subscribe(
      res=>{
        this.categories = res['merchant'].categories;
        this.products = res['products'];
      }
    );
  }

  getProducts(id, category){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/opt-products', this.lang], {queryParams:{'id': id}});
    let prods: any[] = [];
    this.products.forEach(
      element=>{
        if(element.category_id == id){
          prods.push(element);
        }
    });
    localStorage.setItem('OtpCategory', category);
    localStorage.setItem('OtpProducts', JSON.stringify(prods));
  }
}
