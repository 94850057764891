import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $:any;


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit,OnDestroy {

  public shoppingCartItems  :   any[] = [];
  lang:string;
  cartClass: string = '';
  totalPrice: number;
  closeResult = '';
  @ViewChild('content')
  content: any;
  item: any;
  merchantName:any;
  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    public translate: TranslateService, 
    private router:Router,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
    ) {
     }
   
  ngOnInit() {
    // this.shoppingCartItems = this.cartService.getItems();
    this.cartClass = this.router.url.toString().split('/')[1];
    console.log(this.cartClass);
    this.getCartItems();
    this.cartService.cartUpdated.subscribe(res => {
      this.getCartItems();
    });
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    // this.cartService.orderChanged.subscribe(
    //   res=>{
    //     this.shoppingCartItems = res;
    //   }
    // );
    
    // this.cartService.cartUpdated.subscribe(res => {
    //   this.shoppingCartItems = this.cartService.getItems();
    // })
  }

  getCartItems(){
    this.spinner.show();
    this.cartService.getItems().subscribe(
      res=>{
        this.shoppingCartItems = res['cart']?.product_items;
        console.log('Shopping Cart', this.shoppingCartItems)
        this.totalPrice = res['cart']?.total;
        this.merchantName = res['cart']?.merchant?.name;
        this.spinner.hide();
      },
      error=>{
        this.spinner.hide();
      }
    );
    
  }

 
  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    quantity = product.quantity + 1;
    this.cartService.updateCartQuantity(product,quantity, product.id).subscribe(
      res=>{
        // this.toasterService.success(res['message']);
        this.getCartItems();
        this.cartService.cartUpdated.emit();
      },
      error=>{
        console.log(error);
      }
    )
    
  }
  
  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    quantity = product.quantity - 1;
    if(quantity == 0){
      // this.removeItem(product);
      this.item = product;
      this.open(this.content);
    }else{
      this.cartService.updateCartQuantity(product,quantity, product.id).subscribe(
        res=>{
          // this.toasterService.success(res['message']);
          this.getCartItems();
        },
        error=>{
          console.log(error);
        }
      )
    }
    
  }
  
  // Get Total
  // public getTotal() {
  //   this.totalPrice = this.cartService.getTotalAmount();
  // }
  
  // Remove cart items
  public removeItem(item: any) {
    this.cartService.removeFromCart(item.id).subscribe(
      res=>{
        this.toasterService.success(res['message']);
        this.getCartItems();
        this.cartService.cartUpdated.emit();
        this.modalService.dismissAll();
      }
    );
    
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  save(){
    console.log('Deleted Successfully');
    this.removeItem(this.item);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    // this.cartService.cartUpdated.unsubscribe();
  }

  gotoCheckout() {
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/payments/checkout', this.lang]);
  }

  gotoCategory() {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang]);
  }

  gotoProduct(id) {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/product', this.lang, id]);
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

}
