import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $:any;



@Component({
  selector: 'app-cart6',
  templateUrl: './cart6.component.html',
  styleUrls: ['./cart6.component.css']
})
export class Cart6Component implements OnInit {


  public shoppingCartItems  :   any[] = [];
  lang:string;
  total:any;

  constructor(private productsService: ProductsService,
    private cartService: CartService,public translate: TranslateService, private router:Router
    ) {
     }
   
  ngOnInit() {
    // this.shoppingCartItems = this.cartService.getItems();
    this.getCartItems();
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    // this.cartService.orderChanged.subscribe(
    //   res=>{
    //     this.shoppingCartItems = res;
    //   }
    // );
    
    // this.cartService.cartUpdated.subscribe(res => {
    //   this.shoppingCartItems = this.cartService.getItems();
    // })
  }

  getCartItems(){
    this.cartService.getItems().subscribe(
      res=>{
        this.shoppingCartItems = res['cart'].product_items;
        this.total = res['cart'].total_price;
      }
    );
  }

 
  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    quantity = product.quantity + 1;
    this.cartService.updateCartQuantity(product,quantity, product.id).subscribe(
      res=>{
        // this.toasterService.success(res['message']);
        this.getCartItems();
      },
      error=>{
        console.log(error);
      }
    )
    
  }
  
  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    quantity = product.quantity - 1;
    this.cartService.updateCartQuantity(product,quantity, product.id).subscribe(
      res=>{
        // this.toasterService.success(res['message']);
        this.getCartItems();
      },
      error=>{
        console.log(error);
      }
    )
  }
  
  // Get Total
  // public getTotal(): number {
  //   return this.cartService.getTotalAmount();
  // }
  
  // Remove cart items
  public removeItem(item: any) {
    this.cartService.removeFromCart(item.id);
    this.getCartItems();
  }

  ngOnDestroy() {
    // this.cartService.cartUpdated.unsubscribe();
  }


}
