import { Component, OnInit } from '@angular/core';
import { AboutService} from '../../../../shared/services/about.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  social;

  twitter;
  twitter_link;

  instagram;
  instgram_link;

  constructor(private service:AboutService) { }

  ngOnInit() {
    this.service.getSociallinks().subscribe(res=>{
      console.log("social links=> ",res)
       this.social = res;
    })
  }

  openLink(link)
  {

    window.open(`${link}`,'_blank')
  }

}
