import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import { TranslateService } from '@ngx-translate/core';
import {AllproductsService} from '../../../../shared/services/allproducts.service';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  
  public products : any[] = [];
  lang;	
  newproducts;
  constructor(
    private productsService: ProductsService,
    private translate: TranslateService,
    private allproductservice:AllproductsService,
    private router: Router) { }
 
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 

    this.allproductservice.getnewproducts().subscribe(
      (product: any) => {
        this.products = product['data'];
        console.log("The New Products", this.products);
        this.products.forEach(element=>{
          console.log("Images", element?.mediaCollection[0]?.default);
        })
    }); 
    // this.allproductservice.getnewproducts().subscribe((res:any)=>{
    //   this.newproducts = res;
    // })
  }

  gotoDetails(id: number) {
    let routs:any[] = this.router.url.split('/');
    console.log(this.router.url.split('/'))
    this.router.navigate([routs[1] + '/product', this.lang, id])
  }

}
 