import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    let newValue = value.split(":");

    return newValue[0] + ":" + newValue[1];
  }
}
