export const time = [
  { key: "00:00:00", text: "00:00 AM" },
  { key: "00:30:00", text: "00:30 AM" },
  { key: "01:00:00", text: "01:00 AM" },
  { key: "01:30:00", text: "01:30 AM" },
  { key: "02:00:00", text: "02:00 AM" },
  { key: "02:30:00", text: "02:30 AM" },
  { key: "03:00:00", text: "03:00 AM" },
  { key: "03:30:00", text: "03:30 AM" },
  { key: "04:00:00", text: "04:00 AM" },
  { key: "04:30:00", text: "04:30 AM" },
  { key: "05:00:00", text: "05:00 AM" },
  { key: "05:30:00", text: "05:30 AM" },
  { key: "06:00:00", text: "06:00 AM" },
  { key: "06:30:00", text: "06:30 AM" },
  { key: "07:00:00", text: "07:00 AM" },
  { key: "07:30:00", text: "07:30 AM" },
  { key: "08:00:00", text: "08:00 AM" },
  { key: "08:30:00", text: "08:30 AM" },
  { key: "09:00:00", text: "09:00 AM" },
  { key: "09:30:00", text: "09:30 AM" },
  { key: "10:00:00", text: "10:00 AM" },
  { key: "10:30:00", text: "10:30 AM" },
  { key: "11:00:00", text: "11:00 AM" },
  { key: "11:30:00", text: "11:30 AM" },
  { key: "12:00:00", text: "12:00 PM" },
  { key: "12:30:00", text: "12:30 PM" },
  { key: "13:00:00", text: "01:00 PM" },
  { key: "13:30:00", text: "01:30 PM" },
  { key: "14:00:00", text: "02:00 PM" },
  { key: "14:30:00", text: "02:30 PM" },
  { key: "15:00:00", text: "03:00 PM" },
  { key: "15:30:00", text: "03:30 PM" },
  { key: "16:00:00", text: "04:00 PM" },
  { key: "16:30:00", text: "04:30 PM" },
  { key: "17:00:00", text: "05:00 PM" },
  { key: "17:30:00", text: "05:30 PM" },
  { key: "18:00:00", text: "06:00 PM" },
  { key: "18:30:00", text: "06:30 PM" },
  { key: "19:00:00", text: "07:00 PM" },
  { key: "19:30:00", text: "07:30 PM" },
  { key: "20:00:00", text: "08:00 PM" },
  { key: "20:30:00", text: "08:30 PM" },
  { key: "21:00:00", text: "09:00 PM" },
  { key: "21:30:00", text: "09:30 PM" },
  { key: "22:00:00", text: "10:00 PM" },
  { key: "22:30:00", text: "10:30 PM" },
  { key: "23:00:00", text: "11:00 PM" },
  { key: "23:30:00", text: "11:30 PM" },
  { key: "00:00:00", text: "00:00 AM" },
];
