<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
            <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <h2>{{'Vendor Performance' | translate}}</h2>

                <div class="performance">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Orders</h4>
                                    <div class="row">
                                        <div class="col-md-4">
                                            Total Sales
                                            <p>{{totalSales}} {{'EGP' | translate}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            Successful orders
                                            <p>{{successfulOrders}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            Failed orders
                                            <p>{{failedOrders}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                                </div>
                                <div class="card-footer">
                                    <!-- <button class="btn btn-link">
                                        SEE ALL ORDERS
                                    </button> -->
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h2>Loss due to cancellations</h2>
                                    <img src="assets/images/starRating.svg" alt="img"/>
                                    <p>
                                        No cancelled orders for the selected period
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h2>Order health score</h2>
                                    <p-chart type="doughnut" [data]="data" [options]="chartOptions" [style]="{'width': '25%'}"></p-chart>
                                    <p>Order health is the talabat's standard for operational experience. It reports on order response time, number of customer contacts and delivery handoff delay.</p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h2>Customers</h2>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h4>Returning customers</h4>
                                            <span>{{returnCustomers}}</span>
                                        </div>

                                        <div class="col-md-6">
                                            <h4>New customers</h4>
                                            <span>{{newCustomers}}</span>
                                        </div>
                                    </div>
                                    <p-chart type="bar" [data]="barData" [options]="horizontalOptions"></p-chart>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <h2>Order contacts</h2>
                                    <img src="assets/images/starRating.svg" alt="img"/>
                                    <p>
                                        We didn't get any complaints or inquires from your customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>