<!-- price filter start here -->
<div class="collection-collapse-block border-0 open">
    <h3 class="collapse-block-title">Rating</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div class="mt-5">
               <star-rating value="3" totalstars="5" checkedcolor="orange" uncheckedcolor="black" size="24px" readonly="false" ></star-rating>
            </div>
        </div>
    </div>
</div>