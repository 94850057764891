<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav2></app-side-nav2>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <h2>{{ "Support Requests" | translate }}</h2>

        <div class="row">
          <div class="col-md-4 col-sm-3 col-xs-12" style="text-align: center">
            <button class="btn cuurent-status">
              <span
                class="circle"
                style="color: rgb(254, 92, 103); padding: 0px 5px"
                >{{ open }}</span
              >
              <span style="color: rgb(239, 46, 59)">{{
                "Open Requests" | translate
              }}</span>
            </button>
          </div>

          <div class="col-md-4 col-sm-3 col-xs-12" style="text-align: center">
            <button class="btn cuurent-status">
              <span
                class="circle"
                style="color: rgb(109, 149, 197); padding: 0px 5px"
                >{{ pending }}</span
              >
              <span style="color: rgb(81, 112, 151)">{{
                "Pending Requests" | translate
              }}</span>
            </button>
          </div>

          <div class="col-md-4 col-sm-3 col-xs-12" style="text-align: center">
            <button class="btn cuurent-status">
              <span
                class="circle"
                style="color: rgb(101, 184, 101); padding: 0px 5px"
                >{{ solved }}</span
              >
              <span style="color: rgb(81, 151, 81)">{{
                "Solved Requests" | translate
              }}</span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <table class="table" *ngIf="requests?.length > 0">
              <thead>
                <tr style="background: #f8f9fa">
                  <th scope="col">{{ "id" | translate }}</th>
                  <th scope="col">{{ "topic" | translate }}</th>
                  <th scope="col">{{ "Brand Name" | translate }}</th>
                  <th scope="col">{{ "Request text" | translate }}</th>
                  <th scope="col">{{ "Request file" | translate }}</th>
                  <th scope="col">{{ "Reply" | translate }}</th>
                  <th scope="col">{{ "change status" | translate }}</th>
                  <!-- <th scope="col">{{'Status'|translate}}</th> -->
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let request of requests">
                  <th>{{ request.id }}</th>

                  <td *ngIf="request.topic != null">
                    {{ request.topic.title }}
                  </td>
                  <td *ngIf="request.topic == null"></td>

                  <td *ngIf="request.merchant_brand !== null">
                    {{ request.merchant_brand.title }}
                  </td>
                  <td *ngIf="request.merchant_brand === null"></td>

                  <td>
                    {{ request.request_text }}
                  </td>
                  <td>
                    <button
                      (click)="viewFile(request)"
                      *ngIf="request.request_file"
                      class="btn btn-primary"
                    >
                      View File
                    </button>
                  </td>

                  <td *ngIf="request.admin_reply == null">
                    <button
                      class="reply-btn"
                      (click)="
                        OpenPopup(
                          content,
                          request.id,
                          request.status,
                          request.merchant_brand_id
                        )
                      "
                    >
                      {{ "Send Your Reply" | translate }}
                    </button>
                  </td>

                  <td *ngIf="request.admin_reply != null">
                    {{ request.admin_reply }}
                  </td>

                  <td>
                    <mat-form-field>
                      <mat-select
                        class="cuurent-status"
                        (selectionChange)="changeStat($event, request.id)"
                        [(value)]="request.status"
                      >
                        <mat-option value="open"> open </mat-option>

                        <mat-option value="pending"> pending </mat-option>

                        <mat-option value="solved"> solved </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>

                  <!-- <td>{{ request.status}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "Send Your Reply" | translate }}
    </h4>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="replyForm">
    <div class="modal-body">
      <input class="form-control" formControlName="admin_reply" type="text" />
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-outline-dark"
        (click)="SendReply(replyForm.value); modal.dismiss('Cross click')"
      >
        {{ "submit" | translate }}
      </button>
      <button
        class="btn btn-outline-dark"
        (click)="modal.dismiss('Cross click')"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
  </form>
</ng-template>
