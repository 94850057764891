import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation-error',
  templateUrl: './donation-error.component.html',
  styleUrls: ['./donation-error.component.css']
})
export class DonationErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999);
  }

}
