<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "All Reports" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <p>
              Disclaimer: Please note that the reports present raw and
              unconditioned order data. Revenues include only food values
              (excluding discounts, vouchers, delivery fees, etc). Final
              settlements can be found from the invoices.
            </p>

            <div class="sections">
              <div class="section" (click)="goto('end-of-day')">
                <div class="row">
                  <div class="col-md-2">
                    <!-- <i class="fa fa-dollar-sign"></i> -->
                    <i class="fas fa-file-alt"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "End of day report" | translate }}</h1>
                    <p>{{ "Summary of The day" | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="section" (click)="goto('area-performance')">
                <div class="row">
                  <div class="col-md-2">
                    <i class="fa fa-chart-line"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "Performance Report" | translate }}</h1>
                    <p>
                      {{ "Orders and revenue with share option" | translate }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="section" (click)="goto('order-details-report')">
                <div class="row">
                  <div class="col-md-2">
                    <!-- <i class="fa fa-bars"></i> -->
                    <i class="fas fa-file-signature"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "Order details" | translate }}</h1>
                    <p>{{ "Order history with detailed data" | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="section" (click)="goto('revenue-hour-report')">
                <div class="row">
                  <div class="col-md-2">
                    <!-- <i class="fa fa-bars"></i> -->
                    <i class="fas fa-file-invoice"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "Revenue by hour" | translate }}</h1>
                    <p>{{ "Revenue by hours report" | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="section" (click)="goto('menu-performance')">
                <div class="row">
                  <div class="col-md-2">
                    <!-- <i class="fas fa-utensil-spoon"></i> -->
                    <i class="fas fa-file-invoice-dollar"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "Sales by Item" | translate }}</h1>
                    <p>{{ "Menu items by popularity" | translate }}</p>
                  </div>
                </div>
              </div>

              <div class="section" (click)="goto('sales-by-area')">
                <div class="row">
                  <div class="col-md-2">
                    <i class="fas fa-chart-area"></i>
                  </div>

                  <div class="col-md-10">
                    <h1>{{ "Sales by Area" | translate }}</h1>
                    <p>{{ "Sales by Area Report" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
