<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <h2>
          {{ "Branch Status" | translate }}
        </h2>
        <div class="text-desc-content-card">
          <h3>
            {{ merchantName }}
          </h3>
          <!-- <div class="text-desc-content" *ngIf="status == 'busy'">
                <i class="fa fa-hand-paper mr-3"></i>
                <p>
                  {{'busy branch' | translate}}
                </p>
            </div>

            <div class="text-desc-content" *ngIf="status == 'open'">
              <i class="fa fa-check mr-3"></i>
              <p>
                  {{'open branch' | translate}}
              </p>
            </div>

          <div class="text-desc-content" *ngIf="status == 'closed'">
            <i class="fa fa-times mr-3"></i>
            <p>
              {{'close branch' | translate}}
            </p>
          </div>

            <div class="card" (click)="changeStatus('open')">
                <div class="card-body">
                  <h3>{{'OPEN' | translate}}</h3>
                  <p>{{'Ready To Receive Orders' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="makeMerchantBusy(30, 'busy')">
                <div class="card-body">
                  <h3>{{'BUSY' | translate}}</h3>
                  <p>30 {{'mins' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="makeMerchantBusy(60, 'busy')">
                <div class="card-body">
                  <h3>{{'BUSY' | translate}}</h3>
                  <p>60 {{'mins' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="makeMerchantBusy(120, 'busy')">
                <div class="card-body">
                  <h3>{{'BUSY' | translate}}</h3>
                  <p>120 {{'mins' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="makeMerchantBusy(240, 'busy')">
                <div class="card-body">
                  <h3>{{'BUSY' | translate}}</h3>
                  <p>240 {{'mins' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="makeMerchantBusy(720, 'busy')">
                <div class="card-body">
                  <h3>{{'BUSY' | translate}}</h3>
                  <p>720 {{'mins' | translate}}</p>
                </div>
              </div>

              <div class="card" (click)="changeStatus('closed')">
                <div class="card-body">
                  <h3>{{'Closed' | translate}}</h3>
                  <p>{{'Not Ready To Receive Orders, Untill Opened' | translate}}</p>
                </div>
              </div> -->

          <div class="row mb-3" style="margin-top: 20px">
            <div class="col-md-4">
              <div class="card" style="border-left: 15px solid #53a153">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 text-right">
                      <span class="number">{{ OpenBranchsCount }}</span>
                    </div>
                    <div class="col-md-6">
                      <h3>{{ "open" | translate | titlecase }}</h3>
                      <span>{{ "Branches" | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card" style="border-left: 15px solid #f95c00">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 text-right">
                      <span class="number">{{ BusyBranchsCount }}</span>
                    </div>
                    <div class="col-md-6">
                      <h3>{{ "busy" | translate }}</h3>
                      <span>{{ "Branches" | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card" style="border-left: 15px solid #d50000">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 text-right">
                      <span class="number">{{ ClosedBranchsCount }}</span>
                    </div>
                    <div class="col-md-6">
                      <h3>{{ "closed" | translate }}</h3>
                      <span>{{ "Branches" | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-1 align-self-end">
              <div class="refresh-status">
                <label>{{ refreshStatusCounter }}s</label>
                <mat-icon class="replay" (click)="onReloadCounterClick()"
                  >replay</mat-icon
                >
              </div>
            </div>
          </div>
          <div class="card" style="border-radius: 0px; margin-top: 20px">
            <div class="card-body">
              <div class="row">
                <div
                  [class.col-md-3]="lang == 'en'"
                  [class.col-md-2]="lang == 'ar'"
                >
                  <h2 *ngIf="UserType == 'brand_manager'">
                    {{ "Branches" | translate }}
                  </h2>
                  <h2 *ngIf="UserType != 'brand_manager'">
                    {{ merchantName }}
                  </h2>
                  <ul *ngIf="UserType == 'brand_manager'">
                    <li (click)="this.tabId = 0">
                      <a
                        >{{ "All" | translate }}({{
                          this.MerchantsList?.length
                        }})</a
                      >
                    </li>
                    <li (click)="this.tabId = 1">
                      <a
                        >{{ "open" | translate }}({{
                          this.OpenBranchsCount
                        }})</a
                      >
                    </li>
                    <li (click)="this.tabId = 2">
                      <a
                        >{{ "busy" | translate }}({{
                          this.BusyBranchsCount
                        }})</a
                      >
                    </li>
                    <li (click)="this.tabId = 3">
                      <a
                        >{{ "closed" | translate }}({{
                          this.ClosedBranchsCount
                        }})</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-md-9">
                  <table class="table">
                    <tr>
                      <th>{{ "branch_name" | translate | titlecase }}</th>
                      <th>{{ "status" | translate | titlecase }}</th>
                      <th>{{ "time" | translate }}</th>
                      <th></th>
                    </tr>
                    <tr *ngFor="let branch of setCurrentTab()">
                      <td>{{ branch.name | titlecase }}</td>
                      <td>{{ branch.status | translate | titlecase }}</td>
                      <td>
                        {{ branch.updated_at | date: "MMM d, y, h:mm a" }}
                      </td>
                      <td>
                        <button
                          class="btn btn-default"
                          (click)="openStatusBar(branch)"
                        >
                          {{ "update" | translate }}
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Side Bar For Status update-->
<div
  class="sidebar-slider"
  [class.sidebar-slide-in]="showUpdateBar"
  [class.sidebar-slider-ar]="lang == 'ar'"
>
  <div class="sidebar-header">
    <div
      class="sidebar-close"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'en'"
    >
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div
      class="sidebar-close"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'ar'"
    >
      <mat-icon>arrow_forward</mat-icon>
    </div>
    <div class="branch-name">
      <h1>{{ currentBranch?.name | titlecase }}</h1>
    </div>
  </div>
  <div class="sidebar-content">
    <div class="info-wrapper row">
      <div class="status col">
        <label class="title">{{ "status" | translate | titlecase }}</label>
        <div class="cuurent-status">
          <span
            class="circle"
            [class]="
              currentBranch?.status != 'closed' &&
              currentBranch?.status != 'busy' &&
              currentBranch?.status != 'open'
                ? 'busy'
                : currentBranch?.status
            "
          ></span>
          <span>{{ currentBranch?.status | translate | titlecase }}</span>
        </div>
      </div>
      <div class="status col">
        <label class="title">{{ "time" | translate }}</label>
        <label>{{ currentBranch?.updated_at | date: "MMM d, y" }}</label>
        <label>{{ currentBranch?.updated_at | date: "h:mm a" }}</label>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row info-wrapper">
      <mat-form-field>
        <span
          class="circle"
          [class]="
            status != 'closed' && status != 'busy' && status != 'open'
              ? 'busy'
              : status
          "
        ></span>
        <p *ngIf="status == 'busy'">{{ currentBranch?.status | translate }}</p>
        <mat-select
          [(ngModel)]="status"
          (selectionChange)="onStatusChange($event)"
        >
          <mat-option
            class="cuurent-status"
            *ngFor="let s of StatusList"
            [value]="s"
            [disabled]="
              s == currentBranch?.status ||
              (currentBranch?.status == 'closed' && s.includes('busy'))
            "
            ><span
              [class]="s != 'closed' && s != 'open' ? 'busy' : s"
              class="circle"
            ></span>
            <span>{{ s | translate | titlecase }}</span></mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row info-wrapper justify-content-end">
      <button mat-button class="btn" (click)="updateBranchStatus()">
        {{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
