<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3 header">
        <div>
          <h2>
            {{ "menu management" | translate }}
          </h2>
          <h3>
            {{ merchantName }}
          </h3>
        </div>
        <div *ngIf="type == 'brand_manager'">
          <mat-form-field>
            <mat-select
              (selectionChange)="onMerchantChange($event)"
              [value]="merchant"
            >
              <mat-option
                class="cuurent-status"
                *ngFor="let m of merchantsList"
                [value]="m"
              >
                {{ m.name | titlecase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="card-container">
        <mat-card [routerLink]="['/otphome/menu-availability', lang]"
          ><mat-icon>visibility_off</mat-icon>
          <label> {{ "menu availabilty" | translate }}</label></mat-card
        >
        <mat-card *ngIf="type == 'brand_manager'" [routerLink]="['/otphome/menu-operations', lang]"
          ><mat-icon>list</mat-icon
          ><label>{{ "menuoperation" | translate }}</label></mat-card
        >
      </div>
    </div>
  </div>
</div>
