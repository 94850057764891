import { Component, OnInit } from "@angular/core";
// import { ExportAsConfig, ExportAsService } from 'ngx-export-as';

@Component({
  selector: "app-print-orders",
  templateUrl: "./print-orders.component.html",
  styleUrls: ["./print-orders.component.css"],
})
export class PrintOrdersComponent implements OnInit {
  orders: any[] = [];
  displayedColumns: string[] = [];
  config: any;
  page: number = 1;
  perPage: any;
  totalCount: any;
  hasPagination: boolean = false;
  // exportAsConfig: ExportAsConfig = {
  //   type: 'pdf', // the type you want to download
  //   elementIdOrContent: 'element', // the id of html/table element
  // };

  constructor() {} // private exportAsService: ExportAsService,

  ngOnInit(): void {
    this.orders = JSON.parse(localStorage.getItem("orders"));
    this.displayedColumns = JSON.parse(
      localStorage.getItem("displayedColumns")
    );
  }

  print() {
    // this.exportAsService.save(this.exportAsConfig, 'Orders').subscribe(() => {
    //     // save started
    //   });
  }
}
