<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <div class="page-title">
          <h2>{{'wishlist'|translate}}</h2>
        </div>
      </div>
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="''">{{'home'|translate}}</a></li>
            <li class="breadcrumb-item active">{{'wishlist'|translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!--section start-->
<section class="wishlist-section section-b-space">
  <div class="container">
    <div class="col-md-12 empty-cart-cls text-center" *ngIf='!wishlistItems.length'>
      <img src="assets/images/empty-wishlist.png" alt="Empty cart" title="Emprt cart">
      <h3 class="mt-4"><b>{{'wishempty'|translate}}</b></h3>
      <h4>{{'Explore More'|translate}}</h4>
    </div>
    <div class="row" *ngIf='wishlistItems.length'>
      <div class="col-sm-12">
        <table class="table cart-table table-responsive-xs">
          <thead>
            <tr class="table-head">
              <th scope="col">{{'image'|translate}}</th>
              <th scope="col">{{'product'|translate}}</th>
              <th scope="col">{{'price'|translate}}</th>
              <th scope="col">{{'productavalibility'|translate}}</th>
              <th scope="col">{{'action'|translate}}</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of wishlistItems">
            <tr>
              <td>
                <a [routerLink]="['/product', item.id]">
                  <img [src]="item.pictures[0]" alt="">
                </a>
              </td>
              <td>
                <a [routerLink]="['/product', item.id]">{{ item.name }}</a>
                <div class="mobile-cart-content row">
                  <div class="col-xs-3">
                    <p>in stock</p>
                  </div>
                  <div class="col-xs-3">
                    <h2 class="td-color">{{ item.price }} {{'SAR'|translate}}</h2>
                  </div>
                  <div class="col-xs-3">
                    <h2 class="td-color">
                      <a [routerLink]="" (click)="removeItem(item)" class="icon mr-1">
                        <i class="ti-close"></i>
                      </a>
                      <a [routerLink]="" (click)="addToCart(item)" class="cart">
                        <i class="ti-shopping-cart"></i>
                      </a>
                    </h2>
                  </div>
                </div>
              </td>
              <td>
                <h2>{{ item.price }} {{'SAR'|translate}}</h2>
              </td>
              <td>
                <p>{{'stock'|translate}}</p>
              </td>
              <td>
                <a [routerLink]="" (click)="removeItem(item)" class="icon mr-3">
                  <i class="ti-close"></i>
                </a>
                <a [routerLink]="'/cart'" (click)="addToCart(item)" class="cart">
                  <i class="ti-shopping-cart"></i>
                </a>
              </td> 
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row wishlist-buttons" *ngIf='wishlistItems.length'>
      <div class="col-12">
        <a [routerLink]="['/home/left-side/collection/all']" class="btn btn-solid">{{'continueShopping'|translate}}</a>
        <a [routerLink]="['payments/checkout']" class="btn btn-solid">{{'checkout'|translate}}</a>
      </div>
    </div>
  </div>
</section>
<!--section end-->
