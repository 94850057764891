import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {

  public products: Product[] = [];
  
  constructor(private productsService: ProductsService,private translate: TranslateService) { }
  lang;

  ngOnInit() {
    this.productsService.getProducts().subscribe(product => this.products = product);
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
  }

}  
