import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-all-reports",
  templateUrl: "./all-reports.component.html",
  styleUrls: ["./all-reports.component.scss"],
})
export class AllReportsComponent implements OnInit {
  name;
  lang;
  isLogged: boolean = false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.name = localStorage.getItem("merchantName");
    if (localStorage.getItem("optToken")) {
      this.isLogged = true;
    }

    this.userService.isLogged.subscribe((res) => {
      this.isLogged = res;
    });
  }

  goto(page) {
    let routs: any[] = this.router.url.split("/");
    if (this.isLogged) {
      this.router.navigate([routs[1] + "/" + page, this.lang]);
    } else {
      this.router.navigate([routs[1] + "/otplogin", this.lang]);
    }
  }
}
