<div class="footer-title">
    <h4>{{'categories'|translate}}</h4>
</div>
<div style="display: block !important;">
    <ul>
        <!-- <li><a [routerLink]="['/category','women']">{{'women-fashion'|translate}}</a></li>
        <li><a [routerLink]="['/category','men']">{{'mens-fashion'|translate}}</a></li>
        <li><a [routerLink]="['/category','watche']">Watches</a></li>
        <li><a [routerLink]="['/category','flower']">{{'flower'|translate}}</a></li> -->
        <li *ngFor="let cat of categories">
            <!-- <a [routerLink]="['/category',cat.id]" (click)="getOneCat(cat.id)" *ngIf="lang == 'en'">{{cat.title.En}}</a>
            <a [routerLink]="['/category',cat.id]" (click)="getOneCat(cat.id)" *ngIf="lang == 'ar'">{{cat.title.ar}}</a> -->
            <a [routerLink]="['/category']" [queryParams]="{'categories': cat.id}"  *ngIf="lang == 'en'">{{cat.title.En}}</a>
            <a [routerLink]="['/category']" [queryParams]="{'categories': cat.id}" *ngIf="lang == 'ar'">{{cat.title.ar}}</a>
        </li>
    </ul>
</div>