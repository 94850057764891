<div class="container-fluid px-0">

    <div class="row no-gutters">
        <div class="col-1">
            <div class="side-nav">
                <ul>
                    <li class="nav-item">
                    
                        <a class="nav-link" [routerLink]="['../../opt-orders', lang]">Orders <span
                                class="sr-only">(current)</span> </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['../../opt-adv-repo', lang]">Advanced Reports</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['../../opt-menu', lang]">Active Menu</a>
                    </li>
                </ul>
            </div>
            <ul>

            </ul>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <div class="row">
                    <div class="col-md-4">
                        <div class="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action active" id="list-home-list"
                                data-toggle="list" href="#list-home" role="tab" aria-controls="home">
                                Order Overview
                            </a>
                            <a class="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list"
                                href="#list-profile" role="tab" aria-controls="profile">
                                Recent Orders
                            </a>
                            <a class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list"
                                href="#list-messages" role="tab" aria-controls="messages">my menu</a>
                        </div>
                    </div>


                    <div class="col-md-8">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                aria-labelledby="list-home-list">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h3>New
                                            <span>0</span>

                                        </h3>
                                        <div class="card">
                                            <div class="card-body">
                                                No new Orders
                                            </div>
                                        </div>


                                        <div class="sidebar-opener" (click)="sidebarShow = !sidebarShow">
                                            <div class="card accepted-card">
                                                <div class="card-body purple-bg">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 class="mb-0">
                                                                #00
                                                            </h4>
                                                            <span>1 item</span>
                                                        </div>
                                                        <div>
                                                            15 mints

                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-wrap justify-content-between accepted-btns">
                                                        <button type="button"
                                                            class="btn btn-outline-light">Accept</button>
                                                        <button type="button"
                                                            class="btn btn-outline-light">Reject</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow">
                                            <div class="sidebar-close" (click)="sidebarShow = !sidebarShow">
                                                X
                                            </div>
                                            <div class="sidebar-content">
                                                <div>
                                                    <div class="my-2 w-100 d-flex flex-wrap justify-content-between">
                                                        <h2 class="mb-0">Order#00</h2>
                                                        <span class="mints">15 mints</span>
                                                    </div>

                                                    <p>xxx-12345</p>

                                                    <div class="badge badge-pill badge-primary text-uppercase">
                                                        Test Order
                                                    </div>

                                                    <div class="order-content">
                                                        <div class="d-flex">
                                                            <i class="fas fa-map-pin"></i>
                                                            <div>
                                                                <div class="main-det">
                                                                    <p>
                                                                        Lorem ipsum dolor sit, amet consectetur
                                                                        adipisicing elit.
                                                                    </p>
                                                                    <p>
                                                                        Berlin
                                                                    </p>
                                                                </div>


                                                                <div class="det">
                                                                    <p>
                                                                        Floor:1
                                                                    </p>

                                                                    <p>
                                                                        APT:42
                                                                    </p>
                                                                </div>

                                                            </div>




                                                        </div>

                                                        <div class="d-flex align-items-center">
                                                            <i class="fas fa-phone-alt"></i>
                                                            <p>
                                                                44465875664944000
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="gray-bg">
                                                    <div class="row order-row">
                                                        <div class="col-sm-2">
                                                            <span>

                                                                1 x
                                                            </span>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <h4>
                                                                Pizza
                                                            </h4>

                                                            <span class="text-purple">
                                                                Lorem ipsum dolor sit .
                                                            </span>


                                                        </div>
                                                        <div class="col-sm-2">
                                                            <span>
                                                                3.25$
                                                            </span>
                                                        </div>
                                                    </div>


                                                    <div class="size">
                                                        <div class="row">
                                                            <div class="col-sm-2"></div>
                                                            <div class="col-sm-8">

                                                                <p>
                                                                    0 x large
                                                                </p>
                                                            </div>
                                                            <div class="col-sm-2">

                                                                <p>
                                                                    0.00$
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="total">
                                                        <div class="d-flex justify-content-between">
                                                            <p>Total</p>

                                                            <p>
                                                                12$
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>





                                                <div class="payment-btns">
                                                    <button class="btn w-100 mb-3"> Card</button>
                                                    <button class="btn w-100 mb-3"> Cash</button>
                                                </div>


                                                <div class="alert alert-primary my-3" role="alert">
                                                    On its way!
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                    <div class="col-md-6">
                                        <h3>Accepted
                                            <span>0</span>
                                        </h3>

                                        <div class="card">
                                            <div class="card-body">
                                                Not Accepted orders
                                            </div>
                                        </div>


                                        <div class="card accepted-status">
                                            <div class="card-body">
                                                <div
                                                    class="my-2 w-100 d-flex flex-wrap justify-content-between align-items-center">
                                                    <h2 class="mb-0">#000</h2>
                                                    <span class="badge badge-pill badge-secondary">
                                                        On way
                                                    </span>
                                                    <span class="badge badge-pill badge-primary">
                                                        Test Order
                                                    </span>
                                                    <span class="badge badge-pill badge-info">
                                                        Credit Card
                                                    </span>

                                                </div>

                                                <p>xxx-12345</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="list-profile" role="tabpanel"
                                aria-labelledby="list-profile-list">

                                <h2>
                                    Recent Orders
                                </h2>


                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Order</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">test</th>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">test</th>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                            <td>test</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="tab-pane fade" id="list-messages" role="tabpanel"
                                aria-labelledby="list-messages-list">

                                <mat-accordion class="example-headers-align" multi>

                                    <mat-expansion-panel *ngFor="let category of categories"
                                        (opened)="getProducts(category.id)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                {{category.name}}
                                            </mat-panel-title>

                                            <mat-panel-description>

  <!-- <div class="row">
                                        <div class="col-md-4" *ngFor="let product of products">
                                            <div *ngIf="product?.category_id == category?.id">
                                                <div class="card">
                                                    <a>

                                                        <img class="card-img-top"
                                                            [src]="product.mediaCollection[0]['default']"
                                                            alt="Card image cap" style="height:200px">
                                                    </a>

                                                    <div class="card-body">
                                                        <h4 class="card-title">
                                                            {{product.title}}
                                                        </h4>

                                                        <bar-rating rate={{product.avg_rank}} [readOnly]="true">
                                                        </bar-rating>


                                                        <p class="card-text">
                                                            {{product?.price }}
                                                            {{'SAR'|translate}}
                                                        </p>


                                                        <div class="rating-wrapper">
                                                            <div class="rating-image-word">
                                                                <div class="smiley-rating rating_good">
                                                                </div>
                                                                <div class="rating-word">
                                                                    <p class="card-text tax-style">
                                                                        ({{product.tax?.percentage}}%
                                                                        {{product.tax?.title.en}})
                                                                    </p>
                                                                 
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>     -->


                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                      
                                  
                                       
                                    </mat-expansion-panel>
                                </mat-accordion>

                            </div>
                            <div class="tab-pane fade" id="list-settings" role="tabpanel"
                                aria-labelledby="list-settings-list">
                            </div>
                        </div>


                    </div>

                </div>

                <!-- <div class="example-action-buttons">
                        <button mat-button (click)="accordion.openAll()">Expand All</button>
                        <button mat-button (click)="accordion.closeAll()">Collapse All</button>
                      </div> -->


            </div>
        </div>
    </div>

</div>