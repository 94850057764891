import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Product } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { CartService } from '../../../../shared/services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {AllproductsService} from '../../../../shared/services/allproducts.service';

import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.scss']
})
export class ProductLeftSidebarComponent implements OnInit {
  @Input() product:any;
  public products           :   Product[] = [];
  public counter            :   number = 1;
  public selectedSize       :   any = '';
  public iStyle               = "width:100%; height:100%;"
  sizes=[{en:"S",ar:"صغير"},{en:"M",ar:"متوسط"},{en:"L",ar:"كبير"}];
  addTax;
  lang;
  review;
  product_id;
  rateID;
  comment;
  review_text;
  ReviewForm: FormGroup;
  newproducts;
  productClass: string ='';
  token: any;
  reviewsCriteria: any[] = [];

  //Get Product By Id
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public productsService: ProductsService,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private toastrService: ToastrService,
    private allproductservice:AllproductsService,
    private spinner:NgxSpinnerService,
    private activatedRoute: ActivatedRoute,

    ) {
  }
  ngDoChanges(){

  }
  ngDoCheck(){
    // console.log("product_change=> ",this.product)

  }
  ngOnInit() {
    // this.reloadComponent();
    this.productClass = this.router.url.toString().split('/')[1];
    this.addTax = JSON.parse(localStorage.getItem("etaam_config"));
    console.log("addTax2=> ",this.addTax);

    this.token = localStorage.getItem('token');
   
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.route.params.subscribe(params=>{
      this.product_id = params.id;
    })
    this.initForm();

    this.getReviewsCriteria();
  }

  // product zoom
  onMouseOver(): void {
    document.getElementById("p-zoom").classList.add('product-zoom');
  }

  onMouseOut(): void {
    document.getElementById("p-zoom").classList.remove('product-zoom');
  }

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  };

  public slideNavConfig = {
    vertical: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.product-slick',
    arrows: false,
    dots: false,
    focusOnSelect: true
  }

  public increment() {
      this.counter += 1;
  }

  public decrement() {
      if(this.counter >1){
         this.counter -= 1;
      }
  }

  // For mobile filter view
  public mobileSidebar() {
    $('.collection-filter').css("left", "-15px");
  }

  // Add to cart
  public addToCart(product: Product, quantity) {
    if (quantity == 0) return false;
    this.cartService.checkProduct(product);
    // this.cartService.updateCartNew(product);
    console.log("shopping cart array after added => ",this.cartService.cartItemsNew);
  }

  // Add to cart
  public buyNow(product: Product, quantity) {
    let routs:any[] = this.router.url.split('/');
    console.log(product)
    console.log(routs);
     if (quantity > 0)
       this.cartService.addToCart(product,parseInt(quantity), []);
       this.router.navigate([routs[1] + '/payments/checkout', this.lang]);
  }

  // Add to wishlist
  public addToWishlist(product: Product) {
     this.wishlistService.addToWishlist(product);
    //  this.toastrService.success('This product has been added to WishList.');

  }


  // Change size variant
  public changeSizeVariant(variant) {
     this.selectedSize = variant;
  }

  onRate(event) {
    this.rateID = event.newValue;
    // console.log(event.newValue)
  }

  initForm() {
    this.ReviewForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    })
  }

  getReviewsCriteria(){
    this.productsService.getreviewsCriteria('product').subscribe(
      (res:any)=>{
        this.reviewsCriteria = res['review_criterias'];
      }
    );
  }

  public reviewsubmit(){
    let submitObject = {
      "reviewable_type":"product",
      "reviewable_id": this.product_id,
      "rank" : this.rateID,
      "comment" : this.ReviewForm.value,
      "product_id" :this.product_id  ,
    }
    let formData = new FormData();
    formData.append('reviewable_type', submitObject.reviewable_type);
    formData.append('comment', this.ReviewForm.value.comment);
    formData.append('reviewable_id', submitObject.reviewable_id);
    let rates: any[] = [];
    // this.reviewsCriteria.forEach(element=>{
    //   rates['review_criteria_id'].push(element.id);
    //   rates['rate'].push(submitObject.rank);
    // });

    for(let i=0; i< this.reviewsCriteria.length; i++){
      formData.append('rates['+i+'][review_criteria_id]', this.reviewsCriteria[i]['id']);
      formData.append('rates['+i+'][rate]', submitObject.rank);
    }

    console.log("Reviews Rates", formData.getAll);

     if(this.token != null){
      this.productsService.submitreview(formData).subscribe(res=>{
        //  this.productsService.handleResponse(res);
        if(this.lang == 'en'){
          this.toastrService.success("Your Review Has Been Submitted Successfully"); // toasr services
        }else{
          this.toastrService.success("تم تأكيد تقييمك بنجاح"); // toasr services
        }
        
        this.productsService.reviewAdded.emit();
  
       },error =>{
          console.log(error)
          if(error.error.errors.product_id){
            this.toastrService.error(error.error.errors.product_id);
          }else if(error.error.errors.rank){
            this.toastrService.error(error.error.errors.rank);
          }
           // toasr services
          
       })
     }else{
       if(this.lang == 'en'){
        this.toastrService.error('Please login first');
       }else{
        this.toastrService.error('من فضلك قم بتسجيل الدخول أولا');
       }
       this.router.navigate(['login']);
     }
  }


  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

  gotoProducts(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/products' , this.lang]);
  }



}
