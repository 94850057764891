import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-donation',
  templateUrl: './blog-donation.component.html',
  styleUrls: ['./blog-donation.component.scss']
})
export class BlogDonationComponent implements OnInit {
    lang;
  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    //  console.log("The language is =========>",this.lang)
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
}

slideConfig = {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "cssEase": 'linear',
   "verticalSwiping":true

};


}
