import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { DatePipe } from "@angular/common";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sales-report",
  templateUrl: "./sales-report.component.html",
  styleUrls: ["./sales-report.component.scss"],
})
export class SalesReportComponent implements OnInit {
  name;
  lang;
  orders: any[] = [];
  config: any;
  page: number = 1;
  perPage: any;
  totalCount: any;
  users: any[] = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  isLoading: boolean = false;
  idList;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("Select") select: MatSelect;
  visible: boolean = false;
  displayedColumns: string[] = [
    "id",
    "order_time",
    "status",
    "total_amount",
    "type",
    "merchant_name",
    "area",
    "address",
  ];
  filterForm: FormGroup = new FormGroup({
    status: new FormControl(null),
    user: new FormControl(null),
    start: new FormControl(""),
    end: new FormControl(""),
  });
  id: number;
  type: string;
  merchantsList: any;
  merchant: any;
  downloadreportPDF: any;
  downloadreport: any;
  userIds = [];
  fromDate: string;
  toDate: string;
  payment;

  constructor(
    private translate: TranslateService,
    private http: HttpClientService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.name = localStorage.getItem("merchantName");
    this.id = +localStorage.getItem("merchantId");
    this.type = localStorage.getItem("type");
    this.getList();
    this.translate.use(this.lang);
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.name = this.merchant.name;
          this.idList = [this.id];
          this.initReports();
        }
      });
  }
  initReports() {
    this.getOrders(1);
    this.getUsers();
    this.initForm();
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount,
    };
    this.dropdownSettings = {
      idField: "id",
      textField: "name",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    if (this.lang == "ar") {
      this.dropdownSettings.noDataAvailablePlaceholderText =
        "لا يوجد بيانات متوفرة";
      this.dropdownSettings.searchPlaceholderText = "ابحث فى المستخدمين";
    } else {
      this.dropdownSettings.noDataAvailablePlaceholderText =
        "No Data Avialable";
      this.dropdownSettings.searchPlaceholderText = "Search In Users";
    }
  }
  initForm() {
    this.filterForm = new FormGroup({
      status: new FormControl(null),
      user: new FormControl(null),
      start: new FormControl(""),
      end: new FormControl(""),
    });
  }
  getOrders(page?) {
    this.isLoading = false;
    let body: any = {
      merchants: [...this.idList],
      user: [...this.userIds],
    };
    if (this.payment) {
      body.type = this.payment;
    }
    if (this.fromDate) {
      body.from = this.fromDate;
    }
    if (this.toDate) {
      body.to = this.toDate;
    }
    this.http.post("merchant/orders", body, { page: page }).subscribe((res) => {
      this.orders = res["data"].orders;
      this.perPage = res["data"].per_page;
      this.totalCount = res["data"].total;
      this.isLoading = true;
      this.downloadreportPDF = res["data"].pdf_download_file;
      this.downloadreport = res["data"].download_file;
      this.updateConfig();
    });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  getUsers() {
    this.http.get("merchant/search-ordered-users", {}).subscribe((res) => {
      this.users = res["data"];
      this.users = this.users.filter((r) => r.first_name != null);
      this.users = this.users.map((r) => {
        return {
          id: r.id,
          name: r.first_name + " " + r.last_name,
        };
      });
      let all = { id: "All", name: "Select All" };
      this.users = [all, ...this.users];
    });
  }

  onChangePage(event) {
    this.config.currentPage = event;
    this.page = event;
    this.getOrders(event);
    this.updateConfig();
  }

  updateConfig() {
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount,
    };
  }

  onChange(event) {
    this.payment = event.value;
    this.getOrders(this.page);
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");

    this.fromDate = date;
    this.getOrders(this.page);
  }

  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");

    this.toDate = date;
    this.getOrders(this.page);
  }

  onItemSelect(e?) {
    let isAll = false;
    this.userIds = [];
    e.value.forEach((element) => {
      if (element.id == "All") {
        isAll = true;
      }
    });
    if (isAll) {
      this.onAllSelect();
    } else {
      this.userIds = [...e.value.map((res) => res.id)];
      this.getOrders(this.page);
    }
  }
  onClick(e) {
    if (e.path[1].id == "All") {
      let isAll = false;
      this.selectedItems.forEach((element) => {
        if (element.id == "All") {
          isAll = true;
        }
      });
      console.log(isAll);
      if (!isAll) {
        this.selectedItems = [];
        this.userIds = [];
        this.getOrders(this.page);
      }
    }
  }

  onAllSelect() {
    this.userIds = [...this.users.map((res) => res.id)];
    this.selectedItems = [...this.users];
    this.getOrders(this.page);
  }

  onFilterChange(event) {
    console.log(event);
    this.http
      .get("merchant/search-ordered-users", { q: event })
      .subscribe((res) => {
        this.users = res["data"];
        this.users = this.users.filter((r) => r.first_name != null);
        this.users = this.users.map((r) => {
          return {
            id: r.id,
            name: r.first_name + " " + r.last_name,
          };
        });
        let all = { id: "All", name: "Select All" };
        this.users = [all, ...this.users];
      });
  }
  handlePageEvent(event) {
    console.log(event);
  }
  download() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }
  reset() {
    this.filterForm.reset();
    this.userIds = [];
    this.toDate = null;
    this.fromDate = null;
    this.payment = null;
    this.selectedItems = null;
    this.getOrders(this.page);
  }
  onMerchantChange(e) {
    this.merchant = e.value;
    this.id = e.value.id;
    this.idList = [this.id];
    this.reset();
  }
}
