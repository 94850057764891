<div class="container">
    <div class="row">

        <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="branches-list">
                <ul class="list-group custom-list-group">
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[1],'click');"> الإدارة العامة</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[10],'click');">فرع شمال الرياض</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[3],'click');">فرع جنوب الرياض</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[0],'click');">فرع شرق الرياض</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[11],'click');">فرع غرب الرياض</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[7],'click');">فرع حي الروضة بالرياض</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[5],'click');">فرع الخرج</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[4],'click');">فرع حوطة بني تميم</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[2],'click');">فرع الأفلاج</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[9],'click');">فرع السليل</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[14],'click');">فرع وادي الدواسر</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[12],'click');">فرع القويعية</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[6],'click');">فرع الدوادمي</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[16],'click');">فرع شقراء</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[17],'click');">فرع عفيف</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[13],'click');">فرع المجمعة</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[8],'click');">فرع الزلفي</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[15],'click');">فرع رماح</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[19],'click');">فرع  مرات</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[20],'click');">فرع محافظة الحريق</a></li>
                    <li class="list-group-item"><i class="fa fa-map-marker"></i><a href="javascript:google.maps.event.trigger(gmarkers[18],'click');">نادي إنسان الاجتماعي</a></li>
                </ul>
            </div>
        </div>

        <div class="col-md-9 col-sm-12 col-xs-12">
            <!-- <agm-map [latitude]='latitude' [longitude]='longitude'[mapTypeId]='mapType'>
           </agm-map> -->
         

           <agm-map
            [latitude]="latitude"
            [longitude]="longitude"
            [zoom]="2"
            (mapClick)="addMarker($event.coords.lat, $event.coords.lng)"
            >
            <agm-marker
                *ngFor="let marker of markers"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [opacity]="marker.alpha"
                [markerDraggable]="true"
                (markerClick)="selectMarker($event)"
            >
            </agm-marker>
            <agm-rectangle
                [north]="max('lat')"
                [east]="max('lng')"
                [south]="min('lat')"
                [west]="min('lng')"
            >
            </agm-rectangle>
            </agm-map>


        </div>

    </div>
</div>