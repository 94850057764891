<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
      <div class="row">
          <div class="col-sm-6 text-left">
              <nav aria-label="breadcrumb" class="theme-breadcrumb">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Order Details</li>
                  </ol>
              </nav>
          </div>
      </div>
  </div>
</div>
<!-- breadcrumb End -->
<section class="section-b-space">
  <div class="container padding-cls">
      <div class="checkout-page">
          <div class="checkout-form">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                
                
              </div>
            </div>
            <div *ngFor="let item of ordersList; index as i" class="row"  (click)="showOrders(i)">
              <div class="col-lg-12 col-sm-12 col-xs-12 order-container margin-bottom-small">
                <span>
                  <h4>
                    <span *ngIf="theOrder?.order_status_id == null" class="status">{{'NOSTATUS'|translate}}</span>
                    <span *ngIf="theOrder?.order_status_id !=null" class="status">{{status}}</span>
                    <!-- <span *ngIf="theOrder?.order_status !=null && lang =='ar'" class="status">{{theOrder?.order_status.title.ar}}</span> -->
                    {{'ORDER_NUM'|translate}} #{{theOrder?.id}}
                  </h4>
                  <p>{{theOrder?.created_at | date}}</p>
                
                <span><i class="fa fa-sort-desc" aria-hidden="true"></i></span></span>

              </div>
              <div class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-small display--none" id="orders_list_body_{{i}}" style="padding-left: 0px;padding-right: 0px;" show="false">
                  <div class="order-container">
                      <div class="row">


                          <div class="col-md-12 col-sm-12 col-xs-12 order-item-container" *ngFor="let item of theOrder?.products">
                            <div class="row order-item">
                              <div class="col-md-1 col-sm-1 col-xs-12 ">
                                <div class="order-product-num">
                                  <p class="text-center">{{item?.quantity}}</p>
                                </div>
                                <img class="order-img" [src]="item?.product?.mediaCollection[0]?.default">
                              </div>
                              <div class="col-md-10 col-sm-10 col-xs-12">
                                <span>
                                  <star-rating [value]="item?.product.avg_rank" totalstars="5"
                                      checkedcolor="orange" uncheckedcolor="black" size="24px"
                                      readonly="true"></star-rating>
                                </span>
                          
                                <p><span>{{"Order" | translate}} #{{theOrder?.id}}</span> </p>
                              <p><span>{{item?.product.price}} </span></p>
                              </div>

                            </div>
                            
                          </div>

                          

                        <div class="col-md-12 col-sm=12 col-xs-12">
                            <hr>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                          <p class="font--600  text-center" style="margin-bottom: 6px; font-size: 16px;">
                            {{"total" | translate}}: <span>{{theOrder?.total}}</span></p>
                            
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
  </div>
</section>