import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-help-center",
  templateUrl: "./help-center.component.html",
  styleUrls: ["./help-center.component.scss"],
})
export class HelpCenterComponent implements OnInit {
  lang;
  selected = false;
  arrayOfTopics;
  level2 = false;
  arrayOfTopics2;
  form = {
    request_text: null,
    request_file: null,
  };
  topic: any;
  subTopic: any;
  showForm = false;
  id: string;
  formData: FormData;
  merchant_brand_id;
  merchant_brand_id_1;

  topic_description;

  constructor(
    private router: Router,
    private http: HttpClientService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.id = localStorage.getItem("merchantId");
    let merchants = JSON.parse(localStorage.getItem("Brand_Merchants_array"));

    if (merchants) {
      this.merchant_brand_id = merchants.find((m) => {
        return m.id == this.id;
      })?.merchant_brand_id;
    }
  }
  navigate() {
    this.router.navigateByUrl(`otphome/help-orders/${this.lang}`);
  }
  getTopics() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("help-center?type=Merchant", {}, headers)
      .subscribe((res: any) => {
        this.selected = true;
        this.arrayOfTopics = res.topic_categories;
      });
  }
  getTopics2(e) {
    this.topic = e;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      category_id: e.id,
    };
    this.http
      .post(`help-center/topics`, body, {}, headers)
      .subscribe((res: any) => {
        this.selected = true;
        this.level2 = true;
        this.arrayOfTopics2 = res.topics;
      });
  }

  getForm(e) {
    this.subTopic = e;
    this.showForm = true;
    this.topic_description = e.description;
  }

  submitForm() {
    if (!this.form.request_text) {
      this.toaster.error("Please Write Your Message");
    } else {
      // this.formData = new FormData();
      this.formData.append("request_text", this.form.request_text);
      this.formData.append("topic_id", this.subTopic.id);
      this.formData.append("merchant_brand_id", this.merchant_brand_id);
      // this.merchant_brand_id = parseInt(this.merchant_brand_id_1) ;
      console.log("The Merchant ID :::::::: ", this.merchant_brand_id);
      let headers: HttpHeaders = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("optToken")}`,
      });
      this.http
        .post("support_request/store", this.formData, {}, headers)
        .subscribe(
          (res) => {
            this.toaster.success("Request Added Successfully");
            this.selected = false;
            this.level2 = false;
            this.showForm = false;
          },
          (error) => {
            console.log(error.error.merchant_brand_id);
            if (error.error.merchant_brand_id) {
              this.toaster.error(error.error.merchant_brand_id);
            }
          }
        );
    }
  }

  handleFileInput(files: FileList) {
    let file = files.item(0);
    this.formData = new FormData();
    this.formData.append("request_file", file, file.name);
  }

  changeStat(event) {
    console.log("The admin status ::::::", event);
  }

  gotosupportrequests() {
    this.router.navigateByUrl(`otphome/requests/${this.lang}`);
  }
}
