<p>redeem works!</p>
<!-- <router-outlet></router-outlet> -->


<nav class="navbar fixed-bottom navbar-light bg-light">
    <!-- <a class="navbar-brand" href="#">
        <i class="fas fa-home"></i>
    </a> -->
    <a class="" href="#">
        <i class="fas fa-home"></i>
    </a>
    <a class="" href="#">
        <i class="fas fa-shopping-bag"></i>
    </a>
    <a class="" href="#">
        <i class="fas fa-search"></i>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#">Disabled</a>
          </li>
        </ul>
      </div>
  </nav>


  <img src="../../../../assets/images/first-img.png" alt="">
