import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private httpClientService: HttpClientService) { }
  
  getCountries() {
    return this.httpClientService.get('countries', {});
  }

  getCities() {
    return this.httpClientService.get('cities',{});
  }

  getColors() {
    return this.httpClientService.get('colors', {});
  }

  getSizes() {
    return this.httpClientService.get('sizes', {});
  }

  getLanguages() {
    return this.httpClientService.get('languages', {});
  }

  getMetaData(type, id=null) {
    let metableData = {}
    if(type) {
      metableData['metable_type'] = type;
    }
    if(id) {
      metableData['metable_id'] = id;
    }
    return this.httpClientService.get('meta-data', {});
  }

  getReturnReasons(){
    return this.httpClientService.get('lookups/return-reasons', []);
  }
}
