import { EventEmitter, Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";

import { AuthService } from "./auth.service";
import { BehaviorSubject } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  token;
  header: HttpHeaders = new HttpHeaders();
  isLogged: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasDefaultAddress: EventEmitter<boolean> = new EventEmitter<boolean>();
  areaChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  socialUser: BehaviorSubject<any> = new BehaviorSubject<any>({});
  constructor(
    private httpClientService: HttpClientService,
    private authService: AuthService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.token = localStorage.getItem("token");
    // console.log("access token service ",this.token);
  }

  register(userData) {
    return this.httpClientService.post("auth/register", userData, {});
  }

  registerUpdated(userData) {
    return this.httpClientService.post("auth/register-updated", userData, {});
  }

  login(userData) {
    return this.httpClientService.post("auth/login", userData, {});
  }

  forgetPassword(userData) {
    return this.httpClientService.post("auth/forgot", userData, {});
  }

  verify(mobileVerification) {
    return this.httpClientService.post("verify", mobileVerification, {});
  }

  handleResponse(response) {
    this.authService.setToken(response.token);
  }

  viewprofile() {
    return this.httpClientService.get("user", {});
  }

  updateprofile(userData) {
    return this.httpClientService.post("user-update", userData, {});
  }

  resetPasword(body) {
    console.log("body=> ", body);
    return this.httpClientService.post("auth/reset", body, {});
  }

  checkUser(provider, token) {
    return this.httpClientService.get("auth/social", {
      provider: provider,
      access_token: token,
    });
  }

  search(query, area_id) {
    return this.httpClientService.get("merchants", { q: query, area: area_id });
  }

  updatePassword(body) {
    return this.httpClientService.post("change-password", body, {});
  }
}
