<div class="container">
    <div class="row">

        <div class="col-md-3">
            <div class="page-sidebar">
                <div class="well well-sm well-sidebar">مشاريعنا</div>
                <ul class="sidebar-nav">
                    <li><a href="donation-methods.html">طريقة التبرع</a></li>
                    <li><a href="account-numbers.html">أرقام الحسابات</a></li>
                    <li><a href="permanent-programs.html">المشاريع الدائمة</a></li>
                    <li><a href="seasonal-programs.html">المشاريع الموسمية</a></li>
                    <li><a href="other-programs.html">المشاريع الأخرى</a></li>
                    <li class="dropdown">
                        <a href="#" class="active" data-toggle="dropdown">أوقاف الجمعية</a>
                        <ul class="dropdown-menu">
                            <li><a href="endowments-sites.html">مواقع الأوقاف</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-md-9"></div>

    </div>
</div>