<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>
              {{ "openingTime" | translate }}
            </h2>
            <h3>{{ merchantName }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="text-desc-content-card">
          <div class="card" style="margin-top: 15px" *ngFor="let day of days">
            <div class="card-body">
              <h3>{{ day?.day | translate }}</h3>
              <p class="opening-time-date">
                {{ getTime(day.opening_time) }} -
                {{ getTime(day.closing_time) }}
              </p>
            </div>
          </div>

          <div class="card">
            <div class="card-body padding-xs">
              <h3 *ngIf="type == 'brand_manager'">
                <a class="btn btn-link right" (click)="open(content)">{{
                  "Copy To Other Branches" | translate
                }}</a>
              </h3>
              <p>
                <button
                  (click)="gotoEdit()"
                  class="btn btn-link opening-time-date mar-top"
                  [ngStyle]="{
                    'margin-top': type == 'brand_manager' ? '30px' : '0px'
                  }"
                >
                  {{ "Edit" | translate }}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <div>
        <h4 class="modal-title" id="modal-basic-title">
          {{ "Copy timings to other branches" | translate }}
        </h4>
        <p>{{ merchantName }}</p>
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div>
      <div>
        <div role="document">
          <div class="modal-content">
            <div class="px-3 modal-body">
              <div class="form-group" *ngFor="let branch of merchantsList">
                <div *ngIf="branch.id != id">
                  <input
                    type="checkbox"
                    name="branch"
                    (change)="oncheck($event, branch)"
                    [checked]="merchantsIds.includes(branch.id)"
                  />
                  <label>{{ branch.name }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button class="btn btn-link" (click)="none()">
                    {{ "None" | translate }}
                  </button>
                </div>
                <div class="col-md-6">
                  <button class="btn btn-link" (click)="checkAll()">
                    {{ "All" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-link" (click)="modal.dismiss('Cross click')">
        {{ "Cancel" | translate }}
      </button>
      <button
        class="btn btn-default"
        (click)="copyToMerchants(content)"
        [disabled]="merchantsIds.length == 0"
      >
        {{ "Confirm" | translate }}
      </button>
    </div>
  </ng-template>
</div>
