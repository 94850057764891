import { Component, OnInit } from "@angular/core";

import { DatePipe } from "@angular/common";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-analysis",
  templateUrl: "./analysis.component.html",
  styleUrls: ["./analysis.component.scss"],
})
export class AnalysisComponent implements OnInit {
  analysis;
  email = "";
  password = "";
  loged = true;
  from = new Date();
  to = new Date();
  constructor(
    private http: HttpClientService,
    private toaster: ToastrService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAnalysis();
  }
  getAnalysis() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get(
        `generalStatistics?from=${this.datePipe.transform(
          this.from,
          "yyyy-MM-dd"
        )}&to=${this.datePipe.transform(this.to, "yyyy-MM-dd")}`,
        {},
        headers
      )
      .subscribe((res: any) => {
        this.analysis = res.data;
      });
  }
  login() {
    if (
      this.email.toLowerCase() == "admin@sahla.com" &&
      this.password == "123456789"
    ) {
      this.loged = true;
    } else {
      this.toaster.error("Please insert Valid Credentials");
    }
  }
}
