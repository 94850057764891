<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav2></app-side-nav2>
    </div>

    <div class="col-md-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Search Customer" | translate }}</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-11 col-sm-10 col-xs-12" style="text-align: center">
            <input
              class="form-control inp"
              [(ngModel)]="text"
              placeholder="{{ 'Search Customer' | translate }}"
            />
          </div>
          <button
            class="btn btn-primary col-md-1 col-sm-2 col-xs-12"
            (click)="search()"
          >
            Search
          </button>

          <div class="col-md-12 col-sm-12 col-xs-12 padd">
            <div class="beside">
              <!-- <label class="container"
                >{{ "Customer Name" | translate }}
                <input
                  type="radio"
                  id="Name"
                  name="fav_language"
                  value="Name"
                  [(ngModel)]="type"
                />
                <span class="checkmark"></span>
              </label> -->

              <label class="container"
                >{{ "Customer Mobile" | translate }}
                <input
                  type="radio"
                  id="Mobile"
                  name="fav_language"
                  value="Mobile"
                  [(ngModel)]="type"
                />
                <span class="checkmark"></span>
              </label>

              <label class="container"
                >{{ "Transaction ID" | translate }}  <input
                  type="radio"
                  id="Transaction"
                  name="fav_language"
                  value="Transaction"
                  [(ngModel)]="type"
                />
                <span class="checkmark"></span>
              </label>

              <label class="container"
                >{{ "Order ID" | translate }}
                <input
                  type="radio"
                  id="Order"
                  name="fav_language"
                  value="Order"
                  [(ngModel)]="type"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="row" style="padding: 20px" *ngIf="result">
          <div class="card">
            <div class="row">
              <div
                class="col-md-2 col-sm-12 col-xs-12"
                style="text-align: center; padding-top: 10px"
              >
                <img class="user-img" src="assets/images/usr.jpg" />
              </div>

              <div class="col-md-10 col-sm-12 col-xs-12">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>
                      {{ "First Name" | translate }} {{ result.first_name }}
                    </h4>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>
                      {{ "Last Name" | translate }} : {{ result.last_name }}
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>
                      {{ "Birth Date" | translate }} :{{ result.birth_date }}
                    </h4>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>{{ "Gender" | translate }} : {{ result.gender }}</h4>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>
                      {{ "Mobile Number" | translate }} : {{ result.mobile }}
                    </h4>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>{{ "Email" | translate }} : {{ result.email }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="result">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="card" *ngIf="result.orders && result.orders.length > 0">
              <h2 class="sub-title">{{ "My Orders" | translate }}</h2>

              <div class="row order" *ngFor="let item of result.orders">
                <div
                  class="col-md-2 col-sm-12 col-xs-12"
                  style="padding: 50px 15px"
                ></div>
                <div
                  class="col-md-10 col-sm-12 col-xs-12"
                  style="padding: 30px"
                >
                  <p style="font-weight: bold">
                    {{ item.cashback.brand.title }}
                  </p>
                  <p class="order" *ngIf="item.order_status == 'pending'">
                    {{ item.order_status }}
                  </p>
                  <p
                    class="order-cancel"
                    *ngIf="item.order_status == 'cancelled'"
                  >
                    {{ item.order_status }}
                  </p>
                  <p
                    class="order-deliver"
                    *ngIf="item.order_status == 'delivered'"
                  >
                    {{ item.order_status }}
                  </p>

                  <p>{{ item.created_at | date }}</p>
                  <p>{{ "Order ID" | translate }} : {{ item.id }}</p>
                  <p>{{ "Amount" | translate }}: {{ item.total }} EGP</p>
                  <p style="color: rgb(51, 133, 235)">
                    {{ "Cashback" | translate }} :
                    {{ item.cashback_amount }} EGP
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="card">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <h2 class="sub-title">{{ "Wallet" | translate }}</h2>
                <button class="btn" (click)="open()">
                  {{ "Add" | translate }}
                </button>
              </div>
              <div
                class="row order"
                *ngFor="let w of result.walletTransactions"
              >
                <div
                  class="col-md-12 col-sm-12 col-xs-12"
                  style="padding: 15px"
                >
                  <p style="color: rgb(98, 98, 98)">
                    {{ "TRANSACTION" | translate }} :
                    <span style="color: black; font-weight: bold"
                      >#{{ w.id }}</span
                    >
                  </p>
                  <p style="color: rgb(98, 98, 98)">
                    {{ "Merchant" | translate }} :
                    <span style="color: black; font-weight: bold"
                      >{{ w.merchant_name }}
                    </span>
                  </p>
                  <p style="color: rgb(98, 98, 98)">
                    {{ "Placed On" | translate }} :
                    <span style="color: black; font-weight: bold">
                      {{ w.created_at | date }}
                    </span>
                  </p>
                  <p style="color: rgb(98, 98, 98)">
                    {{ "Status" | translate }} :
                    <span style="color: black; font-weight: bold">
                      {{ w.status }}</span
                    >
                  </p>
                  <p style="color: rgb(98, 98, 98)">
                    {{ "Amount" | translate }} :
                    <span style="color: black; font-weight: bold">
                      {{ w.wallet_amount }} EGP</span
                    >
                  </p>

                  <!-- <p style="color: rgb(98, 98, 98)" id="balance">
                    {{ "Wallet Balance" | translate }} :
                    <span style="color: rgb(123, 200, 155); font-weight: bold"
                      >100.50 EGP</span
                    >  
                  </p>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "Wallet Balance" | translate }}</h4>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-12">
            <h4>{{ "Amount" | translate }} :</h4>
          </div>
          <div class="col-md-9 col-sm-9 col-xs-12">
            <input
              class="form-control"
              type="number"
              placeholder="140"
              [(ngModel)]="amount"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addCashBack()"
          [disabled]="!amount"
        >
          {{ "Add Cashback" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          {{ "Close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
