<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <h2>{{ "Help Center" | translate }}</h2>
        <h1>
         <a [routerLink]="['../../help', lang]" style="color: black;  cursor: pointer;">Home</a>  <mat-icon>arrow_forward_ios</mat-icon> I need Help With an Active
          Order(s)
        </h1>
      </div>
    </div>
  </div>
</div>
