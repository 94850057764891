import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-order',
  templateUrl: './help-order.component.html',
  styleUrls: ['./help-order.component.scss']
})
export class HelpOrderComponent implements OnInit {

  lang;


  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
  }

}
