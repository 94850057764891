import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { forkJoin } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AddingMetaService } from 'src/app/shared/services/adding-meta.service';
import { AllproductsService } from 'src/app/shared/services/allproducts.service';

declare var $: any;

@Component({
  selector: 'app-cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.scss']
})
export class CatComponent implements OnInit {

  categories: any[];
  catClass: string = '';
  topMerchants: any[] = [];

  constructor(
    private categoriesService: CategoriesService, 
    private translate: TranslateService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private allProducts: AllproductsService,
    private lookupService: LookupService, 
    private metaService2: AddingMetaService,
    private spinner: NgxSpinnerService) { }
  lang;
  ngOnInit(): void {
    this.catClass = this.router.url.toString().split('/')[1];
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.spinner.show();
    this.getTopCashbackStores();
    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999);

    if (this.activatedRoute.snapshot.queryParams['cat_id']) {
      this.categoriesService.getsubCategories(this.activatedRoute.snapshot.queryParams['cat_id']).subscribe(res => {
        this.categories = res['data'];
        this.spinner.hide();
        this.lookupService.getMetaData('category', this.activatedRoute.snapshot.queryParams['cat_id']).subscribe((res: any) => {
          let metaObj = res;
          // this.metaService.addTags([
          //   { name: 'description', content: metaObj?.description_seo['ar'] }
          // ]);
          // this.titleService.setTitle(metaObj?.meta_data_seo['ar']);
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
      },
      error=>{
        this.spinner.hide();
      })
    } else if (this.activatedRoute.snapshot.queryParams['sub_cat_id']) {
      this.categoriesService.getSubSubCategories(this.activatedRoute.snapshot.queryParams['sub_cat_id']).subscribe(res => {
        this.categories = res['data']; 
        this.spinner.hide();
        this.lookupService.getMetaData('category', this.activatedRoute.snapshot.queryParams['sub_cat_id']).subscribe((res: any) => {
          let metaObj = res;
          // this.metaService.addTags([
          //   { name: 'description', content: metaObj?.description_seo['ar'] }
          // ]);
          // this.titleService.setTitle(metaObj?.meta_data_seo['ar']);
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
      }, 
      error=>{
        this.spinner.hide();
      })

    }else if (this.activatedRoute.snapshot.queryParams['sub_sub_cat_id']) {
      this.categoriesService.getSubSubCategories(this.activatedRoute.snapshot.queryParams['sub_sub_cat_id']).subscribe(res => {
        this.categories = res['data']; 
        this.spinner.hide();
        this.lookupService.getMetaData('category', this.activatedRoute.snapshot.queryParams['sub_sub_cat_id']).subscribe((res: any) => {
          let metaObj = res;
          // this.metaService.addTags([
          //   { name: 'description', content: metaObj[0]?.description_seo['ar'] }
          // ]);
          // this.titleService.setTitle(metaObj[0]?.meta_data_seo['ar']);
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
      },
      error=>{
        this.spinner.hide();
      })

    } 
    else {
      this.categoriesService.getCategories().subscribe((res: any[]) => {
        this.categories = res['categories'];
        console.log('Categories', this.categories);
        this.spinner.hide();
      },
      error=>{
        this.spinner.hide();
      });
      this.lookupService.getMetaData('category').subscribe((res: any) => {
        let metaObj = res;
        // this.metaService.addTags([
        //   { name: 'description', content: metaObj[0]?.description_seo['ar'] }
        // ]);
        // this.titleService.setTitle(metaObj[0]?.meta_data_seo['ar']);
        this.metaService2.updateMeta({
          title: 'Categories',
          description: metaObj[0]?.description_seo['ar'],
          url: window.location.href
        })
        this.spinner.hide();
      },
      error=>{
        this.spinner.hide();
      })
    }

    this.activatedRoute.queryParams.subscribe(res => {
      if (this.activatedRoute.snapshot.queryParams['cat_id']) {
        this.categoriesService.getsubCategories(this.activatedRoute.snapshot.queryParams['cat_id']).subscribe(res => {
          // console.log(res);
          this.categories = res['data'];
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
        this.lookupService.getMetaData('category', this.activatedRoute.snapshot.queryParams['cat_id']).subscribe((res: any) => {
          let metaObj = res;
          // this.metaService.addTags([
          //   { name: 'description', content: metaObj?.description_seo['ar'] }
          // ]);
          // this.titleService.setTitle(metaObj?.meta_data_seo['ar']);
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
      }
      if (this.activatedRoute.snapshot.queryParams['sub_cat_id']) {
        this.categoriesService.getSubSubCategories(this.activatedRoute.snapshot.queryParams['sub_cat_id']).subscribe(res => {
          // console.log(res);
          this.categories = res['data'];
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
        this.lookupService.getMetaData('category', this.activatedRoute.snapshot.queryParams['sub_cat_id']).subscribe((res:any) => {
          let metaObj = res;
          // this.metaService.addTags([
          //   { name: 'description', content: metaObj?.description_seo['ar'] }
          // ]);
          // this.titleService.setTitle(metaObj?.meta_data_seo['ar']);
          this.spinner.hide();
        },
        error=>{
          this.spinner.hide();
        })
      }

    })

  }

  getTopCashbackStores(){
    this.allProducts.getTopCashbackStores().subscribe(
      res=>{
        console.log("TOP Cashback", res);
        this.topMerchants = res['merchants'];
        this.spinner.hide();
      }
    );
  }

  navigateCategory(cat) {
    let routs:any[] = this.router.url.split('/');
    // if (cat.parent_category_id != 0) {
    //   let query = cat.level == 0 ? "categories" : (cat.level == 1 ? 'subCategories' : (cat.level == 2 ? 'subSubCategories' : "subSubSubCategories"))
    //   // this.router.navigate([routs[1] +'/category', this.lang], {
    //   //   queryParams: {
    //   //     'categories': cat.parent_category_id,
    //   //     'subCategories':cat.id
    //   //   }
    //   // })

    //   this.categories.forEach(element=>{
    //     if(element.id == cat.id){
    //       if(element.all_children.length == 0 || element.all_children == []){
    //         let query = cat.level == 0 ? "categories" : (cat.level == 1 ? 'subCategories' : (cat.level == 2 ? 'subSubCategories' : "subSubSubCategories"))
    //         this.router.navigate([routs[1] + '/category', this.lang], {
    //           queryParams: {
    //             [query]: cat.id
    //           }
    //         })
    //       }else{
    //         this.categories = element.all_children;
    //       }
          
    //     }
    //   })

    // } else {
    //   let query = cat.level == 0 ? "cat_id" : (cat.level == 1 ?"sub_cat_id": (cat.level == 2 ? 'sub_sub_cat_id' : "sub_sub_sub_cat_id"));
    //   // this.router.navigate(['../categories'], {
    //   //   queryParams: {
    //   //     [query]: cat.id
    //   //   },
    //   //   relativeTo: this.route
    //   // })

    //   this.categories.forEach(element=>{
    //     if(element.id == cat.id){
    //       if(element.all_children.length == 0 || element.all_children == []){
    //         let query = cat.level == 0 ? "categories" : (cat.level == 1 ? 'subCategories' : (cat.level == 2 ? 'subSubCategories' : "subSubSubCategories"))
    //         this.router.navigate([routs[1] + '/category', this.lang], {
    //           queryParams: {
    //             [query]: cat.id
    //           }
    //         })
    //       }else{
    //         this.categories = element.all_children;
    //       }
          
    //     }
    //   })
    // }

    this.router.navigate([routs[1] + '/category', this.lang], {queryParams:{'categories': cat.id}});
  }

  gotoMerchant(id){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/merchant-details', this.lang], {queryParams:{'id': id}});
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }


// Start Slider


customOptions: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: true,
  navSpeed: 700,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 2
    },
    740: {
      items: 3
    },
    940: {
      items: 4
    }
  },
  nav: true
}


}
