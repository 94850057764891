import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
declare var $: any; 
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.scss']
})
export class ProductCompareComponent implements OnInit {

  public product : Observable<Product[]> = of([]);
  public products : Product[] = [];
  compareClass: string = '';

  constructor(
    private translate: TranslateService,
    private productsService: ProductsService,
    private cartService: CartService,
    private router: Router) { }
    lang;
  ngOnInit() {
    this.compareClass = this.router.url.toString().split('/')[1];
    $('.loader-wrapper').fadeOut('slow', function() {
      console.log("its ready")
      $(this).remove();
    });
    this.lang = localStorage.getItem("lang_ref");
    console.log(this.lang) 
    this.translate.use(this.lang);
    console.log("langID compare=> ",localStorage.getItem("lang_ref"))
    this.product = this.productsService.getComapreProducts();
    this.product.subscribe(products =>{
      this.products = products
      console.log("products=> ", products)
    } );
  }

  // Add to cart
  public addToCart(product: Product, quantity: number = 1) {
     this.cartService.addToCart(product, quantity, []);
  }
  
  // Remove from compare list
  public removeItem(product: Product) {
    this.productsService.removeFromCompare(product);
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

}
