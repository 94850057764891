import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';

const routes: Routes = [
  // {path:'',component:OrdersComponent},
  {path:'orders',component:OrdersComponent},
  {path:'order-details',component:OrderDetailsComponent},
  {path:'order-summary',component:OrderSummaryComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
