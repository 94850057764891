import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/shared/services/home.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit {
  lang;
  results:any[] = [];
  results1:any[] = [];
  constructor(
    private router: Router,
    private translate: TranslateService,
    private homeService: HomeService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.getAreas();
  }

  getAreas(){
    this.homeService.getAreas().subscribe(
      res=>{
        this.results = res['areas'];
      }
    )
  }

  search(searchText){
    console.log(searchText.target.value);
    if(searchText.target.value == ''){
      this.results1 = [];
    }else{
      this.results1 = [];
      this.results.forEach(element=>{
        if(element.name.en.includes(searchText.target.value)){
          console.log(element);
          this.results1.push(element);
        }
      })
    }
    
  }

  gotoMerchantsWithArea(result){
    localStorage.setItem('area_id', result.id);
    localStorage.setItem('area_name', JSON.stringify(result.name));
    this.userService.areaChanged.next(true);
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/search', this.lang], {queryParams:{'areas': result.id}});
  }
}
