import { Component, OnInit } from "@angular/core";

import { FirebaseMessaging } from "src/app/shared/services/Firebase-messageing/firebase-messaging.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-token-popup",
  templateUrl: "./token-popup.component.html",
  styleUrls: ["./token-popup.component.scss"],
})
export class TokenPopupComponent implements OnInit {
  token: string = "not generated";
  constructor(
    private firebaseMessagingService: FirebaseMessaging,
    public dialogRef: MatDialogRef<TokenPopupComponent>
  ) {}

  ngOnInit(): void {
    this.firebaseMessagingService.token$.subscribe((res) => {
      this.token = res;
    });
  }

  closeDilog() {
    this.dialogRef.close();
  }
}
