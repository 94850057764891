<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 text-left">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a (click)="gotoHome()">{{ "home" | translate }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "verifyOTP" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
</div> -->
<!-- breadcrumb End -->
<!-- section start -->
<section class="pwd-page">
  <div class="container">
    <div class="row">
      <div class="col-md-2 text-center"></div>
      <div class="col-md-8 text-center">
        <img src="assets/images/sahlalogo.png" alt="Sahla Logo" />
      </div>
      <div class="col-md-2 text-center">
        <button class="download-btn" (click)="dowloadReport()">
          {{ "End of day report" | translate }}
        </button>
      </div>

      <div class="col-lg-6 offset-lg-3">
        <h1>{{ merchantName }}</h1>
        <h2>{{ "verifyOTP" | translate }}</h2>
        <form [formGroup]="verifyOtp" class="theme-form">
          <div class="form-row">
            <div class="col-md-12">
              
              <input
                formControlName="otp"
                (keyup)="check($event)"
                type="text"
                class="form-control"
                id="email"
                minlength="6"
                maxlength="6"
                placeholder="{{ 'OTP' | translate }}"
              />

              <span
                *ngIf="
                  verifyOtp.controls['otp'].touched &&
                  verifyOtp.controls['otp']?.errors?.required
                "
                class="error-msg"
              >
                {{ "otpReqMes" | translate }}
              </span>
            </div>

            <div class="col-md-12">
              <p *ngIf="transactionType == 'debit'">
                {{ "Reedem Amount" | translate }}: {{ totalAmount }}
                {{ "EGP" | translate }}
              </p>
              <input
                formControlName="amount"
                type="text"
                class="form-control"
                id="price"
                placeholder="{{ 'Transaction total price' | translate }}"
              />

              <span
                *ngIf="
                  verifyOtp.controls['amount'].touched &&
                  verifyOtp.controls['amount']?.errors?.required
                "
                class="error-msg"
              >
                {{ "transactionAmountReqMes" | translate }}
              </span>
            </div>
            <button
              [disabled]="!verifyOtp.valid"
              (click)="confirmAmount()"
              class="btn btn-solid form-control"
            >
              {{ "submit" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
