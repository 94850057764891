import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor(public translate: TranslateService) { }
  lang;
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 

  }

}
