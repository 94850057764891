import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AboutService} from '../../../shared/services/about.service';
import { CheckoutService } from '../../services/checkout.service';
import { HomeService } from '../../services/home.service';
import { LookupService } from '../../services/lookup.service';
import { ProductsService } from '../../services/products.service';
import { UserService } from '../../services/user.service';


declare var $:any;

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  lang;
  ContactData;

  address_en;
  address_ar;

  email;
  phone;
  fax;
  routs:any[];
  areas: any[] = [];
  cities: any[] = [];
  products: any[] = [];
  defaultAddress:any;
  areaId:any;

  constructor(
    public translate: TranslateService, 
    private router: Router,
    private service:AboutService,
    private homeService: HomeService,
    private lookupService: CheckoutService,
    private productService: ProductsService,
    private userService: UserService) { }
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    this.userService.hasDefaultAddress.subscribe(
      res=>{
        console.log("THE Response is:- ", res);
        if(res == true){
          this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
          console.log("DEFAULT", this.defaultAddress);
          this.areaId = this.defaultAddress.area.id;
        }
      }
    );
    if(JSON.parse(localStorage.getItem('userObject'))){
      this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
    }
    
    this.areaId = localStorage.getItem('area_id');
    this.service.getStoreInfo().subscribe(res=>{
      console.log(this.address_en)
      this.ContactData = res;
      console.log("contact Data=> ",this.ContactData)
      this.address_en = this.ContactData[7]?.content?.en;
      this.address_ar = this.ContactData[7]?.content?.ar;
      this.fax = this.ContactData[3].content.ar;
      this.email =  this.ContactData[5]?.content.en;
      this.phone = this.ContactData[6]?.content.en;
      console.log("address_en => ",this.address_en)


    });

    this.homeService.getAreas().subscribe(
      res=>{
        this.areas = res['areas']
      }
    );

    this.lookupService.getcities().subscribe(
      res=>{
        this.cities = res['cities'];
      }
    );

    this.productService.getProductsByCriteria({}).subscribe(
      res=>{
        this.products = res['data'];
      }
    )

  }
  changeLang(lang) {
    console.log(lang)
      if(lang == 'en') {
        this.lang = 'ar'
        this.translate.use('ar');
        localStorage.setItem("lang_ref",'ar')
        window.location.reload()
      }
      else {
        this.lang = 'en';
        $("body").removeClass('rtl');
        this.translate.use('en');
        localStorage.setItem("lang_ref",'en')
        // console.log("language => ",this.translate.getDefaultLang())
        window.location.reload()
      }

      console.log("final lang => ",this.lang)

  }

  gotoHome(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/search', this.lang]);
  }

  gotoHome2(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/', this.lang]);
  }

  gotoCategories(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/categories', this.lang]);
  }

  gotoProducts(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/products', this.lang]);
  }

  gotoInstore(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/instore', this.lang]);
  }

  gotoAbout(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/about-us', this.lang]);
  }

  gotoContact(){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/contact', this.lang]);
  }

  gotoMerchants(id){
    this.routs = this.router.url.split('/');
    this.router.navigate([this.routs[1] + '/category', this.lang], {queryParams:{'areas': id}});
  }

}
