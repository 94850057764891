<!-- collection banner -->
<section class="banner-padding banner-furniture mt-5">
    <div class="container">
        <h2 class="section-title">
            <span>

                {{'Categories' | translate}}
            </span>
        </h2>
        <div class="row partition3 ">
            <div (click)="navigateCategory(categories[0])" class="col-md-12">
                <div class="img-wz-title">
                    <img [src]="categories[0]?.home_image[0]?.default" *ngIf="categories.length" alt=""
                        class="img-fluid big-img">
                    <div>
                        <h3 class="cat-title" *ngIf="lang == 'en'">
                            <span>

                                {{categories[0]?.name.en}}
                            </span>
                        </h3>
                        <h3 class="cat-title" *ngIf="lang == 'ar'">
                            <span>

                                {{categories[0]?.name.ar}}
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div (click)="navigateCategory(category)" *ngFor="let category of categories; let i =index"
                        [className]="i>0? 'col-md-6 col-sm-6 col-xs-12  cursor--pointer' : 'col-md-6 col-sm-6 col-xs-12 display-none  cursor--pointer'"
                        class="">
                        <div class="img-wz-title min-col">

                            <img [src]="category?.home_image[0]?.default" alt="small Images"
                                class="img-fluid small-img">
                            <div>
                                <h3 class="cat-title" *ngIf="lang == 'en'">
                                    <span>

                                        {{category?.name.en}}
                                    </span>
                                </h3>
                                <h3 class="cat-title" *ngIf="lang == 'ar'">
                                    <span>

                                        {{category?.name.ar}}
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Teasting-->
            <!-- <div class="col-md-6 col-sm-6 col-xs-12 cursor--pointer">
                <img style="height: 457px;" src="https://souqcms.s3-eu-west-1.amazonaws.com/cms/boxes/img/desktop/1604850187_FashionMerch-970x678-en.png"  alt="" class="img-fluid">
                <div>
                    <h3 class="cat-title" *ngIf="lang == 'en'">{{categories[0].title.En}}</h3>
                    <h3 class="cat-title" *ngIf="lang == 'ar'">{{categories[0].title.ar}}</h3>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 12px;">
                        <img src="https://souqcms.s3-eu-west-1.amazonaws.com/cms/boxes/img/desktop/1603627967_banner-3-en.jpg"  alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 12px;">
                        <img src="https://souqcms.s3-eu-west-1.amazonaws.com/cms/boxes/img/desktop/1603627967_banner-3-en.jpg"  alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 12px;">
                        <img src="https://souqcms.s3-eu-west-1.amazonaws.com/cms/boxes/img/desktop/1603627967_banner-3-en.jpg"  alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 12px;">
                        <img src="https://souqcms.s3-eu-west-1.amazonaws.com/cms/boxes/img/desktop/1603627967_banner-3-en.jpg"  alt="" class="img-fluid">
                    </div>
                </div>
            </div> -->

            <!--End Teasting-->
            <!-- <div *ngFor="let category of categories" class="col-md-6 col-sm-6 col-xs-12" style="margin-bottom: 12px;">
                <a class="action" (click)="navigateCategory(category)">
                    <div class="collection-banner p-left text-left" style="box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.05);">
                        <img [src]="category?.home_image[0]?.default" style="height: 230px;" alt="" class="img-fluid">
                        <div class="custom-title">
                            <div>
                                <h3 class="cat-title" *ngIf="lang == 'en'">{{category.title.En}}</h3>
                                <h3 class="cat-title" *ngIf="lang == 'ar'">{{category.title.ar}}</h3>
                            </div>
                        </div>
                    </div>
                </a>
            </div> -->
        </div>
    </div>
</section>