import { Component, OnInit } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { time } from "../edit-opening-times/time";

@Component({
  selector: "app-opening-time",
  templateUrl: "./opening-time.component.html",
  styleUrls: ["./opening-time.component.scss"],
})
export class OpeningTimeComponent implements OnInit {
  merchantName: string;
  closeResult = "";
  lang = "";
  days;
  id: string;
  type: string;
  merchantsList: any;
  merchant: any;
  merchantsIds: any[] = [];
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClientService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.merchantName = localStorage.getItem("merchantName");
    this.id = localStorage.getItem("merchantId");
    this.type = localStorage.getItem("type");
    this.getList();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.merchantName = this.merchant.name;
          this.getWorkingDays();
          this.toaster.success(res["message"]);
        }
      });
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.merchantName = event.value.name;
    this.getWorkingDays();
  }
  getWorkingDays() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = { merchant_id: this.id };
    this.http
      .get("merchant/working_days", body, headers)
      .subscribe((res: any) => {
        this.days = res.working_days;
      });
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.merchantsIds = [];
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.merchantsIds = [];
      return "by clicking on a backdrop";
    } else {
      this.merchantsIds = [];
      return `with: ${reason}`;
    }
  }
  getTime(t) {
    return time.find((res) => res.key == t).text;
  }
  gotoEdit() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/edit-opening", this.lang, this.id]);
  }
  oncheck(e, branch) {
    if (e.target.checked) {
      this.merchantsIds.push(branch.id);
    } else {
      this.merchantsIds = this.merchantsIds.filter((b) => {
        return b != branch.id;
      });
    }
  }
  copyToMerchants(content) {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      merchant_id: this.id,
      merchants_ids: this.merchantsIds,
    };
    this.http
      .post("merchant/working_days/copyToAll", body, {}, headers)
      .subscribe((res) => {
        this.merchantsIds = [];
        this.modalService.dismissAll(content);
      });
  }
  checkAll() {
    this.merchantsIds = this.merchantsList.map((res) => res.id);
    this.merchantsIds = this.merchantsIds.filter((res) => res != this.id);
  }
  none() {
    this.merchantsIds = [];
  }
}
