<div style="min-height: 300px;" class="ng-scope">
    <div class="ng-scope">
        <section class="ng-scope">
            <div class="container menu-page inner-page">

                <div class="row p-t">
                    <div class="col-xs-16 rest-info">
                        <div class="media rest-media">
                            <div [class]="lang == 'en'?'media-left':'media-right'">
                                <div class="rest-logo-bg">
                                    <!-- <img [src]="merchantData?.cover_image[0]?.default"> -->
                                    <img [src]="merchantData?.cover_image[0]?.default? merchantData?.cover_image[0]?.default : 'assets/images/sahlalogo.png'">
                                </div>
                            </div>

                            <div class="media-body">
                                <div class="row">
                                    <div class="col-md-8 p-r-0">
                                            <h1 class="f-15-m media-heading ng-binding">
                                                {{merchantData?.merchant_brand_title}}<br><small
                                                    class="ng-binding">{{merchantData?.name}}</small>
                                            </h1>
                                    </div>

                                    <div class="col-md-4 f-15-m " [style.text-align]="lang == 'en'?'left':'right'">
                                        <div [style.text-align]="lang == 'en'?'right':'left'">
                                            <b class="h5 menu-open ng-binding red-text"
                                                ng-class="{'red-text': model.area.stt, 'greentext': !model.area.stt}"
                                                style="color: #f9523d;">Busy</b>


                                            <div class="rating-sect-menu p-t-5-m  text-left-m"
                                                [style.text-align]="lang == 'en'?'right':'left'">
                                                <!-- New Rating -->
                                                <div ng-if="model.restaurant.rat >= 1" data-restaurant-rating=""
                                                    rating="model.restaurant.rat" class="ng-scope ng-isolate-scope">
                                                    <div class="rating-wrapper">
                                                        <div class="rating-image-word">
                                                            <!-- <i class="fa fa-star"></i> -->
                                                            <i class="fab fa-gratipay"></i>
                                                            <div class="rating-word ng-binding">Very good</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>


                                <div class="row">
                                    <div class="col-md-12 text-75 card-data p-0">
                                        <div class="row m-0">

                                            <div class="col-md-8  p-t p-t-5-m">

                                                <div>
                                                    <span>

                                                        {{'deliverfees' | translate}}:
                                                    </span>
                                                    <span class="ng-binding ng-scope">{{merchantData?.delivery_fees}}
                                                        {{'EGP' |
                                                        translate}}</span>
                                                </div>
                                                <div *ngIf="merchantData?.cachback_percent">
                                                    <span>

                                                        {{'Cashback' | translate}}
                                                    </span>

                                                    <span>

                                                        {{merchantData?.cachback_percent}} %
                                                    </span>


                                                </div>
                                                <div>
                                                    <span>

                                                        {{'phoneno' | translate}}:
                                                    </span>
                                                    <span>

                                                        {{merchantData?.phone}}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span>

                                                        {{'Min Order Amount' | translate}}:
                                                    </span>
                                                    <span>

                                                        {{merchantData?.min_order}}
                                                    </span>
                                                </div>

                                                <div>
                                                    <span>

                                                        {{'deliveryTime' | translate}}:
                                                    </span>
                                                    <span>

                                                        {{merchantData?.delivery_time}}
                                                    </span>
                                                </div>

                                                <div>
                                                    <bar-rating rate={{merchantData?.avg_rank}} [readOnly]="true"></bar-rating>  
                                                </div>



                                                <div>
                                                    <button class="btn btn-success"
                                                        (click)="gotoBranches(merchantData?.merchant_brand_id)">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>
                                                            Show
                                                        </span>
                                                    </button>
                                                </div>

                                            </div>
                                            <div class="col-md-4">

                                                <div class="m-b-0 payment-img"
                                                    [style.text-align]="lang == 'en'?'right':'left'">
                                                    <img src="https://www.talabat.com/images/talabat/cash-icon.svg"
                                                        ng-if="::model.restaurant.ac" class="ng-scope">

                                                    <img src="https://www.talabat.com/images/talabat/Visa_blue.png"
                                                        ng-if="::model.restaurant.acr" class="ng-scope">
                                                    <img src="https://www.talabat.com/images/talabat/mastercard-icon.svg"
                                                        ng-if="::model.restaurant.acr" class="ng-scope">
                                                </div>

                                            </div>

                                        </div>





                                    </div>


                                    <!-- <div class="col-md-6 p-l-0">
                                        <p class="m-b-0 payment-img" [style.text-align]="lang == 'en'?'right':'left'">
                                            <img src="https://www.talabat.com/images/talabat/cash-icon.svg"
                                                ng-if="::model.restaurant.ac" class="ng-scope">

                                            <img src="https://www.talabat.com/images/talabat/Visa_blue.png"
                                                ng-if="::model.restaurant.acr" class="ng-scope">
                                            <img src="https://www.talabat.com/images/talabat/mastercard-icon.svg"
                                                ng-if="::model.restaurant.acr" class="ng-scope">
                                        </p>
                                    </div> -->
                                </div>



                            </div>

                        </div>

                        <div class="media info-row p-y m-t ng-scope">
                            <div class="p-x pull-left">
                                <!-- <i class="fa fa-info-circle fa-3x"></i> -->
                                <img src="./assets/images/delivery-truck.png" alt="">

                            </div>
                            <div class="font-weight-bold">Delivered by us, with live tracking</div>

                        </div>


                    </div>
                </div>

                <div class="row clearfix m-t tab-section m-x-0 b-b">
                    <div class="col-sm-14 col-md-12">

                        <ul class="btn-group btn-group-justified most-tab-group nav nav-tabs " role="tablist">
                            <li class="nav-item  btn-group" role="presentation">
                                <button class="btn active nav-link" id="menu-tab" data-toggle="tab" href="#menu"
                                    role="tab" aria-controls="menu">
                                    <p class="h4 f-15-m"><span class="p-r hidden-xs">
                                            <img src="https://www.talabat.com/images/talabat/menu-ico.svg"
                                                alt="menu tab" height="25">
                                        </span><span class="text-left">{{'HOME'|translate}}</span></p>
                                </button>
                            </li>
                            <li class="nav-item  btn-group" role="presentation">
                                <button class="btn  nav-link" id="review-tab" data-toggle="tab" href="#review"
                                    role="tab" aria-controls="review">
                                    <p class="h4 f-15-m"><span class="p-r hidden-xs">
                                            <img src="https://www.talabat.com/images/talabat/icon_reviews.svg"
                                                alt="menu tab" height="25">
                                        </span><span class="text-left">{{'Review'|translate}}</span></p>
                                </button>
                            </li>

                            <!-- <li class="btn-group">
                                <button class="btn  nav-link" id="info-tab" data-toggle="tab" href="#info" role="tab"
                                    aria-controls="info">
                                    <p class="h4 f-15-m"><span class="p-r hidden-xs">
                                            <img src="https://www.talabat.com/images/talabat/icon_info_menu.svg"
                                                alt="menu tab" height="25">
                                        </span><span class="text-left">{{'storeinfo'|translate}}</span></p>
                                </button>
                            </li> -->

                        </ul>

                    </div>

                </div>
                <div class="row p-t">
                    <div class="col-md-8 rest-info itemsection tab-content" style="width: 68.75%;">
                        <section class="menu-section tab-pane fade show active" id="menu" role="tabpanel"
                            aria-labelledby="menu-tab">
                            <div class="row">
                                <div class=" wrapper-sticky navba col-md-4"
                                    [style.text-align]="lang == 'en'?'left':'right'">

                                    <div class="cat-info ">
                                        <div class="category-area ng-scope">
                                            <label class="f-16 m-b-md"><b>{{'category'| translate}}</b></label>
                                            <p *ngFor="let cat of categories ; let i=index"
                                                class="text-646464 ng-scope active"
                                                [style.text-align]="lang == 'en'?'left':'right'">
                                                <a data-toggle="collapse" (click)="onClick('tab'+i)">
                                                    <span class="truncate menu-division ng-binding">{{cat.name}}</span>

                                                </a>
                                            </p>

                                        </div>
                                    </div>


                                </div>

                                <div class="col-md-8 menu-info">
                                    <div class="row p-y-0 ng-scope">
                                        <div class="col-xs-16">
                                            <form class="ng-pristine ng-valid">
                                                <div class="form-group">
                                                    <div class="input-group itemsearch block ng-scope">
                                                        <input
                                                            class="full-width ng-pristine ng-valid ng-empty ng-touched input "
                                                            type="text" id="search_query" [(ngModel)]="searchQuery"
                                                            (ngModelChange)="searchProducts()"
                                                            placeholder="What are you looking for?" name="q"
                                                            autocomplete="off">

                                                        <span class="search-icon"
                                                            [style.right]="lang=='en'?'11px':'auto'"><i
                                                                class="fa fa-search"></i></span>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                    <!-- products -->
                                    <div [attr.id]="'tab'+i" *ngFor="let catt of categories ; let i =index"
                                        class="ng-scope">

                                        <div>

                                            <div class="card card-collapse mx-0 w-100">
                                                <!-- product title -->
                                                <a class="card-header custom-card-header fs-17" data-toggle="collapse"
                                                    [attr.href]="'#tab_' + i" role="button" aria-expanded="false"
                                                    aria-controls="collapseExample"
                                                    [style.text-align]="lang == 'en'?'left':'right'">

                                                    {{catt.name}}
                                                    <i class="fas fa-chevron-down btn-direction"
                                                        [style.float]="lang == 'en'?'right':'left'"></i>

                                                </a>

                                                <div class="collapse show" [attr.id]="'tab_' + i">
                                                    <!-- <div class=" card-body"> -->
                                                    <div class="cat-items   ng-scope">
                                                        <div class="cat-item media"
                                                            [ngClass]="product.category_id != catt.id? 'dn':''"
                                                            [class.p-y]="catt.id===product.category_id"
                                                            [class.b-b]="catt.id===product.category_id"
                                                            *ngFor="let product of allproducts  ; let i =index">
                                                            <div class="media-left"
                                                                *ngIf="product.category_id === catt.id">
                                                                <!-- product image -->
                                                                <div class="item-logo ng-scope b-a">
                                                                    <a tabindex="0" ng-show="item.img">
                                                                        <img
                                                                            [src]="product.mediaCollection[0]['default']? product.mediaCollection[0]['default'] : 'assets/images/sahlalogo.png'">
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div class="media-body"
                                                                *ngIf="catt.id===product.category_id">
                                                                <div class="row text-212121 regular">
                                                                    <!-- product price -->
                                                                    <div class="col-md-6 item-info" (click)="open(content, product)">
                                                                        <h4 class="f-15 m-b-0 ng-binding"
                                                                            style="font-weight: bold;">
                                                                            {{product.title}} </h4>

                                                                        <bar-rating rate={{product.avg_rank}}
                                                                            [readOnly]="true"></bar-rating>

                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="pull-left priceSection">
                                                                            <span class="">
                                                                                <p class="">
                                                                                    <span class="font-weight-bold">

                                                                                        {{product?.price }}
                                                                                    </span>
                                                                                    {{'EGP'|translate}}
                                                                                </p>
                                                                            </span>

                                                                            <div class="">
                                                                                <div class="">
                                                                                    <div class="rating-wrapper">
                                                                                        <div class="rating-image-word">

                                                                                            <!-- div
                                                                                                class="rating-word ng-binding">
                                                                                                <span class="font-weight-bold">

                                                                                                    ({{product.tax?.percentage}}%
                                                                                                </span>
                                                                                                <span
                                                                                                    class="tax-style">{{product.tax?.title.en}})</span>
                                                                                            </div>< -->
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="pull-right addItemSection">
                                                                            <i class="fas fa-plus-circle rounded-circle"
                                                                                data-toggle="modal"
                                                                                *ngIf="product.custom_fields.length > 0"
                                                                                (click)="open(content, product)"
                                                                                [attr.data-target]="'#'+product.id"></i>
                                                                            <i class="fas fa-plus-circle rounded-circle"
                                                                                data-toggle="modal"
                                                                                *ngIf="product.custom_fields.length == 0"
                                                                                (click)="addToCart(product)"
                                                                                [attr.data-target]="'#'+product.id"></i>
                                                                            <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCGb3IRCoqLJJyOIr_AkTmfhhYfYk3sI1svg&usqp=CAU"
                                                                                alt="..." class="rounded-circle"
                                                                                style="width: 25px; "
                                                                                data-toggle="modal"
                                                                                (click)="addToCart(product)"
                                                                                [attr.data-target]="'#'+product.id"> -->


                                                                            <!-- ////////////////////////////////////((start popup)))//////////////////////////////////////////////// -->
                                                                            <!-- Modal -->


                                                                            <!-- ////////////////////////////////////((end popup)))//////////////////////////////////////////////// -->

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <hr> -->


                                                        </div>
                                                    </div>
                                                    <!-- </div> -->
                                                </div>

                                                <div *ngIf="allproducts?.length == 0" class="collapse show"
                                                    [attr.id]="'tab_' + i">

                                                    <div class="cat-items   ng-scope">
                                                        <div class="cat-item media ">
                                                            <section _ngcontent-cmj-c1=""
                                                                class="empty-cart p-y-md f-16 text-center ng-scope"
                                                                style="width: 100%; background-color: #fff;">
                                                                <img _ngcontent-cmj-c1=""
                                                                    src="https://www.talabat.com/images/talabat/empty-cart.svg"
                                                                    width="40">
                                                                <p _ngcontent-cmj-c1="" class="p-t-md f-14"
                                                                    style="text-align: center;">
                                                                    <b _ngcontent-cmj-c1="">{{'notAvialable' |
                                                                        translate}}</b>
                                                                </p>
                                                            </section>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </section>

                        <section class="tab-pane fade skelton-tab" id="review" role="tabpanel"
                            aria-labelledby="review-tab">
                            <section class="reviews-available">
                                <div class="row">
                                    <div class="col-xs-16">
                                        <p class="f-18">
                                            <b class="ng-binding">({{reviewsCount}}) Reviews</b>

                                        </p>
                                        <div class="m-b-lg">
                                            <div ratings="reviews.sr">
                                                <ul class="reveiw-counts p-t p-r">
                                                    <li class="ng-binding" *ngFor="let rate of rates">{{rate?.rate}} &nbsp; 
                                                        <bar-rating rate={{rate?.rate}} [readOnly]="true"></bar-rating> 
                                                        <p *ngIf="lang == 'ar'" class="m-b-0">{{rate?.criteria?.title['ar']}}</p>
                                                        <p *ngIf="lang == 'en'" class="m-b-0">{{rate?.criteria?.title['en']}}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="reviews.length == 0 || reviews == null">
                                            <p>{{'noreview'| translate}}</p>
                                        </div>
                                        <div class="b-radius b-a p-a f9f9f9-bg m-b ng-scope"
                                            *ngFor="let review of reviews">
                                            <div class="m-b text-727272">
                                                <span class="pull-right ng-binding">{{review.created_at | date}}</span>
                                                <br class="visible-xs">
                                                <span class="f-14 p-t-5-m ng-binding">Adham
                                                    A***</span>
                                            </div>
                                            <p>
                                                <b class="f-14 ng-binding">{{review.comment}}
                                                </b>
                                            </p>

                                        </div>
                                    </div>


                                </div>

                            </section>

                        </section>

                        <!-- <section class="tab-pane fade skelton-tab " id="info" role="tabpanel"
                            aria-labelledby="info-tab">
                            <div class="row">
                                <div class="col-xs-16">
                                    <p><b class="f-18 f-13-m ng-binding"
                                            [style.float]="lang == 'en'?'left':'right'">{{'STORE_INFORMATION'|translate}}</b>
                                    </p>
                                    <div class="p-a">
                                        <div class="row p-y b-t">
                                            <div class="col-xs-7 p-l-0"><b
                                                    [style.float]="lang == 'en'?'left':'right'">{{'address'|translate}}</b>
                                            </div>
                                            <div class="col-xs-9">
                                                <b class="ng-binding ng-scope">
                                                    <span *ngIf="lang == 'en'" class="gray-med-color lineHeight"
                                                        [innerHTML]="address_en">
                                                    </span>
                                                    <span *ngIf="lang == 'ar'" class="gray-med-color lineHeight"
                                                        [innerHTML]="address_ar">
                                                    </span>
                                                </b>
                                            </div>
                                        </div>
                                        <div class="row p-y b-t">
                                            <div class="col-xs-7 p-l-0"><b
                                                    [style.float]="lang == 'en'?'left':'right'">{{'call-us'|translate}}:</b>
                                            </div>
                                            <div class="col-xs-9">
                                                <p class="gray-med-color lineHeight" [innerHTML]="phone"></p>
                                            </div>
                                        </div>
                                        <div class="row p-y b-t">
                                            <div class="col-xs-7 p-l-0"><b
                                                    [style.float]="lang == 'en'?'left':'right'">{{'storemail'|translate}}</b>
                                            </div>
                                            <div class="col-xs-9">
                                                <b class="ng-binding ng-scope"
                                                    [style.float]="lang == 'en'?'left':'right'">
                                                    <p class="gray-med-color lineHeight" [innerHTML]="email"></p>
                                                </b>
                                            </div>
                                        </div>
                                        <div class="row p-y b-t">
                                            <div class="col-xs-7 p-l-0"><b
                                                    [style.float]="lang == 'en'?'left':'right'">{{'storefax'|translate}}</b>
                                            </div>
                                            <div class="col-xs-9">
                                                <b class="ng-binding ng-scope"> <span class="gray-med-color lineHeight"
                                                        [innerHTML]="fax"></span></b>
                                            </div>
                                        </div>

                                        <div class="row p-y b-t">
                                            <div class="col-xs-7 p-l-0"><b
                                                    [style.float]="lang == 'en'?'left':'right'">{{'payment'|translate}}</b>
                                            </div>
                                            <div class="col-xs-9 payment-img">
                                                <img src="https://www.talabat.com/images/talabat/cash-icon.svg"
                                                    ng-if="::model.restaurant.ac" class="ng-scope">
                                                <img src="https://www.talabat.com/images/talabat/Visa_blue.png"
                                                    ng-if="::model.restaurant.acr" class="ng-scope">
                                                <img src="https://www.talabat.com/images/talabat/mastercard-icon.svg"
                                                    ng-if="::model.restaurant.acr" class="ng-scope"
                                                    style="margin-left:10px;">
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section> -->
                    </div>

                    <div class="col-md-4 wrapper-sticky">
                        <div class=" cart-info  m-b">
                            <div class="ng-isolate-scope">
                                <div class="cart-section">
                                    <div class="cart-loading">
                                        <span><i class="fa fa-spin fa-circle-o-notch orange fa-3x"></i></span>
                                    </div>
                                    <div class="cart-head orange-bg p-a"
                                        [style.text-align]="lang == 'en'?'left':'right'">

                                        <span class="f-20">{{'YourCart'|translate}}</span>
                                    </div>

                                    <!-- cart empty -->
                                    <section class="empty-cart p-y-md f-16 text-center ng-scope"
                                        *ngIf="(shoppingCartItems?.length == 0 || shoppingCartItems == undefined) && !isLoading">
                                        <img src="https://www.talabat.com/images/talabat/empty-cart.svg" width="80">
                                        <p class="p-t-md f-14" style="text-align: center;">
                                            <b>{{'noproduct'|translate}}</b>
                                        </p>
                                    </section>

                                    <!-- cart Not empty -->
                                    <section class="cart-available-section" *ngIf="shoppingCartItems?.length > 0 && !isLoading">
                                        <section class="ng-scope">

                                        </section>

                                        <section class="cartItems p-a ng-scope ">
                                            <table class="table table-condensed m-b-0">
                                                <tbody>
                                                    <tr class="p-y-md f-12 cart-item ng-scope"
                                                        *ngFor="let item of shoppingCartItems;index as i">

                                                        <td class="p-l-0 td">
                                                            <section class="qty-update-section incart b-a ng-scope">
                                                                <button type="button" class="btn btn-sm b-r"
                                                                    [disabled]="product?.stock_status_id == 3"
                                                                    (click)="decrement(item)">
                                                                    <i class="fa fa-minus orange"
                                                                        style="background-color: white;"></i></button>

                                                                <span class="f-11">
                                                                    <input type="text" name="quantity" disabled
                                                                        class="ng-binding count"
                                                                        [(ngModel)]="item.quantity">
                                                                </span>

                                                                <button type="button" class="btn btn-sm b-l"
                                                                    [disabled]="product?.stock_status_id == 3"
                                                                    (click)="increment(item)">
                                                                    <i class="fa fa-plus orange"
                                                                        style="background-color: white;"></i></button>
                                                            </section>

                                                        </td>

                                                        <td class="p-x-0 cart-item-nm">

                                                            <a class="cart-product-name">
                                                                <b class="ng-binding"
                                                                    style="font-weight: normal;">{{ lang == 'ar' ?
                                                                    item.itemable.title['ar'] :
                                                                    item.itemable.title['en'] }}
                                                                    <!-- <i class="fa fa-info-circle"></i> -->
                                                                </b>
                                                            </a>

                                                        </td>

                                                        <td class="p-r-0 text-center price-td ng-scope"
                                                            style="min-width: 67px;">
                                                            <label class="text-center ng-binding">
                                                                {{ item.itemable.price * item.quantity }}
                                                            </label>
                                                            <label class="p-l-5 m-b-0  del-item"
                                                                [style.text-align]="lang == 'en'?'left':'right'">
                                                                <i class="fa fa-minus-circle"
                                                                    (click)="removeItem(item)"></i>
                                                            </label>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </section>

                                        <section class="total-section p-a b-radius pt-0 ng-scope">
                                            <table class="table table-condensed">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding-top:20px;min-width: 159px; text-align: left;"
                                                            [style.text-align]="lang == 'en'?'left':'right'">
                                                            <b class="f-14"> {{'deliverfees'|translate}}</b>
                                                        </td>
                                                        <td class=" price "
                                                            [style.text-align]="lang == 'en'?'left':'right'"
                                                            style="padding-top:20px; min-width: 107px;"
                                                            [style.text-align]="lang == 'en'?'right':'left'">
                                                            <b class="f-14 ng-binding">{{ deliveryfees }} {{'EGP'
                                                                |translate}}</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="padding-top:20px;min-width: 159px; text-align: left;"
                                                            [style.text-align]="lang == 'en'?'left':'right'">
                                                            <b class="f-14"> {{'cashback'|translate}}</b>
                                                        </td>
                                                        <td class=" price "
                                                            [style.text-align]="lang == 'en'?'left':'right'"
                                                            style="padding-top:20px; min-width: 107px;"
                                                            [style.text-align]="lang == 'en'?'right':'left'">
                                                            <b class="f-14 ng-binding">{{ cashback }} {{'EGP'
                                                                |translate}}</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="padding-top:20px;min-width: 159px; text-align: left;"
                                                            [style.text-align]="lang == 'en'?'left':'right'">
                                                            <b class="f-14"> {{'subtotal'|translate}}</b>
                                                        </td>
                                                        <td class=" price "
                                                            [style.text-align]="lang == 'en'?'left':'right'"
                                                            style="padding-top:20px; min-width: 107px;"
                                                            [style.text-align]="lang == 'en'?'right':'left'">
                                                            <b class="f-14 ng-binding">{{ subTotal }} {{'EGP'
                                                                |translate}}</b>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="padding-top:20px;min-width: 159px; text-align: left;"
                                                            [style.text-align]="lang == 'en'?'left':'right'">
                                                            <b class="f-14"> {{'total'|translate}}</b>
                                                        </td>
                                                        <td class=" price "
                                                            [style.text-align]="lang == 'en'?'left':'right'"
                                                            style="padding-top:20px; min-width: 107px;"
                                                            [style.text-align]="lang == 'en'?'right':'left'">
                                                            <b class="f-14 ng-binding">{{ total }} {{'EGP'
                                                                |translate}}</b>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div class="ng-scope">
                                                <button (click)="gotoCheckout()" type="button"
                                                    class="btn btn-block b-radius-3 green f-14  m-b-5 ng-scope">
                                                    Proceed to Checkout
                                                </button>
                                            </div>
                                        </section>

                                    </section>

                                    <section class="cart-available-section text-center" *ngIf="isLoading">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">{{'Product Details' | translate}}</h4> -->
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div>
        <div>
            <div role="document">
                <div class="modal-content ">


                    <!-- <div class="py-2 px-3 modal-header">
                        <div class="f-20 color-black">
                            Add Item Choices</div>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->


                    <div class=" px-3 modal-body">
                        <div class="pt-0">
                            <div class="mb-3">
                                <!-- <div class="container" style="max-width: 100%;"> -->
                                    <div class="row align-items-center">
                                        <div class="col-lg-9 col-md-9 col-sm-12 col-12">
                                            <p class="f-400 f-14 mb-0">
                                                <strong>
                                                    {{theProduct.title}}
                                                </strong>
                                            </p>
                                            <span>{{theProduct.content}}</span>
                                        </div>
                                        <div
                                            class="reverse d-flex justify-content-end col-lg-7 col-md-7 col-sm-12 col-16">
                                            <div
                                                class="counterstyles__CounterContainer-sc-1tb097t-0 fpCVhu from-choice">
                                                <button type="button" (click)="decrement1()"
                                                    class="btn pa-1 b-r primary-text f-10 transparent">
                                                    <i class="fa fa-minus orange transparent"></i>
                                                </button>

                                                <span class="f-11 ">
                                                    <input type="text" name="quant" disabled [(ngModel)]="counter"
                                                        class="ng-binding count">
                                                </span>

                                                <button type="button" (click)="increment1()"
                                                    class="btn pa-1 b-l primary-text f-10 transparent">
                                                    <i class="fa fa-plus orange transparent"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                <!-- </div> -->
                            </div>

                            <div class="card">
                                <div>
                                    <div class="px-0 py-2 b-t px-m-0">
                                            <div class="container">
                                            <div class="row" *ngFor="let field of customeFields">
                                                <h2 class="col-md-12 mt-3" *ngIf="field?.map?.length > 0">
                                                    {{field?.field?.name}}</h2>
                                                <div *ngIf="field?.field?.type == 'radio'">
                                                    <div class="py-1 col-md-12" *ngFor="let option of field?.map">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions + {{field?.id}}"
                                                                id="inlineRadio1" (change)="handleChange($event, field)"
                                                                [value]="option?.field_option_id">
                                                            <label class="form-check-label" for="inlineRadio1">
                                                                <span class="text">{{option?.field_option_name}}</span>
                                                            </label>

                                                            <span>{{option?.price_value}} {{'EGP' | translate}}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="field?.field?.type == 'select'">
                                                    <div class="py-1 col-md-12" *ngFor="let option of field?.map">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                [name]="option" [(ngModel)]="option.Checked"
                                                                (change)="getCheckboxValues($event, option, field?.id)">
                                                            <label class="form-check-label" for="inlineRadio1">
                                                                <span class="text">{{option?.field_option_name}}</span>
                                                            </label>

                                                            <span>{{option?.price_value}} {{'EGP' | translate}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 mt-4 d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center 
                             f-400 f-18 py-2 px-2 btn btn-success mt-0"
                            (click)="addToCart(theProduct, counter)" title="Add to cart" data-dismiss="modal">
                            <img class="mr-2" width="20" src="./assets/images/shopping-bag.png">
                            Add To Cart</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ngx-spinner color="#f95733"></ngx-spinner>