<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">{{'newproducts'|translate}}</h5>
  <ngx-slick-carousel class="offer-slider" #slickModal="slick-carousel">
    <div ngxSlickItem>
      <div class="media" *ngFor="let product of products">
        <a (click)="gotoDetails(product.id)">
          <img class="img-fluid" [src]="product?.mediaCollection[0]?.default" alt="">
        </a>
        <div class="media-body align-self-center">
          <a *ngIf="lang == 'ar'" (click)="gotoDetails(product.id)"><h6>{{product.title.ar}}</h6></a>
          <a *ngIf="lang == 'en'" (click)="gotoDetails(product.id)"><h6>{{product.title.en}}</h6></a>
          <h4 *ngIf="!productsService?.catalogMode">{{product.price}} {{'SAR' | translate}}</h4>

          <bar-rating [rate]="5" [readOnly]="true"></bar-rating>

        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<!-- side-bar single product slider end -->
