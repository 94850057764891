import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartItem } from '../classes/cart-item';
import { Product } from '../classes/product';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from './http-client.service';
import { Router } from '@angular/router';
import { element } from 'protractor';


// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItem")) || [];
@Injectable({
  providedIn: 'root'
})

export class CartService implements OnInit {
  cartItemsNew = [];
  orderChanged: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  lang;
  token = '';
  cartToken;
  // Array
  public cartItems: any[] = [];
  cartUpdated: EventEmitter<any> = new EventEmitter<any>();



  constructor(
    private toastrService: ToastrService, 
    private translate: TranslateService,
    private httpClientService: HttpClientService,
    private router: Router
    ) {
    // this.cartItems.subscribe(products => products = products);


  }
  ngOnInit(): void {
    this.getItems();
    this.lang = localStorage.getItem('lang_ref');
    this.token = localStorage.getItem('token');
    console.log('CCCCart TOOOken', this.token);
    this.cartToken = localStorage.getItem('cartToken');
  }

  // Get Products
  getItems(){
    if(localStorage.getItem('token') != null){
      return this.httpClientService.get('cart', []);
    }else{
      return this.httpClientService.get('cart', {'cart_token':localStorage.getItem('cartToken')});
    }
    
  }


  setCartItems(items) {
    items.forEach(item => {
      if (item['quantity'] == 0) {
        let currentProductIndex = items.findIndex(itom => itom.id == item.id);
        if (currentProductIndex > -1) {
          items.splice(currentProductIndex, 1);
          // this.setCartItems(items);
        }
      }
    });

    localStorage.setItem('currentCart', JSON.stringify(items));
    this.orderChanged.next(items);
    this.cartUpdated.emit();
  }

  //check product
  checkProduct(product:any){
    this.getItems().subscribe(
      (res:any[])=>{
        console.log(res)
        if(res['cart']?.product_items?.length > 0){
          res['cart']?.product_items.forEach(element=>{
            if(element.itemable.id == product.id){
              this.updateCartQuantity(product, element.quantity+1, element.id).subscribe(
                res=>{
                  this.getItems();
                  this.cartUpdated.emit();
                }
              );
            }else{
              this.addToCart(product, 1, [])
            }
          });
        }else{
          this.addToCart(product, 1, [])
        }
        
      }
    );
  }

  // Add to cart
  public addToCart(product: Product, quantity: number, fields:any[]) {
    let routs:any[] = this.router.url.split('/');
    let obj = {
      "itemable_type":"products",
      "itemable_id": product.id,
      "quantity":quantity,
      "custom_fields": fields,
      "cart_token":''
    }

    console.log('Token isss', this.token)
    
    if(this.token == '' || this.token == undefined || this.token =='null'){
      // if(localStorage.getItem('cartToken') == 'null' || localStorage.getItem('cartToken') == ''){
        delete obj.cart_token
      // }else{
      //   obj.cart_token = localStorage.getItem('cartToken')
      // }
      
    }

    if(product.stock_status_id != 3){
      this.httpClientService.post('cart', obj, []).subscribe(
        (res: any)=>{
          if(localStorage.getItem('lang_ref') == 'en'){
            this.toastrService.success('Added successfully');
          }else if(localStorage.getItem('lang_ref') == 'ar'){
            this.toastrService.success('تم الإضافة بنجاح');
          }
          localStorage.setItem('cartToken', res.cart.token);
          this.cartUpdated.emit();
          // this.router.navigate([routs[1] + '/cart', localStorage.getItem('lang_ref')])
        },
        error=>{
          if(error.error.errors.quantity){
            console.log(error.error.errors.quantity);
            this.toastrService.error(error.error.errors.quantity[0]);
          }
          
          // error.error.errors.quantity.forEach(
          //   ele=>{
          //     this.toastrService.error(ele);
          //   }
          // );
          
        }
      );
    }else{
      if(this.lang == 'en'){
        this.toastrService.warning('Product is not avialable in stock');
      }else{
        this.toastrService.warning('المنتج غير متوفر فى المخزن');
      }
    }
    
  }



  // Update Cart Value
  public updateCartQuantity(product: any, quantity: number, id) {
    let obj = {
      "quantity": quantity,
      "_method":"put"
    }

    console.log(obj);
   return this.httpClientService.post('cart/' + id, obj, [])
  }

  // Calculate Product stock Counts
  public calculateStockCounts(product: CartItem, quantity): CartItem | Boolean {
    let qty = product.quantity + quantity;
    let stock = product.product.stock;
    if (stock < qty && this.lang == 'en') {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    else if (stock < qty && this.lang == 'ar') {
      this.toastrService.error('لا يمكنك اضافة المزيد من المنتجات عن المتوفر في المخزون ' + stock + ' المنجات.');
      return false
    }
    return true
  }

  // Removed in cart
  public removeFromCart(id: number) {
    return this.httpClientService.delete('cart/' + id, []);
  }

  // Total amount 
  public getTotalAmount() {
    
  }

  updateCartNew(product) {
    this.cartItems.push(product);
  }

  emptyCart() {
    this.cartItems = [];
    localStorage.removeItem('currentCart');
  }


}