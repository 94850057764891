<!-- category -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <ngx-slick-carousel class="category-m no-arrow" #slickModal="slick-carousel" [config]="catSlideConfig">
                    <div ngxSlickItem *ngFor="let cats of categories">
                        <div class="category-wrapper">
                        
                                    <img [src]="cats.image" class="img-fluid" alt="" style="    height: 176px;">
                                    <h4>{{cats.title}}</h4>
                                    <ul class="category-link" [innerHTML]="cats.text">
                                    </ul>
                                    <a [routerLink]="['/category','watch']"
                                        class="btn btn-outline">{{'VIEW_MORE'|translate}}</a>
                
                        </div>

                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</section>
<!-- category end -->