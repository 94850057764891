import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-support-requests",
  templateUrl: "./support-requests.component.html",
  styleUrls: ["./support-requests.component.scss"],
})
export class SupportRequestsComponent implements OnInit {
  requests;
  status;
  Topic_ID;
  Merchant_ID;

  merchant_brand_id;

  pending;
  open;
  solved;
  replyForm: FormGroup;

  closeResult = "";

  status_array;

  constructor(
    private http: HttpClientService,
    private toaster: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http.get("support_request", {}, headers).subscribe((res: any) => {
      this.requests = res.support_requests;
      this.open = res.support_requests_count_open;
      this.pending = res.support_requests_count_pending;
      this.solved = res.support_requests_count_solved;
    });

    this.http
      .get("support_request/listStatuses", {}, headers)
      .subscribe((res: any) => {
        this.status_array = res.list_statuses;
      });

    this.initForm();

    this.Merchant_ID = localStorage.getItem("merchantId");
    let merchants = JSON.parse(localStorage.getItem("Brand_Merchants_array"));
    this.merchant_brand_id = merchants.find((m) => {
      return m.id == this.Merchant_ID;
    })?.merchant_brand_id;
  }

  initForm() {
    this.replyForm = new FormGroup({
      admin_reply: new FormControl("", []),
    });
  }

  viewFile(e) {
    window.open(e.request_file, "_blank");
  }

  changeStat(event, Topic_ID) {
    console.log("The admin status ::::::", event.value);
    this.status = event.value;
    this.Topic_ID = Topic_ID;

    this.Updatethestatus();
  }

  Updatethestatus() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      topic_id: this.Topic_ID,
      merchant_brand_id: this.merchant_brand_id,
      status: this.status,
    };
    this.http
      .post(`support_request/update/${this.Topic_ID}`, body, {}, headers)
      .subscribe((res: any) => {
        this.toaster.success("Status Updated Successfuly");
      });
  }

  SendReply(form) {
    console.log(" send reply function ::::::::::", form);
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      topic_id: this.Topic_ID,
      merchant_brand_id: this.merchant_brand_id,
      status: this.status,
      admin_reply: form.admin_reply,
    };
    this.http
      .post(`support_request/update/${this.Topic_ID}`, body, {}, headers)
      .subscribe((res: any) => {
        this.toaster.success("Reply Sent Successfuly");
      });

    this.replyForm.reset();
  }

  OpenPopup(content, ID, Status, MerchantID) {
    this.Topic_ID = ID;
    this.status = Status;
    this.merchant_brand_id = MerchantID;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
