<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="''">{{'home'|translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{'collection'|translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb end -->
<!-- section start -->
<section class="section-b-space" [ngClass]="collectionClass">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">

        <!-- Collection Sidebar -->
        <div class="col-md-3 collection-filter">
          <div class="coll_sidebar_sticky">
            <div class="collection-mobile-back" (click)="mobileFilterBack()">
              <span class="filter-back">
                <i class="fa fa-angle-left" aria-hidden="true"></i> {{'back'|translate}}
              </span>
            </div>
            <div class="collection-filter-block">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'category' | translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let cat of categories;let i = index">
                      <input type="checkbox" [checked]="isCatSelected('categories', cat.id)"
                        (change)="setCategory('categories', cat.id)" id="checkboxCategory_{{i}}"
                        class="regular-checkbox" />
                      <label for="checkboxCategory_{{i}}">{{lang == 'ar' ? cat.name['ar'] : cat.name['en']}}</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- <div class="collection-filter-block">
              <app-price (priceFilters)="updatePriceFilters($event)"></app-price>
            </div> -->

            <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'rating'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let rate of rating;let i = index">
                      <input type="radio" [checked]="isChecked && rate == val" (click)="updaterate(rate)" name="rate"
                        id="checkboxcity_{{i}}" class="regular-checkbox" />
                      <label for="checkboxcity_{{i}}">
                        <bar-rating [rate]="rate" [readOnly]="true" class="ratefilter"></bar-rating>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Areas'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let area of areas;let i = index">
                      <input type="radio" [checked]="isAreaChecked && area?.id == val1" (click)="updateArea(area?.id)"
                        name="rate" id="checkboxcity_{{i}}" class="regular-checkbox" />
                      <label for="checkboxcity_{{i}}">
                        <span *ngIf="lang == 'ar'">{{area?.name?.ar}}</span>
                        <span *ngIf="lang == 'en'">{{area?.name?.en}}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Start Merge of filters -->
            <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
              <h3 class="collapse-block-title">{{'filters'|translate}}</h3>
              <div class="new-checkbox">
                <input type="radio" [checked]="isOnlinePaymentChecked"
                  (click)="updateOnlinePayment(!isOnlinePaymentChecked)" name="onlinePayment" id="onlinePayment"
                  class="regular-checkbox" />
                <label for="onlinePayment">
                  {{'Online payment' | translate}}
                </label>
              </div>

              <div class="new-checkbox">
                <input type="radio" [checked]="isOnlineChecked" (click)="updateOnline(!isOnlineChecked)" name="online"
                  id="online" class="regular-checkbox" />
                <label for="online">
                  {{'Online Order' | translate}}
                </label>
              </div>

              <div class="new-checkbox">
                <input type="radio" [checked]="isDeliveryChecked" (click)="updateDelivery(!isDeliveryChecked)"
                  name="delivery" id="delivery" class="regular-checkbox" />
                <label for="delivery">
                  {{'Free Delivery' | translate}}
                </label>
              </div>

              <div class="collection-brand-filter">
                <div class="new-checkbox" *ngFor="let cuisine of cuisines;let i = index">
                  <input type="radio" [checked]="isCuisineChecked" (click)="updateCuisine(brand?.id)" name="cuisine"
                    id="checkboxcuisine_{{i}}" class="regular-checkbox" />
                  <label for="checkboxcuisine_{{i}}">
                    {{cuisine.title}}
                  </label>
                </div>
              </div>

            </div>
          </div>
            <!-- End Merge of filters-->

            <!-- <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Brands'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let brand of brands;let i = index">
                      <input 
                        type="radio"
                        [checked]="isBrandChecked"
                        (click)="updateBrand(brand?.id)" 
                        name="brand" 
                        id="checkboxbrand_{{i}}" 
                        class="regular-checkbox" />
                      <label for="checkboxbrand_{{i}}">
                        {{brand.title}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Cuisines'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let cuisine of cuisines;let i = index">
                      <input 
                        type="radio"
                        [checked]="isCuisineChecked"
                        (click)="updateCuisine(brand?.id)" 
                        name="cuisine" 
                        id="checkboxcuisine_{{i}}" 
                        class="regular-checkbox" />
                      <label for="checkboxcuisine_{{i}}">
                        {{cuisine.title}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Free Delivery'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox">
                      <input 
                        type="radio"
                        [checked]="isDeliveryChecked"
                        (click)="updateDelivery(!isDeliveryChecked)" 
                        name="delivery" 
                        id="delivery" 
                        class="regular-checkbox" />
                      <label for="delivery">
                        {{'Free Delivery' | translate}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Online Order'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox">
                      <input 
                        type="radio"
                        [checked]="isOnlineChecked"
                        (click)="updateOnline(!isOnlineChecked)" 
                        name="online" 
                        id="online" 
                        class="regular-checkbox" />
                      <label for="online">
                        {{'Online Order' | translate}}
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'Online payment'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox">
                      <input 
                        type="radio"
                        [checked]="isOnlinePaymentChecked"
                        (click)="updateOnlinePayment(!isOnlinePaymentChecked)" 
                        name="onlinePayment" 
                        id="onlinePayment" 
                        class="regular-checkbox" />
                      <label for="onlinePayment">
                        {{'Online payment' | translate}}
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>
        <!-- End Collection Sidebar -->

        <div class="collection-content col-md-9">
          <div class="page-main-content">

            <div class="collection-product-wrapper">
              <!-- Filter Block Products -->
              <div class="product-top-filter">
                <div class="container-fluid p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="filter-main-btn">
                        <span class="filter-btn btn btn-theme" (click)="mobileFilter()">
                          <i class="fa fa-filter" aria-hidden="true"></i> Filter
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="product-filter-content">

                        <!-- <div class="collection-grid-view">
                            <ul>
                              <li>
                                <img src="assets/images/icon/2.png" class="product-2-layout-view"
                                  (click)="setLayout('layout1')"
                                  >
                              </li>
                              <li>
                                <img src="assets/images/icon/3.png" class="product-3-layout-view"
                                  (click)="setLayout('layout2')"
                                  >
                              </li>
                              <li>
                                <img src="assets/images/icon/4.png" class="product-4-layout-view"
                                  (click)="setLayout('layout3')"
                                  >
                              </li>
                              <li>
                                <img src="assets/images/icon/6.png" class="product-6-layout-view"
                                  >
                              </li>
                            </ul>
                          </div> -->

                        <div class="product-page-filter select-wrapper">
                          <select (change)="onChangeSorting($event.target.value)"
                            [ngClass]="lang == 'ar'?'select-ar':'select-en'" class="select-css">
                            <option value="a_to_z,asc" style="padding:5px !important;">{{'sort'|translate}}</option>
                            <option value="a_to_z,asc">{{'AscendingOrder'|translate}}</option>
                            <option value="a_to_z,desc">{{'DescendingOrder'|translate}}</option>
                            <option value="a_to_z,asc">{{'Alphabetically, A-Z'|translate}}</option>
                            <option value="a_to_z,desc">{{'Alphabetically, Z-A'|translate}}</option>
                            <!-- <option value="low">{{'Price, low to high'|translate}}</option>
                              <option value="high">{{'Price, high to low'|translate}}</option> -->
                            <option value="delivery_time,asc">{{'Delivery Time Ascending'|translate}}</option>
                            <option value="delivery_time,desc">{{'Delivery Time Descending'|translate}}</option>

                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Block Products -->
              <!-- Display Products -->
              <div class="product-wrapper-grid">
                <div class="container-fluid">
                  <div class="row">
                    <!-- [ngClass]="layout1?'col-xl-6':layout2?'col-md-4': 'col-md-3'" -->
                    <div class="col-grid-box col-md-4"
                      *ngFor="let product of filteredProducts | orderBy:sortByOrder | paginate: config">
                      <div class="product-box">
                        <app-product [product]="product"></app-product>
                      </div>

                    </div>
                    <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!filteredProducts.length">
                      <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                      <h3> {{'noMerchant'|translate}} </h3>
                      <!-- <a (click)="gotoProducts()" class="btn btn-solid">{{'continueShopping'|translate}}</a> -->
                    </div>
                  </div>
                </div>

                <div class="row pagination mt-3 text-center">
                  <pagination-controls previousLabel="{{'previous' | translate}}" nextLabel="{{'next' | translate}}" responsive="true" (pageChange)="onChangePage($event)"></pagination-controls>
                </div>
              </div>
              <!-- End Infinite Scroll -->
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- section End -->
<app-quick-view></app-quick-view>
<app-modal-cart></app-modal-cart>
<ngx-spinner color="#f95733"></ngx-spinner>