<h1 mat-dialog-title>{{ "Choose File Type" | translate }}</h1>
<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="selectedFile"
>
  <mat-radio-button class="example-radio-button" [value]="data.pdf">
    PDF
  </mat-radio-button>
  <mat-radio-button class="example-radio-button" [value]="data.excel">
    Excel
  </mat-radio-button>
</mat-radio-group>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "Cancel" | translate }}</button>
  <button mat-raised-button (click)="print()">{{ "print" | translate }}</button>
</mat-dialog-actions>
