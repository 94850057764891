import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)
  }

}
