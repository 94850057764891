import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription, interval } from "rxjs";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { status as StatusList } from "./status";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-otp-branch",
  templateUrl: "./otp-branch.component.html",
  styleUrls: ["./otp-branch.component.css"],
})
export class OtpBranchComponent implements OnInit, OnDestroy {
  lang;
  status: string;
  merchantName: string;
  refreshStatusCounter = 30;
  reloadSubscription: Subscription;
  UserType = "brand_manager";
  MerchantsList: any;
  OpenBranchsCount = 0;
  BusyBranchsCount = 0;
  ClosedBranchsCount = 0;
  tabId = 0;
  showUpdateBar = false;
  currentBranch;
  StatusList = StatusList;
  merchantId;
  minutes = 0;
  constructor(
    private http: HttpClientService,
    private toaster: ToastrService
  ) {}
  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.setDataFromStorage();
    this.getStatus();
    this.reloadSubscription = interval(1000).subscribe((x) => {
      this.setReloadCounter();
    });
  }
  setDataFromStorage() {
    this.UserType = localStorage.getItem("type");
    this.lang = localStorage.getItem("lang_ref");
    this.merchantId = localStorage.getItem("merchantId");
    this.status = localStorage.getItem("status");
    this.merchantName = localStorage.getItem("merchantName");
  }
  setCurrentTab() {
    if (this.tabId == 0) {
      return this.MerchantsList;
    }
    let type = "open";
    switch (this.tabId) {
      case 1:
        type = "open";
        break;
      case 2:
        type = "busy";
        break;
      case 3:
        type = "closed";
        break;
      default:
        break;
    }
    return this.MerchantsList.filter((b) => {
      return b.status == type;
    });
  }
  setReloadCounter() {
    if (this.refreshStatusCounter == 0) {
      this.getStatus();
    } else this.refreshStatusCounter--;
  }
  onReloadCounterClick() {
    this.refreshStatusCounter = 30;
    this.getStatus();
  }
  openStatusBar(branch) {
    this.minutes = 0;
    this.status = branch?.status;
    this.currentBranch = { ...branch };
    if (this.currentBranch.status == "busy") {
      let today: any = new Date();
      let busy_until: any = new Date(this.currentBranch.busy_until);
      this.minutes = Math.round((busy_until - today) / 60000);
    }
    this.showUpdateBar = true;
  }
  updateBranchStatus() {
    if (this.minutes > 0) this.status = "busy";
    let body = {
      status: this.status,
      merchant_id: this.currentBranch.id,
      minutes: this.minutes,
    };
    this.http
      .post("merchant/update-merchant-status", body, {})
      .subscribe((res) => {
        if (res["success"] == true) {
          localStorage.setItem("status", this.status);
          this.toaster.success(res["message"]);
          this.showUpdateBar = false;
          this.getStatus();
        }
      });
  }
  onStatusChange(s) {
    let list = s.value.split(" ");
    if (list[4]) {
      this.minutes = list[4];
    } else this.minutes = 0;
  }
  getStatus() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.refreshStatusCounter = 30;
          this.MerchantsList = res.data.merchants;
          this.OpenBranchsCount = res.data.status_count.open;
          this.BusyBranchsCount = res.data.status_count.busy;
          this.ClosedBranchsCount = res.data.status_count.closed;
          this.toaster.success(res["message"]);
        }
      });
  }
}
