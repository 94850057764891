<div class="breadcrumb full-width">
    <div class="background">
        <div class="container">
          <div class="contact"><h1 class="contactfont" id="title-page">{{'login'|translate}}</h1></div>
        </div>
    </div>
  </div>
  
<div class="main-content full-width">
    <div class="background-content"></div>
    <div class="background">
        <div class="shadow"></div>
        <div class="pattern">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div id="content" class="col-md-9 center-column body-other content-with-background">
                                <div class="row">
                                    <div class="col-sm-6" style="font-family: Poppins, Sans-Serif;font-size: 16px;">
                                        <div class="well">
                                            <h2>{{'newcustomer'|translate}}</h2>
                                            <p><strong>{{'createaccount'|translate}}</strong></p>
                                            <p >{{'createaccounttext'|translate}}</p>
                                            <a  *ngIf="lang == 'en'"  [routerLink]="['/home_six/signup6', lang]" class="btn btn-primary" style="margin-left: 0px;">{{'createaccount'|translate}}</a>

                                            <a  *ngIf="lang == 'ar'"[routerLink]="['/home_six/signup6', lang]" class="btn btn-primary">{{'createaccount'|translate}}</a>
                                        </div>
                                    </div>
                                    <div class="col-sm-6"
                                        style="font-family:Poppins, Sans-Serif;font-size: 16px;width: 50%; float: right;min-height: 1px;padding-left: 15px;padding-right: 15px;">
                                        <div id="customer-login" class="well">
                                            <h2>{{'customerlogin'|translate}}</h2>
                                            <!-- <p><strong>انا لدي حساب</strong></p> -->
                                            <form [formGroup]="loginForm" class="theme-form" id="customer_login">
                                                <!-- <input type="hidden" name="form_type" value="customer_login">
                                                <input type="hidden" name="utf8" value="✓"> -->
                                                <div class="form-group required">
                                                    <label class="control-label" for="input-email">{{'email'|translate}}</label>

                                                        <input formControlName="name" type="email"
                                                        class="form-control {{loginForm.controls['name'].touched && loginForm.controls['name'].errors ? 'error-control' : ''}}"
                                                        id="input-email" placeholder="{{'email' | translate}}" name="customer[email]">

                                                        <span
                                                        *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.required"
                                                        class="error-msg">
                                                        {{'emailReqMes'|translate}}
                                                    </span>
                                                    <span *ngIf="loginForm.controls['name'].touched && loginForm.controls['name']?.errors?.email"
                                                        class="error-msg">
                                                        {{'emailmustbe'|translate}}.
                                                    </span>

                                                </div>
                                                
                                                <div class="form-group required">
                                                    <label class="control-label" for="input-password">{{'Password'|translate}}</label>

                                                        <input formControlName="password" type="password" required="required" name="customer[password]"
                                                        class="form-control {{loginForm.controls['password'].touched && loginForm.controls['password'].errors ? 'error-control' : ''}}"
                                                        id="input-password" placeholder="{{'Password'|translate}}">
                                                 
                                                        <span
                                                        *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.required"
                                                        class="error-msg">
                                                        {{'passwordReqMes'|translate}}
                                                    </span>
                                                    <span
                                                        *ngIf="loginForm.controls['password'].touched && loginForm.controls['password']?.errors?.minlength"
                                                        class="error-msg">
                                                        {{'passwordMinLengMes'|translate}}.
                                                    </span>

                                                </div>
                                                <div id="forgot_password">
                                                    <a  class="forget-password" [routerLink]="['/forget-pass6']">{{'forget-password'|translate}}</a>
                                                </div>
                                                <!-- <input type="submit" value="دخول" class="btn btn-primary"><input
                                                    type="hidden" name="return_url" value="/account"> -->

                                                    <button  (click)="login()"
                                                    class="btn btn-primary">{{'login'|translate}}</button>
                                            </form>
                                        </div>
                            
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>