import { Component, OnInit, Input } from '@angular/core';
// import * as $ from 'jquery';
import { Product } from '../../../shared/classes/product';
import { ProductsService } from '../../../shared/services/products.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { forkJoin } from 'rxjs';
import { AllproductsService } from 'src/app/shared/services/allproducts.service';
declare var $: any;


@Component({
  selector: 'app-three-tabs',
  templateUrl: './three-tabs.component.html',
  styleUrls: ['./three-tabs.component.css']
})
export class ThreeTabsComponent implements OnInit {
  @Input() homeData;
  public products: Product[] = [];
  prods: any[] =[];
  constructor(private productsService: ProductsService, private homeService: HomeService, private allproductservice:AllproductsService) { }

 
  ngOnInit(): void {
   
    $.getScript('assets/js/script.js');
     // tab js
     $("#tab-1").css("display", "Block");
     $(".default").css("display", "Block");

     $(".tabs li a").on('click', function() {
       event.preventDefault();
       $(this).parent().parent().find("li").removeClass("current");
       $(this).parent().addClass("current");
       var currunt_href = $(this).attr("href");
       $('#' + currunt_href).show();
       $(this).parent().parent().parent().find(".tab-content").not('#' + currunt_href).css("display", "none");
     console.log("clicked on tab");
    });

    this.allproductservice.getnewproducts().subscribe(
      (product: any) => {
        this.prods = product['data'];
        console.log("The New Products", this.prods);
        this.prods.forEach((element:any)=>{
          console.log("Images", element?.mediaCollection[0]?.default);
        })
    }); 
    
  }

}
