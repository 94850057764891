import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DOCUMENT, DatePipe } from "@angular/common";
import { FormControl, FormGroup } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, combineLatest, fromEvent, interval } from "rxjs";

import { FirebaseMessaging } from "../../../shared/services/Firebase-messageing/firebase-messaging.service";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { TokenPopupComponent } from "./components/token-popup/token-popup.component";
import { status } from "../otp-branch/status";

@Component({
  selector: "app-otp-advanced-reports",
  templateUrl: "./otp-advanced-reports.component.html",
  styleUrls: ["./otp-advanced-reports.component.css"],
})
export class OtpAdvancedReportsComponent implements OnInit, OnDestroy {
  lang;
  public sidebarShow: boolean = false;
  active: boolean = false;
  payment_method: any;
  newOrders: any[] = [];
  acceptedOrders: any[] = [];
  latestOrders: any[] = [];
  categories: any[] = [];
  products: any[] = [];
  slictedOrder: any;
  config: any;
  page: number = 1;
  perPage: any;
  totalCount: any;
  id: number;
  closeResult = "";
  cancellationReasons: any[] = [];
  cancelledId: any;
  cancelReasonId: any;
  showUpdateBar = false;
  StatusList = status;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  source = interval(10000);
  source2 = interval(5000);
  currentStatus: string;
  statusColor: string;
  subscription: Subscription;
  type: any;
  hideDelivery = false;
  prods: any[] = [];
  categoryName: string;

  productForm: FormGroup;

  NewOrderObj;
  Delivery_Time: number = 20;

  currentRoute: string;
  latest_date;

  isShow: boolean = false;

  lat = 22.1862522;
  lng = 22.1862522;
  merchantName: string;
  minutes: any;
  status: string;
  unAvailabeProds;
  fromDate;
  soundInterval: any;
  audio = new Audio();
  audioOn: boolean = false;
  //   latitude: "31.1862522"
  // longitude: "31.1862522"

  constructor(
    private http: HttpClientService,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private firebaseMessaging: FirebaseMessaging,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.recieveNotification();
    this.lang = localStorage.getItem("lang_ref");
    this.merchantName = localStorage.getItem("merchantName");
    this.id = +localStorage.getItem("merchantId");
    this.getStatus();
    if (localStorage.getItem("activated") == "true") {
      this.active = true;
    }
    this.getNewOrders();
    this.getAcceptedOrders();
    this.getCategories();
    this.getCancelReasons("rejection");
    let date = new Date();
    this.latest_date = this.datepipe.transform(date, "yyyy-MM-dd");
    date.setDate(date.getDate() - 1);
    this.fromDate = this.datepipe.transform(date, "yyyy-MM-dd");

    this.getLatestOrders(this.latest_date, this.fromDate);

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount,
    };
    if (document.getElementById("minsdiv"))
      document.getElementById("minsdiv").style.display = "block";
    if (document.getElementById("hoursdiv"))
      document.getElementById("hoursdiv").style.display = "none";
    if (document.getElementById("daysdiv"))
      document.getElementById("daysdiv").style.display = "none";

    this.getAcceptedOrders();

    this.initForm();
  }

  recieveNotification() {
    this.firebaseMessaging.requestPermission();
    this.firebaseMessaging.receiveMessage();
    this.firebaseMessaging.currentMessage$.subscribe((res) => {
      if (res) {
        console.log("new order recieved ========> ", res);
        this.getNewOrders();
      }
    });
  }

  getStatus() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.currentStatus = res.data.merchants[0].status;
          this.status = res.data.merchants[0].status;
          if (this.status == "busy") {
            let today: any = new Date();
            let busy_until: any = new Date(res.data.merchants[0].busy_until);
            this.minutes = Math.round((busy_until - today) / 60000);
          }
        }
      });
  }
  updateBranchStatus() {
    if (this.minutes > 0) this.currentStatus = "busy";
    let body = {
      status: this.currentStatus,
      merchant_id: this.id,
      minutes: this.minutes,
    };
    this.http
      .post("merchant/update-merchant-status", body, {})
      .subscribe((res) => {
        if (res["success"] == true) {
          this.toaster.success(res["message"]);
          this.status = this.currentStatus;
          this.showUpdateBar = false;
          this.getStatus();
        }
      });
  }
  onStatusChange(s) {
    let list = s.value.split(" ");
    if (list[4]) {
      this.minutes = list[4];
    } else this.minutes = 0;
  }

  getNewOrders() {
    let body = { status: "pending" };

    this.http.post("merchant/ordersOnline", body, {}).subscribe((res) => {
      // this.createEvents();
      // this.toaster.success(res["message"]);
      this.newOrders = res["data"].data;
      if (this.newOrders.length > 0) {
        this.audioOn = true;
        this.playAudio();
        this.soundInterval = setInterval(() => {
          this.playAudio();
        }, 5000);
      }
      console.log("New orders data from api:---", this.newOrders);

      this.newOrders.forEach((element) => {
        element.payment_method = JSON.parse(element.payment_method);

        this.lat = parseFloat(element.shipping_address_arr.latitude);
        this.lng = parseFloat(element.shipping_address_arr.longitude);

        console.log("The order lat and long:---- ", this.lat, this.lng);
      });
    });
  }
  playAudio() {
    this.audio.src = "assets/sounds/ring.mp3";
    this.audio.load();
    if (this.audioOn) {
      this.audio.play();
    } else {
      this.stopSound();
    }
  }
  getAcceptedOrders() {
    let body = { status: "processing" };
    this.http.post("merchant/ordersOnline", body, {}).subscribe((res) => {
      this.acceptedOrders = res["data"].data;
      console.log("Accepted orders data from api:---", this.acceptedOrders);

      this.acceptedOrders.forEach((element) => {
        element.payment_method = JSON.parse(element.payment_method);
        // console.log('ele', element.payment_method)
      });
    });
  }

  getCategories() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      merchant_id: this.id,
    };
    this.http.post("merchant/products", body, {}, headers).subscribe((res) => {
      this.categories = res["merchant"].categories_new;
      this.unAvailabeProds = res["merchant"].unavailable_product;
      this.getunAvailabeProds();
      // this.products = res["products"];
    });
  }

  getProducts(id, category) {
    if (this.categoryName == category) this.resetproducts();
    else {
      this.prods = this.categories[id].data;
      localStorage.setItem("OtpCategory", category);
      localStorage.setItem("OtpProducts", JSON.stringify(this.prods));
      this.categoryName = localStorage.getItem("OtpCategory");
    }
  }
  getunAvailabeProds() {
    if (this.categoryName == "unavailable products") {
      this.resetproducts();
    } else {
      this.prods = this.unAvailabeProds;
      localStorage.setItem("OtpCategory", "Un Available Products");
      localStorage.setItem("OtpProducts", JSON.stringify(this.prods));
      this.categoryName = "unavailable products";
    }
  }
  getlength(prod) {
    return prod.filter((p) => p.published == 1).length;
  }
  resetproducts() {
    this.prods = [];
    this.categoryName = "";
  }

  openSideBar(order) {
    this.sidebarShow = !this.sidebarShow;
    this.slictedOrder = order;
    this.payment_method = this.slictedOrder?.payment_method;
    // payment_method = JSON.parse(payment_method)
  }

  updateOrderStatus(id, status, delivertime, type) {
    let body = {
      status: status,
      delivery_time: delivertime,
      delivery_type: type,
    };
    this.http
      .post("merchant/update-order-status/" + id, body, {})
      .subscribe((res) => {
        console.log(
          "change the status from pending to accepted(processing) Api:-------",
          res
        );

        this.getNewOrders();
        this.getAcceptedOrders();
        this.getLatestOrders(this.latest_date);
        // this.sidebarShow = !this.sidebarShow;
      });
  }

  UpdateOrderStatusNoTime(id, status) {
    this.sidebarShow = !this.sidebarShow;

    let body = {
      status: status,
    };
    this.http
      .post("merchant/update-order-status/" + id, body, {})
      .subscribe((res) => {
        console.log(
          "change the status of order from accepted(processing)  to on its way(picked up) :-------",
          res
        );
        this.getAcceptedOrders();
        this.getLatestOrders(this.latest_date);
      });
  }

  getLatestOrders(date, from?) {
    let body: any = { to: date };
    if (from) {
      body = {
        ...body,
        from: from,
      };
    }
    this.http.post("merchant/ordersOnline", body, {}).subscribe((res) => {
      this.latestOrders = res["data"].data;
      console.log("Latest orders data from api:---", this.latestOrders);

      this.perPage = res["data"].per_page;
      this.totalCount = res["data"].total;
      this.latestOrders.forEach((element) => {
        element.payment_methods_translation = JSON.parse(
          element.payment_methods_translation
        );
      });

      console.log("Ordrs", this.latestOrders);
    });
  }

  handlePageEvent(event) {
    console.log(event);
  }

  open(content, id, type) {
    this.type = type;
    this.cancelledId = id;
    this.getCancelReasons(type);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getCancelReasons(type) {
    let body = {
      type: type,
    };
    this.http
      .post("merchant/reasons-list", body, [])
      .subscribe((res: any[]) => {
        this.cancellationReasons = res["data"];
        console.log("Cancel reasons:-----", this.cancellationReasons);
      });
  }

  cancelOrder(status) {
    let body = { status: status, reason_id: this.cancelReasonId };
    this.http
      .post("merchant/update-order-status/" + this.cancelledId, body, {})
      .subscribe((res) => {
        this.getAcceptedOrders();
        if (res["success"] == true) {
          this.toaster.success(res["message"]);
        } else {
          this.toaster.success(res["message"]);
        }

        this.modalService.dismissAll();
      });
  }

  onChange(event) {
    console.log(event.target.value);
    this.cancelReasonId = event.target.value;
  }

  // ================= products functions ==================================

  open2(content, product) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    this.productForm.patchValue({
      product_id: product.id,
      name_en: product.title,
      name_ar: product.title,
      price: product.price,
    });
  }

  initForm() {
    this.productForm = new FormGroup({
      product_id: new FormControl(""),
      name_en: new FormControl(""),
      name_ar: new FormControl(""),
      price: new FormControl(""),
    });
  }

  onSubmit() {
    console.log(this.productForm.value);
    this.http
      .post(
        "merchant/send-updating-product-request",
        this.productForm.value,
        []
      )
      .subscribe((res) => {
        console.log("res", res);
        if (res["success"] == true) {
          this.toaster.success(res["message"]);
          this.modalService.dismissAll();
        }
      });
  }

  updateStatus(event, id) {
    let body = {
      published: event.value,
      merchant_id: this.id,
    };
    this.http
      .post("merchant/update-product-publishing/" + id, body, {})
      .subscribe((res) => {
        if (res["success"] == true) {
          this.getCategories();
          this.toaster.success(res["message"]);
        } else {
          this.toaster.error(res["message"]);
        }
      });
  }

  OpenNewOrder(content3, order, hideDelivery = false) {
    console.log("open");

    this.audioOn = false;
    this.modalService
      .open(content3, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.hideDelivery = hideDelivery;
    this.NewOrderObj = order;

    console.log("popup object:--", this.NewOrderObj);
    document.getElementById("minsdiv").style.display = "block";
    document.getElementById("hoursdiv").style.display = "none";
    document.getElementById("daysdiv").style.display = "none";
  }

  ChooseMinutesTime() {
    document.getElementById("minsdiv").style.display = "block";
    document.getElementById("hoursdiv").style.display = "none";
    document.getElementById("daysdiv").style.display = "none";
  }
  ChooseHoursTime() {
    document.getElementById("minsdiv").style.display = "none";
    document.getElementById("hoursdiv").style.display = "block";
    document.getElementById("daysdiv").style.display = "none";
  }
  ChooseDaysTime() {
    document.getElementById("minsdiv").style.display = "none";
    document.getElementById("hoursdiv").style.display = "none";
    document.getElementById("daysdiv").style.display = "block";
  }

  TakeDeliveryValue(time) {
    this.Delivery_Time = time;
  }

  AddToAcceptOrder(order_ID, Status, time, type) {
    console.log("The Parameters of API:---", time, order_ID, Status);
    this.updateOrderStatus(order_ID, Status, time, type);

    // this.toaster.success("Accepted")
    if (this.lang == "en") {
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Accepted",
        text: "Next time, try accepting faster. Do not keep customers waiting. ",
        showConfirmButton: false,
        timer: 4000,
      });
    } else {
      Swal.fire({
        position: "top",
        icon: "success",
        title: "وافقت",
        text: "في المرة القادمة ، حاول القبول بشكل أسرع. لا تجعل العملاء ينتظرون. ",
        showConfirmButton: false,
        timer: 4000,
      });
    }

    this.getNewOrders();
  }

  OpenDeclineOrder(content4, id, type) {
    this.type = type;
    this.cancelledId = id;
    this.getCancelReasons(type);
    this.modalService
      .open(content4, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  open5(content5, ReasonID) {
    this.cancelReasonId = ReasonID;
    this.modalService
      .open(content5, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  DeclineOrder(orderID, Status) {
    let body = {
      status: Status,
      reason_id: this.cancelReasonId,
    };
    this.http
      .post("merchant/update-order-status/" + orderID, body, {})
      .subscribe((res) => {
        console.log(
          "change the status from pending to Declined(Rejected) API:-------",
          res
        );
        this.getNewOrders();

        if (res["success"] == true) {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "order declined",
            // background: '#e34850',
            showConfirmButton: false,
            timer: 4000,
          });
        } else {
          this.toaster.success(res["message"]);
        }
      });
  }

  stopSound() {
    clearInterval(this.soundInterval);
    this.audio.pause();
  }

  openTokenPopup() {
    this.dialog.open(TokenPopupComponent, {
      panelClass: "sound-dialog-container",
    });
  }
}
