import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../shared/classes/product';

import { CartItem } from '../../shared/classes/cart-item';
import { ProductsService } from '../../shared/services/products.service';
import { WishlistService } from '../../shared/services/wishlist.service';
import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr'; 
declare var $:any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit { 
    lang;
    @Input() product : any;
    @Input() products: Product;
    addTax;
    token;
    // taxName: string = '';


  public variantImage  :  any = ''; 
  public selectedItem  :  any = '';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public productsService: ProductsService, 
    private wishlistService: WishlistService, 
    private cartService: CartService,
    private toastrService: ToastrService) { 
  }

  ngOnInit() {  
    this.addTax = JSON.parse(localStorage.getItem("etaam_config"));
    // console.log("addTax=> ",this.addTax)
    // if(this.addTax.name == "product_price_with_tax"){
    //   this.taxName = 'Tax';
    // }

    // console.log("products111=> ",this.product)
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
    this.token = localStorage.getItem('token');

  }

  // Add to cart
  public addToCart(product: Product,  quantity: number = 1) {
    this.cartService.checkProduct(product);
  }

  // Add to compare
  public addToCompare(product: Product) {

     this.productsService.addToCompare(product); 

  }

  // ngDocheck(){
  //   if(this.token == null || this.token == ''){
      
  //   }
  // }
  // Add to wishlist
  public addToWishlist(product: Product) {
     if(this.token != null){
      this.wishlistService.addToWishlist(product);
      // if(this.lang == 'en'){
      //   this.toastrService.success('This product added to Wishlist.'); // toasr services
      // }
      // else if (this.lang == 'ar'){
      //   this.toastrService.success('هذا المنتج تم اضافته للمفضلة'); // toasr services
      // }
     }else{
      if(this.lang == 'en'){
        this.toastrService.error('Please Sign In'); // toasr services
      }
      else if (this.lang == 'ar'){
        this.toastrService.error('من فضلك قم بتسجيل الدخول'); // toasr services
      }
      let routs:any[] = this.router.url.split('/');
       this.router.navigate([routs[1] + '/login', this.lang]);
     }
    //  this.toastrService.success('This product has been added to WishList.');

  }
 
 // Change variant images
  public changeVariantImage(image) {
     this.variantImage = image;
     this.selectedItem = image; 
  } 

  gotoDetails(id: number) {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/merchant-details', this.lang], {queryParams:{'id': id}});
  }

}
