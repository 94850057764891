// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    megaMenu?: boolean;
    megaMenuType?: string; // small, medium, large
    image?: string;
    children?: Menu[];
  }
  
  export const MENUITEMS: Menu[] = [
      // {title:'ABOUT_US',type:'link',path:'/about-us'},
      // {title:'PRIVACY', type:'link', path:'/privacy_policy'},
      // {title: 'TERMS_AND_CONDITIONS', type: 'link', path: '/terms_condition'},
    // {title: 'FAQ',type: 'link', path:'/faq'},
    
    {title:'HOME',type:'link',path:'/home_eleven'},
    // {title:'HOME',type:'link',path:'/home_six'},
    {title: 'CAT', type: 'link', path: '/home_eleven/categories'},
    {title: 'CAT', type: 'dropdown', path: '/home_eleven/categories'},
    {title:'PRODUCTS', type:'link', path:'/home_eleven/products'},
    // {title: 'COMPARE', type: 'link', path: '/compare'},
    {title:'ABOUT_US',type:'link',path:'/home_eleven/about-us'},
    {title: 'CONTACT_US',type: 'link', path:'/home_eleven/contact'},
  
    // {title: 'hhjbhjbjbh',type: 'link', path:''},
    // {title: 'wewghyuu',type: 'link', path:''},
    // {title: '',type: 'link', path:''},
    // {title: '',type: 'link', path:''}
  
  
  
    // {title: 'Espresso',type: 'link', path:'/categories_'},
      // {title: 'Cofe Equipment',type: 'link', path:'/categories_'},
  
      // {title: 'SUPPLIERS',type: 'link', path:'/suppliers'},
    
  ]