import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../services/home.service';
import { LookupService } from '../../services/lookup.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-change-dialog',
  templateUrl: './change-dialog.component.html',
  styleUrls: ['./change-dialog.component.scss']
})
export class ChangeDialogComponent implements OnInit {

  cities:any[] = [];
  areas:any[] = [];
  isCitySelected: boolean = false;
  isAreaSelected: boolean = false;
  lang;
  cityTitle:any;
  show: boolean = false;
  constructor(
    private lookupService:LookupService,
    private homeService: HomeService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.getCities();
  }

  close(){
    this.dialog.closeAll();
  }

  goBack(){
    this.isCitySelected = false;
    this.isAreaSelected = false;
  }

  getCities(){
    this.lookupService.getCities().subscribe(
      res=>{
        this.cities = res['cities'];
      }
    );
  }

  getAreas(id){
    this.homeService.getAreasByCityId(id).subscribe(
      res=>{
        this.areas = res['areas'];
        if(this.areas.length > 0){
          this.show = true;
        }
      }
    );
  }

  selectCity(id, title){
    this.isCitySelected = true;
    this.cityTitle = title;
    this.getAreas(id);
  }

  selectArea(id, name){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);

    if(routs[2] == "search"){
      this.dialog.closeAll();
      localStorage.setItem("area_id", id);
      localStorage.setItem("area_name", JSON.stringify(name));
      this.userService.areaChanged.next(true);
    }else{
      localStorage.setItem("area_id", id);
      localStorage.setItem("area_name", JSON.stringify(name));
      this.userService.areaChanged.next(true);
      this.router.navigate([routs[1] + '/category', this.lang], {queryParams:{'areas': id}});
      this.dialog.closeAll();
    }
  }

  detectLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
          console.log(position);
          this.homeService.getAreaByCoords(position.coords.latitude, position.coords.longitude).subscribe(
            res=>{
              console.log(res);
            },
            error=>{
              console.log(error);
            }
          );
        });
    }else {
      console.log("User not allow")
    }
  }

}
