<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/home']">{{ "home" | translate }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "forget-password" | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="pwd-page">
  <div class="container">
    <div class="row">
      <div *ngIf="!email_check" class="col-lg-6 offset-lg-3">
        <h2>{{ "forget-password" | translate }}</h2>
        <form [formGroup]="forgetPasswordForm" class="theme-form">
          <div class="form-row">
            <div class="col-md-12">
              <input
                formControlName="email"
                type="text"
                class="form-control {{
                  !forgetPasswordForm.controls['email'].valid
                    ? 'is-invalid'
                    : ''
                }}"
                id="email"
                placeholder="{{ 'email' | translate }}"
              />


              <span
                *ngIf="
                  forgetPasswordForm.controls['email'].touched &&
                  forgetPasswordForm.controls['email']?.errors?.required
                "
                class="error-msg"
              >
                {{ "emailReqMes" | translate }}
              </span>
              <span
                *ngIf="
                  forgetPasswordForm.controls['email'].touched &&
                  forgetPasswordForm.controls['email']?.errors?.email
                "
                class="error-msg"
              >
                {{ "emailmustbe" | translate }}.
              </span>
            </div>
            <button
              [disabled]="!forgetPasswordForm.valid"
              (click)="forgetPassword()"
              class="btn btn-solid"
            >
              {{ "submit" | translate }}
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="email_check" class="col-lg-6 offset-lg-3" >
        <h2>{{ "forget-password" | translate }}</h2>
        <form
          [formGroup]="newPasswordForm"
          (ngSubmit)="changePassword(newPasswordForm.value)"
          class="theme-form"
        >
          <div class="form-row">
            <div class="col-md-12">
                <input
                  type="Code"
                  placeholder="{{'code' | translate}}"
                  class="form-control  {{
                    !newPasswordForm.controls['token'].valid
                      ? 'is-invalid'
                      : ''
                  }}"
                  formControlName="token"
                />
                <span
                  *ngIf="
                    newPasswordForm.controls['token'].touched &&
                    newPasswordForm.controls['token']?.errors?.required
                  "
                  class="error-msg"
                >
                  {{ "password-code" | translate }}
                </span>
              </div>

            <div class="col-md-12">
              <input
                type="password"
                placeholder="{{'new-password-label' | translate}}"
                class="form-control  {{
                  !newPasswordForm.controls['password'].valid
                    ? 'is-invalid'
                    : ''
                }}"
                formControlName="password"
              />
              <span
                *ngIf="
                  newPasswordForm.controls['password'].touched &&
                  newPasswordForm.controls['password']?.errors?.required
                "
                class="error-msg"
              >
                {{ "new-password" | translate }}
              </span>
            </div>
            

            <div class="col-md-12">
              <input
                type="password"
                placeholder="{{'confirm-new-password-label' | translate}}"
                class="form-control  {{
                  !newPasswordForm.controls['password_confirmation'].valid
                    ? 'is-invalid'
                    : ''
                }}"
                formControlName="password_confirmation"
              />
              <span
                *ngIf="
                  newPasswordForm.controls['password_confirmation'].touched &&
                  newPasswordForm.controls['password_confirmation']?.errors
                    ?.required
                "
                class="error-msg"
              >
                {{ "confirm-new-password" | translate }}
              </span>
            </div>
            <button
              type="submit"
              class="btn btn-solid"
              [disabled]="!newPasswordForm.valid"
            >
              {{ "submit" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->