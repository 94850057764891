import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-special-products-five',
  templateUrl: './special-products.component.html',
  styleUrls: ['./special-products.component.scss']
})
export class SpecialProductsFiveComponent implements OnInit {
  
  @Input() products: Product;
  defaultLang:string;
  lang;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
 
  ngOnInit() {
    this.defaultLang = this.translateService.getDefaultLang();
    this.lang = localStorage.getItem('lang_ref');
  }

  openProductDetails(id: number){
    let routs: any[] = [];
    routs = this.router.url.split('/');
    console.log('routs', routs)
    this.router.navigate([routs[1] + '/product', this.lang, id],{queryParams:{'ref': 'offer'}});
  }

  openProducts() {
    let routs: any[] = [];
    routs = this.router.url.split('/');
    this.router.navigate([routs[1] + '/products', this.lang]);
  }

}
