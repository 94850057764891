<!-- <div class="footer-title">
    <h4>{{'why we'|translate}}</h4>
</div> -->
<div>
    <ul>
        <li><a (click)="gotoPrivacy()">{{'PRIVACY'|translate}}</a></li>
        <li><a (click)="gotoTerms()">{{'TERMS_AND_CONDITIONS'|translate}}</a></li>
        <!-- <li><a [routerLink]="['/faq']">{{'FAQ'|translate}}</a></li> -->
    </ul>
</div>
