<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
            <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <h2>
                    {{'onlineSalesReport' | translate}}
                </h2>

                <div class="col-md-3">
                    <div class="mx-sm-3">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'Choose date' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="getDate('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>

                      <button (click)="download()" class="btn btn-success">{{'Download' | translate}}</button>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12">
                        
                        <mat-spinner *ngIf="isLoading"></mat-spinner>
                        <app-table [reports]="orders" [fields]="displayedColumns" [length]="totalCount" [perPage]="perPage"
                        [config]="config" [currentPage]="page" (eventFromChild)="handlePageEvent($event)"></app-table>

                        <div class="text-center" *ngIf="orders?.length == 0 && !isLoading">
                            {{'NO_ORDERS' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>