import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";

@Component({
  selector: "app-otp-monthly-report",
  templateUrl: "./otp-monthly-report.component.html",
  styleUrls: ["./otp-monthly-report.component.scss"],
})
export class OtpMonthlyReportComponent implements OnInit {
  id: number;
  name: string;
  type: string;
  merchantsList: any;
  merchant: any;
  isLoading = false;
  lang;
  status;
  year;
  reports: any;
  dates: string[];
  yearsList = [];
  idList: any;
  downloadreportPDF: any;
  downloadreport: any;
  constructor(private http: HttpClientService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.lang = localStorage.getItem("lang_ref");
    this.getList();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          let dates = res.data.merchants.map((r) => r.created_at);
          let start = new Date(dates[0]).getFullYear();
          dates.forEach((element) => {
            element = new Date(element).getFullYear();
            if (start > element) start = element;
          });
          let difference = new Date().getFullYear() - start;
          for (let index = difference; index >= 0; index--) {
            this.yearsList.push(start + index);
          }
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.name = this.merchant.name;
          this.idList = [this.id];
          this.getData();
        }
      });
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.idList = [this.id];
    this.getData();
  }
  getData() {
    this.isLoading = false;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      type: this.status,
      year: this.year,
      merchants: this.idList,
    };
    this.http
      .post("report/monthly-report", body, {}, headers)
      .subscribe((res: any) => {
        console.log(res);
        this.reports = res.data.orders;
        this.downloadreportPDF = res["data"].pdf_download_file;
        this.downloadreport = res["data"].download_file;
        this.dates = Object.keys(this.reports);
        this.isLoading = true;
      });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  printAll() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }
  reset() {
    this.status = "";
    this.year = "";
    this.getData();
  }
  downloadFiles(item) {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: item.downloadreportPDF, excel: item.downloadreport },
    });
  }
}
