import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RedeemRoutingModule } from './redeem-routing.module';
import { RedeemComponent } from './redeem.component';
// import { Home7FooterComponent } from 'src/app/home/components/home7-footer/home7-footer.component';


@NgModule({
  declarations: [RedeemComponent,
    // Home7FooterComponent
  ],
  imports: [
    CommonModule,
    RedeemRoutingModule
  ],
  exports:[
    RedeemComponent
  ]
})
export class RedeemModule { }
