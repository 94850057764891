<mat-card class="example-card">
  <div class="badge">
    <p
      [ngClass]="{
        update: data.product?.id ? true : false,
        new: data.product?.id ? false : true
      }"
    >
      {{ data.product?.id ? "Update" : "New" }}
    </p>
  </div>
  <img
    mat-card-image
    [src]="
      data?.mediaCollection?.length > 0
        ? data?.mediaCollection[0]?.default
          ? data?.mediaCollection[0]?.default
          : data?.mediaCollection[0]
        : data.product.mediaCollection?.length > 0
        ? data.product.mediaCollection[0]?.default
          ? data.product.mediaCollection[0]?.default
          : data.product.mediaCollection[0]
        : ''
    "
    [alt]="data.product.title_en"
  />
  <mat-card-content>
    <div>
      <mat-card-title>Item Name</mat-card-title>
      <mat-card-subtitle>{{ data.product.title_en }}</mat-card-subtitle>
      <mat-card-subtitle>{{ data.product.title_ar }}</mat-card-subtitle>
    </div>
    <div>
      <mat-card-title>Item Description</mat-card-title>
      <mat-card-subtitle>{{ data.product.content_en }}</mat-card-subtitle>
      <mat-card-subtitle>{{ data.product.content_ar }}</mat-card-subtitle>
    </div>
    <div>
      <mat-card-title>Price</mat-card-title>
      <mat-card-subtitle>{{ data.product.price }} L.E</mat-card-subtitle>
    </div>
    <hr />
    <div *ngIf="data?.product?.custom_fields.length > 0">
      <div *ngFor="let item of data?.product?.custom_fields" class="column">
        <div>
          <div>
            <mat-card-title>Choice Category</mat-card-title>
            <mat-card-subtitle>{{ item.name_en }}</mat-card-subtitle>
            <mat-card-subtitle>{{ data.name_ar }}</mat-card-subtitle>
          </div>
          <div>
            <mat-card-title>Max Quantity</mat-card-title>
            <mat-card-subtitle>{{ item.max_qty }}</mat-card-subtitle>
          </div>
          <div>
            <mat-card-title>Min Quantity</mat-card-title>
            <mat-card-subtitle>{{ item.min_qty }}</mat-card-subtitle>
          </div>
        </div>
        <hr />
        <div *ngFor="let choice of item.choices">
          <div>
            <mat-card-title>Choice</mat-card-title>
            <mat-card-subtitle>{{ choice.name_en }}</mat-card-subtitle>
            <mat-card-subtitle>{{ choice.name_ar }}</mat-card-subtitle>
          </div>
          <div>
            <mat-card-title>Price</mat-card-title>
            <mat-card-subtitle>{{ choice.price }}</mat-card-subtitle>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
