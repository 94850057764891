import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { FirebaseMessaging } from "src/app/shared/services/Firebase-messageing/firebase-messaging.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";

declare var $: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  check_login;

  profileData;
  name;
  mobile;
  email;
  address;
  image;
  type;
  lang;
  profileClass: string = "";
  mainPage: string = "";
  gender;
  birthdate;

  constructor(
    private userservice: UserService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private firebaseMessaging: FirebaseMessaging
  ) {}

  ngOnInit() {
    this.profileClass = this.router.url.toString().split("/")[1];
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.mainPage = this.router.url.split("/")[1];

    setTimeout(function () {
      $(".loader-wrapper").fadeOut("slow", function () {
        $(this).remove();
      });
    }, 1999);

    this.check_login = localStorage.getItem("token");
    console.log("token profile=> ", localStorage.getItem("token"));
    //  console.log("Token From Profile Page", this.check_login)
    this.getProfileInfo();
  }

  getSelectedTab(event) {
    console.log(event);
    if (event.index == 0) {
      this.getProfileInfo();
    }
  }
  getProfileInfo() {
    this.userservice.viewprofile().subscribe(
      (res: any) => {
        this.profileData = res;
        this.name = res.first_name + " " + res.last_name;
        this.mobile = res.mobile;
        this.email = res.email;
        this.address = res.address;
        this.image = res.image_url;
        this.type = res.type;
        this.gender = res.gender;
        this.birthdate = res.birth_date;
        console.log("profile Image  => ", this.image);
        console.log("Profile Data", this.profileData);

        // console.log("profile data", this.profileData)
      },
      (error) => {
        this.toastrService.error(error.error.message); // toasr services
      }
    );
  }

  // logout(){

  //   this.check_login = null;
  //   // console.log("local storage is cleared");
  //   this.toastrService.success("You Have Been Logged out !");
  //   let routs:any[] = this.router.url.split('/');
  //   this.router.navigate([routs[1] + '/login', this.lang]);
  //   // window.location.reload();

  // }

  logout() {
    this.firebaseMessaging.deleteFirebaseToken();
    localStorage.removeItem("optToken");
    localStorage.removeItem("token");
    localStorage.removeItem("wishlistItem");
    localStorage.removeItem("userObject");
    localStorage.removeItem("cartToken");

    console.log("local storage is cleared");
    let msg: string = "";
    this.translate.get("You Have Been Logout").subscribe((res) => {
      msg = res;
    });
    if (this.lang == "en") {
      this.toastrService.success("You Have Been Logged out !");
    } else {
      this.toastrService.success("تم تسجيل الخروج");
    }

    this.userService.isLogged.next(false);
    localStorage.setItem("isLogged", "false");
    let routs: any[] = this.router.url.split("/");
    if (this.mainPage == "otphome") {
      this.router.navigate([routs[1] + "/otplogin", this.lang]);
    } else {
      this.router.navigate([routs[1] + "/login", this.lang]);
    }
  }

  gotoEditProfile() {
    let routs: any[] = this.router.url.split("/");
    console.log(routs);
    this.router.navigate([routs[1] + "/user/profile-update", this.lang]);
  }

  gotoOrders() {
    let routs: any[] = this.router.url.split("/");
    if (this.mainPage != "otphome") {
      this.router.navigate([routs[1] + "/orders", this.lang], {
        queryParams: { ref: "profile" },
      });
    } else {
      this.router.navigate(["otphome/opt-adv-repo", this.lang], {
        queryParams: { ref: "profile" },
      });
    }
  }

  gotoProfile() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/profile", this.lang], {
      queryParams: { ref: "profile" },
    });
  }

  gotoWishlist() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/favourite", this.lang], {
      queryParams: { ref: "profile" },
    });
  }

  gotoChangePassword() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/change-password", this.lang], {
      queryParams: { ref: "change", source: "profile" },
    });
  }

  gotoAddress() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/user/address", this.lang], {
      queryParams: { ref: "profile" },
    });
  }

  gotoWallet() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/wallet", this.lang], {
      queryParams: { source: "profile" },
    });
  }

  gotoPaymet() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/user/payment-methods", this.lang], {
      queryParams: { source: "profile" },
    });
  }

  gotoHome() {
    let routs: any[] = this.router.url.split("/");
    console.log(routs);
    this.router.navigate([routs[1] + "/", this.lang]);
  }
}
