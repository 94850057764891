import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/shared/classes/product';
import { ChangeDialogComponent } from 'src/app/shared/header/change-dialog/change-dialog.component';
import { AboutService } from 'src/app/shared/services/about.service';
import { AllproductsService } from 'src/app/shared/services/allproducts.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { UserService } from 'src/app/shared/services/user.service';
// import * as _ from 'lodash'
@Component({
  selector: 'app-home-talabat',
  templateUrl: './home-talabat.component.html',
  styleUrls: ['./home-talabat.component.scss']
})
export class HomeTalabatComponent implements OnInit {
  allproducts: any = [];
  responseProduct;
  totalItems: number = 0;
  showMenu: boolean = false;
  public counter: number = 1;
  public shoppingCartItems: any[] = [];
  lang: string;
  ContactData: Object;
  email: any;
  phone: any;
  address_ar: any;
  updown: boolean = false;
  catproducts: any = [];
  result: any;
  total: any;
  results:any[] = [];
  reviews = []
  product_id;
  fax;
  query:string = '';

  constructor(
    private router: Router,
    private allproductservice: AllproductsService,
    private homeService: HomeService,
    public translate: TranslateService,
    private userService: UserService,
    private route: ActivatedRoute,
    public dialog: MatDialog) { }

  categories: any[];
  topCategories: any[] = [];
  brands:any[] = [];
  topStores:any[] = [];
  searchQuery: string;
  searchRes: any[] = [];
  areas:any[] = [];
  searchSubscription: any;
  search_show;
  main_search_show;
  sliderImages:any[] = [];
  id;
  // image
  public variantImage: any = '';
  public selectedItem: any = '';
  defaultAddress:any;
  selectedID:any;

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    if(JSON.parse(localStorage.getItem('userObject'))){
      this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
    }

    this.userService.areaChanged.subscribe(
      res=>{
        if(res == true){
          this.id = +localStorage.getItem('area_id');
          this.getHomeData(this.id);
        }
      }
    );
    if(localStorage.getItem('userObject')){
      this.defaultAddress = JSON.parse(localStorage.getItem('userObject')).default_address;
    }
    if(this.defaultAddress != null){
      this.id = this.defaultAddress.area.id;
    }else{
      this.id = localStorage.getItem('area_id');
      console.log('Thiss  id', this.id);
      if(!this.id){
        // this.openDialog();
      }
      // this.route.queryParams.subscribe(
      //   res=>{
      //     this.id = res['areas'];
      //   }
      // );
    }
    // if(localStorage.getItem('token') && this.defaultAddress != null){
    //   this.id = JSON.parse(localStorage.getItem('userObject')).default_address.area.id;
    // }else{
    //   this.id = +localStorage.getItem('area_id');
    // }
    
    // this.getTopcashbackStores();
    // this.getTopcashbackCategories();

    // navigator.geolocation.getCurrentPosition((position)=>{
    //   console.log(`lat: ${position.coords.latitude}, lan: ${position.coords.longitude}`);
    // });

    this.getHomeData(this.id);
    this.getSliderImages();
    this.getAreas();
    setTimeout(function () {
      $('.loader-wrapper').fadeOut('slow', function () {
        $(this).remove();
      });
    }, 1999)
  }
  address_en(address_en: any) {
    throw new Error('Method not implemented.');
  }

  openDialog() {
    const dialogRef = this.dialog.open(ChangeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  navigateCategory(cat) {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang], {queryParams:{'categories': cat.id}})
  }

  gotoMerchants(){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang])
  }

  gotoMerchant(id){
    this.searchQuery = '';
    this.searchRes = [];
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/merchant-details', this.lang], {queryParams:{'id': id}});
  }

  // Change variant images
  public changeVariantImage(image) {
    this.variantImage = image;
    this.selectedItem = image;
  }


  gotoProduct(id) {
    let routs: any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/product', this.lang, id]);
  }

  gotoDetails(id: number) {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/merchant-details', this.lang], {queryParams:{'id': id}});
  }

  getSliderImages(){
    this.homeService.getSliderImages().subscribe(
      (res: any[])=>{
        this.sliderImages = res;
      }
    );
  }

  slides = [
    { img: "https://luckyegypt.com/_next/static/media/slider-2-en.cc4cbb2746639d9a73310d505eb6743b.png" },
    { img: "https://luckyegypt.com/_next/static/media/slider-3-en.cf52b2f17725be86b41f4d5697ef1899.png" },
    { img: "https://luckyegypt.com/_next/static/media/slider-4-en.058b65f744aada0b7b5b07f50afd4da3.png" },
    { img: "https://luckyegypt.com/_next/static/media/slider-3-en.cf52b2f17725be86b41f4d5697ef1899.png" }
  ];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };


  // Slick slider config
  // Slick slider config
  public catSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 586,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };

  getTopcashbackStores() {
    this.allproductservice.getTopCashbackStores().subscribe(
      res => {
        this.topStores = res['merchants'];
        console.log('topstores', this.topStores)
      }
    );
  }

  getTopcashbackCategories(){
    this.allproductservice.getTopCashbackCategories().subscribe(
      res=>{
        this.topCategories = res['data'];
        console.log('topcategories', this.topCategories)
      }
    );
  }

  getHomeData(id) {
    this.allproductservice.getHomeData(id).subscribe(
      res=>{
        this.topStores = res['data'][0].merchants_with_top_cashback;
        this.topCategories = res['data'][0].categories;
        this.brands = res['data'][0].brands;
      }
    );
  }

  search(query: string) {
    if(this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if(! this.selectedID){
      this.selectedID = +localStorage.getItem('area_id');
    }
    if(query) {
      this.userService.search(query, this.selectedID).subscribe(
        res=>{
          console.log('RES', res);
          this.searchRes = res['merchants'].data;
        }
      );
    }
     else {
      this.searchRes = [];
      // return;
    }
  }

  menuHide(event) {
    this.showMenu = false;
  }

  menuShow() {
    this.showMenu = true;
  }

  getAreas(){
    this.homeService.getAreas().subscribe(
      res=>{
        // this.searchRes = res['areas'];
        this.areas = res['areas'];
      }
    )
  }

  changeSelection(event){
    console.log(event.target.value);
    this.selectedID = event.target.value;
  }

  gotoCategories(){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/categories', this.lang], {})
  }

}
