<div style="overflow-x: auto" id="element">
  <table
    class="table"
    [ngClass]="visible ? 'top-margin' : ''"
    *ngIf="reports?.length > 0"
  >
    <thead>
      <tr>
        <th scope="col" *ngFor="let field of fields">
          {{ field | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let item of reports
            | paginate
              : {
                  itemsPerPage: perPage,
                  currentPage: currentPage,
                  totalItems: length
                }
        "
      >
        <td *ngFor="let field of fields">
          <span
            *ngIf="
              field != 'date' &&
              field != 'created_at' &&
              field != 'accepted_at' &&
              field != 'canceled_at' &&
              field != 'rejected_at' &&
              field != 'delivered_at' &&
              field != 'order_time' &&
              field != 'payment_method' &&
              field != 'payment_methods_translation' &&
              field != 'payment_methods'
            "
            >{{ item[field] | translate }}</span
          >
          <span *ngIf="field == 'payment_method'">{{ item[field][0] }}</span>
          <span *ngIf="field == 'payment_methods_translation'">{{
            item[field][0]
          }}</span>
          <span *ngIf="field == 'payment_methods'"
            >{{ item[field][0]
            }}<span *ngIf="item[field][2]">, {{ item[field][1] }}</span></span
          >
          <span
            *ngIf="
              field == 'date' ||
              field == 'created_at' ||
              field == 'accepted_at' ||
              field == 'canceled_at' ||
              field == 'rejected_at' ||
              field == 'order_time' ||
              field == 'delivered_at'
            "
            >{{ item[field] | date: "short" }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row" *ngIf="hasPagination">
  <div class="w-100 pagination mt-3 text-center" *ngIf="reports?.length > 0">
    <mat-paginator
      (page)="handlePageEvent($event)"
      class="w-100"
      [length]="length"
      [pageSize]="perPage"
    >
    </mat-paginator>

    <!-- <pagination-controls responsive="true" (pageChange)="handlePageEvent($event)"></pagination-controls> -->
  </div>
</div>
