import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-why-we-choose',
  templateUrl: './why-we-choose.component.html',
  styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {

  lang;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang_ref');
  }

  gotoPrivacy(){
    this.router.navigate(['privacy_policy', this.lang], {relativeTo: this.route});
  }

  gotoTerms(){
    this.router.navigate(['terms_condition', this.lang], {relativeTo: this.route});
  }
}
