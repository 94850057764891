import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-all-areas",
  templateUrl: "./all-areas.component.html",
  styleUrls: ["./all-areas.component.css"],
})
export class AllAreasComponent implements OnInit {
  lang;
  areas: any[] = [];
  id;
  name;
  merchantsList;
  merchant;
  type;
  searchValue;
  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getList();
    this.lang = localStorage.getItem("lang_ref");
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.translate.use(this.lang);
  }

  getAllAreas() {
    let body = {
      merchant_id: this.id,
    };
    this.http.get("merchant/merchant-areas", body).subscribe((res) => {
      this.areas = res["merchant_areas"];
    });
  }
  onSearch() {
    let body = {
      merchant_id: this.id,
      keyword: this.searchValue,
    };
    this.http.get("merchant/merchant-areas", body).subscribe((res) => {
      this.areas = res["merchant_areas"];
      this.toaster.success(res["message"]);
    });
  }
  updateArea(event, area) {
    console.log(event);
    let body = {
      merchant_id: this.id,
      is_active: event.checked ? 1 : 0,
    };
    this.http
      .post(`merchant/merchant-areas/update/${area.id}`, body, {})
      .subscribe((res) => {
        this.getAllAreas();
        this.toaster.success(res["message"]);
      });
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.getAllAreas();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.name = this.merchant.name;
          this.getAllAreas();
          this.toaster.success(res["message"]);
        }
      });
  }
}
