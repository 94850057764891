import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  lang;
  myAddresses: any[] = [];
  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.getMyAddresses();
  }

  getMyAddresses(){
    this.http.get('address', []).subscribe(
      (res: any[])=>{
        console.log('rrrrrreeeess', res)
        this.myAddresses = res;
      }
    );
  }

  gotoEdit(address, id){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/user/address-edit', this.lang], {queryParams:{'id': id}});
    localStorage.setItem('edittedAddress', JSON.stringify(address));
  }

  AddNew(){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/user/address-edit', this.lang]);
  }

}
