<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
    <div class="container">
      <div class="row">
     
        <div class="col-sm-6 text-left">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/home']">{{'home'|translate}}</a></li>
              <li class="breadcrumb-item active">{{'cart'|translate}}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div> -->

  <div class="breadcrumb full-width">
    <div class="background">
      <div class="container">
        <div class="contact">
          <h1 class="contactfont" id="title-page">{{'cart'|translate}}</h1>
        </div>
      </div>
    </div>
  </div>
  
  <!-- breadcrumb End -->
  <!-- section start -->
  <section class="cart-section section-b-space">
    <div class="container">
      <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems.length">
        <div class="pull-right">

            <button [routerLink]="['/home6']" class="btn" type="button" (click)="update()">
                {{'Continue shopping' | translate}}</button>
        
        </div>
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>{{'Cart is Empty'| translate}}</strong></h3>
       
        <!-- <h4>Add something to make me happy :)</h4> -->
      </div>
      <div class="row" *ngIf='shoppingCartItems.length'>
        <div class="col-sm-12">
        
          <div class="row" *ngFor="let item of shoppingCartItems;index as i">
            
            <div class="col-md-12 col-sm-12 col-xs-12 cart_container_new">
            
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <i class="fa fa-times cart-remove-product" (click)="removeItem(item)" aria-hidden="true"></i>
                </div>
                <div class="col-md-1 col-sm-2 col-xs-12">
                  <img class="" [src]="item.itemable.mediaCollection[0]?.large" alt="">
                </div>
                <div class="col-md-12 col-sm-10 col-xs-12">
                  <a [routerLink]="['/product', item.id]" class="cart-product-name">{{ lang == 'ar' ? item.itemable.title['ar'] : item.itemable.title['En'] }}</a>
                  <br>
                  <p>
                    <!-- <span>{{'size'|translate}}: 12</span> -->
                    <span>
                      <div class="qty-box cart-qty-box">
                        <div class="input-group">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" (click)="decrement(item)" data-type="minus" data-field="">
                            <i class="ti-angle-left"></i>
                          </button>
                        </span>
                          <input type="text" name="quantity" disabled class="form-control input-number" [(ngModel)]="item.quantity">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" (click)="increment(item)" data-type="plus" data-field="">
                            <i class="ti-angle-right"></i>
                          </button>
                        </span>
                        </div>
                        <h4 class="cart-product-total">{{'total'|translate}}: {{ item.itemable.price * item.quantity }} {{'SAR' |translate}}</h4>
                      </div>
                    </span>
                  </p>
  
                  <!-- <p>
                    <span class="cart-product-color">اسود</span>
                   
                  </p> -->
                 
    
                    <!-- <h4 *ngIf="lang == 'en'">{{'soldby'|translate}} : <span [routerLink]="['/suppliers',2,'Samsung']" style="cursor: pointer;    padding-left: 0 !important;">Samsung</span> 
                    </h4>
                    <h4 *ngIf="lang == 'ar'">{{'soldby'|translate}} : <span [routerLink]="['/suppliers',2,'Samsung']" style="cursor: pointer;    padding-left: 0 !important;">سامسونج</span> 
                    </h4> -->
                    <hr>
                  <h4 >{{item.itemable.price}}<del>{{item?.salePrice}} </del> {{'SAR' |translate}} </h4>
                </div>
  
              </div>
              
              <div class="row">
  
                <!-- <div class="col-md-12 col-sm-12 col-xs-12 text-center payment-method">
                  <span class="cart-product-payment"><span>Downpayment <span class="cart-product-down">122 {{'SAR' |translate}}</span>
                    <span class="cart-radio-check"> <input type="radio" name= {{i}} class="intermediate" />
                    <span style="margin:10px">
                     </span></span></span></span>
                     <span class="cart-product-payment"><span>{{'total'|translate}} <span class="cart-product-down">500 {{'SAR' |translate}}</span>
                     <span class="cart-radio-check"> <input type="radio" name= {{i}} class="intermediate" />
                     <span style="margin:10px">
                      </span></span></span></span>
                  <div>
                  </div>
                </div> -->
  
                <div class="col-md-12 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'en'">
                  <textarea class="form-control cart-product-requirements" placeholder="Add special requirements"></textarea>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 cart-container-requirements" *ngIf="lang == 'ar'">
                  <textarea class="form-control cart-product-requirements" placeholder="أضف متطلبات خاصة"></textarea>
                </div>
              </div>
  
            </div>
          </div>
          <table class="table cart-table table-responsive-md">
            <tfoot>
              <tr>
                <td>{{'total'|translate}}</td>
                <td>
                  <h2> {{ total }} {{'SAR' |translate}}</h2>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="row cart-buttons" *ngIf='shoppingCartItems.length'>
        <div class="col-6">
          <a [routerLink]="'../category'" class="btn btn-solid">{{'continueShopping'|translate}}</a>
        </div>
        <div class="col-6">
          <a [routerLink]="'/payments/checkout'" class="btn btn-solid">{{'checkout'|translate}}</a>
        </div>
      </div>
    </div>
  </section>
  <!--section end-->
  