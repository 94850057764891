<div class="container">
    <div class="single-instore">
        <div class="row">
            <div class="col-md-5">
                <h2>
                    In-store offers
                </h2>

                <img class="w-100" src="https://luckyimages.azurewebsites.net/Lucky/Images/Offers/Services.png" alt="">
            </div>


            <div class="col-md-7">
                <div class="offer-details">
                    <div class="offer-details_header">
                        <div class="flex-center"><img class="mr-3 offer-details_img"
                                src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png" alt="">
                            <div>
                                <h4 class="h4-text mb-0">Al Fath Appliances Repairing</h4>
                                <p class="">Services</p>
                            </div>
                        </div>
                        <div><button class="btn btn-primary">Get Offer</button></div>
                    </div>
                    <div class="offer-details_body">
                        <div class="discount-row">
                            <div class="discount">Cashback</div>
                            <div><i class="far fa-star mr-2"></i>2 Favourites</div>
                        </div>
                        <div>
                            <h3 class="h3-text">5% Cashback when you pay with Lucky</h3>
                            <p>Head to Al Fath Appliances Repairing and enjoy an amazing 5% cashback on your purchases
                                with
                                Lucky! It's literally, money in your pocket! </p>
                            <p class="card-text text-muted">Exp. 2022-06-22</p>
                        </div>
                    </div>
                </div>

                <div class="branches">
                    <h3>
                        Branches (1)
                    </h3>

                    <div class="branch-desc">
                        <h5>Alexandria</h5>
                        <p>
                            Khaleed Ibn el waleed St.,above Khalil pharmacy,Sidi Bisher
                        </p>
                    </div>

                    <div class="branch-desc">
                        <h5>Alexandria</h5>
                        <p>
                            Khaleed Ibn el waleed St.,above Khalil pharmacy,Sidi Bisher
                        </p>
                    </div>
                </div>




            </div>
        </div>

        <div class="instore-slider">

            <h2>
                More offers from Al Fath Appliances Repairing
            </h2>

            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div>
                        <div class="InstoreOffer">
                            <div class="InstoreOffer-card card">
                                <div class="card-header">
                                    <img src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png">
                                    <div>
                                        <div class="card-title h5">McDonald's - Merghany Street</div>
                                        <p class="card-text">McDonald's</p>
                                    </div>
                                </div><img class="card-img"
                                    src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png" alt="">
                                <div class="card-body">
                                    <div class="discount-row">
                                        <div class="discount"> Cashback</div>
                                        <!-- <div><i class="far fa-star mr-2"></i>1 Favourites</div> -->
                                    </div>
                                    <div class="card-title h5">
                                        20% Cashback when you pay with Sahla
                                    </div>
                                    <!-- <p class="card-text">Exp. 2022-06-22</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div>
                        <div class="InstoreOffer">
                            <div class="InstoreOffer-card card">
                                <div class="card-header">
                                    <img src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png">
                                    <div>
                                        <div class="card-title h5">McDonald's - Merghany Street</div>
                                        <p class="card-text">McDonald's</p>
                                    </div>
                                </div><img class="card-img"
                                    src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png" alt="">
                                <div class="card-body">
                                    <div class="discount-row">
                                        <div class="discount"> Cashback</div>
                                        <!-- <div><i class="far fa-star mr-2"></i>1 Favourites</div> -->
                                    </div>
                                    <div class="card-title h5">
                                        20% Cashback when you pay with Sahla
                                    </div>
                                    <!-- <p class="card-text">Exp. 2022-06-22</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div>
                        <div class="InstoreOffer">
                            <div class="InstoreOffer-card card">
                                <div class="card-header">
                                    <img src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png">
                                    <div>
                                        <div class="card-title h5">McDonald's - Merghany Street</div>
                                        <p class="card-text">McDonald's</p>
                                    </div>
                                </div><img class="card-img"
                                    src="https://couponzimages74.dsquares.com/Lucky/images/Logo/16379.png" alt="">
                                <div class="card-body">
                                    <div class="discount-row">
                                        <div class="discount"> Cashback</div>
                                        <!-- <div><i class="far fa-star mr-2"></i>1 Favourites</div> -->
                                    </div>
                                    <div class="card-title h5">
                                        20% Cashback when you pay with Sahla
                                    </div>
                                    <!-- <p class="card-text">Exp. 2022-06-22</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>