import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { DatePipe } from "@angular/common";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-otp-menu",
  templateUrl: "./otp-menu.component.html",
  styleUrls: ["./otp-menu.component.css"],
})
export class OtpMenuComponent implements OnInit {
  lang;
  merchantId: number;
  config: any;
  reports: any[] = [];
  merchants_array: any[] = [];

  page: number = 1;
  perPage: any = 0;
  length: any = 0;
  displayedColumns: string[] = [
    "menu_item_name",
    "total_selling_count",
    " Revenue",
  ];

  hasPagination: boolean = false;
  isLoading: boolean = false;

  filterForm: FormGroup;
  Hide: boolean = true;

  Merchant_ID;
  Merchant_ID_Array: any[] = [];

  selectedID = 1;
  start_date: any;
  end_date: any;
  downloadreport: any;
  id: number;
  name: string;
  type: string;
  merchantsList: any;
  merchant: any;
  downloadreportPDF: any;
  idList: any[];

  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.merchantId = +localStorage.getItem("merchantId");
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.translate.use(this.lang);
    this.getList();
  }
  initReports() {
    this.getReports();
    this.initForm();

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.length,
    };

    this.Merchant_ID = localStorage.getItem("merchantId");
    this.Merchant_ID_Array.push(this.Merchant_ID);
    console.log("Merchant Id Array :-----", this.Merchant_ID_Array);

    localStorage.getItem("Brand_Merchants_array");
    console.log(
      "Merchants Array from localstorage:--",
      JSON.parse(localStorage.getItem("Brand_Merchants_array"))
    );
    this.merchants_array = JSON.parse(
      localStorage.getItem("Brand_Merchants_array")
    );
    console.log("Merchants Array:--", this.merchants_array);
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.initReports();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.idList = [this.id];
          this.name = this.merchant.name;

          this.initReports();
        }
      });
  }
  initForm() {
    this.filterForm = new FormGroup({
      date: new FormControl(""),
      status: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  getReports() {
    let body = {
      merchants: this.idList,
      date_flag: this.selectedID,
      from: this.start_date,
      to: this.end_date,
    };
    this.isLoading = true;
    this.http.post("report/menu-items-sales", body, []).subscribe((res) => {
      this.reports = res["data"].products;
      // this.perPage = res['data'].perPage;
      // this.length = res['data'].total;

      this.downloadreportPDF = res["data"].pdf_download_file;
      this.downloadreport = res["data"].download_file;
      this.isLoading = false;
    });
  }

  ShowDatePicker() {
    this.Hide = false;
    console.log("Date picker:---", this.Hide);
  }

  printAll() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }

  reset() {
    this.filterForm.reset();
    this.selectedID = 1;
    this.Hide = true;
    this.getReports();
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.start_date = date;
    console.log("The start date value:-----", this.start_date);
    if (this.end_date) this.getReports();
  }

  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.end_date = date;
    console.log("The End date value:-----", this.end_date);
    if (this.start_date) this.getReports();
  }

  changeSelection(event: any) {
    console.log(
      "The select menu option is :-----",
      event.source.selected.value
    );
    this.selectedID = event.source.selected.value;
    if (this.selectedID != 6) this.getReports();
  }
}
