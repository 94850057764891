import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-vendor-performance',
  templateUrl: './vendor-performance.component.html',
  styleUrls: ['./vendor-performance.component.scss']
})
export class VendorPerformanceComponent implements OnInit {

  basicData: any;
  basicOptions: any;
  lang: any;
  totalSales: any;
  successfulOrders: any;
  failedOrders: any;
  returnCustomers: any;
  newCustomers: any;
  data: any;
  chartOptions: any;
  barData: any;
  horizontalOptions: any;
  constructor(
    private http: HttpClientService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);

    this.getVendorData();

    this.data = {
      labels: ['Excellent','Good','Poor'],
      datasets: [
          {
              data: [95, 4, 1],
              backgroundColor: [
                  "rgb(0, 162, 109)",
                  "rgb(255, 192, 0)",
                  "rgb(221, 22, 8)"
              ],
              hoverBackgroundColor: [
                "rgb(0, 162, 109)",
                "rgb(255, 192, 0)",
                "rgb(221, 22, 8)"
              ]
          }
      ]
  };

this.horizontalOptions = {
  indexAxis: 'y',
  plugins: {
      legend: {
          labels: {
              color: '#ebedef'
          }
      }
  },
  scales: {
      x: {
          ticks: {
              color: '#ebedef'
          },
          grid: {
              color: 'rgba(255,255,255,0.2)'
          }
      },
      y: {
          ticks: {
              color: '#ebedef'
          },
          grid: {
              color: 'rgba(255,255,255,0.2)'
          }
      }
  }
};
  }

  getVendorData(){
    let body = {
      "to" :"2021-11-24",
      "from":"2021-09-1"
    }
    this.http.post('merchant/performance-overview-report', body, []).subscribe(
      res=>{
        this.totalSales = res['data'].total_sales;
        this.successfulOrders = res['data'].successful_orders_count;
        this.failedOrders = res['data'].total_failed;
        this.newCustomers = res['data'].new_customers;
        this.returnCustomers = res['data'].return_customers;
        let labels:any[] = [];
        let data:any[] = [];

        res['data'].orders.forEach(element => {
          labels.push(element.date);
          data.push(element.total_sales);
        });
        this.basicData = {
          labels: labels,
          datasets: [
              {
                  label: 'EGP',
                  data: data,
                  fill: false,
                  borderColor: '#42A5F5',
                  tension: .4
              }
          ]
      };

      this.barData = {
        labels: ['January'],
        datasets: [
            {
                label: 'Returning customers',
                backgroundColor: 'rgb(255, 196, 0)',
                data: [this.returnCustomers]
            },
            {
              label: 'New customers',
              backgroundColor: 'rgb(255, 90, 0)',
              data: [this.newCustomers]
          }
        ]
    };
      }
    );
  }

}
