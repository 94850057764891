import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input() reports:any[];
  @Input() fields:any[];
  @Input() length:any;
  @Input() perPage:any;
  @Input() currentPage:any;
  @Output() eventFromChild: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator :MatPaginator;
  @Input() config: any;
  @Input() hasPagination: boolean = true;
  constructor() { }

  ngOnInit(): void {
    console.log('Configggg', this.config);
  }

  handlePageEvent(event){
    console.log(event);
    this.eventFromChild.emit(event.pageIndex + 1);
  }

}
