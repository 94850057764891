import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { sameValueAs } from 'src/app/shared/validators/same-password';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup6',
  templateUrl: './signup6.component.html',
  styleUrls: ['./signup6.component.css']
})
export class Signup6Component implements OnInit {

  constructor(private translate: TranslateService,
    private userService: UserService,
    private toastrService: ToastrService) { } 
registerForm: FormGroup;
lang;
ngOnInit() { 
  setTimeout(function () {
    $('.loader-wrapper').fadeOut('slow', function () {
      $(this).remove();
    });
  }, 1999);

this.lang = localStorage.getItem("lang_ref"); 
this.translate.use(this.lang); 
setTimeout(function(){
$('.loader-wrapper').fadeOut('slow', function() {
$(this).remove();
});
},1999)
this.initRegisterForm()
}


initRegisterForm() {
this.registerForm = new FormGroup({
// name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),

fname: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
lname: new FormControl('', [Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z]+$[a-zA-Z]*')]),
email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
mobile: new FormControl('', [Validators.required, Validators.pattern(/^(009665|9665|\+9665|05|5)(2|5|0|3|6|4|9|1|8|7)([0-9]{7})$/)]),
password: new FormControl('', [Validators.required, Validators.minLength(6)]),
retypePassword: new FormControl('', [Validators.required, Validators.minLength(6)])
}, { validators: sameValueAs })
}


register() {
let userObject = {
name: `${this.registerForm.value.fname} ${this.registerForm.value.lname}`,
email: this.registerForm.value.email,
password: this.registerForm.value.password,
mobile: this.registerForm.value.mobile
}

this.userService.register(userObject).subscribe(res => {
this.userService.handleResponse(res);
if(this.lang == 'ar'){
this.toastrService.success("تم التسجيل بنجاح")
}else{
this.toastrService.success("You Have Registered Successfully !")
}

},error=>{
// console.log(error.error.errors)
if(error.error.errors.name){
this.toastrService.error(error.error.errors.name[0]); // toasr services
}
else if (error.error.errors.password){
this.toastrService.error(error.error.errors.password[0]); // toasr services
}
else if (error.error.errors.mobile){
this.toastrService.error(error.error.errors.mobile[0]); // toasr services
}
else if (error.error.errors.email){
  this.toastrService.error(error.error.errors.email[0]); // toasr services
  }
})
}



}
