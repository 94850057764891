import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-confirm-transaction',
  templateUrl: './confirm-transaction.component.html',
  styleUrls: ['./confirm-transaction.component.css']
})
export class ConfirmTransactionComponent implements OnInit {

  lang;
  confirmTransaction: FormGroup;
  transactionId;
  transactionType;
  code:any;
  constructor(
    private router: Router,
    private service: HttpClientService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.transactionType = localStorage.getItem('transactionType');
    this.transactionId = localStorage.getItem('transactionId');
    this.code = localStorage.getItem('otpCode');
    this.initForm();
  }

  initForm(){
    this.confirmTransaction = new FormGroup({
      amount: new FormControl('', [Validators.required])
    });
  }

  gotoHome() {
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/', this.lang]);
  }

  verifyOtpCode() {
      let body1 = {
        'transaction_id': this.transactionId,
        'amount': this.confirmTransaction.value.amount
      }
  
      let body2 = {
        'transaction_id': this.transactionId,
        'total': this.confirmTransaction.value.amount
      }
  
      console.log('The code is:- ', body1);
      if(this.transactionType == 'debit'){
        this.service.post('wallet/confirm-redeem-transaction', body2, []).subscribe(
          res=>{
            console.log('Response', res);
            localStorage.setItem('transaction', JSON.stringify(res['data'].transaction));
            localStorage.setItem('credit-transaction', JSON.stringify(res['data'].credit_transaction));
            
            this.toast.success(res['message']);
            let routs:any[] = this.router.url.split('/');
            this.router.navigate([routs[1] + '/print-receipt', this.lang]);
            // this.printDocument(res['data']);
          },
          error=>{
            console.log('error',error);
          }
        );
      }else if(this.transactionType == 'credit'){
        this.service.post('wallet/confirm-reward-transaction', body1, []).subscribe(
          res=>{
            console.log('Response', res);
            localStorage.setItem('transaction', JSON.stringify(res['data'].transaction));
            localStorage.setItem('credit-transaction', JSON.stringify(res['data'].credit_transaction));

            this.toast.success(res['message']);
            let routs:any[] = this.router.url.split('/');
            this.router.navigate([routs[1] + '/print-receipt', this.lang]);
            // this.printDocument(res['data']);
          },
          error=>{
            console.log('error',error);
          }
        );
      }
   
    
  }

  printDocument(transaction:any){
    let printContents, popupWin;
    // printContents = document.getElementById("print").innerHTML.toString();
    // printContents = (<string>printContents + "").replace("col-sm", "col-xs");
    // console.log(printContents);
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    // popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Reporte</title>
          <meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
          <link rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
          <style>
            .salto_pagina_despues{
              page-break-after:always;
            }
            
            .salto_pagina_anterior{
              page-break-before:always;
            }

            .content {
              height: 100vh;
              width: 100%;
              display: flex;
              flex-direction: column;
            }

            .img-content {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .observation {
              height: 150px;
              overflow: hidden;
              overflow-y: auto;
            }
          </style>
        </head>
        <body onload="window.print();">
        <div id="invoice-POS" class="col-md-4 m-auto">
          <center id="top">
            <div class="info">
              <h2>Sahla LLC</h2>
              <br>
              <p>www.sahlaegypt.com</p>
            </div>
          </center>
          <div id="mid">
            <div class="info">
            <h2>Transaction Info</h2>
            <p>
              CODE : {{code}}<br>
              Date : {{transaction?.created_at | date:'short'}}<br>
              Amount : {{transaction?.total_amount}} {{'EGP' | translate}}<br>
              Type : {{transaction?.source}}<br>
            </p>
            <br>
            <p>
              Merchant : {{transaction?.merchant?.name}}<br>
              Consumer : Marwan Atef<br>
              Consumer Ref ID : #11450<br>
            </p>
          </div>
        </div>
        <div id="bot">
          <div id="legalcopy">
            <p class="legal"><p><strong>Thank you for your Using Sahla !</strong>&nbsp;</p> Payment is expected within 1 hour; please process this invoice within that time. There will be no interest charge per month on late invoices.
            </p>
          </div>
        </div>
        </div>
        </body>
      </html>`);
    // window.close(); 
    popupWin.document.close();
}

}
