<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
      <div class="row">
          <!-- <div class="col-sm-6">
              <div class="page-title">
                  <h2>{{'policy'|translate}}</h2>
              </div>
          </div> -->
          <div class="col-sm-6 text-left">
              <nav aria-label="breadcrumb" class="theme-breadcrumb">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a [routerLink]="['/home']">{{'home'|translate}}</a></li>
                      <li class="breadcrumb-item active" aria-current="page">{{'policy'|translate}}</li>
                  </ol>
              </nav>
          </div>
      </div>
  </div>
</div>
<!-- breadcrumb end -->
<!-- about section start -->
<section class="about-page  section-b-space" [ngClass]="privacyClass">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="banner-section">
                  <!-- <img  src="assets/images/about/aboutus.png" class="img-fluid" alt=""/> -->
              </div>
          </div>
          <div class="col-sm-12">
              <div *ngIf="lang == 'en'" [innerHTML]="privacy_Content_EN">
              </div>

                <div *ngIf="lang == 'ar'" [innerHTML]="privacy_Content_AR">
                </div>

          </div>
      </div>
  </div>
</section>