import * as firebase from "firebase";

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";

declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore
  ) {}

  lang;
  loginForm: FormGroup;
  isLoading: boolean = false;

  fieldTextType: boolean;

  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    localStorage.removeItem("optToken");
    localStorage.removeItem("wishlistItem");
    localStorage.removeItem("userObject");
    localStorage.removeItem("cartToken");
    localStorage.removeItem("type");
    if (!this.lang) {
      this.lang = "en";
      localStorage.setItem("lang_ref", this.lang);
    }
    this.translate.use(this.lang);
    $(".loader-wrapper").fadeOut("slow", function () {
      console.log("its ready");
      $(this).remove();
    });
    this.initForm();
  }

  initForm() {
    this.loginForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        ,
        Validators.pattern(/^01[0-5]\d{1,8}$/),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  login() {
    this.isLoading = true;
    if (localStorage.getItem("cartToken")) {
      this.loginForm.value.cart_token = localStorage.getItem("cartToken");
    }

    let routs: any[] = this.router.url.split("/");
    console.log("Login Data", this.loginForm.value);
    this.userService.login(this.loginForm.value).subscribe(
      (res: any) => {
        console.log(res, "useeer");

        this.userService.handleResponse(res);
        // console.log("The Response",res)
        this.isLoading = false;
        let name = res.name.split(" ");
        let firstName = name[0];
        let lastName = name[1];
        let email = res.email;
        let mobile = res.mobile;

        let userObject = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mobile,
        };
        localStorage.setItem("userObject", JSON.stringify(res));
        if (res.default_address) {
          localStorage.setItem(
            "area_name",
            JSON.stringify(res.default_address.area.name)
          );
          localStorage.setItem("area_id", res.default_address.area.id);
        }
        this.userService.isLogged.next(true);
        this.userService.areaChanged.next(true);
        localStorage.setItem("isLogged", "true");

        // if(res['default_address'] != null){
        //   this.userService.hasDefaultAddress.next(true);
        this.router.navigate([routs[1] + "/search", this.lang]);
        // }else{
        //   this.userService.hasDefaultAddress.next(false);
        //   this.router.navigate([routs[1] + '/', this.lang]);
        // }
      },
      (error) => {
        this.isLoading = false;
        if (error?.error?.errors?.mobile) {
          error?.error?.errors?.mobile.forEach((element) => {
            this.toastrService.error(element);
          });
        } // toasr services
        if (
          error.error.message == "Mobile  Not verified" ||
          error.error.message == "رقم الجوال لم يتم التحقق منه"
        ) {
          this.router.navigate([routs[1] + "/verify-phone", this.lang], {
            queryParams: { phone: this.loginForm.value.name },
          });
        }
      }
    );
  }

  gotoRegister() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/register", this.lang]);
  }

  gotoFrogetPassword() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/forget-password", this.lang]);
  }

  gotoHome() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/", this.lang]);
  }

  googleLogin() {
    return this.afAuth
      .signInWithPopup(new firebase.default.auth.GoogleAuthProvider())
      .then((result: any) => {
        console.log("tokennnnn", result);
        this.checkUserExist(
          "google",
          result["credential"].accessToken
        ).subscribe((res) => {
          console.log("Response", res);
          if (!res["token"]) {
            localStorage.setItem("socialUser", JSON.stringify(res));
            this.userService.socialUser.next(res);
            let routs: any[] = this.router.url.split("/");
            this.router.navigate([routs[1] + "/register", this.lang]);
          } else {
            this.userService.handleResponse(res);
            this.userService.isLogged.next(true);
            localStorage.setItem("isLogged", "true");
            // window.location.replace('')
            let routs: any[] = this.router.url.split("/");
            this.router.navigate([routs[1] + "/search", this.lang]);
          }
        });
      });
  }

  facebookLogin() {
    return this.afAuth
      .signInWithPopup(new firebase.default.auth.FacebookAuthProvider())
      .then((result: any) => {
        console.log("tokennnnn", result);
        this.checkUserExist(
          "facebook",
          result["credential"].accessToken
        ).subscribe(
          (res) => {
            console.log("Response", res);
            if (!res["token"]) {
              localStorage.setItem("socialUser", JSON.stringify(res));
              this.userService.socialUser.next(res);
              let routs: any[] = this.router.url.split("/");
              this.router.navigate([routs[1] + "/register", this.lang]);
            } else {
              this.userService.handleResponse(res);
              this.userService.isLogged.next(true);
              localStorage.setItem("isLogged", "true");
              let routs: any[] = this.router.url.split("/");
              this.router.navigate([routs[1] + "/search", this.lang]);
            }
          },
          (error) => {
            console.log("Error", error);
          }
        );
      });
  }

  checkUserExist(provider, token) {
    return this.userService.checkUser(provider, token);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
