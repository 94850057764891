<div class="full-height">
    <div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2 class="text-center">{{'Order_Food_Online' | translate}}</h2>
        </div>
        
        <div class="col-md-6 m-auto">
            <input class="form-control inp" (keyup)="search($event)" placeholder="Enter Your Area"/>
            <ul *ngIf="results1.length > 0">
                <li *ngFor="let result of results1" style="display: block;" (click)="gotoMerchantsWithArea(result)">
                    <span *ngIf="lang == 'ar'">{{result.name.ar}}</span>
                    <span *ngIf="lang == 'en'">{{result.name.en}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>