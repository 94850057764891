<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
           
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'COMPARE'| translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="compare-padding" [ngClass]="compareClass">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="compare-page">
                     <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!products.length">
                        <img src="assets/images/empty-compare.png" class="img-fluid mb-4" >
                          <h3><strong>{{'Compare List'| translate}}</strong></h3>
                    </div>
                    <div class="table-wrapper table-responsive" *ngIf="products.length">
                        <table class="table compare-table">
                            
                            <thead>
                            <tr class="th-compare">
                                <td>
                                <span>
                                    {{'action'| translate}}
                                    </span>
                                </td>
                                <th class="item-row" *ngFor="let product of products">
                                  <span type="button" (click)="removeItem(product)" class="remove-compare cursor--pointer">
                                       {{'remove-item'| translate }} 
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody id="table-compare">
                            <!-- Product Name -->
                            <tr *ngIf="lang == 'ar'">
                                <th class="product-name">
                                <span>

                                    {{'ProductName'|translate}}
                                </span>
                            </th>
                                <td class="grid-link__title"  *ngFor="let product of products">
                                    <span>
                                        {{product.title.ar}}

                                    </span>
                                </td>
                                <!-- <td class="grid-link__title" *ngIf="lang == 'en'" *ngFor="let product of products">{{product.title.ar}}</td> -->
                            </tr>
                            <tr *ngIf="lang == 'en'">
                                <th class="product-name">
                                <span>

                                    {{'ProductName'|translate}}
                                </span>
                                </th>
                                <td class="grid-link__title"  *ngFor="let product of products">
                                    <span>

                                    {{product.title.en}}
                                </span>
                                
                                </td>
                                <!-- <td class="grid-link__title" *ngIf="lang == 'en'" *ngFor="let product of products">{{product.title.ar}}</td> -->
                            </tr>
                            <!-- Product Image -->
                            <tr>
                                <th class="product-name ">
                                <span>

                                    {{'ProductImg'|translate}}
                                </span>
                                </th>
                                <td class="item-row" *ngFor="let product of products">
                                    <img [src]="product?.mediaCollection[0]?.preview" width="185" class="featured-image">
                                    <div class="product-price product_price"> 
                                        <span> <strong>{{'PRICE' | translate}}: </strong></span><span>{{product.price}}</span></div>
                                    <form class="variants clearfix">
                                      <button title="Add to Cart" (click)="addToCart(product)" class="add-to-cart btn btn-solid">{{'cartbtn' | translate}}</button>
                                    </form>
                                </td>
                            </tr>
                            <!-- Product Description -->
                            <tr>
                                <th class="product-name">
                                <span>

                                    {{'Product Description'|translate}}
                                    </span>
                                </th>
                                <td class="item-row" *ngFor="let product of products">
                                    <p *ngIf="lang == 'ar' " class="description-compare">{{product.content.ar}}</p>
                                    <p *ngIf="lang == 'en' " class="description-compare">{{product.content.En}}</p>
                                </td>
                            </tr>
                            <!-- Product Availability -->
                            <tr>
                                <th class="product-name">
                                <span>
                                    {{'Availability'|translate}}
                                </span>
                                 </th>
                                <td class="available-stock" *ngFor="let product of products">
                                    <p> {{product['stock_status_text']}} </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->