import { Component, OnInit , Input  } from '@angular/core';
import { Product } from '../../../shared/classes/product';


@Component({
  selector: 'app-product-new2',
  templateUrl: './product-new2.component.html',
  styleUrls: ['./product-new2.component.css']
})
export class ProductNew2Component implements OnInit {

  @Input() products: Product;


  constructor() { }

  ngOnInit(): void {
  }


  // Slick slider config
  public productSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

}
