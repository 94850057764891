<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11" *ngIf="!selected && !level2 && !showForm">
      <div class="mx-3 px-3 my-3">
        <h2>{{ "Help Center" | translate }}</h2>
        <h1>How can we help?</h1>

        <!-- <div class="row">
          <div class="col-md-6">
            <div class="help" (click)="navigate()">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-headset"></i>
                </div>
                <div class="col-md-10">I need Help With an Active Order(s)</div>
                <div class="col-md-1">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-6" style="cursor: pointer;">
            <div class="help" (click)="getTopics()">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-headset"></i>
                </div>
                <div class="col-md-10">New Inquiry</div>
                <div class="col-md-1">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" style="cursor: pointer;" (click)="gotosupportrequests()">
            <div class="help">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-save"></i>
                </div>
                <div class="col-md-10">My Support Requests</div>
                <div class="col-md-1">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-11" *ngIf="selected && !level2 && !showForm">
      <div class="mx-3 px-3 my-3" style="cursor: pointer;">
        <h2>{{ "Help Center" | translate }}</h2>
        <h1 (click)="selected = false">New Inquiry</h1>

        <div class="row" *ngFor="let item of arrayOfTopics">
          <div class="col-md-6" style="cursor: pointer;">
            <div class="help" (click)="getTopics2(item)">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-headset"></i>
                </div>
                <div class="col-md-10">{{ item.title }}</div>
                <div class="col-md-1">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-11" *ngIf="selected && level2 && !showForm">
      <div class="mx-3 px-3 my-3" style="cursor: pointer;">
        <h2>{{ "Help Center" | translate }}</h2>
        <h1>
          <span (click)="selected = false; level2 = false">New Inquiry</span>
          <mat-icon>arrow_forward_ios</mat-icon>
          <span (click)="level2 = false">{{ topic.title }}</span>
        </h1>

        <div class="row" *ngFor="let item of arrayOfTopics2">
          <div class="col-md-6" style="cursor: pointer;">
            <div class="help" (click)="getForm(item)">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-headset"></i>
                </div>
                <div class="col-md-10">{{ item.title }}</div>
                <div class="col-md-1">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-11" *ngIf="selected && level2 && showForm">
      <div class="mx-3 px-3 my-3" style="cursor: pointer;">
        <h2>{{ "Help Center" | translate }}</h2>
        <h1>
          <span (click)="selected = false; level2 = false; showForm = false"
            >New Inquiry</span
          >
          <mat-icon>arrow_forward_ios</mat-icon>
          <span (click)="level2 = false; showForm = false">{{
            topic.title
          }}</span>
          <mat-icon>arrow_forward_ios</mat-icon>
          <span (click)="showForm = false">{{ subTopic.title }}</span>
        </h1>
        <div class="row" >
          <div class="col">
            <div class=" help-form">
              <div class="row">
                <div class="col-md-7 col-sm-12 col-xs-12" style=" padding: 30px;
                                                                  margin-bottom: 20px;
                                                                  background: #eee;
                                                                  border-radius: 20px;">

                  <div>
                    <h4 [innerHtml]="topic_description"></h4>
                  </div>
                  <div>
                    <mat-label>Request Text</mat-label>
                    <textarea  [(ngModel)]="form.request_text" class="form-control"  rows="5"></textarea>
                  </div>
                  <div>
                    <mat-label>Request File</mat-label>
                    <input
                      (change)="handleFileInput($event.target.files)"
                      type="file"
                      #file
                      placeholder="Upload"
                      id="profile_img"
                    />
                  </div>
                  <div>
                    <button (click)="submitForm()" class="btn btn-primary">
                      Submit
                    </button>
                  </div>

                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    
    
  </div>
</div>
