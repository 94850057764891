<!-- price filter start here -->
<div class="collection-collapse-block border-0 open">
    <h3 class="collapse-block-title">{{'price'|translate}}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">

            <div class="mt-5">
                 <range-slider [min]="min" [max]="max" [step]="1" [(ngModel)]="range" (onRangeChange)="priceChanged($event)" [highlightClass]="'stephighlightClass'" [barClass]="'stepBarClass'" [sliderClass]="'stepSliderClass'">
                 </range-slider>
                 <!-- <div  (click)="test()">Tetsttt</div>
                 <div *ngFor = "let item of getRange(max,blocksNum); index as i" class="text-center price-container cursor--pointer">
                     <p  class="no-margin-bottom">{{item}}</p>
                 </div> -->
            </div>

        </div>
    </div>
</div>