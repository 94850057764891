<div class="container">
  <div class="row">
    <div class="col-md-6 sec" *ngIf="type != 'cashier'">
      <a (click)="gotoPage('reports')">Reports</a>
    </div>
    <div class="col-md-6 sec">
      <a (click)="gotoPage('verifyOtp')">In Store</a>
    </div>
    <div class="col-md-6 sec">
      <a (click)="gotoPage('opt-adv-repo')">Online Orders</a>
    </div>
  </div>
</div>
