import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-otp-orders",
  templateUrl: "./otp-orders.component.html",
  styleUrls: ["./otp-orders.component.css"],
})
export class OtpOrdersComponent implements OnInit {
  lang;
  merchantName: string;
  constructor(private translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.merchantName = localStorage.getItem("merchantName");
  }

  gotoPage(page) {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/" + page, this.lang]);
  }
}
