<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Order List</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
  </div>
  <!-- breadcrumb End -->
  <section class="section-b-space">
    <div class="container padding-cls">
        <div class="checkout-page">
            <div class="checkout-form">
                    <div class="row" >
                        <div class="col-md-12 col-sm-12 col-xs-12 no-padding-rl">
                            <h4>Delivery Address</h4>
                            <div class="address-container">
                                <span (click)="openXl(content)" class="cursor-pointer"><i class="fa fa-pencil-square-o" aria-hidden="true" title = "Change The Delivery Address" >
                             
                                </i>
                                </span>
                                <p>Area C,EL Thawra street</p>
                                <p>Block 8740</p>
                                <p>Mokattam</p>
                                <p>Cairo,Egypt</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <h4>items</h4>
                                <div class="remaad-payment-container">
                                    <p class="color--main underline--text">apply Reemadpayment for checkout in one step</p>
                                    <p >Paying throw Reemadguarantee your order's quality and Reemadguarantee fully refund</p>
                                    <input class= "margin-input" type="checkbox" id="remaad" name="remaad" >
                                    <label for="remaad"> ReemadPayment</label><br><br>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-xs-12 order-container margin-bottom-small">
                            <h4>ReemadPayment</h4>
                            <hr>
                            <div class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-small"  style="padding-left: 0px;padding-right: 0px;" show="false">
                                <div class="order-container">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 order-item-container">
                                            <div class="row order-item">
                                                <div class="col-md-1 col-sm-1 col-xs-12 ">
                                                    <div class="order-product-num"><p>x15</p></div>
                                                  <img class="order-img" src="assets/images/default/shop.jpg">
                                                </div>
                                                <div class="col-md-10 col-sm-10 col-xs-12">
                                                      <h4>Convers all stars 
                                                        <span>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                      </h4>
                                                    <p class="color--main">size: 39</p>
                                                    <p class="sold_by">Sold by 8 stars</p>
                                                    <p>556 SAR <del>278 SAR</del></p>
      
                                                </div>
                                            </div>
                                        </div>
      
                                        <div class="col-md-12 col-sm-12 col-xs-12 order-item-container">
                                            <div class="row order-item">
                                                <div class="col-md-1 col-sm-1 col-xs-12 ">
                                                    <div class="order-product-num"><p>x15</p></div>
                                                  <img class="order-img" src="assets/images/default/shop.jpg">
                                                </div>
                                                <div class="col-md-10 col-sm-10 col-xs-12">
                                                      <h4>Convers all stars 
                                                        <span>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                      </h4>
                                                    <p class="color--main">size: 39</p>
                                                    <p class="sold_by">Sold by 8 stars</p>
                                                    <p>556 SAR <del>278 SAR</del></p>
      
                                                </div>
                                            </div>
                                        </div>
      
                                      <div class="col-md-12 col-sm=12 col-xs-12">
                                          <hr>
                                      </div>
                                      <div class="col-md-3 col-sm-3 col-xs-12"></div>
                                      <div class="col-md-6 col-sm-6 col-xs-12">
                                        <table class="table table-bordered">
                                           
                                            <tbody>
                                              
                                              <tr>
                                                <td colspan="2">ٍSub total</td>
                                                <td>29 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2">Shipping</td>
                                                <td>12 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2">VAT(15%)</td>
                                                <td>12 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2">Total</td>
                                                <td>55 SAR</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <hr>
                                          <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                            <button class="btn pay-btn">Pay throw remaad</button>
                                          </div>
                                      </div>
                                      <div class="col-md-3 col-sm-3 col-xs-12"></div>
                                      
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="col-lg-12 col-sm-12 col-xs-12 order-container margin-bottom-small">
                            <h4>Istore Egypt</h4>
                            <hr>
                            <div class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-small" style="padding-left: 0px;padding-right: 0px;" show="false">
                                <div class="order-container">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 order-item-container">
                                            <div class="row order-item">
                                                <div class="col-md-1 col-sm-1 col-xs-12 ">
                                                    <div class="order-product-num"><p>x15</p></div>
                                                  <img class="order-img" src="assets/images/default/shop.jpg">
                                                </div>
                                                <div class="col-md-10 col-sm-10 col-xs-12">
                                                      <h4>Convers all stars 
                                                        <span>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                      </h4>
                                                    <p class="color--main">size: 39</p>
                                                    <p class="sold_by">Sold by 8 stars</p>
                                                    <p>556 SAR <del>278 SAR</del></p>
      
                                                </div>
                                            </div>
                                        </div>
      
                                        <div class="col-md-12 col-sm-12 col-xs-12 order-item-container">
                                            <div class="row order-item">
                                                <div class="col-md-1 col-sm-1 col-xs-12 ">
                                                    <div class="order-product-num"><p>x15</p></div>
                                                  <img class="order-img" src="assets/images/default/shop.jpg">
                                                </div>
                                                <div class="col-md-10 col-sm-10 col-xs-12">
                                                      <h4>Convers all stars 
                                                        <span>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                          <i class="fa fa-star" aria-hidden="true"></i>
                                                        </span>
                                                      </h4>
                                                    <p class="color--main">size: 39</p>
                                                    <p class="sold_by">Sold by 8 stars</p>
                                                    <p>556 SAR <del>278 SAR</del></p>
      
                                                </div>
                                            </div>
                                        </div>
      
                                      <div class="col-md-12 col-sm=12 col-xs-12">
                                          <hr>
                                      </div>
                                      <div class="col-md-3 col-sm-3 col-xs-12"></div>
                                      <div class="col-md-6 col-sm-6 col-xs-12">
                                        <table class="table table-bordered">
                                           
                                            <tbody>
                                              
                                              <tr>
                                                <td colspan="2">ٍSub total</td>
                                                <td>29 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2">Shipping</td>
                                                <td>12 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2">VAT(15%)</td>
                                                <td>12 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2" class="font--600">Total</td>
                                                <td class="font--600">55 SAR</td>
                                              </tr>
                                              <tr>
                                                <td colspan="2" class="font--600">Down payment</td>
                                                <td class="font--600">55 SAR</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <hr>
                                          <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                            <button class="btn pay-btn">Pay directly to supplier</button>
                                          </div>
                                      </div>
                                      <div class="col-md-3 col-sm-3 col-xs-12"></div>
                                      
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
            </div>
        </div>
    </div>
  </section>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Change Delivery Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class=" col-md-12 col-sm-12 col-xs-12">
               <div class="field-label">Address</div>
               <textarea style="width: 100%;" autocomplete="off" name="firstname" placeholder="" type="text" class="ng-pristine ng-invalid ng-touched">
                </textarea>
            </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
