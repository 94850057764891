// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url:"http://be.nfrmart.urstore.io/api/v1",
  // base:"http://be.nfrmart.urstore.io/api/v1"
  // base_url:"https://cors-anywhere.herokuapp.com/https://admin.etaam.com/api/v1",
  // base:"https://cors-anywhere.herokuapp.com/https://admin.etaam.com/api/v1"
  // base_url:"https://dashboard.urstore.io/api/v1",
  // base:"https://admin.etaam.com/api/v1"

  base_url: "https://api.sharwa.vip/api/v1",
  // base_url: "http://35.180.42.190/api/v1",

  base: "https://api.sharwa.vip/api/v1",

  // base_url:"https://cors-anywhere.herokuapp.com/https://tlbatak.dest.urstore.io/api/v1",
  // base:"https://tlbatak.dest.urstore.io/api/v1"
  // https://admin.etaam.com/public/api/v1/products
  // base_url:"https://dest.urstore.io/api/v1"

  firebase: {
    apiKey: "AIzaSyChQt_mYkxMUEy30ZhSx_RIJrnwBsfRfAA",
    authDomain: "sahlaegypt.firebaseapp.com",
    projectId: "sahlaegypt",
    storageBucket: "sahlaegypt.appspot.com",
    messagingSenderId: "352874108758",
    appId: "1:352874108758:web:f1f934ae661d7ecd0968f7",
    measurementId: "G-VSQ8L6L962",
  },

  pusher: {
    key: "7466d3da519218b9699f",
    secret: "4b9af7cf7522dec3f9b4",
    cluster: "eu",
  },
  // PUSHER_APP_ID=1222108,
  // PUSHER_APP_KEY=7466d3da519218b9699f,
  // PUSHER_APP_SECRET=4b9af7cf7522dec3f9b4,
  // PUSHER_APP_CLUSTER=mt1,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
