import { Injectable, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { BehaviorSubject, Observable, of, Subscriber} from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { HttpClientService } from './http-client.service';
import { Router } from '@angular/router';

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable()

export class ProductsService {
  
  public currency : string = 'EUR';
  public catalogMode : boolean = false;
  lang: string;
  
  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;
  reviewAdded: EventEmitter<any> = new EventEmitter<any>();

  // Initialize 
  constructor(
    private httpClientService: HttpClientService,
    private toastrService: ToastrService,
    private router: Router) { 
     this.compareProducts.subscribe(products => products = products);
     this.lang = localStorage.getItem('lang_ref');
  }

  // Observable Product Array
  private products(): Observable<Product[]> {
     return of([]);

  }

  // Get Products
  public getProducts(): Observable<Product[]> {
    return this.products();
  }

  // Get Products By Id
  public getProduct(id: number): Observable<Product> {
    return this.products().pipe(map(items => { return items.find((item: Product) => { return item.id === id; }); }));
  }

  // Get reviews Criteria
  public getreviewsCriteria(type: string) {
    return this.httpClientService.get(`review-criterias`, {'type': type});

  }

   // Get review By Id
   public getreview(id: number) {
    return this.httpClientService.get(`reviews/product/${id}`, {});

  }

   //  submit review 
   public submitreview(productdata) {
    return this.httpClientService.post('reviews',productdata,{});

  }

  getProductsByCriteria(queryParams) {
    return this.httpClientService.get('products', queryParams);
  }

  getProductsBycat(catId) {
    return this.httpClientService.get('products', {'categories':catId});
  }
  getProductByID(id: number) {
    return this.httpClientService.get(`products/${id}`, {});
  }


  getCategoryProducts(catID) {
    return this.httpClientService.get(`products`, {category_id: catID});
  }

  searchProducts(search) {
    return this.httpClientService.get('products', {title: search});
    
  }

   // Get Products By category
  public getProductByCategory(category: string): Observable<Product[]> {
    return this.products().pipe(map(items => 
       items.filter((item: Product) => {
         if(category == 'all')
            return item
         else
            return item.category === category; 
        
       })
     ));
  }
  
   /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

  // Get Compare Products
  public getComapreProducts(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: Product): Product | boolean {
    let routs:any[] = this.router.url.split('/');
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = products.filter(item => item.id === product.id)[0];
      const index = products.indexOf(item);
    } else {
      if(products.length < 4){
        products.push(product);
        if(this.lang == 'en'){
          this.toastrService.success('This product has been added to Compare List.');
        }else{
          this.toastrService.success('هذا المنتج تمت اضافته للمقارنة');
        }

        this.router.navigate([routs[1] + '/compare', localStorage.getItem('lang_ref')])
      }
        
        
        
      else{
        if(this.lang == 'en'){
          this.toastrService.warning('Maximum 4 products are in compare.'); // toasr services
        }else{
          this.toastrService.warning('الحد الاقصى للمقارنة 4 منتجات'); // toasr services
        }
      }
        
    }
      localStorage.setItem("compareItem", JSON.stringify(products));
      return item;
  }

  // Removed Product
  public removeFromCompare(product: Product) {
    if (product === undefined) { return; }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }

  /* Get Custom Fields */
  public getCustomFields(){
    return this.httpClientService.get('fields',[]);
  }

  /* Get Higest rank Product */
  public getHighestRankProduct(){
    return this.httpClientService.get('products/highest-rank', []);
  }

  /* Get Newest Product */
  public getNewestProduct(){
    return this.httpClientService.get('products/newest/5', []);
  }

  /* Get Most Viewed Product */
  public getMostViewedProduct(){
    return this.httpClientService.get('products/most-viewed', []);
  }

  /* Get Most Viewed Product */
  public getBestSeller(){
    return this.httpClientService.get('products/best-seller/5', []);
  }

}