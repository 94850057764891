import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';

const routes: Routes = [
  {path:'about-us',component:AboutUsComponent},
  {path:'faq',component:FaqComponent},
  {path:'feedback',component:FeedbackComponent},
  {path:'privacy_policy',component:PrivacyPolicyComponent},
  {path:'terms_condition',component:TermsConditionComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
