import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-choice-card",
  templateUrl: "./choice-card.component.html",
  styleUrls: ["./choice-card.component.scss"],
})
export class ChoiceCardComponent implements OnInit {
  @Input() item;
  @Output() delete = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onDelete() {
    this.delete.emit();
  }
}
