import { FormGroup, ValidatorFn, FormControl } from "@angular/forms";

export function sameValueAs(group: FormGroup): ValidatorFn {
    return (control: FormControl) => {
          const myValue = control.value;
          const compareValue = group.controls['password'].value;

          return (myValue === compareValue) ? null : {confirmPasswordError: true};

    };
}