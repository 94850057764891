import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";

@Component({
  selector: "app-links",
  templateUrl: "./links.component.html",
  styleUrls: ["./links.component.scss"],
})
export class LinksComponent implements OnInit {
  type;
  lang;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.type = localStorage.getItem("type");
  }

  gotoPage(name) {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/" + name, this.lang]);
  }
}
