<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
            <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <h2>
                    {{'onlinePerformanceReport' | translate}}
                </h2>

                <div class="row">
                    <div class="col-md-12">
                        <button (click)="download()" class="btn btn-success">{{'Download' | translate}}</button>
                        <mat-spinner *ngIf="isLoading"></mat-spinner>
                        <app-table [reports]="orders" [fields]="displayedColumns" [length]="totalCount" [perPage]="perPage"
                        [config]="config" [currentPage]="page" (eventFromChild)="handlePageEvent($event)"></app-table>

                        <div class="text-center" *ngIf="orders?.length == 0 && !isLoading">
                            {{'NO_ORDERS' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>