<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>
    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "onlineOrders" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
    </div>
  </div>
</div>
