import { Component, OnInit } from '@angular/core';
import { MENUITEMS, Menu } from './left-menu-items';
import { LandingFixService } from 'src/app/shared/services/landing-fix.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { Router } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  public menuItems: Menu[] = [];

  categories: any[];
  subcat;
  lang:string;
  constructor(private fix: LandingFixService, private categoriesService: CategoriesService, private translate: TranslateService, private router: Router) { }

  ngOnInit() {
    this.lang = this.translate.currentLang;
    // $.getScript('assets/js/menu.js');
    //  this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    this.categoriesService.getCategories().subscribe((res: any[]) => {
      this.categories = res['data'];
      console.log("categories test => ", this.categories)
     })

  }


  navigateCategory(cat) {
    this.closeNav();
    if (cat.is_leaf) {
      let query = cat.level == 1 ? "categories" : (cat.level == 2 ? 'sub_categories' : "sub_sub_categories")
      this.router.navigate(['category'], { queryParams: {
        [query]: cat.id
       }
     })
    } else {
      this.router.navigate(['categories'], {
        queryParams: {
          cat_id: cat.id
        }
      })
    }
  }


  closeNav() {
    this.fix.removeNavFix();
 }

}
