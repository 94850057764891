import { Component, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { SoundPopupComponent } from "./home/pages/otp-advanced-reports/components/sound-popup/sound-popup.component";
import { TranslateService } from "@ngx-translate/core";

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(translate: TranslateService, private dialog: MatDialog) {
    // this.dialog.open(SoundPopupComponent, {
    //   panelClass: "sound-dialog-container",
    //   disableClose: false,
    // });
    if (localStorage.getItem("lang_ref") == null) {
      translate.setDefaultLang("en");
      localStorage.setItem("lang_ref", "en");
      $("body").removeClass("rtl");
      $("html").attr("dir", "ltr");
      translate.use("en");
    } else {
      translate.setDefaultLang(localStorage.getItem("lang_ref"));
      if (localStorage.getItem("lang_ref") == "en") {
        $("body").removeClass("rtl");
        $("html").attr("dir", "ltr");
        translate.use("en");
      } else {
        $("body").addClass("rtl");
        translate.use("ar");
      }
    }
  }
  ngOnInit(): void {}
}
