import { Component, OnInit } from '@angular/core';
import { Order } from '../../../shared/classes/order';
import { OrderService } from '../../../shared/services/order.service';
declare var $:any
@Component({
  selector: 'app-order-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  
  public orderDetails : Order = {};

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    $(".loader-wrapper").fadeOut("slow", function () {
      console.log("its ready");
      $(this).remove();
    });
    console.log("i am here");
    
  	// this.orderDetails = this.orderService.getOrderItems();
  }

}
