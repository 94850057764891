<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>search</h2>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="''">Home</a></li>
            <li class="breadcrumb-item active">search</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="authentication-page section-b-space">
  <div class="container">
    <section class="search-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <form class="form-header">
              <div class="form-group">
                <input type="text" (keyup)="searchTerm($event.target.value)" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
              </div>
              <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <div class="col-sm-12 text-center" *ngIf="!searchProducts.length">
      <img src="assets/images/empty-search.jpg" class="img-fluid mb-4" >
      <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
      <p>Please check if you have misspelt something or try searching with other words.</p>
      <a [routerLink]="'/home/one'" class="btn btn-solid">continue shopping</a>
    </div>
    <!-- product section start -->
    <section class="section-b-space" *ngIf="searchProducts.length">
      <div class="container">
        <div class="row search-product">
          <div class="col-xl-2 col-md-4 col-sm-6" *ngFor="let product of searchProducts">
            <div class="product-box mt-3 mb-3">
              <app-product [product]="product"></app-product>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- product section end -->
  </div>
</section>
<!-- section end -->
