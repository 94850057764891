<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 text-left">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/home']">{{ "home" | translate }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ "Verify Phone" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb End -->
  <!-- section start -->
  <section class="pwd-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <h2>{{ "Verify Phone" | translate }}</h2>
          <form [formGroup]="VerifyForm" class="theme-form">
            <div class="form-row">
              <div class="col-md-12">
                <input
                  formControlName="code"
                  type="text"
                  class="form-control"
                  id="code"
                  placeholder="{{ 'code' | translate }}"
                />
  
  
              </div>
              <button
                (click)="verifyPhone()"
                class="btn btn-solid"
              >
                {{ "submit" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!--Section ends-->