import { Component, OnInit } from '@angular/core';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  closeResult = '';

  // ordersList = [1,2,3]
  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)
  }

  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }

  showOrders(i) {
    console.log("ioiioio",i)
    let show  = $(`#orders_list_body_${i}`).attr('show');
    if(show == 'false') {
      $(`#orders_list_body_${i}`).attr("show","true")
      $(`#orders_list_body_${i}`).removeClass('bounceOutUp');

      $(`#orders_list_body_${i}`).addClass('animated bounceInDown');
      $(`#orders_list_body_${i}`).removeClass('display--none');
      return;
    }
    $(`#orders_list_body_${i}`).attr("show","false")
    $(`#orders_list_body_${i}`).removeClass('bounceInDown');
    $(`#orders_list_body_${i}`).addClass('bounceOutUp');
    setTimeout(function(){
      $(`#orders_list_body_${i}`).addClass('display--none');
    },1000)

    
    console.log(i)
   
  }


}
