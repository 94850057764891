import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { map, startWith } from "rxjs/operators";

import { CoverageAreaService } from "src/app/shared/services/coverage-area.service";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { timeStamp } from "console";

@Component({
  selector: "app-coverage-area",
  templateUrl: "./coverage-area.component.html",
  styleUrls: ["./coverage-area.component.scss"],
})
export class CoverageAreaComponent implements OnInit {
  cityTitle: any = "";
  cityId: number;
  cityCtrl = new FormControl();
  AreaCtrl = new FormControl();
  citiesList: any = [];
  areasList: any = [];
  innerareasList: any = [];

  filteredOptions: Observable<string[]>;

  page: number = 1;
  config: any;
  totalCount: number;
  pageSize: number = 10;
  new_area = "";

  ID;
  merchantName;
  type;

  merchantsList: any;
  merchant: any;
  merchantsIds: any[] = [];
  ids;

  ParentID;

  areaForm: FormGroup;
  area;

  formData: FormData = new FormData();

  Mainarea;

  constructor(
    private coverageAreaService: CoverageAreaService,
    private cdr: ChangeDetectorRef,
    private toaster: ToastrService,
    private http: HttpClientService
  ) {}

  ngOnInit(): void {
    this.ID = localStorage.getItem("merchantId");
    this.type = localStorage.getItem("type");

    this.getList();
    this.getCities();

    this.filteredOptions = this.cityCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.filteredOptions = this.AreaCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.config = {
      itemsPerPage: this.pageSize,
      currentPage: this.page,
      totalItems: this.totalCount,
    };

    this.areasList = [];

    this.areaForm = new FormGroup({
      merchant_id: new FormControl(),
      is_active: new FormControl(),
      price: new FormControl("", Validators.required),
      duration: new FormControl("", Validators.required),
      duration_type: new FormControl("", Validators.required),
    });
  }

  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.ids)
            .pop();
          this.merchantName = this.merchant.name;
        }
      });
  }

  getCities() {
    this.coverageAreaService.getCities(this.ID).subscribe(
      (res: any) => {
        if (res.cities) {
          this.citiesList = res.cities;
          this.innerareasList = [];
        }
      },
      (err) => {
        this.toaster.error(err.error.message);
      }
    );
  }

  getAreas(cityId, page, pageSize) {
    this.coverageAreaService
      .getAreas(cityId, page, pageSize, this.ID)
      .subscribe(
        (res: any) => {
          this.areasList = res.areas;
          // this.totalCount = res.pagination.total;
          // this.config.totalItems = res.pagination.total;
          console.log("The main areas ::::::::", this.areasList);
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }

  GetInnerAreas(cityId, page, pageSize, ParentID) {
    this.coverageAreaService
      .getInnerAreas(cityId, page, pageSize, this.ID, ParentID)
      .subscribe(
        (res: any) => {
          console.log("res_area=> ", res);

          if (res.areas) {
            this.innerareasList = res.areas;
            this.totalCount = res.pagination.total;
            this.config.totalItems = res.pagination.total;
            console.log("Inear Areas :::::::::::", this.innerareasList);

            this.AreaCtrl.setValue("");
            this.areasList = [];
            // document.getElementById('mat-option').style.display = 'none'
            // this.clear();
          }
        },
        (err) => {
          this.toaster.error(err.error.message);
        }
      );
  }

  clear() {
    this.cityCtrl.setValue("");
    this.AreaCtrl.setValue("");
    this.areasList = [];
    this.innerareasList = [];
  }

  // change page
  pageChanged(page) {
    this.config.currentPage = page;
    this.getAreas(this.cityId, page, this.pageSize);
    this.GetInnerAreas(this.cityId, this.page, this.pageSize, this.ParentID);
  }

  onCitySelectionChanged(event) {
    const cityId = event.option.value;
    this.cityTitle = this.citiesList.find((element) => {
      if (element.id == event.option.value) {
        return element.title;
      }
    });
    console.log("title_test=> ", this.cityTitle);

    this.cityId = cityId;
    this.config.currentPage = 1;

    this.cdr.detectChanges();
    this.cityCtrl.setValue(event.option._mostRecentViewValue);
    this.getAreas(cityId, this.page, this.pageSize);
  }

  onAreaSelectionChanged(event) {
    console.log("area changed ::::", event.value);
    // console.log("area changed new::::", event)

    const areaId = event.value;
    this.ParentID = areaId;
    this.config.currentPage = 1;

    // this.AreaCtrl.setValue(event.source._mostRecentViewValue);
    this.GetInnerAreas(this.cityId, this.page, this.pageSize, this.ParentID);
    this.cdr.detectChanges();
    let x = this.areasList.find((element) => {
      if (element.id === event.value) {
        console.log("element name ::::", element.name);

        return element.name;
      }
    });

    console.log("area name ::::", x);
    this.new_area = x.name;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.areasList.filter((area) =>
      area.toLowerCase().includes(filterValue)
    );
  }

  onMerchantChange(event) {
    this.ID = event.value.id;
    this.merchantName = event.value.name;
  }

  openedChange(opened: boolean) {
    console.log(opened ? "opened" : "closed");
  }

  addArea(data) {
    // let body = {
    //   merchant_id: this.ID,
    //   areas: [this.ParentID],
    //   is_active: data.is_active == true ? 1 : 0,
    //   price: data.price,
    //   duration: data.duration,
    //   duration_type: data.duration_type,
    // };

    // this.formData.append("areas", this.ParentID);

    // let formData = new FormData();

    this.innerareasList.forEach((element: any, index: any) => {
      this.formData.append(`areas${index}.id`, element.id);
    });

    this.formData.append("merchant_id", this.ID);
    this.formData.append(
      "is_active",
      JSON.stringify(data.is_active == true ? 1 : 0)
    );
    this.formData.append("price", data.price);
    this.formData.append("duration", data.duration);
    this.formData.append("duration_type", data.duration_type);
    this.formData.append("area_id", this.ParentID);

    console.log("The form data ::::::::::::", this.formData);

    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .post("merchant/coverage-areas/add-multiple", this.formData, {}, headers)
      .subscribe(
        (res: any) => {
          this.area = res.content;
          console.log("add multiple areas api res :::::::::::", res);
          this.toaster.success(res["message"]);
          this.GetInnerAreas(this.cityId, this.page, this.pageSize, this.ParentID);

        },
        (err: any) => [this.toaster.error(err["message"])]
      );
  }

  // addArea(data, id) {
  //   let body = {
  //     merchant_id: 122,
  //     area_id: id,
  //     is_active: data.is_active == true ? 1 : 0,
  //     price: data.price,
  //     duration: data.duration,
  //     duration_type: data.duration_type,
  //   };
  //   this.coverageAreaService.createAreaMerchant(body).subscribe(
  //     (res: any) => {
  //       this.toaster.success(res["message"]);
  //     },
  //     (err: any) => [this.toaster.error(err["message"])]
  //   );
  // }

  updateArea(data, id) {
    let areaId = id;
    let body = {
      merchant_id: 122,
      is_active: data.is_active == true ? 1 : 0,
      price: data.price,
      duration: data.duration,
      duration_type: data.duration_type,
    };
    this.coverageAreaService.updateAreaMerchant(body, areaId).subscribe(
      (res: any) => {
        this.toaster.success(res["message"]);
        this.GetInnerAreas(
          this.cityId,
          this.page,
          this.pageSize,
          this.ParentID
        );
      },
      (err: any) => [this.toaster.error(err["message"])]
    );
  }

  addAreas(data, id) {
    let body = {
      merchant_id: 122,
      area_id: id,
      is_active: data.is_active == true ? 1 : 0,
      price: data.price,
      duration: data.duration,
      duration_type: data.duration_type,
    };
    this.coverageAreaService.createAreaMerchant(body).subscribe(
      (res: any) => {
        this.toaster.success(res["message"]);
        this.GetInnerAreas(
          this.cityId,
          this.page,
          this.pageSize,
          this.ParentID
        );
      },
      (err: any) => [this.toaster.error(err["message"])]
    );
  }
}
