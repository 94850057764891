import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-upload-excel',
  templateUrl: './upload-excel.component.html',
  styleUrls: ['./upload-excel.component.scss']
})
export class UploadExcelComponent implements OnInit {

  filename: any;
  uploaded_file: boolean;
  isChanged: boolean = false;
  isClicked: boolean = false;
  image_url: any;
  merchantId:any;
  brandId: any;
  uploadForm: FormGroup;
  constructor(
    private http: HttpClientService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    // this.merchantId = localStorage.getItem('merchantId');
    this.initForm();
  }

  initForm(){
    this.uploadForm = new FormGroup({
      file: new FormControl('', [Validators.required])
    });
  }

  readImage(event) {
    var files = event.target.files[0];
    // this.name = event.target.files[0].name;
    console.log("The File is ======>", files);
    this.filename = event.target.files[0].name;
    this.uploaded_file = true;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);

    this.image_url = files;
    this.isChanged = true;
    this.uploadForm.get('file').setValue(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    let url = btoa(binaryString);
    
    // console.log(this.image_url )

  }

  confirmUpload(){
    this.isClicked = true;
    let formData = new FormData();
    if(this.isChanged){
      formData.append('request', this.image_url);
    }

    console.log('Data to be Sent:- ',this.uploadForm.value);

    this.http.post('merchants/import-products', formData, []).subscribe(
      res=>{
        this.isClicked = false;
        console.log(res);
        this.toaster.success(res['message']);
      }
    );
  }

}
