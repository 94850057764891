<div>
    <div class="row">
        <div class="container">
            <div class="col-sm-12 col-md-12 text-center" id="main_items">
                <ul class="row">
                    <li>
                        <a href="">
                            <img src="assets/images/new/charity1.png">
                            <div class="item_title">التبرع السريع</div>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src="assets/images/new/charity2.png">
                            <div class="item_title">مستشفى<br>مكة الوقفي الافتراضي</div>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src="assets/images/new/charity3.png">
                            <div class="item_title">كفالة المرضى</div>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <img src="assets/images/new/charity4.png">
                            <div class="item_title">إهداء تبرع</div>
                        </a>
                    </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity5.png">
                                    <div class="item_title">تصدقت اليوم</div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity1.png">
                                    <div class="item_title">رفع البلاء</div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity2.png">
                                    <div class="item_title">الأسهم الوقفية</div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity3.png">
                                    <div class="item_title">قصة شفاء - جديد</div>
                                </a>
                            </li>
                            <li>
                                <a href="" class="selected">
                                    <img src="assets/images/new/charity4.png">
                                    <div class="item_title">زكاة</div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity5.png">
                                    <div class="item_title">هدية الوالدين</div>
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/new/charity1.png">
                                    <div class="item_title">وقف</div>
                                </a>
                            </li>
                    <li>
                        <a href="">
                            <img src="assets/images/new/charity2.png">
                            <div class="item_title">برنامج<br>المستشفيات الافتراضية</div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>