import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CartItem } from '../../../../shared/classes/cart-item';
import { CartService } from '../../../../shared/services/cart.service';
import { ProductsService } from '../../../../shared/services/products.service';
import { Observable, of } from 'rxjs';
import { NotificationsService } from '../../../../shared/services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

declare var $: any;

@Component({
  selector: 'app-header-widgetsGifts',
  templateUrl: './settingsGifts.component.html',
  styleUrls: ['./settingsGifts.component.scss']
})
export class SettingsGiftsComponent implements OnInit,OnDestroy {
  @Input() shoppingCartItems: any[] = [];
  // shoppingCartItems  :   any[] = []; 

  public show: boolean = false;

  check_login;

  notifications;
  notifs;
  adminNotification;
  notification_msg;
  notification_count;
  notifications_array = [];
  mySubscription: any;
  isLogged: boolean = false;
  fromLocal:  string = '';
  total: any;

  constructor(private translate: TranslateService,
    private cartService: CartService,
    public productsService: ProductsService,
    public notifiservice: NotificationsService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService) { }
  ngOnDestroy(): void {
    // this.cartService.cartUpdated.unsubscribe();
  }
  lang;

  // init() {
  //   this.cartService.productCount.subscribe(
  //     (res) => {
  //       console.log('count is', res);
  //     }
  //   );
  // }
  ngOnInit() {
    // this.init();
    this.userService.isLogged.subscribe(
      res=>{
        this.isLogged = res;
        console.log('Thr Res', res);
        if(res == true){
          localStorage.setItem('isLogged', 'true');
        }else{
          localStorage.setItem('isLogged', 'false');
          this.fromLocal = 'false';
        }
      }
    );

    this.fromLocal = localStorage.getItem('isLogged')
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.check_login = localStorage.getItem('token');

    // this.shoppingCartItems = this.cartService.getItems();
    this.getCartItems();
    this.cartService.cartUpdated.subscribe(res => {
      this.getCartItems();
    });
    if (this.check_login != null) {
      this.notifiservice.getNotification().subscribe((res: any) => {
        this.notifications = res;
        this.notifs = res.data;
        this.notifs = this.notifs.reverse();
        this.notification_count = this.notifications.data.length;
        console.log("The Notifications =======>", this.notifs)
      });

      this.notifiservice.getAdminNotification().subscribe( (res: any)=> {
        this.adminNotification = res.reverse();
        console.log('Admin Notification', this.adminNotification)
      });
    }
  }

  getCartItems(){
    this.cartService.getItems().subscribe(
      res=>{
        this.shoppingCartItems = res['cart'].product_items;
        this.total = res['cart'].total_price;
      }
    );
  }


  readselected(id) {

    document.getElementById("notifi").classList.toggle("read");
    this.notification_count = this.notification_count - 1;

    this.notifiservice.getNotification().subscribe(res => {

      this.notification_count;
    })

  }

  deleteNotification(id) {

    this.notifiservice.deleteNotification(id).subscribe(res => {
      if (this.lang == 'en') {
        this.toastrService.success("This Notification Has Been Deleted Successfully"); // toasr services
      }
      else if (this.lang == 'ar') {
        this.toastrService.success("تم مسح هذا الاشعار بنجاح"); // toasr services
      }
      window.location.reload();

    })
  }


  public updateCurrency(curr) {
    this.productsService.currency = curr;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  public openSearch() {
    this.show = true;
  }

  public closeSearch() {
    this.show = false;
  }

  // public getTotal(): number {
  //   let total = this.cartService.getTotalAmount();
  //   return total;
  // }

  public removeItem(item: any) {
    this.cartService.removeFromCart(item.id);
    this.getCartItems();
  }

  openOrders(){
    this.router.navigate(['orders']);
  }

  navigateToCart(){
    this.router.navigate(['cart'], {relativeTo: this.route})
  }

  navigateToOrders() {
    this.router.navigate(['orders'], {relativeTo: this.route})
  }

}
