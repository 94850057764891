import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {AllproductsService} from '../../../../shared/services/allproducts.service';


@Component({
  selector: 'product-details-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  lang;
  newproducts;
  newproduct_name;
  newproduct_name_ar;

  newproduct_image;
  newproduct_price;
  newproduct_id;



  constructor(private translate: TranslateService,private productservice:AllproductsService) { }
  
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
    this.productservice.getnewproducts().subscribe((res:any)=>{
      console.log("tesssst=>",res.data)
      this.newproducts = res['data'];
      // this.newproduct_id = res['data'].id;
      // this.newproduct_name = res['data'].title.En;
      // console.log("new_product=> ",this.newproduct_name)
      // this.newproduct_name_ar = res['data'].title.ar;

      // this.newproduct_image = res['data'].mediaCollection[0].default;
      // this.newproduct_price = res['data'].price
      // console.log("New Products========>",this.newproducts)
    })

    

  }


}
 