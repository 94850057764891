<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav2></app-side-nav2>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "General Analysis" | translate }}</h2>
          </div>
        </div>

        <div class="container" *ngIf="loged">
          <div class="row" style="margin-bottom: 20px">
            <div class="col-md-4 col-sm-12 col-xs-12">Date Range :</div>
            <div class="col-md-3">
              <div class="form-group">
                <h4>{{ "From" | translate }}</h4>
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <input
                    matInput
                    [(ngModel)]="from"
                    [matDatepicker]="picker"
                    [ngModelOptions]="{ standalone: true }"
                    [max]="to"
                    (dateChange)="getAnalysis()"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <h4>{{ "To" | translate }}</h4>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [(ngModel)]="to"
                    [matDatepicker]="endPicker"
                    [ngModelOptions]="{ standalone: true }"
                    [min]="from"
                    (dateChange)="getAnalysis()"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="div-container">
            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Most area order from:
                  <h3>{{ analysis?.most_area_order_from }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Most brand order from:
                  <h3>{{ analysis?.most_brand_order_from }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Most merchant order from:
                  <h3>{{ analysis?.most_merchant_order_from }}</h3>
                </div>
              </div>
            </div>

            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Most searched terms:
                  <h3>{{ analysis?.most_searched_terms }}</h3>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num orders canceled:
                  <h3>{{ analysis?.num_orders_canceled }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num orders delivered:
                  <h3>{{ analysis?.num_orders_delivered }}</h3>
                </div>
              </div>
            </div>

            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num orders paid cash:
                  <h3>{{ analysis?.num_orders_paid_cash }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num orders paid visa:
                  <h3>{{ analysis?.num_orders_paid_visa }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num orders paid wallet:
                  <h3>{{ analysis?.num_orders_paid_wallet }}</h3>
                </div>
              </div>
            </div>

            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers:
                  <h3>{{ analysis?.num_customers }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers female:
                  <h3>{{ analysis?.num_customers_female }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers male:
                  <h3>{{ analysis?.num_customers_male }}</h3>
                </div>
              </div>
            </div>

            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers had orders:
                  <h3>{{ analysis?.num_customers_had_orders }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers used instore:
                  <h3>{{ analysis?.num_customers_used_instore }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers used search:
                  <h3>{{ analysis?.num_customers_used_search }}</h3>
                </div>
              </div>
            </div>

            <div class="row row-style">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers used wallet:
                  <h3>{{ analysis?.num_customers_used_wallet }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers with cart without order:
                  <h3>{{ analysis?.num_customers_with_cart_without_order }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num customers without order:
                  <h3>{{ analysis?.num_customers_without_order }}</h3>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num guests used search:
                  <h3>{{ analysis?.num_guests_used_search }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Num transactions instore:
                  <h3>{{ analysis?.num_transactions_instore }}</h3>
                </div>
              </div>

              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="statistics-card">
                  Wallet greatest used amount:
                  <h3>{{ analysis?.wallet_greatest_used_amount }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!loged" class="form">
          <div class="form-group">
            <label for="email">{{ "email" | translate }}</label>
            <input
              [(ngModel)]="email"
              type="text"
              class="form-control"
              id="email"
              placeholder="{{ 'email' | translate }}"
            />
          </div>
          <div class="form-group">
            <label for="review">{{ "Password" | translate }}</label>
            <input
              [(ngModel)]="password"
              type="password"
              class="form-control"
              id="password"
              placeholder="{{ 'Password' | translate }}"
            />
          </div>
          <div class="action_bottom">
            <button type="submit" class="btn btn-primary" (click)="login()">
              {{ "submit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
