<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{'collection'|translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb end -->
<!-- section start -->
<section class="section-b-space" [ngClass]="productsClass">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <!-- Collection Sidebar -->
        <div class="col-md-3 collection-filter">
          <div class="coll_sidebar_sticky">

            <div class="collection-filter-block">
              <app-categories-products></app-categories-products>
            </div>

            <div class="collection-filter-block">
              <app-price (priceFilters)="updatePriceFilters($event)"></app-price>
            </div>
            <div class="collection-filter-block scroll">
              <div class="collection-collapse-block open">
                <h3 class="collapse-block-title">{{'rating'|translate}}</h3>
                <div class="collection-collapse-block-content">
                  <div class="collection-brand-filter">
                    <div class="new-checkbox" *ngFor="let rate of rating;let i = index">
                      <input type="checkbox" id="checkboxcity_{{i}}" (click)="updaterate(rate)" class="regular-checkbox" />
                      <label for="checkboxcity_{{i}}">
                        <bar-rating [rate]="rate" [readOnly]="true" class="ratefilter"></bar-rating>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <!-- <div class="col-grid-box" *ngFor="let product of newarrival | orderBy:sortByOrder">
                <div class="product-box">
                      <app-new-product></app-new-product>
                </div>
                <div class="col-sm-12 text-center section-b-space mt-5 no-found " *ngIf="product.length === 0">
                      <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                      <h3> {{'noproduct'|translate}} </h3>

                </div>

            </div> -->


          </div>
        </div>
        <!-- End Collection Sidebar -->


        <div class="collection-content col-md-9">
          <div class="page-main-content">
           


                  <div class="collection-product-wrapper">
                    <!-- Filter Block Products -->
                    <div class="product-top-filter">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="col-xl-12">
                            <div class="filter-main-btn">
                              <span class="filter-btn btn btn-theme" (click)="mobileFilter()">
                                <i class="fa fa-filter" aria-hidden="true"></i> {{'Filter'|translate}}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="product-filter-content">
                              <!-- <div class="search-count">
                                <h2 class="section-title">
                                  <span>

                                    {{'show product'|translate}} {{fromItem}}-{{totalCount}}
                                  </span>
                                </h2>
                              </div> -->
                              <!-- <div class="collection-grid-view">
                                <ul class="m-auto">
                                  <li class="m-auto">
                                    <img src="assets/images/icon/2.png" class="product-2-layout-view"
                                      (click)="twoCol()">
                                  </li>
                                  <li class="m-auto">
                                    <img src="assets/images/icon/3.png" class="product-3-layout-view"
                                      (click)="threeCol()">
                                  </li>
                                  <li class="m-auto">
                                    <img src="assets/images/icon/4.png" class="product-4-layout-view"
                                      (click)="fourCol()">
                                  </li>
                                  <li class="m-auto">
                                    <img src="assets/images/icon/6.png" class="product-6-layout-view"
                                      (click)="sixCol()">
                                  </li>
                                </ul>
                              </div> -->
                              <div class="product-page-filter">
                                <select class="all-product-filter" (change)="onChangeSorting($event.target.value)">
                                  <option value="asc">{{'sort'|translate}}</option>

                                    <option value="asc">{{'AscendingOrder'|translate}}</option>
                                    <option value="desc">{{'DescendingOrder'|translate}}</option>
                                    <option value="a-z">{{'Alphabetically, A-Z'|translate}}</option>
                                    <option value="z-a">{{'Alphabetically, Z-A'|translate}}</option>
                                    <option value="low">{{'Price, low to high'|translate}}</option>
                                    <option value="high">{{'Price, high to low'|translate}}</option>
                                    <!-- <option value="high">{{'Price, high to low'|translate}}</option> -->

                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Block Products -->

                    <!-- Display Products -->

                    <div class="product-wrapper-grid">
                      <div class="">
                        <div class="row" [@Animation]="animation">
                          <div class="col-xl-4 col-md-6 col-grid-box"
                            *ngFor="let product of merchants | paginate: config">

                            <div class="product-box">
                              <app-product [product]="product"></app-product>
                            </div>

                          </div>
                          
                        </div>
                      </div>
                      <div class="row pagination mt-3 text-center">
                        <pagination-controls responsive="true" (pageChange)="onChangePage($event)"></pagination-controls>
                      </div>
                    </div>



                    <div class="product-infinitescroll">
                      <div class="theme-paggination-block">
                        <div class="container-fluid p-0">
                          <div class="row">

                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- End Infinite Scroll -->
                  </div>
              
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- section End -->
<app-quick-view></app-quick-view>
<app-modal-cart></app-modal-cart>

<ngx-spinner color="#f95733"></ngx-spinner>