import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  lang;
  type;

  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.type = localStorage.getItem("type");

  }
  

}
