import { Component, OnInit } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-menu-view",
  templateUrl: "./menu-view.component.html",
  styleUrls: ["./menu-view.component.scss"],
})
export class MenuViewComponent implements OnInit {
  merchantName;
  id;
  type;
  merchantsList;
  lang;
  merchant: any;
  categories: any;
  displayCat: any;
  unAvailabeProds: any;
  currentSection: any;
  currentSection2:any;
  closeResult = "";
  editCat;
  showUpdateBar = false;
  requestType;
  drafts;

  Basic_Design;
  cat_ID;

  Sub_categories;
  displaySubCat;


  Draft_products:any = [];
  Pending_products:any = [];
  Rejected_products:any = [];
  Approved_products:any = [];


  constructor(
    private http: HttpClientService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.merchantName = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.id = localStorage.getItem("merchantId");
    this.lang = localStorage.getItem("lang_ref");
    this.getList();

    this.Basic_Design =  localStorage.getItem("basic_design");

    this.GetProductsRequests();

  }

  onMerchantChange(event) {
    this.id = event.value.id;
    this.merchantName = event.value.name;
    localStorage.setItem("merchantId", this.id);
    this.getCategories();
  }

  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          if (this.id) {
            this.merchantName = res.data.merchants.find(
              (r) => r.id == this.id
            ).name;
            this.merchant = res.data.merchants.find((r) => r.id == this.id);
          } else {
            this.id = res.data.merchants[0].id;
            this.merchantName = res.data.merchants[0].name;
            this.merchant = res.data.merchants[0];
          }
          this.getCategories();
        }
      });
  }

  getCategories() {

    if (this.Basic_Design == 1) {
      let headers: HttpHeaders = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("optToken")}`,
      });
      let body = {
        merchant_id: this.id,
      };
      this.http.post("merchant/products", body, {}, headers).subscribe((res) => {
        this.categories = res["merchant"].categories_new;
        this.displayCat = res["merchant"].categories_new[0];
        this.unAvailabeProds = res["merchant"].unavailable_product;
        this.currentSection = 0;
        this.toaster.success(res["message"]);
        localStorage.setItem("catId", res["merchant"].categories_new[0].id);
      });
      
      this.http
        .get("merchant/product-request?status=draft", {}, headers)
        .subscribe((res: any) => {
          this.drafts = res.product_requests;
          this.drafts = res.product_requests.map((r) => {
            return {
              category_id: r.product.category_id,
              content: r.product.content,
              content_all: { ar: r.product.content_ar, en: r.product.content_en },
              currency: { en: "EGP", ar: "ج.م.‏" },
              custom_fields: r.product.custom_fields,
              isDraft: true,
              id: r.id,
              mediaCollection: r.mediaCollection,
              price: r.product.price,
              title: r.product.title_en,
              title_all: { en: r.product.title_en, ar: r.product.title_ar },
            };
          });
        },error=>{
          this.toaster.error(error.error.message)
        });
    }

    else {

         
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });

    let body = {
      merchant_id: this.id,
    };
    this.http.post("merchant/products", body, {}, headers).subscribe((res) => {
      this.categories = res["merchant"].categories_new;
      // this.displayCat = res["merchant"].categories_new[0];
      this.unAvailabeProds = res["merchant"].unavailable_product;
      this.currentSection = 0;
      this.toaster.success(res["message"]);
      localStorage.setItem("catId", res["merchant"].categories_new[0].id);
    });

    }
   
  }


  onSectionChange(cat, i) {
    this.displayCat = cat;
    this.currentSection = i;

    localStorage.setItem("catId", cat.id);

      if(this.Basic_Design == 0){
        let headers: HttpHeaders = new HttpHeaders({
          Authorization: `Bearer ${localStorage.getItem("optToken")}`,
        });
        let body = {};
        this.cat_ID = localStorage.getItem("catId")
        this.http.get(`sub-categories/${this.cat_ID}`, body, headers).subscribe((res:any) => {
          this.Sub_categories = res.categories;
          this.displaySubCat = res.categories[0];
          // this.unAvailabeProds = res["merchant"].unavailable_product;
          this.currentSection = 0;
          // this.toaster.success(res["message"]);
          // localStorage.setItem("catId", res["merchant"].categories[0].id);
        });
      }

      else{
        let headers: HttpHeaders = new HttpHeaders({
          Authorization: `Bearer ${localStorage.getItem("optToken")}`,
        });
        this.http
          .get("merchant/product-request?status=draft", {}, headers)
          .subscribe((res: any) => {
            this.drafts = res.product_requests;
            this.drafts = res.product_requests.map((r) => {
              return {
                category_id: r.product.category_id,
                content: r.product.content,
                content_all: { ar: r.product.content_ar, en: r.product.content_en },
                currency: { en: "EGP", ar: "ج.م.‏" },
                custom_fields: r.product.custom_fields,
                isDraft: true,
                id: r.id,
                mediaCollection: r.mediaCollection,
                price: r.product.price,
                title: r.product.title_en,
                title_all: { en: r.product.title_en, ar: r.product.title_ar },
              };
            });
          },error=>{
            this.toaster.error(error.error.message)
          });

      }

  }

  onSectionChange2(cat, i){

    this.currentSection2 = i;
    this.displayCat = cat;



    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      merchant_id: this.id,
      categories:cat.id
    };
    this.http.get("products", body, headers).subscribe((res) => {
      this.displayCat = res;
      // this.unAvailabeProds = res["merchant"].unavailable_product;
      this.currentSection = 0;
      this.currentSection2 = 0;
      // this.toaster.success(res["message"]);
      // localStorage.setItem("catId", res["merchant"].categories_new[0].id);
    });

  }

  open(cat?, content?: any) {
    if (cat) this.editCat = cat;
    else {
      this.editCat = {};
    }
    if (content)
      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else if (reason?.isDraft) {
      this.deleteProd(reason);
    } else {
      return `with: ${reason}`;
    }
  }
  
  openStatusBar(type) {
    this.requestType = type;
    this.showUpdateBar = true;
  }

  getApproved(item) {
    return { ...item, approved: true };
  }

  getRejected(item) {
    return { ...item, rejected: true };
  }

  addProduct(e?) {
    if (e) localStorage.setItem("prod", JSON.stringify(e));
    else {
      localStorage.removeItem("prod");
    }
    this.router.navigateByUrl(
      `otphome/edit-product/${this.lang}/${e ? e.id : "new"}`
    );
  }

  deleteProd(e) {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .delete(`merchant/product-request/delete/${e.id}`, {}, headers)
      .subscribe((res: any) => {
        this.toaster.success(res.message);
        this.modalService.dismissAll();
        this.getCategories();
      });
  }

  GetProductsRequests(){
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    
    this.http.get("merchant/product-request?status=draft",  {}, headers).subscribe((res:any) => {
       this.Draft_products = res.product_requests;
    });

    this.http.get("merchant/product-request?status=pending",  {}, headers).subscribe((res:any) => {
      this.Pending_products = res.product_requests;
    });

   this.http.get("merchant/product-request?status=approved",  {}, headers).subscribe((res:any) => {
        this.Approved_products = res.product_requests;
    });

    this.http.get("merchant/product-request?status=rejected",  {}, headers).subscribe((res:any) => {
      this.Rejected_products = res.product_requests;
    });


  }

}
