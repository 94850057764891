import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999);
  }

}
