<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
            <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <p class="back" (click)="goBack()">
                <i class="fa fa-arrow-left"></i> {{'Back To Categories' | translate}}
            </p>
            <h4 class="category">{{categoryName}}</h4>
            <div *ngFor="let product of products">
                <div class="details">
                    <span>{{product?.title}}</span>
                    <span>{{product?.price}} {{'EGP' | translate}}</span>

                    <button class="btn" (click)="open(content, product)">
                        <i class="fa fa-pencil"></i>
                    </button>

                    <mat-form-field appearance="fill">
                        <mat-label>{{'select status' | translate}}</mat-label>
                        <mat-select [(value)]="product.published" (selectionChange)="updateStatus($event, product?.id)">
                            <mat-option [value]="1">
                                {{'avaialable' | translate}}
                            </mat-option>
                            <mat-option [value]="0">
                                {{'unavaialable' | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    
                </div>

            </div>
            <div *ngIf="products.length == 0" class="details">
                {{'noproduct' | translate}}
            </div>

            <ng-template #content let-modal>
                <div class="modal-header">
                    <h4 class="modal-title mb-0" id="modal-basic-title">{{'Update Product Request'|translate}}</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>{{'En Name' | translate}}</label>
                                    <input type="text" formControlName="name_en" class="form-control" placeholder="{{'En Name' | translate}}"/>
                                    <span
                                        *ngIf="productForm.controls['name_en'].dirty && productForm.controls['name_en']?.errors?.required"
                                        class="error-msg">
                                        {{'EnnameReq'|translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>{{'Ar Name' | translate}}</label>
                                    <input type="text" formControlName="name_ar" class="form-control" placeholder="{{'Ar Name' | translate}}"/>
                                    <span
                                        *ngIf="productForm.controls['name_ar'].dirty && productForm.controls['name_ar']?.errors?.required"
                                        class="error-msg">
                                        {{'ArnameReq'|translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>{{'price' | translate}}</label>
                                    <input type="text" formControlName="price" class="form-control" placeholder="{{'price' | translate}}"/>
                                    <span
                                        *ngIf="productForm.controls['price'].dirty && productForm.controls['price']?.errors?.required"
                                        class="error-msg">
                                        {{'priceReq'|translate}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary" type="submit">{{'save' | translate}}</button>
                    </form>
                </div>
                <!-- <div class="modal-footer">
                    
                </div> -->
            </ng-template>

        </div>
        
    </div>
</div>