import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/* import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core'; */
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';

/* const swal: SweetAlert = _swal as any;
 */
declare var $: any;
@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {
check_login;
_next = 1;
_submit:Boolean = false;
_ref:string ="Change Password";
_reset:Boolean = true;
changePasswordForm: FormGroup;
mobile_number="0100-111-1223"
isFromProfile: boolean = false;
passwordClass: string = '';
showPass: boolean = false;
showOldPass: boolean = false;
confirmPass: boolean = false;
constructor(private translate: TranslateService,
            private activatedRoute: ActivatedRoute,
            private router: Router,
            private _userService: UserService,
            private toastr: ToastrService,
            private formBuilder: FormBuilder
  ) { 
    this.changePasswordForm = formBuilder.group({
      "old_password": ['',Validators.required],
      "password": ['',Validators.required],
      "password_confirmation": ['',Validators.required],
    })
  } 

lang;

ngOnInit() {
  this.passwordClass = this.router.url.toString().split('/')[1];
  this.check_login = localStorage.getItem('token')
  this.activatedRoute.queryParams.subscribe(params=>{
    if (params.ref == 'forgot'){
      this._ref = "Reset Password";
      this._reset = true;
    }
    if(params.source == 'profile') {
      this.isFromProfile = true;
    }
    
    console.log("ref=> ",this._ref)
  });

  $(".toggle-password").click(function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  this.lang = localStorage.getItem("lang_ref"); 
  this.translate.use(this.lang); 
  setTimeout(function(){
    $('.loader-wrapper').fadeOut('slow', function() {
      $(this).remove();
    });
  },1999)
}

goTo(ref) {
  if(ref  == 'next'){
    if(this._next == 1){
      this._next +=1 ;
      $("#change_step").addClass("bounceOutLeft");
      $("#_1_step").removeClass("bounceOutRight");
      setTimeout(()=>{
        $("#change_step").addClass("display--none");
        $("#_1_step").removeClass("display--none");
        $("#_1_step").addClass("bounceInRight");
      },1000)
    }
    else if(this._next==2){
      console.log("tany next")
      this._next +=1 ;
      $("#_1_step").addClass("bounceOutLeft");
      $("#_2_step").removeClass("bounceOutRight");
      setTimeout(()=>{
        $("#_1_step").addClass("display--none");
        $("#_2_step").removeClass("display--none");
        $("#_2_step").addClass("bounceInRight");
      },1000)
    }
    else{
      console.log("tany next")
      this._next +=1 ;
      $("#_2_step").addClass("bounceOutLeft");
      $("#_3_step").removeClass("bounceOutRight");
      setTimeout(()=>{
        $("#_2_step").addClass("display--none");
        $("#_3_step").removeClass("display--none");
        $("#_3_step").addClass("bounceInRight");
      },1000)
    }
    console.log("ttt=> ",this._next)
  }
  else{
    if(ref == 'back'){
      this._next -= 1;
      console.log("ttt=> ",this._next)
    
      if(this._next == 3){
        $("#_3_step").removeClass("bounceInRight");
        $("#_3_step").addClass("bounceOutRight");
        setTimeout(()=>{
          $("#_3_step").addClass("display--none");
          $("#_2_step").removeClass("bounceOutLeft");
          $("#_2_step").removeClass("bounceInRight");
          $("#_2_step").removeClass("display--none");
          $("#_2_step").addClass("bounceInLeft");
        },1000)
      }
      if(this._next == 2){
        $("#_2_step").removeClass("bounceInRight");
        $("#_2_step").addClass("bounceOutRight");
        setTimeout(()=>{
          $("#_2_step").addClass("display--none");
          $("#_1_step").removeClass("bounceOutLeft");
          $("#_1_step").removeClass("bounceInRight");
          $("#_1_step").removeClass("display--none");
          $("#_1_step").addClass("bounceInLeft");
        },1000)
      }
      if(this._next == 1) {
        $("#_1_step").removeClass("bounceInRight");
        $("#_1_step").addClass("bounceOutRight");
        setTimeout(()=>{
          $("#_1_step").addClass("display--none");
          $("#change_step").removeClass("bounceOutLeft");
          $("#change_step").removeClass("display--none");
          $("#change_step").addClass("bounceInLeft");
        },1000)
      }
     
    }
    else{
      /** Success*/
     /*  swal({
        title: "Success",
        text: "Your Password has been successfully changed",
        icon: "success",
        dangerMode: true,
        buttons: {
          confirm: true,
        }
      })
        .then(success => {
          if (success) { */
           this.router.navigate(['/login'])
          /* }
        }); */
      /** Error*/
      // swal({
      //   title: "Error",
      //   text: "",
      //   icon: "error",
      //   dangerMode: true,
      //   buttons: {
      //     confirm: true,
      //   }
      // })
      //   .then(success => {
      //     if (success) {
      //     }
      //   });

    }
  }

}

changePassword()
{
  let routs:any[] = this.router.url.split('/');
  console.log(this.changePasswordForm.value);
  this._userService.updatePassword(this.changePasswordForm.value).subscribe(response=>{
    console.log("response=> ",response);
    this.toastr.success(response['message']);

    if(routs[1] == 'otphome'){
      this.check_login = null;
      localStorage.removeItem('optToken');
      this.router.navigate([routs[1] + '/login', this.lang])
    }else{
      this.check_login = null;
      localStorage.removeItem('token');
      this.router.navigate([routs[1] + '/login', this.lang])
    }
    
    
  },error=>{
    if(error?.error?.errors?.password) {
      error?.error?.errors?.password.forEach(err=>{
        this.toastr.error(err);
      })
    }
    if(error?.error?.errors?.old_password) {
      error?.error?.errors?.old_password.forEach(err=>{
        this.toastr.error(err);
      })
    }

    if(error?.error?.status){
      this.toastr.error(error.error.status);
    }
  })
}

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

  gotoProfile(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/profile' , this.lang]);
  }

  uncover(){
    this.showOldPass = !this.showOldPass;
  }

  uncoverPass(){
    this.showPass = !this.showPass;
  }

  uncoverConfirm(){
    this.confirmPass = !this.confirmPass;
  }

}
