import { BehaviorSubject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { CartItem } from "../classes/cart-item";
import { HttpClientService } from "./http-client.service";
import { Injectable } from "@angular/core";
import { Order } from "../classes/order";
import { Product } from "../classes/product";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  // Array
  public OrderDetails;

  constructor(
    private router: Router,
    private httpClientService: HttpClientService
  ) {}

  // Get order items
  public getOrderItems(): Order {
    return this.OrderDetails;
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
      shippingDetails: details,
      product: product,
      orderId: orderId,
      totalAmount: amount,
    };
    this.OrderDetails = item;
    this.router.navigate(["payments/checkout/success"]);
  }

  // List Orders
  getorders() {
    return this.httpClientService.get("orders", {});
  }

  //List Returns
  public getAllReturns() {
    return this.httpClientService.get("returns", []);
  }

  public SubmitReturn(data: any) {
    return this.httpClientService.post("returns", data, []);
  }

  // OrderSound(){
  //   let audio = new Audio();
  //   audio.src = "assets/sounds/ring.mp3";
  //   audio.load();
  //   audio.play()
  // }
}
