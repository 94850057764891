import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-four-categories',
  templateUrl: './four-categories.component.html',
  styleUrls: ['./four-categories.component.scss']
})
export class FourCategoriesComponent implements OnInit {
  @Input() categories:any = [];
  lang:string;

  constructor(private categoriesService: CategoriesService,
              private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  // ngOnChanges(){
  //   console.log("-=-=-=-=-=-=-=-=-=-==-=-=")
  //   console.log("changes=> ",this.categories)
  // }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    console.log("THE CATEGORIES", this.categories);
    this.lang = this.translate.currentLang;
    this.categories = this.categories.filter(item => item.featured == true);
  }

  navigateCategory(cat) {
    let routs: any[] = [];
    routs = this.router.url.split('/');
    if (cat.parent_category_id == 0) {
      let query = cat.level == 0 ? "categories" : (cat.level == 2 ? 'sub_categories' : "sub_sub_categories")
      this.router.navigate([routs[1] + '/category', this.lang], { queryParams: {
        [query]: cat.id
       }
     })
    } else {
      let query = cat.level == 1 ? "cat_id" : "sub_cat_id";
      this.router.navigate([routs[1] + '/categories', this.lang], {
        queryParams: {
          [query]: cat.id
        }
      })
    }
  }

}
