<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">

            <div class="row bottom-margin">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
                
            </div>

            <div class="row bottom-margin">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
                
            </div>
            
            <div class="row bottom-margin">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading text-center" style="height: 80px">
                            [<small>APP108866</small>]
                            <br>
                            مياه بيضاء
                        </div>
                        <div class="panel-body ar-style" style="height: 150px">
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يعاني من :</label>
                                    مياه بيضاء مكتملة
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>يحتاج إلى :</label>
                                    سحب المياه البيضاء بالفاكو مع زرع عدسة العين اليسرى
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-3"><label>الإجمالي</label></div>
                                <div class="col-md-9"><small>( 1,700.00 )</small></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المدفوع</label></div>
                                <div class="col-md-9">
                                    <small>( 35.00 )</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"><label>المتبقي</label></div>
                                <div class="col-md-9"><small>( 1,665.00 )</small></div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label>مشاركة</label>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 text-right">
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>
            
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Patients/Preview/65834">
            
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer ar-style">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="input-group custom-input">
                                        <input type="text" class="form-control custom-height" value="1,665.00" data-patient-id="65834" data-max-amount="1665.0000">
                                        <span class="input-group-btn custom-height">
                                            <button class="btn btn-primary main-btn"  title="شراء"><i class="fa fa-credit-card"></i></button>
                                            <button type="button" class="btn btn-primary main-btn"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
                
            </div>
            
    
        </div>
    </div>


</div>