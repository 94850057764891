<div [formGroup]="areaForm" class="card my-3 mat-elevation-z8">
    <div class="card-header d-flex justify-content-between">
      <p class="card-title">{{ area.name }}</p>
      <mat-slide-toggle
        formControlName="is_active"
        [checked]="area.merchant_area?.is_active == 1 ? true : false"
        >Active</mat-slide-toggle
      >
    </div>
    <div class="card-body px-0">
      <table class="example-full-width">
        <tr>
          <td>
            <mat-form-field class="min-width">
              <mat-label class="text-align">{{
                "deliverfees" | translate
              }}</mat-label>
              <input matInput type="number" formControlName="price" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="min-width">
              <mat-label class="text-align">{{
                "deliveryTime" | translate
              }}</mat-label>
              <input matInput type="number" formControlName="duration" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="min-width">
              <mat-label class="text-align">duration_type</mat-label>
              <mat-select formControlName="duration_type">
                <mat-option value="minutes">Minutes</mat-option>
                <mat-option value="hours">Hours</mat-option>
                <mat-option value="days">Days</mat-option>
                <mat-option value="months">Months</mat-option>
                <mat-option value="years">Years</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <button
              [disabled]="areaForm.invalid"
              *ngIf="!area.merchant_area"
              mat-button
              mat-icon-button
              style="color: #198dca"
              (click)="addArea(areaForm.value, area.id)"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              [disabled]="areaForm.invalid"
              *ngIf="area.merchant_area"
              mat-button
              mat-icon-button
              style="color: #108d10"
              (click)="updateArea(areaForm.value, area.merchant_area?.id)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
</div>
  