import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-login6',
  templateUrl: './login6.component.html',
  styleUrls: ['./login6.component.css']
})
export class Login6Component implements OnInit {

  constructor(private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService) { } 

 lang;
 loginForm: FormGroup;

 ngOnInit() { 
  setTimeout(function () {
    $('.loader-wrapper').fadeOut('slow', function () {
      $(this).remove();
    });
  }, 1999);
  
   this.lang = localStorage.getItem("lang_ref"); 
   this.translate.use(this.lang); 
   $('.loader-wrapper').fadeOut('slow', function() {
     console.log("its ready")
     $(this).remove();
   });
   this.initForm();
 }


 initForm() {
   this.loginForm = new FormGroup({
     name: new FormControl('', [Validators.required, Validators.email]),
     password: new FormControl('', [Validators.required, Validators.minLength(6)])
   })
 }


 login() {
   debugger
   console.log("clickeddd")
   this.userService.login(this.loginForm.value).subscribe((res: any) => {
     this.userService.handleResponse(res);
     // console.log("The Response",res)
     let name = res.name.split(" ")
     let firstName = name[0];
     let lastName = name[1];
     let email = res.email;
     let mobile = res.mobile;

     let userObject = {firstName: firstName, lastName: lastName, email: email, mobile: mobile};
     localStorage.setItem('userObject', JSON.stringify(userObject));
     this.userService.isLogged.next(true);
     localStorage.setItem('isLogged', 'true');
     window.location.replace('') 

   },error=>{
     this.toastrService.error(error.error.message); // toasr services
   }
   )
 }


}
