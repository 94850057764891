import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sales-by-area",
  templateUrl: "./sales-by-area.component.html",
  styleUrls: ["./sales-by-area.component.scss"],
})
export class SalesByAreaComponent implements OnInit {
  data: any[] = [];
  merchants_array: any[] = [];

  lang: any;
  isLoading: boolean = false;
  Merchant_ID;
  Merchant_ID_Array: any[] = [];

  filterForm: FormGroup;
  Hide: boolean = true;

  selectedID: any = 1;
  downloadreport: any;
  id: number;
  name: string;
  type: string;
  merchantsList: any;
  merchant: any;
  downloadreportPDF: any;
  idList: any[];
  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.getList();
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  initReports() {
    this.Merchant_ID = localStorage.getItem("merchantId");
    this.Merchant_ID_Array.push(this.Merchant_ID);
    console.log("Merchant Id Array :-----", this.Merchant_ID_Array);
    this.getSalesByArea();
    this.initForm();

    localStorage.getItem("Brand_Merchants_array");
    console.log(
      "Merchants Array from localstorage:--",
      JSON.parse(localStorage.getItem("Brand_Merchants_array"))
    );
    this.merchants_array = JSON.parse(
      localStorage.getItem("Brand_Merchants_array")
    );
    console.log("Merchants Array:--", this.merchants_array);
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.initReports();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.idList = [this.id];
          this.name = this.merchant.name;

          this.initReports();
        }
      });
  }
  getSalesByArea() {
    let body = {
      merchants: this.idList,
      date_flag: this.selectedID,
    };
    this.isLoading = false;
    this.http.post("report/area-sales", body, []).subscribe((res) => {
      console.log("Sales Area Reports:----", res);
      this.data = res["data"].orders;
      this.downloadreportPDF = res["data"].pdf_download_file;
      this.downloadreport = res["data"].download_file;
      this.isLoading = true;
    });
  }
  initForm() {
    this.filterForm = new FormGroup({
      date: new FormControl(""),
      status: new FormControl(null),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });
  }
  reset() {
    this.filterForm.reset();
    this.selectedID = 1;
    this.getSalesByArea();
  }

  download() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
    });
  }

  ShowDatePicker() {
    this.Hide = false;
    console.log("Date picker:---", this.Hide);
  }

  changeSelection(event: any) {
    console.log(
      "The select menu option is :-----",
      event.source.selected.value
    );
    this.selectedID = event.source.selected.value;
    this.getSalesByArea();
  }
}
