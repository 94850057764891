import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-otp-sales-report',
  templateUrl: './otp-sales-report.component.html',
  styleUrls: ['./otp-sales-report.component.css']
})
export class OtpSalesReportComponent implements OnInit {

  lang;
  orders: any[] =[];
  displayedColumns: string[] = ['id', 'created_at', 'amount', 'payment_methods', 'branch_name', 'area'];
  perPage:any;
  totalCount:any;
  page: number = 1;
  config:any;
  isLoading: boolean = false;
  constructor(
    private translate: TranslateService,
    private http: HttpClientService,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.getOrders(1);

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount
    };
  }

  getOrders(page?, body?){
    this.isLoading = true;
    this.http.post('merchant/online-orders-sales-report',body, {page: page}).subscribe(
      res=>{
        this.orders = res['data'].data;
        this.totalCount = res['data'].total;
        this.perPage = res['data'].per_page;
        this.isLoading = false;
      }
    );
  }

  handlePageEvent(event){
    console.log(event);
    this.getOrders(event.pageIndex + 1);
  }

  getDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    let body = {'date': date};
    console.log(body);
    this.getOrders(this.page, body);
  }

  download(){
    localStorage.setItem('orders', JSON.stringify(this.orders));
    localStorage.setItem('displayedColumns', JSON.stringify(this.displayedColumns));
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/print-order', this.lang]);
  }

}
