<div class="icon-nav">
  <ul>
 
    <li _ngcontent-iuj-c19="" class="onhover-div mobile-order " id="orders-widgets"
      *ngIf="isLogged || fromLocal=='true'" >
      <div _ngcontent-iuj-c19=""><a _ngcontent-iuj-c19="" (click)="navigateToOrders()">
          <!-- <img style="    width: 27px;" _ngcontent-iuj-c19="" alt="" class="img-fluid order-icon"
            src="assets/images/icon/service1.png"> -->
            <i _ngcontent-iuj-c19="" class="fa fa-truck desktop-icon"></i>
            
            <i _ngcontent-iuj-c19="" class="fa fa-truck"></i>
          </a>
      </div>
    </li>
    <li class="onhover-div mobile-cart" *ngIf="!productsService?.catalogMode"> 
      <div>
        <a (click)="navigateToCart()">
          <!-- <img src="assets/images/icon/cart.png" class="img-fluid" alt=""> -->
          <i class="ti-shopping-cart desktop-icon"></i>
        </a>
      </div>
      <ul class="show-div shopping-cart" *ngIf='!shoppingCartItems.length'>
        <h5>{{'cartempty'|translate}}</h5>
      </ul>
      <span class="cart_qty_cls">{{shoppingCartItems.length}}</span>
      <ul class="show-div shopping-cart" *ngIf='shoppingCartItems.length'>
        <li *ngFor="let item of shoppingCartItems" >
          <div class="media">

            <!-- <a [routerLink]="['products/product', item.product.id]"><img class="mr-3" [src]="item.product?.mediaCollection[0].default" alt="Generic placeholder image"></a> -->

            <a [routerLink]="['product', item.id]">
              <img class="mr-3" [src]="item?.itemable.mediaCollection[0]?.large"
                alt="Generic placeholder image"></a>
            <div class="media-body">
              <a [routerLink]="['product', item.id]">
                <h4>{{ lang == 'ar' ? item.itemable.title['ar'] : item.itemable.title['En'] }}</h4>
              </a>
              <h4><span>{{ item.quantity }} x {{ item.itemable.price }} {{'SAR'|translate}}</span></h4>
            </div>
          </div>
          <div class="close-circle">
            <a (click)="removeItem(item)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>{{'subtotal'|translate}} : <span>{{ total }} {{'SAR'|translate}}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a (click)="navigateToCart()" class="view-cart">{{'viewcartbtn'|translate}}</a>
            <a [routerLink]="'/payments/checkout'" class="checkout">{{'checkout'|translate}}</a>
          </div>
        </li>
      </ul>
    </li>

    <li class="onhover-div mobile-cart notification-div" *ngIf="isLogged || fromLocal=='true'">
      <div>
        <!-- <a> -->
        <!-- <img src="assets/images/icon/notification.png" class="img-fluid notification_img" alt=""> -->
        <i class="fa fa-bell desktop-icon"></i>
        <!-- </a> -->
      </div>
      <!-- <ul class="show-div shopping-cart" *ngIf='!notification_count'>
        <h5>{{'notificationempty'|translate}}</h5>
      </ul> -->
      <span class="cart_qty_cls">{{notification_count}}</span>
      <ul class="show-div shopping-cart">
        <ul class="nav nav-tabs d-flex  border-0" id="myTab" role="tablist">
          <li class="nav-item mx-1 notification-style" role="presentation">
            <a class="nav-link active" id="myNotification-tab" data-toggle="tab" href="#myNotification" role="tab"
              aria-controls="myNotification" aria-selected="true">{{'mynotification'|translate}}</a>
          </li>

          <li class="nav-item mx-1 notification-style" role="presentation">
            <a class="nav-link" id="SysNotification-tab" data-toggle="tab" href="#SysNotification" role="tab"
              aria-controls="SysNotification" aria-selected="false">{{'systemnotification'|translate}}</a>
          </li>

        </ul>

        <div class="tab-content mt-2" id="myTabContent">

          <div class="tab-pane fade show active" id="myNotification" role="tabpanel"
            aria-labelledby="myNotification-tab">

            <!-- Start tab content -->
            <div class="sets row mx-0">

              <div>
                <a class="special">
                  <div class="card border-0 mx-3 pt-3">

                    <div>
                      <!-- <p class="card-text"><span>{{item?.data.title}}</span></p>
                  <p ><span>{{item?.data.body}}</span></p>  
                  <small>{{item?.created_at | date:"short"}} </small> -->
                      <ul>
                        <li *ngFor="let item of notifs" style="cursor: pointer;" (click)="openOrders()">

                          <div class="notifitication_section" id="notifi">
                
                            <!-- <p>{{item.data.title}}</p> -->
                            <p class="notification_msg theme-colored">{{item?.data.title}}</p>
                            <p class="notification_msg ">{{item?.data.body}}</p>
                            <!-- <p>Your Order ID is:- {{item.data.order_id}}</p> -->
                            <p class="notifications_date theme-colored">{{'withdate'|translate}} <span
                                class="span_colour">{{item?.created_at | date}}</span> </p>
                
                            <div class="close-circle">
                              <a (click)="deleteNotification(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                
                          </div>
                
                        </li>
                      </ul>
                    </div>
                  </div>

                </a>
              </div>
              <h2 *ngIf="notifications?.data.length == 0">
              {{'no notification'|translate}}
            </h2>
            </div>

            <!-- End tab content -->

          </div>
          <div class="tab-pane fade" id="SysNotification" role="tabpanel" aria-labelledby="SysNotification-tab">

            <!-- Start tab content -->
            <div class="sets row mx-0">

              <div>
                <a class="special">
                  <div class="card border-0 mx-3">

                    <div>
                      <!-- <p class="card-text"><span>{{item?.title}}</span></p>
                  <p ><span>{{item?.body}}</span></p>  
                  <small>{{item?.created_at | date:"short"}} </small> -->
                  
                        <ul>
                          <li *ngFor="let item of adminNotification" style="cursor: pointer;" (click)="openOrders()">

                            <div class="notifitication_section" id="notifi">
                  
                              <!-- <p>{{item.data.title}}</p> -->
                              <p class="notification_msg theme-colored">{{item?.title}}</p>
                              <p class="notification_msg">{{item?.body}}</p>
                              <!-- <p>Your Order ID is:- {{item.data.order_id}}</p> -->
                              <p class="notifications_date theme-colored">{{'withdate'|translate}} <span
                                  class="span_colour">{{item?.created_at | date}}</span> </p>
                  
                  
                  
                              <div class="close-circle">
                                <a (click)="deleteNotification(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                              </div>
                  
                            </div>
                  
                          </li>
                        </ul>
                  
                    </div>
                  </div>

                </a>
              </div>
              <h2 *ngIf="adminNotification?.length == 0">
              {{'no notification'|translate}}
            </h2>
            </div>

            <!-- End tab content -->

          </div>


        </div>
        


      </ul>
    </li>


  </ul>
</div>
<!-- Search -->
<div id="search-overlay" class="search-overlay" *ngIf="show" [ngStyle]="{ 'display': 'block' }">
  <div>
    <span class="closebtn" title="Close Overlay" (click)="closeSearch()">×</span>
    <div class="overlay-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <form>
              <div class="form-group">
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
              </div>
              <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>