<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Sales Report Details" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <form [formGroup]="filterForm">
          <div class="dates">
            <mat-form-field appearance="outline">
              <mat-label>{{ "Choose Type" | translate | titlecase }}</mat-label>
              <mat-select
                (selectionChange)="onChange($event)"
                formControlName="status"
              >
                <mat-option selected disabled [value]="null">
                  {{ "Choose Type" | translate }}
                </mat-option>
                <mat-option [value]="'online'">
                  {{ "onlineOrders" | translate }}
                </mat-option>
                <mat-option [value]="'instore'">{{
                  "instore" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-select
                multiple
                [placeholder]="'choose user' | translate"
                (selectionChange)="onItemSelect($event)"
                [(ngModel)]="selectedItems"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-form-field class="search-select">
                  <input
                    matInput
                    placeholer="Search"
                    (input)="onFilterChange($event.target.value)"
                  />
                </mat-form-field>
                <mat-option
                  *ngFor="let user of users"
                  [value]="user"
                  (click)="onClick($event)"
                  [id]="user.id"
                  >{{ user.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <!-- <div class="col-md-3 res">
              <select
                class="form-control"
                (change)="onChangeMethod($event)"
                formControlName="status"
              >
                <option selected disabled [value]="null">
                  {{ "Choose payment" | translate }}
                </option>
                <option [value]="'cash'">{{ "Cash" | translate }}</option>
                <option [value]="'wallet'">{{ "Wallet" | translate }}</option>
                <option [value]="'credit'">{{ "credit" | translate }}</option>
              </select>
            </div> -->
          </div>
          <div class="dates">
            <mat-form-field appearance="outline">
              <mat-label>{{ "Choose Starting date" | translate }}</mat-label>
              <input
                matInput
                formControlName="start"
                [matDatepicker]="picker"
                (dateChange)="getStartDate('change', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ "Choose Ending date" | translate }}</mat-label>
              <input
                matInput
                formControlName="end"
                [matDatepicker]="picker2"
                (dateChange)="getEndDate('change', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >

            <div class="btns">
              <button (click)="reset()" class="btn btn-success mx-2">
                {{ "Reset" | translate }}
              </button>

              <button (click)="download()" class="btn btn-success">
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>
        <mat-spinner *ngIf="!isLoading"></mat-spinner>

        <div class="info" *ngIf="visible">
          <div class="row">
            <div class="col-md-3">
              <img src="assets/images/sahlalogo.png" width="100" height="100" />
            </div>
            <div class="col-md-7"></div>
            <div class="col-md-2">
              <p class="align-middle" style="margin-top: 60px">Penta Value</p>
            </div>
          </div>
        </div>

        <!-- <div style="overflow-x:auto;" id="element">
                <table class="table" [ngClass]="visible?'top-margin':''" *ngIf="orders.length > 0">
                  <thead>
                    <tr>
                      <th scope="col">{{'id' | translate}}</th>
                      <th scope="col">{{'orderTime' | translate}}</th>
                      <th scope="col">{{'Amount' | translate}}</th>
                      <th scope="col">{{'payment1' | translate}}</th>
                      <th scope="col">{{'areaName' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of orders | paginate : config">
                      <th [ngClass]="lang == 'ar'?'arb':'eng'">{{order.id}}</th>
                      <td>{{order.created_at | date:'short'}}</td>
                      <td>{{order.total}}</td>
                      <td>
                        <span>{{order.payment_method[1]}}</span>
                        <span *ngIf="order.payment_method[2]">, {{order.payment_method[2]}}</span>
                      </td>
                      <td>{{order.shipping_address}}</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->

        <app-table
          [reports]="orders"
          [fields]="displayedColumns"
          [length]="totalCount"
          [perPage]="perPage"
          [config]="config"
          [currentPage]="page"
          (eventFromChild)="onChangePage($event)"
          *ngIf="isLoading"
        ></app-table>

        <div class="" *ngIf="orders?.length == 0 && isLoading">
          {{ "noOrders" | translate }}
        </div>
        <div
          class="footer"
          style="margin: 530px auto 0px; width: 22%"
          *ngIf="visible"
        >
          <a
            href="https://pentavalue.com/en"
            class="text-center"
            style="text-align: center"
            >Developed By PentaValue</a
          >
        </div>

        <!-- <div class="text-center" *ngIf="orders?.length == 0">
                {{'NO_ORDERS' | translate}}
            </div> -->

        <!-- <div class="row">
              <div class="w-100 pagination mt-3 text-center" *ngIf="orders?.length > 0">
                <mat-paginator 
                  class="w-100"
                  (page)="handlePageEvent($event)"
                  [length]="totalCount"
                  [pageSize]="perPage"
                  [pageSizeOptions]="perPage">
                </mat-paginator>
              </div>
            </div> -->
      </div>
    </div>
  </div>
</div>
