<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>
    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Sales by Area" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="seperator"></div>
        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-md-3 res">
              <mat-form-field
                appearance="outline"
                style="background-color: white"
              >
                <!-- <mat-label>{{ "select status" | translate }}</mat-label> -->
                <mat-select
                  (selectionChange)="changeSelection($event)"
                  [(ngModel)]="selectedID"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option [value]="1">
                    {{ "last 7 days" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "last 30 days" | translate }}
                  </mat-option>

                  <mat-option [value]="3">
                    {{ "Current month" | translate }}
                  </mat-option>
                  <mat-option [value]="4">
                    {{ "Previous month" | translate }}
                  </mat-option>
                  <mat-option [value]="5">
                    {{ "Last 3 months" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >
            <div class="btns">
              <button (click)="reset()" class="btn btn-success">
                {{ "Reset" | translate }}
              </button>
              <button
                (click)="download()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="text-desc-content-card" *ngIf="isLoading">
          <div class="header-container">
            <div class="left-heading">
              {{ "area" | translate }}
            </div>
            <div class="right-heading">
              {{ "Order amount / Revenue" | translate }}
            </div>
          </div>
          <div class="card" *ngFor="let item of data">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                  <h3 *ngIf="lang == 'ar'">{{ item?.area?.name?.ar }}</h3>
                  <h3 *ngIf="lang == 'en'">{{ item?.area?.name?.en }}</h3>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-xs-6 half-width"
                  style="text-align: end"
                >
                  <p class="order-count">
                    {{
                      ("Order amount" | translate) + ": " + item?.orders_count
                    }}
                  </p>
                  <span class="total-sales">{{
                    ("Revenue" | translate) +
                      ": " +
                      (item?.total_sales | number)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="data.length == 0 && isLoading">
          <div class="card-body">
            {{ "No Area" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
