<section class="prod-cards">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12 crad-container-new" *ngFor="let product of products" style="margin-bottom: 11px;">
                
                <div class="card border-20" (click)="openProductDetails(product?.id)">
                    <div class="card-img">

                        <img [src]="product?.mediaCollection[0]?.default" class="card-img-top" alt="" style="height:219px">
                    </div>
                    <div class="card-body">
                      <bar-rating [rate]="5" [readOnly]="true" style="text-align: center !important;"></bar-rating>

                      <h4 class="card-title text-left" *ngIf="defaultLang == 'en'">{{product?.title.en}} </h4>
                      <h4 class="card-title text-right" *ngIf="defaultLang == 'ar'">{{product?.title.ar}} </h4>
                      <p class="card-text" *ngIf="defaultLang == 'en'" title="{{product?.content.En}}">{{product?.content.en}}</p>
                      <p class="card-text" *ngIf="defaultLang == 'ar'" title="{{product?.content.ar}}">{{product?.content.ar}}</p>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 view-all text-center">
                <a (click)="openProducts()" >{{"VIEW_ALL"|translate}}</a>
            </div>
        </div>
    </div>
</section>

<!-- <section class="section-b-space">
    <div class="full-box   no-padding">
        <div class="container">
            <div class="row special-products-new">
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:2:4">
                                    <app-product-box-vertical [product]="product"></app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 center-slider">
                    <div>
                        <div class="offer-slider">
                            <div>
                                 <div class="product-box product-wrap" *ngFor="let product of products | slice:1:2">

                                    <app-product-boxx [product]="product"></app-product-boxx>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:3:6">
                                    <app-product-box-vertical [product]="product"></app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 view-all text-center">

                    <a class="btn btn-outline" [routerLink]="['/products']" >{{"VIEW_ALL"|translate}}</a>
                    </div>
            </div>
        </div>
    </div>
</section> -->
