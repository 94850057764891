                <!-- ************Start Instore Page*************** -->
                <section class="instore-page">
                    <div class="container">
                        <div class="row">
                            <!-- <div class="col-md-3">
                                <div class="filter-col">
                                    <h4>
                                        Filter by location
                                    </h4>
    
                                    <div class="filteration-list">
                                        <div id="accordion">
                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h5 class="mb-0">
                                                        <button class="btn btn-link" data-toggle="collapse"
                                                            data-target="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            Cairo
                                                        </button>
                                                    </h5>
                                                </div>
    
                                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                                    data-parent="#accordion">
                                                    <div class="card-body p-0">
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios1" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios1">
                                                                Nasr City
                                                            </label>
                                                        </div>
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios2" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios2">
                                                                Nwe Cairo
                                                            </label>
                                                        </div>
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios3" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios3">
                                                                Madinaty
                                                            </label>
                                                        </div>
    
                                                    </div>
                                                </div>


                                                <div class="card-header" id="headingTwo">
                                                    <h5 class="mb-0">
                                                        <button class="btn btn-link" data-toggle="collapse"
                                                            data-target="#collapseTwo" aria-expanded="true"
                                                            aria-controls="collapseTwo">
                                                            Alexandria
                                                        </button>
                                                    </h5>
                                                </div>
    
                                                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                                    data-parent="#accordion">
                                                    <div class="card-body p-0">
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios1" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios1">
                                                                Miami
                                                            </label>
                                                        </div>
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios2" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios2">
                                                                Montazah
                                                            </label>
                                                        </div>
    
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio"
                                                                name="exampleRadios" id="exampleRadios3" value="option1"
                                                                checked>
                                                            <label class="form-check-label" for="exampleRadios3">
                                                                Manshya
                                                            </label>
                                                        </div>
    
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
    
                                    </div>

                                </div>
                               
                            </div> -->

                            <div class="col-md-9">
                                <!-- <h3 class="page-title">
                                    In-store offers

                                </h3>
                                <span class="offers">
                                    Offers found
                                </span> -->

                                <div class="row" *ngIf="Merchants.length > 0">
                                    <div class="col-12 col-md-6 col-lg-4 " *ngFor="let merchant of Merchants">
                                   
                                   <a (click)="openPlace(merchant?.lat, merchant?.long)">
                                        <div class="InstoreOffer">
                                            <div class="InstoreOffer-card card">
                                                <div class="card-header">
                                                    <img
                                                    [src]="merchant?.brand_cover_image[0]?.default? merchant?.brand_cover_image[0]?.default: 'assets/images/sahlalogo.png'"
                                                      >
                                                    <div>
                                                        <div class="card-title h5">{{merchant.merchant_brand_title}}</div>
                                                        <p class="card-text">{{merchant?.merchant_brand_title}}</p>
                                                    </div>
                                                </div><img class="card-img"
                                                [src]="merchant?.brand_cover_image[0]?.default? merchant?.brand_cover_image[0]?.default: 'assets/images/sahlalogo.png'"
                                                    alt="">
                                                    
                                                <div class="card-body">
                                                    <p class="card-text">{{merchant?.phone}}</p>
                                                    <div class="discount-row">
                                                        <!-- <div class="discount"> Cashback</div> -->
                                                        <!-- <div><i class="far fa-star mr-2"></i>1 Favourites</div> -->
                                                    </div>
                                                    <div class="card-title h5">{{merchant?.cachback_percent}}% Cashback when you pay with Sahla</div>
                                                    <!-- <p class="card-text">Exp. 2022-06-22</p> -->
                                                </div>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </div>

                                <div class="row" *ngIf="Merchants.length == 0">
                                    <h2>{{'No Merchant Found' | translate}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>




                <!-- ************End Instore Page*************** -->

