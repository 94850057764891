import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
declare var $: any;

@Component({
  selector: 'app-categories-footer',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesFooterComponent implements OnInit {
  categories: any[];
  lang:string;

  constructor(private categoriesService: CategoriesService, private translate: TranslateService) { }
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang); 
    this.lang = this.translate.currentLang;
    // $.getScript('assets/js/menu.js');
     /* this.menuItems = MENUITEMS.filter(menuItem => menuItem); */
     this.categoriesService.getCategories().subscribe((res: any[]) => {
       this.categories = res['data'];

      //  console.log(this.categories)
     })

  }

  
} 
