import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { time } from "./time";

@Component({
  selector: "app-edit-opening-times",
  templateUrl: "./edit-opening-times.component.html",
  styleUrls: ["./edit-opening-times.component.scss"],
})
export class EditOpeningTimesComponent implements OnInit {
  id;
  time = time;
  days: any;
  lang;
  constructor(
    private router: Router,
    private http: HttpClientService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.id = this.router.url.split("/")[4];
    this.getWorkingDays();
  }
  getWorkingDays() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = { merchant_id: this.id };
    this.http
      .get("merchant/working_days", body, headers)
      .subscribe((res: any) => {
        this.days = res.working_days;
      });
  }
  updateDay(e, index) {
    this.days[index].is_opened = e.checked ? 1 : 0;
  }
  copyToAllDays(start, end) {
    this.days.forEach((element) => {
      element.opening_time = start;
      element.closing_time = end;
    });
  }
  save() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = { merchant_id: this.id, working_days: [...this.days] };
    this.http
      .post("merchant/working_days/update", body, {}, headers)
      .subscribe((res: any) => {
        this.days = res.working_days;
        // this.toaster.success(res["message"]);
        let routs: any[] = this.router.url.split("/");
        localStorage.setItem("merchantId", this.id);
        this.router.navigate([routs[1] + "/opening/", this.lang]);
      });
  }
}
