import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from "@angular/animations";
import { Product, ColorFilter, TagFilter } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import * as _ from 'lodash'
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { findIndex } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllproductsService } from 'src/app/shared/services/allproducts.service';



@Component({
  selector: 'app-collection-left-sidebar',
  templateUrl: './collection-left-sidebar.component.html',
  styleUrls: ['./collection-left-sidebar.component.scss'],
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})
export class CollectionLeftSidebarComponent implements OnInit {

  public products: Product[] = [];
  public items: Product[] = [];
  public allItems: Product[] = [];
  public sortByOrder: string = '';   // sorting
  categories: any[] = [];
  currentCriteria: any = {
    'categories': [],
    'rank':'',
    'area':'',
    'brand':'',
    'freeDelivery':'',
    'onlineOrder':true,
    'onlinePayment':'',
    'order':''
  }
  currentPage: number = 0;
  lastPage: number = 0;
  filteredProducts: any[] = [];
  totalItems: number = 0;
  fromItem: number = 0;
  lastKey = ''      // key to offset next query from
  finished = false  // boolean when end of data is reached
  rating = ["1","2","3", "4", "5"];
  oneCat = false;
  lang;
  customFields: any[] = [];
  isChecked: boolean =true;
  isBrandChecked: boolean = false;
  isCuisineChecked: boolean = false;
  isDeliveryChecked: boolean = false;
  isOnlineChecked: boolean = false;
  isOnlinePaymentChecked: boolean = false;
  isAreaChecked: boolean = false;

  customs:any[] = [];

  newsub;
  val = '';
  val1 = '';
  config: any;
  totalCount: number;
  perPage: number;
  page = 1;
  defaultChoice;
  collectionClass: string = '';
  layout1: boolean = false;
  layout2: boolean = false;
  brands:any[] = [];
  cuisines:any[] = [];
  areas:any[] = [];
  area_id:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private allProductsService: AllproductsService,
    private categoriesService: CategoriesService, 
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {
     
    }

  ngOnInit() {
    this.collectionClass = this.router.url.toString().split('/')[1];
    this.lang = localStorage.getItem("lang_ref");
    this.area_id = localStorage.getItem("area_id");
    this.updateArea(this.area_id);
    this.translate.use(this.lang);
    this.spinner.show();

    this.route.queryParams.subscribe(
      res=>{
        console.log('RES', res['categories']);
        if(!res['categories']){
          console.log('NONOON');
          this.filterProducts();
        }
      }
    );
    this.initLookups();
    this.getBrands();
    this.getCuisines();
    this.getAreas();

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount
    };

  }

  setLayout(layout: string){
    if(layout == 'layout1'){
      this.layout1 = true;
    }else if(layout == 'layout2'){
      this.layout2 = true;
      this.layout1 = false;
    }else{
      this.layout1 = false;
      this.layout2 = false;
    }
  }

  initLookups() {
    this.categoriesService.getCategories().subscribe(res => { 
      this.categories = res['categories'];
      
      this.route.queryParams.subscribe(res => {
        if (this.route.snapshot.queryParams['categories'] || this.route.snapshot.queryParams['rank'] || this.route.snapshot.queryParams['areas']) {
          let currentCatQueryParam = this.route.snapshot.queryParams['categories'] ? "categories" : this.route.snapshot.queryParams['rank'] ? "rank" : this.route.snapshot.queryParams['rank'] ? "areas" : "";
          let currentCatID = +this.route.snapshot.queryParams['categories'] || +this.route.snapshot.queryParams['subCategories'] || +this.route.snapshot.queryParams['subSubCategories'] || +this.route.snapshot.queryParams['rank'];
          let categoryId = +this.route.snapshot.queryParams['categories'];
          //categories=1,2&sub_categories=3,4,5&sub_sub_categories

          if (currentCatQueryParam == 'rank') {
            this.updaterate(this.route.snapshot.queryParams['rank']);
            this.val = this.route.snapshot.queryParams['rank'];
          }else if (currentCatQueryParam == 'areas') {
            this.updateArea(this.route.snapshot.queryParams['areas']);
            this.val = this.route.snapshot.queryParams['areas'];
          }else if (currentCatQueryParam == 'onlineOrder') {
            this.updateOnline(this.route.snapshot.queryParams['onlineOrder']);
            this.val = this.route.snapshot.queryParams['onlineOrder'];
          } else {
            this.isCatSelected('categories', categoryId);
            this.setCategory(currentCatQueryParam, currentCatID);
          }
        }
      })
      // console.log("the main categories",this.categories)
      // console.log("the sub categories",this.subCategories)
    })

    this.productsService.getCustomFields().subscribe(
      (res: any[])=>{
        console.log("The Custom Fields", res);
        let optionArray: any[] =[];
        res['fields'].forEach(element=>{
          // Two types radio and select
          if(element.type == "radio" && element.use_as_filter == 1){
            this.customFields.push(element);
          }
        });

        console.log("Customs Array", this.customFields);
      }
    );

  }

  getBrands(){
    this.categoriesService.getBrands().subscribe(
      (res:any)=>{
        this.brands = res;
      }
    );
  }

  getAreas(){
    this.categoriesService.getAreas().subscribe(
      res=>{
        this.areas = res['areas']
      }
    );
  }

  getCuisines(){
    this.categoriesService.getCuisines().subscribe(
      (res:any)=>{
        this.cuisines = res['cuisines'];
      }
    );
  }


  isCatSelected(query, catID) {
    return this.currentCriteria[query].findIndex(item => item == catID) > -1;
  }


  onChangeCat(cat) {
    let query = cat.level == 0 ? "categories" : (cat.level == 1 ? 'subCategories' : "subSubCategories")
    this.setCategory(query, cat.id);
  }

  // setCustomFields(customId, optionId){
  //   let x = '{' + customId + ':' + optionId +'}';
  //   let index = this.customs.findIndex(item=> item == x);
  //   if (index > -1) {
  //     this.customs.splice(index, 1);
  //   }else{
  //     this.customs.push(x);
  //   }
    
  //   this.setCategory('customFieldValue',this.customs);
  // }


  filterProducts() {
    this.spinner.show();
    console.log('The Current Criteria is:-', this.currentCriteria);
    this.finished = false;
    let criteria = {
      'categories': '',
      'rank':'',
      'area':'',
      'brand':'',
      'freeDelivery':'',
      'onlineOrder':'',
      'onlinePayment':'',
      'order':'',
      'page':this.page
    }
    // console.log(JSON.stringify(this.currentCriteria['categories']).replace('[','').replace(']',''));
    criteria['categories'] = JSON.stringify(this.currentCriteria['categories']).replace('[','').replace(']','');
    criteria['rank'] = this.currentCriteria['rank'];
    criteria['area'] = this.currentCriteria['area'];
    criteria['brand'] = this.currentCriteria['brand'];
    criteria['freeDelivery'] = this.currentCriteria['freeDelivery'];
    criteria['onlineOrder'] = this.currentCriteria['onlineOrder'];
    criteria['onlinePayment'] = this.currentCriteria['onlinePayment'];
    criteria['order'] = this.currentCriteria['order'];

    if(criteria['categories'] == null || criteria['categories'] == ''){
      delete criteria['categories'];
    }
    if(criteria['rank'] == null || criteria['rank'] == ''){
      delete criteria['rank'];
    }
    if(criteria['area'] == null || criteria['area'] == ''){
      delete criteria['area'];
    }
    if(criteria['brand'] == null || criteria['brand'] == ''){
      delete criteria['brand'];
    }
    if(criteria['freeDelivery'] == null || criteria['freeDelivery'] == ''){
      delete criteria['freeDelivery'];
    }
    if(criteria['onlineOrder'] == null || criteria['onlineOrder'] == ''){
      delete criteria['onlineOrder'];
    }
    if(criteria['onlinePayment'] == null || criteria['onlinePayment'] == ''){
      delete criteria['onlinePayment'];
    }if(criteria['order'] == null || criteria['order'] == ''){
      delete criteria['order'];
    }

    console.log('CriCri', criteria);
    let prods:any[] = [];
    this.allProductsService.getMerchantByCriteria(criteria).subscribe(res => {
      prods = res['merchants'].data;
      this.currentPage = res['merchants'].current_page;
      this.lastPage = res['merchants'].last_page;
      this.totalItems = res['merchants'].total;
      this.fromItem = res['merchants'].from;
      this.totalCount = res['merchants'].total;
      this.perPage = res['merchants'].per_page;

      console.log('Filtered Products', prods)
      this.filteredProducts = prods;
      this.updateConfig();
      this.finished = true;
      this.spinner.hide();
    },
    error=>{
      console.log('Error', error);
      this.spinner.hide();
    });

    this.filteredProducts = prods;
    console.log('Filtered Products', this.filteredProducts)
  }

  setCategory(query, catID, optionId?:any) {
    let catIndex = this.currentCriteria[query].findIndex(item => item == catID);
    this.page = 1;

    if (catIndex > -1) {
      this.currentCriteria[query].splice(catIndex, 1);
      this.categories.forEach(element => {
        console.log('Element', element)
      });
      
    } else {
      this.currentCriteria[query].push(catID);
    }

    this.filterProducts();
  }

  updateConfig(){
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount
    };
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    this.currentCriteria['priceFrom'] = price[0];
    this.currentCriteria['priceTo'] = price[1];
    this.filterProducts();
  }

  choose(event){
    console.log('Event', event);
  }

  updaterate(rank){
    this.page = 1;
    if(rank == ''){
      this.currentCriteria['rank']= '';
      this.isChecked = false;
      this.filterProducts();
    }
    else{
      if(this.currentCriteria['rank'] == rank){
        this.currentCriteria['rank']= '';
        this.isChecked = false;
      }else{
        this.isChecked = true;
        this.currentCriteria['rank']= rank;
        this.val = rank;
      }
      this.filterProducts();
    }
  }

  updateBrand(cuisine){
    this.page = 1;
    if(cuisine == ''){
      this.currentCriteria['brand']= '';
      this.isBrandChecked = false;
      this.filterProducts();
    }
    else{
      if(this.currentCriteria['brand'] == cuisine){
        this.currentCriteria['brand']= '';
        this.isBrandChecked = false;
      }else{
        this.isBrandChecked = true;
        this.currentCriteria['brand']= cuisine;
        // this.val = cuisine;
      }
      this.filterProducts();
    }
  }

  updateCuisine(cuisine){
    this.page = 1;
    if(cuisine == ''){
      this.currentCriteria['cuisine']= '';
      this.isCuisineChecked = false;
      this.filterProducts();
    }
    else{
      if(this.currentCriteria['cuisine'] == cuisine){
        this.currentCriteria['cuisine']= '';
        this.isCuisineChecked = false;
      }else{
        this.isCuisineChecked = true;
        this.currentCriteria['cuisine']= cuisine;
        // this.val = cuisine;
      }
      this.filterProducts();
    }
  }

  updateDelivery(cuisine){
    this.page = 1;
    if(cuisine == false){
      this.currentCriteria['freeDelivery']= '';
      this.isDeliveryChecked = false;
      this.filterProducts();
    }
    else{
        this.isDeliveryChecked = true;
        this.currentCriteria['freeDelivery']= 'true';
      this.filterProducts();
    }
  }

  updateOnline(cuisine){
    this.page = 1;
    if(cuisine == false){
      this.currentCriteria['onlineOrder']= '';
      this.isOnlineChecked = false;
      this.filterProducts();
    }
    else{
        this.isOnlineChecked = true;
        this.currentCriteria['onlineOrder']= 'true';
      this.filterProducts();
    }
  }

  updateOnlinePayment(cuisine){
    this.page = 1;
    if(cuisine == false){
      this.currentCriteria['onlinePayment']= '';
      this.isOnlinePaymentChecked = false;
      this.filterProducts();
    }
    else{
        this.isOnlinePaymentChecked = true;
        this.currentCriteria['onlinePayment']= 'true';
      this.filterProducts();
    }
  }

  updateArea(rank){
    this.page = 1;
    if(rank == ''){
      this.currentCriteria['area']= '';
      this.isAreaChecked = false;
      this.filterProducts();
    }
    else{
      if(this.currentCriteria['area'] == rank){
        this.currentCriteria['area']= '';
        this.isAreaChecked = false;
      }else{
        this.isAreaChecked = true;
        this.currentCriteria['area']= rank;
        this.val1 = rank;
      }
      this.filterProducts();
    }
  }

  // For mobile filter view
  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }

  setPages(currentPage, lastPage) {
    this.currentCriteria['page'] = currentPage;
    this.lastPage = lastPage;
  }

  // Infinite scroll
  public onScroll() {
    if (this.currentPage < this.lastPage) {
      this.currentPage++;
      this.setPages(this.currentPage, this.lastPage)
      // this.filterProducts()
    }
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    if(val == ''){
      this.currentCriteria['order']= '';
      // this.isChecked = false;
      this.filterProducts();
    }
    else{
      if(this.currentCriteria['order'] == val){
        this.currentCriteria['order']= '';
        this.isChecked = false;
      }else{
        this.isChecked = true;
        this.currentCriteria['order']= val;
      }
      this.filterProducts();
    }
  }

  // For mobile view
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

  onChangePage(event){
    this.config.currentPage = event;
    console.log("Event", event);
    this.page = event;
    this.filterProducts();
  }

  gotoProducts(){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/products', this.lang])
  }

}
