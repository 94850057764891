import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AboutService } from 'src/app/shared/services/about.service';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { Menu, MENUITEMS } from './navbar-items';

@Component({
  selector: 'app-navbar11',
  templateUrl: './navbar11.component.html',
  styleUrls: ['./navbar11.component.css']
})
export class Navbar11Component implements OnInit {


  public menuItems: Menu[];
  public toggleNavBar : boolean = false;
  categories: any[] = [];
  homeCategories: any[] = [];
  lang:string;
  ContactData;
  address_en;
  address_ar;

  email;
  phone: string;

  contactForm: FormGroup;
  location_name: string = '';

  constructor(private categoriesService: CategoriesService,
     private translate: TranslateService,
     private service: AboutService,
     private spinner: NgxSpinnerService,
     private toastrService: ToastrService,
     private router:Router) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 

    this.getData();


    ///////
    this.service.getStoreInfo().subscribe(res => {
      this.spinner.hide();

      this.ContactData = res;
      console.log("contactUs=> ", res)

      this.address_en = this.ContactData[7].content.en;
      this.address_ar = this.ContactData[7].content.ar;

      this.email = this.ContactData[5].content.en;
      this.phone = this.ContactData[6].content.en;
      // let result = this.phone.replace('<>', '');
      // this.phone = result;
      console.log('Logger',this.address_ar.replace('<p>','').replace('&nbsp;</p>', ''));
      this.location_name = this.address_ar.replace('<p>','').replace('&nbsp;</p>', '');

    }, error => {
      this.spinner.hide();
      if (this.lang == 'en') {
        this.toastrService.error("PLease Check your Internet Connection,and Try Again")
      } else {
        this.toastrService.error("من فضلك تأكد من وجود انترنت وحاول مرة أخرى")
      }

    })
  }

   //  start categories
   getData() {
    this.categoriesService.getCategories().subscribe((result: any[]) => {
      this.categories = result['categories'];
      console.log("  this.categories  ========>>>>>",  this.categories )
      for (let index = 0; index < result['categories'].length; index++) {
        const category = result['categories'][index];
        const dataToPush = {
          title: category.name.En,
          // type: category.sub_categories.length > 0 ? "sub" : "link",
          type:  "link",

         
        };
        if (dataToPush.type === "link") {
          dataToPush["path"] = `/category/${category.id}`;
        }
        // this.menuItems.push(dataToPush);
        // console.log("menu items",this.menuItems)
      }
      // setTimeout(this.intializeSmartMenue, 1000);
    });
  }

  toggleNav() {
    this.toggleNavBar = !this.toggleNavBar
  }

  navigateCategory(cat) {
    if (cat.is_leaf || cat.is_leaf == null) {
      let query = cat.level == 1 ? "categories" : (cat.level == 2 ? 'subCategories' : "subSubCategories")
      this.router.navigate(['/home_eleven/category', this.lang], {
        queryParams: {
          [query]: cat.id
        }
      })
    } else {
      let query = cat.level == 1 ? "cat_id" : (cat.level == 2 ?"sub_cat_id":"sub_sub_cat_id");
      this.router.navigate(['/home_eleven/categories', this.lang], {
        queryParams: {
          [query]: cat.id
        }
      })
    }
  }

}
