import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import {
  PayPalConfig,
  PayPalEnvironment,
  PayPalIntegrationType,
} from "ngx-paypal";
// import {  IPayPalConfig,  ICreateOrderRequest } from 'ngx-paypal';
import { CartItem } from "../../../shared/classes/cart-item";
import { ProductsService } from "../../../shared/services/products.service";
import { CartService } from "../../../shared/services/cart.service";
import { OrderService } from "../../../shared/services/order.service";
import { TranslateService } from "@ngx-translate/core";
import { CheckoutService } from "../../services/checkout.service";
import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { element } from "protractor";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HomeService } from "../../services/home.service";
declare var $: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  baseURL = environment.base;
  // form group
  public checkoutForm: FormGroup;
  public cartItems: any[] = [];
  public checkOutItems: CartItem[] = [];
  public orderDetails: any[] = [];
  public amount: number;
  public payPalConfig?: PayPalConfig;
  shoppingCartItems;
  check_login: string = '';
  lang;
  allCities;
  selectedvalue;
  paymentMethod: string = "online";
  formdata = new FormData();
  checkout = false;
  isValid: boolean = true;
  bankTransferNote;
  note:any;
  is_shippable: Boolean = false;
  is_shippable_info: Boolean = false;
  filename: any;
  uploaded_file: boolean;
  file_url: string;
  image_url: any;
  bankInfo: any = {bank_name:'', bank_account_number:''};
  total:any;
  transaction_id:any;
  subTotal: any;
  deliveryFees: any;
  vat: any;
  cashback: any;
  showBank: boolean = false;
  showWallet: boolean = false;
  user:any;
  walletAmount: any;
  method:any;
  merchantName:any;
  merchantStatus:any;
  // Form Validator
  constructor(
    private fb: FormBuilder,
    private cartService: CartService,
    public productsService: ProductsService,
    private orderService: OrderService,
    private checkoutService: CheckoutService,
    private authService: AuthService,
    private homeService: HomeService,
    private translate: TranslateService,
    private toast: ToastrService,
    private router: Router,
    private httpClientM: HttpClient
  ) {}

  ngOnInit() {
    // this.cartService.getItems().subscribe(
    //   res=>{
    //     this.shoppingCartItems = res['cart'].product_items;
    //     this.total = res['cart'].total_price;
    //   }
    // );
    this.check_login = localStorage.getItem("token") || ''; 
    console.log("shoppingCart_ => ", this.shoppingCartItems);

    this.initForm();

    this.method = localStorage.getItem('paymentMethod');
    this.getPaymentMethod(this.method);
    $(".loader-wrapper").fadeOut("slow", function () {
      console.log("its ready");
      $(this).remove();
    });

    this.cartService.getItems().subscribe(
      res=>{
        this.cartItems = res['cart'].product_items;
        this.total = res['cart'].total;
        this.subTotal = res['cart'].sub_total;
        this.deliveryFees = res['cart'].delivery_fees;
        this.vat = res['cart'].tax;
        this.cashback = res['cart'].cashback;
        this.merchantName = res['cart'].merchant.name;
        this.merchantStatus = res['cart'].merchant.status;

        this.cartItems.forEach((element) => {
          if (element.itemable.is_shippable == 1) {
            this.is_shippable = true;
            console.log('is Shipple', this.is_shippable)
          }
        });
      }
    );
    console.log("Cart Items==========>>>>",this.cartItems)
    console.log("test test test=> ", this.cartItems["is_shippable"]);
    
    // this.cartItems.subscribe(products => this.checkOutItems = products);
    // this.getTotal().subscribe(amount => this.amount = amount);

    this.initConfig();

    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    this.checkoutService.getcities().subscribe((res) => {
      this.allCities = res["cities"];
      // console.log(res);
      // console.log("the cities :",this.allCities);
    });

    

    this.cartItems.forEach((element, i) => {
      // console.log("element=> ",element);
      let id = element.id;
      // console.log("index=> ",i);
      // this.formdata.append(`products[${i}][id]`,element.id)
      // this.formdata.append(`products[${i}][quantity]`,element.quantity)
    });

    this.getBankInfo();

    if(this.check_login != '' || this.check_login != null){
      this.user = JSON.parse(localStorage.getItem('userObject'));
      if(this.user){
        let name = this.user.name.split(" ")
        this.checkoutForm.patchValue({
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          mobile: this.user.mobile,
          email: this.user.email,
          shipping_address: this.user.default_address?.area?.name?.ar + ' ' + this.user.default_address?.building_number,
          shipping_city: this.user.default_address?.city_id,
          payment_method: this.method
        });
      }
      
    }else{
      this.checkoutForm.patchValue({
        first_name: '',
        last_name: '',
        mobile: '',
        email: ''
      });
    }
  }

  initForm() {
    this.checkoutForm = new FormGroup({
      first_name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[\u0621-\u064Aa-zA-Z]+$"),
      ]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.pattern("^[\u0621-\u064Aa-zA-Z]+$"),
      ]),
      mobile: new FormControl("", [
        Validators.required,
      ]),

      // shipping_first_name :new FormControl('', [Validators.required, Validators.maxLength(50)]),
      // shipping_last_name: new FormControl('', [Validators.required]),
      // shipping_company: new FormControl('', [Validators.required]),
      email: new FormControl("",[Validators.email,Validators.required]),
      shipping_address: new FormControl("", [Validators.required]),
      // payment_transaction_id: new FormControl(""),
      shipping_city: new FormControl("", [Validators.required]),
      comment: new FormControl(""),
      payment_method: new FormControl(""),
      bank_recepit: new FormControl()
    });
  }

  getselectvalue(ref, event) {
    this.selectedvalue = event.target.value;
    let numberValue = parseInt(this.selectedvalue);
    // if(ref == 'currency'){this.checkoutForm.get('currency').setValue(numberValue)}
    // if(ref == 'Payment_city'){this.checkoutForm.get('Payment_city').setValue(numberValue)}
    if (ref == "Shipping_city") {
      this.checkoutForm.get("shipping_city").setValue(numberValue);
    }
  }

  // Get sub Total
  // public getTotal(): number {
  //   return this.cartService.getTotalAmount();
  // }

  //get bank info
  getBankInfo(){
    this.homeService.getConfig().subscribe(
      (res: any[])=>{
        console.log('Global settings', res);
        res.forEach(item=>{
          if(item.name == "bank_name"){
            this.bankInfo['bank_name'] = item.value;
          }
          if(item.name == "bank_account_number"){
            this.bankInfo['bank_account_number'] = item.value;
          }

        });

        console.log("the Bank Info", this.bankInfo);
      }
    );
  }

  // stripe payment gateway
  stripeCheckout() {
    var handler = (<any>window).StripeCheckout.configure({
      key: "PUBLISHBLE_KEY", // publishble key
      locale: "auto",
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.orderService.createOrder(
          this.checkOutItems,
          this.checkoutForm.value,
          token.id,
          this.amount
        );
      },
    });
    handler.open({
      name: "remaad",
      description: "Online  Store",
      amount: this.amount * 100,
    });
  }

  submitOrder() {
    this.isValid = false;
    let products = [];
    this.checkoutForm.value.comment = this.note;
    console.log("shoppingCart => ", this.cartItems);
    this.cartItems.forEach((element) => {
      if (element.itemable.is_shippable == 1) {
        this.is_shippable_info = true;
      }
      let obj = {};
      obj["id"] = element.itemable.id;
      obj["quantity"] = element.quantity;
      products.push(obj);
    });
    // if(this.checkoutForm.get('payment_transaction_id').value == 1){
    //   this.checkoutForm.get('payment_method').setValue('credit');
    // }else if(this.checkoutForm.get('payment_transaction_id').value == 2){
    //   this.checkoutForm.get('payment_method').setValue('bank_deposit');
    // }else if(this.checkoutForm.get('payment_transaction_id').value == 3){
    //   this.checkoutForm.get('payment_method').setValue('cash');
    // }
    var body = {
      ...{products: products},
      ...this.checkoutForm.value
      
    };
    console.log("formValue=> ",body);
    console.log("cartItems=> ", this.cartItems);
    console.log("token=> ", this.check_login);

    if(body.payment_method == ""){
      if(this.lang == 'en'){
        this.toast.error('Please Choose a payment Method to Complete Process');
      }else{
        this.toast.error('من فضلك قم باختيار وسيلة دفع لاكمال العملية');
      }
      
    }
    if (this.check_login == null) {
      if (
        this.checkoutForm.value.first_name &&
        this.checkoutForm.value.last_name &&
        this.checkoutForm.value.mobile
      ) {
        if (this.paymentMethod == "credit") {
          var body1 = {
            ...{ products: products },
            ...this.checkoutForm.value
          };
          let formData = new FormData();

          for(let i=0; i< products.length; i++){
            formData.append('products['+i+'][id]', products[i]['id']);
            formData.append('products['+i+'][quantity]', products[i]['quantity']);
          }
          for (var key in this.checkoutForm.value) {
            if(this.checkoutForm.value[key] == 'credit'){
              formData.append('payment_method['+0+']', 'credit');
            }else{
              formData.append(key, this.checkoutForm.value[key]);
            }
            
          }
          console.log("tokeeen-> ", this.authService.getToken());
          this.checkoutService.postOrder2(formData).subscribe((response: any) => {
            console.log("res=> ", response);
            var paymentUrl = this.baseURL + "/paymentRequest";
            var fr =
              '<form action="' +
              paymentUrl +
              '" method="post">' +
              '<input type="hidden" name="checkoutId" value="' +
              response.checkoutId +
              '" />' +
              '<input type="hidden" name="responseUrl" value="' +
              response.responseUrl +
              '" />' +
              '<input type="hidden" name="paymentMethods" value="' +
              response.paymentMethods +
              '" />' +
              "</form>";
            var form = $(fr);
            $("body").append(form);
            form.submit();
            this.cartService.emptyCart();
            this.cartService.cartUpdated.emit();
            this.cartService.getItems();
          },error=>{
            console.log(error);
            this.toast.error(error.error.message)
            if(error.error.errors.first_name){
              this.toast.error(error.error.errors.first_name);
            }
            if(error.error.errors.last_name){
              this.toast.error(error.error.errors.last_name);
            }
            if(error.error.errors.mobile){
              this.toast.error(error.error.errors.mobile);
            }
          });
        } else if(this.paymentMethod == "bank_deposit") {
          var body1 = {
            ...{ products: products },
            ...this.checkoutForm.value
          };
          let formData = new FormData();
          // body1['products'].forEach(element => {
          //   formData.append('products[0][id]', element['id']);
          //   formData.append('products[0][quantity]', element['quantity']);
          // });

          for(let i=0; i< products.length; i++){
            formData.append('products['+i+'][id]', products[i]['id']);
            formData.append('products['+i+'][quantity]', products[i]['quantity']);
          }
          for (var key in this.checkoutForm.value) {
            if(this.checkoutForm.value[key] == 'bank_deposit'){
              formData.append('payment_method['+0+']', 'bank_deposit');
            }else{
              formData.append(key, this.checkoutForm.value[key]);
            }
            
          }
          formData.append('bank_recepit', this.image_url);
          console.log("=======>>>====Products to Order", formData);
          this.checkoutService.postOrder2(formData).subscribe((response: any) => {
            console.log(response);
            if(this.lang == 'en'){
              this.toast.success("Payment Success");
            }else{
              this.toast.success("عملية دفع ناجحة");
            }
            this.cartService.emptyCart();
            this.cartService.cartUpdated.emit();
            this.cartService.getItems();
            let routs:any[] = this.router.url.split('/');
            this.isValid = true;
            this.router.navigate([routs[1] + '/', this.lang]);
          },
          error=>{
            console.log(error);
            this.toast.error(error.error.message)
            if(error.error.errors.first_name){
              this.toast.error(error.error.errors.first_name);
            }
            if(error.error.errors.last_name){
              this.toast.error(error.error.errors.last_name);
            }
            if(error.error.errors.mobile){
              this.toast.error(error.error.errors.mobile);
            }
          });
        }else if(this.paymentMethod == "cash") {
          var body1 = {
            ...{ products: products },
            ...this.checkoutForm.value
          };
          let formData = new FormData();
          // body1['products'].forEach(element => {
          //   formData.append('products[0][id]', element['id']);
          //   formData.append('products[0][quantity]', element['quantity']);
          // });

          for(let i=0; i< products.length; i++){
            formData.append('products['+i+'][id]', products[i]['id']);
            formData.append('products['+i+'][quantity]', products[i]['quantity']);
          }
          for (var key in this.checkoutForm.value) {
            if(this.checkoutForm.value[key] == 'cash'){
              formData.append('payment_method['+0+']', 'cash');
            }else{
              formData.append(key, this.checkoutForm.value[key]);
            }
            
          }
          console.log("=======>>>====Products to Order", formData);
          this.checkoutService.postOrder2(formData).subscribe((response: any) => {
            console.log(response);
            if(response){
              if(this.lang == 'en'){
                this.toast.success("Payment Success");
              }else{
                this.toast.success("عملية دفع ناجحة");
              }
              this.cartService.emptyCart();
              this.cartService.cartUpdated.emit();
              this.cartService.getItems();
              let routs:any[] = this.router.url.split('/');
              this.isValid = true;
              this.router.navigate([routs[1] + '/', this.lang]);
            }else{
                this.toast.error(response.message);
            }
          },
          error=>{
            console.log(error);
            this.toast.error(error.error.message)
            if(error.error.errors.first_name){
              this.toast.error(error.error.errors.first_name);
            }
            if(error.error.errors.last_name){
              this.toast.error(error.error.errors.last_name);
            }
            if(error.error.errors.mobile){
              this.toast.error(error.error.errors.mobile);
            }
          });
        }
      } else {
        this.toast.error(
          "Please Enter First and Last name and your Mobile Number"
        );
      }
    } else {
      if (this.paymentMethod == "credit") {

        var body1 = {
          ...{ products: products },
          ...this.checkoutForm.value
        };
        let formData = new FormData();

        for(let i=0; i< products.length; i++){
          formData.append('products['+i+'][id]', products[i]['id']);
          formData.append('products['+i+'][quantity]', products[i]['quantity']);
        }
        for (var key in this.checkoutForm.value) {
          if(this.checkoutForm.value[key] == 'credit'){
            formData.append('payment_method['+0+']', 'credit');
          }else{
            formData.append(key, this.checkoutForm.value[key]);
          }
          
        }
        console.log('Body', body);
        console.log("tokeeen-> ", this.authService.getToken());
        this.checkoutService.postOrder2(formData).subscribe((response: any) => {
          console.log("res=> ", response);
          var paymentUrl = this.baseURL + "/paymentRequest";
          var fr =
            '<form action="' +
            paymentUrl +
            '" method="post">' +
            '<input type="hidden" name="checkoutId" value="' +
            response.checkoutId +
            '" />' +
            '<input type="hidden" name="responseUrl" value="' +
            response.responseUrl +
            '" />' +
            '<input type="hidden" name="paymentMethods" value="' +
            response.paymentMethods +
            '" />' +
            "</form>";
          var form = $(fr);
          $("body").append(form);
          form.submit();
          this.cartService.emptyCart();
          this.cartService.cartUpdated.emit();
          this.cartService.getItems();
        },
        error=>{
          console.log(error);
          this.toast.error(error.error.message);
          if(error.error.errors.first_name){
            this.toast.error(error.error.errors.first_name);
          }
          if(error.error.errors.last_name){
            this.toast.error(error.error.errors.last_name);
          }
          if(error.error.errors.mobile){
            this.toast.error(error.error.errors.mobile);
          }

        }
        );
      } else if(this.paymentMethod == "bank_deposit") {
        var body1 = {
          ...{ products: products },
          ...this.checkoutForm.value
        };
        let formData = new FormData();
        // body1['products'].forEach(element => {
        //   formData.append('products[0][id]', element['id']);
        //   formData.append('products[0][quantity]', element['quantity']);
        // });

        for(let i=0; i< products.length; i++){
          formData.append('products['+i+'][id]', products[i]['id']);
          formData.append('products['+i+'][quantity]', products[i]['quantity']);
        }

        for (var key in this.checkoutForm.value) {
          if(this.checkoutForm.value[key] == 'bank_deposit'){
            formData.append('payment_method['+0+']', 'bank_deposit');
          }else{
            formData.append(key, this.checkoutForm.value[key]);
          }
        }
        formData.append('bank_recepit', this.image_url);
        console.log("=======>>>====Products to Order", body1['products']);
        this.checkoutService.postOrder2(formData).subscribe((response: any) => {
          console.log(response);
          if(response){
            if(this.lang == 'en'){
              this.toast.success("Payment Success");
            }else{
              this.toast.success("عملية دفع ناجحة");
            }
            this.cartService.emptyCart();
            this.cartService.cartUpdated.emit();
            this.cartService.getItems();
            let routs:any[] = this.router.url.split('/');
            this.isValid = true;
            this.router.navigate([routs[1] + '/', this.lang]);
          }else{
              this.toast.error(response.message);
          }
        },
        error=>{
          console.log(error);
          // this.toast.error(error.error.message)
          // if(error.error.errors.first_name){
          //   this.toast.error(error.error.errors.first_name);
          // }
          // if(error.error.errors.last_name){
          //   this.toast.error(error.error.errors.last_name);
          // }
          // if(error.error.errors.mobile){
          //   this.toast.error(error.error.errors.mobile);
          // }
        });
      } else if(this.paymentMethod == "cash") {
        var body1 = {
          ...{ products: products },
          ...this.checkoutForm.value
        };
        let formData = new FormData();
        // body1['products'].forEach(element => {
        //   formData.append('products[0][id]', element['id']);
        //   formData.append('products[0][quantity]', element['quantity']);
        // });

        for(let i=0; i< products.length; i++){
          formData.append('products['+i+'][id]', products[i]['id']);
          formData.append('products['+i+'][quantity]', products[i]['quantity']);
        }

        for (var key in this.checkoutForm.value) {
          if(this.checkoutForm.value[key] == 'cash'){
            formData.append('payment_method['+0+']', 'cash');
            // formData.append('payment_method['+1+']', 'wallet');
          }else{
            formData.append(key, this.checkoutForm.value[key]);
          }
        }
        
        this.checkoutService.postOrder2(formData).subscribe((response: any) => {
          console.log(response);
          if(response){
            if(response.success == false){
              this.toast.error(response.message);
            }else if(response){
              if(this.lang == 'en'){
                this.toast.success("Payment Success");
              }else{
                this.toast.success("عملية دفع ناجحة");
              }
              this.cartService.emptyCart();
              this.cartService.cartUpdated.emit();
              this.cartService.getItems();
              let routs:any[] = this.router.url.split('/');
              this.isValid = true;
              this.router.navigate([routs[1] + '/orders', this.lang]);
            }
          }else{
              this.toast.error(response.message);
          }
        },
        error=>{
          if(error.error.message){
            console.log(error.error.message);
            this.toast.error(error.error.message);
          }
          
          
          // this.toast.error(error.error.message)
          // if(error.error.errors.first_name){
          //   this.toast.error(error.error.errors.first_name);
          // }
          // if(error.error.errors.last_name){
          //   this.toast.error(error.error.errors.last_name);
          // }
          // if(error.error.errors.mobile){
          //   this.toast.error(error.error.errors.mobile);
          // }
        });
      }else if(this.paymentMethod == "wallet") {
        var body1 = {
          ...{ products: products },
          ...this.checkoutForm.value
        };
        let formData = new FormData();
        // body1['products'].forEach(element => {
        //   formData.append('products[0][id]', element['id']);
        //   formData.append('products[0][quantity]', element['quantity']);
        // });

        for(let i=0; i< products.length; i++){
          formData.append('products['+i+'][id]', products[i]['id']);
          formData.append('products['+i+'][quantity]', products[i]['quantity']);
        }

        for (var key in this.checkoutForm.value) {
          if(this.checkoutForm.value[key] == 'wallet'){
            formData.append('payment_method['+0+']', 'cash');
            formData.append('payment_method['+1+']', 'wallet');
            formData.append('wallet_amount', this.walletAmount);
            // formData.append('payment_method['+1+']', 'cash');
          }else{
            formData.append(key, this.checkoutForm.value[key]);
          }
        }
        
        this.checkoutService.postOrder2(formData).subscribe((response: any) => {
          console.log(response);
          if(response){
            if(response.success == false){
              this.toast.error(response.message);
            }else if(response){
              if(this.lang == 'en'){
                this.toast.success("Payment Success");
              }else{
                this.toast.success("عملية دفع ناجحة");
              }
              this.cartService.emptyCart();
              this.cartService.cartUpdated.emit();
              this.cartService.getItems();
              let routs:any[] = this.router.url.split('/');
              this.isValid = true;
              this.router.navigate([routs[1] + '/orders', this.lang]);
            }
          }else{
              this.toast.error(response.message);
          }
        },
        error=>{
          console.log(error);
          if(error.error.errors.wallet_amount){
            this.toast.error(error.error.errors.wallet_amount[0]);
          }
          // this.toast.error(error.error.message)
          // if(error.error.errors.first_name){
          //   this.toast.error(error.error.errors.first_name);
          // }
          // if(error.error.errors.last_name){
          //   this.toast.error(error.error.errors.last_name);
          // }
          // if(error.error.errors.mobile){
          //   this.toast.error(error.error.errors.mobile);
          // }
        });
      }
    }
  }

  // Paypal payment gateway
  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      PayPalEnvironment.Sandbox,
      {
        commit: true,
        client: {
          sandbox: "CLIENT_ID", // client Id
        },
        button: {
          label: "paypal",
          size: "small", // small | medium | large | responsive
          shape: "rect", // pill | rect
          //color: 'blue',   // gold | blue | silver | black
          //tagline: false
        },
        onPaymentComplete: (data, actions) => {
          this.orderService.createOrder(
            this.checkOutItems,
            this.checkoutForm.value,
            data.orderID,
            this.amount
          );
        },
        onCancel: (data, actions) => {
          console.log("OnCancel");
        },
        onError: (err) => {
          console.log("OnError");
        },
        transactions: [
          {
            amount: {
              currency: this.productsService.currency,
              total: this.amount,
            },
          },
        ],
      }
    );
  }
  getPaymentMethod(method) {
    
    console.log('Method', method)
    this.paymentMethod = method;
    if(method == 'credit'){
      this.transaction_id = 1;
      this.showBank = false;
      this.showWallet= false;
    }else if(method == 'bank_deposit'){
      this.transaction_id = 2;
      this.showBank = true;
      this.showWallet= false;
    }else if(method == 'cash'){
      this.transaction_id = 3;
      this.showBank = false;
      this.showWallet= false;
    }else if(method == 'wallet'){
      this.transaction_id = 3;
      this.showBank = false;
      this.showWallet= true;
    }
    this.checkoutForm.patchValue({
      'payment_method': method,
      // 'payment_transaction_id': this.transaction_id
    })
  }

  readFile(event) {
    
    var files = event.target.files[0];
    if(files){
      this.checkout = true;
    }else{
      this.checkout = false;
    }
    this.filename = event.target.files[0].name;
    this.uploaded_file = true;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
    this.image_url = files;
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = 'data:file/;base64,' + btoa(binaryString);
    // console.log(this.file_url )

  }
}
