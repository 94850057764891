import * as $ from "jquery";

import { Http, HttpModule } from "@angular/http";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
// import ngx-translate and the http loader
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { AgmCoreModule } from "@agm/core";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
// components
import { AppComponent } from "./app.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { BarRatingModule } from "ngx-bar-rating";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { DonationErrorModule } from "./home/pages/donation-error/donation-error.module";
import { DonationModule } from "./home/pages/donation/donation.module";
import { ErrorModule } from "./home/pages/error/error.module";
// import { ExportAsModule } from "ngx-export-as";
import { HomeModule } from "./home/home.module";
import { HomeNineComponent } from "./home-nine/home-nine.component";
import { HttpClientInterceptor } from "./shared/services/http-client.interceptor";
import { InformationModule } from "./information/information.module";
import { MaterialModule } from "./material/material.module";
//search module
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgModule } from "@angular/core";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxParallaxScrollModule } from "ngx-parallax-scroll";
import { OrdersModule } from "./orders/orders.module";
import { RatingModule } from "ng-starrating";
import { RedeemModule } from "./home/pages/home_screen/redeem/redeem.module";
// import { ShopModule } from "./shop/shop.module";
import { SharedModule } from "./shared/shared.module";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ToastrModule } from "ngx-toastr";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { VerificationModule } from "./home/pages/verification/verification.module";
import { environment } from "src/environments/environment";
import { rootRouterConfig } from "./app.routes";

// import { ChartsModule } from 'ng2-charts';

// import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatTreeModule } from '@angular/material/tree';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeNineComponent,
    // TreeViewComponent
  ],
  imports: [
    JwtModule.forRoot({
      // for JwtHelperService
      config: {
        tokenGetter: () => {
          return "";
        },
      },
    }),
    BrowserModule,
    SlickCarouselModule,
    HttpModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    // ShopModule,
    // ExportAsModule,
    SharedModule,
    HttpClientModule,
    HomeModule,
    AuthenticationModule,
    OrdersModule,
    InformationModule,
    NgbModule,
    NgbModalModule,
    VerificationModule,
    ErrorModule,
    DonationModule,
    NgxPaginationModule,
    DonationErrorModule,
    RedeemModule,
    NgxParallaxScrollModule,
    BarRatingModule,
    Ng2SearchPipeModule,
    // MatSliderModule,
    // MatTreeModule,
    RatingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true,
    }),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: true,
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCt2f7HUxrLa9AJ1DvCd8JyR6cxyI2SlhQ",
    }),
    MaterialModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,

    // ChartsModule
  ],
  providers: [HttpClientInterceptor, JwtHelperService],
  bootstrap: [AppComponent],
})
export class AppModule {}
