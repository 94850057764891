import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  public getMyCartItems() {
    return this.httpClientService.get('cart', []);
  }

  public addItemToCart(data: any) {
    return this.httpClientService.post('cart', data, []);
  }

  public updateCartQuantity(id:number, data: any) {
    return this.httpClientService.post('cart/' + id, data, []);
  }

  public deleteItemFromCart(id: number){
    return this.httpClientService.delete('cart/' + id, []);
  }
}
