<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a (click)="gotoHome()">{{'home' | translate}}</a>
            </li>
            <li *ngIf="!_reset" class="breadcrumb-item">
              <a (click)="gotoProfile()">{{'Profile' | translate}}</a>
            </li>
            <li *ngIf="isFromProfile" class="breadcrumb-item"><a (click)="gotoProfile()">{{'Profile'|translate}}</a></li>

            <li class="breadcrumb-item active" aria-current="page">
              {{'changepassword' | translate}}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div> -->
<!-- breadcrumb End -->
<!-- section start -->
<section class="register-page section-b-space" [ngClass]="passwordClass">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3>{{'changepassword' | translate}}</h3>
        <div class="theme-card">
          <form class="theme-form">
            <div id="_3_step" class="animated">
              <div [formGroup]="changePasswordForm" class="row">
                <div class="col-md-6">
                  <label>{{'old passwors' | translate}}<span class="color--red">*</span></label>
                  <div class="form-group input-center">
                    <input
                      id="password-field"
                      [type]="showOldPass?'text':'password'"
                      class="form-control cursor--pointer input-center"
                      placeholder="{{'old passwors' | translate}}"
                      formControlName="old_password"
                      required=""
                    />
                    <button class="btn" (click)="uncover()">
                      <i [ngClass]="showOldPass?'fa fa-eye':'fa fa-eye-slash'"></i>
                    </button>
                  </div>
                </div>

                <div class="col-md-6">
                  <label>{{'new password' | translate}}<span class="color--red">*</span></label>
                  <div class="form-group input-center">
                    <input
                      id="password-field"
                      [type]="showPass?'text':'password'"
                      class="form-control cursor--pointer input-center"
                      placeholder="{{'new password' | translate}}"
                      formControlName="password"

                      required=""
                    />

                    <button class="btn" (click)="uncoverPass()">
                      <i [ngClass]="showPass?'fa fa-eye':'fa fa-eye-slash'"></i>
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <label
                    >{{'confirmPassword' | translate}}<span class="color--red"
                      >*</span
                    ></label
                  >
                  <div class="form-group input-center">
                    <input
                      id="password-field-confirm"
                      [type]="confirmPass?'text':'password'"
                      class="form-control cursor--pointer"
                      placeholder="{{'confirmPassword' | translate}}"
                      formControlName="password_confirmation"
                      required=""
                    />

                    <button class="btn" (click)="uncoverConfirm()">
                      <i [ngClass]="confirmPass?'fa fa-eye':'fa fa-eye-slash'"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <button
                    [disabled]="!changePasswordForm.valid"
                  class="btn btn-solid color--white"
                  (click)="changePassword()"
                >
                  {{'submit' | translate}}</button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->