<div class="footer-social">
    <!-- <ul>
        <li *ngFor="let item of social">
            <a (click)="openLink(item.value)" [attr.title]="item.name" target="_blank"><i [className]="item.icon" aria-hidden="true"></i></a>
        </li>
    </ul> -->

    <div class="social-media-icons">
        <div  *ngFor="let item of social">
            <a href="{{item.value}}" [attr.title]="item.name" target="_blank"><i [className]="item.icon" aria-hidden="true"></i></a>
        </div>
    </div>
</div>