import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";

import { DatePipe } from "@angular/common";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-order-details-report",
  templateUrl: "./order-details-report.component.html",
  styleUrls: ["./order-details-report.component.scss"],
})
export class OrderDetailsReportComponent
  implements OnInit, AfterContentChecked
{
  lang;
  merchantId: number;
  reports: any;
  merchants_array: any[] = [];
  isLoading: boolean = false;

  Hide: boolean = true;

  selectedID = 1;
  start_date;
  end_date;
  downloadreport: any;
  downloadreportPDF: any;
  id: number;
  name: string;
  type: string;
  merchantsList: any;
  merchant: any;
  startDate: string;
  endDate: string;
  idList: any[];

  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.selectedID = 1;
  }
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.merchantId = +localStorage.getItem("merchantId");
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.translate.use(this.lang);
    this.isLoading = true;
    this.getList();
  }
  onMerchantChange(event) {
    this.isLoading = true;
    this.id = event.value.id;
    this.name = event.value.name;
    this.initReports();
  }
  initReports() {
    this.getReports();
    this.initForm();
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();
          this.idList = [this.id];
          this.name = this.merchant.name;

          this.initReports();
        }
      });
  }
  allBranches(e) {
    this.idList = [...this.merchantsList.map((res) => res.id)];
    if (!e.checked) {
      this.idList = [this.id];
    }
    this.reset();
  }
  getReports() {
    this.isLoading = true;
    let body = {
      merchants: this.idList,
      date_flag: this.selectedID,
      from: this.start_date,
      to: this.end_date,
    };

    this.http.post("report/order-details", body, []).subscribe((res: any) => {
      this.reports = res.data;
      this.downloadreport = res["data"].download_file;
      this.downloadreportPDF = res["data"].pdf_download_file;
      this.isLoading = false;
    });
  }

  initForm() {
    this.startDate = "";
    this.endDate = "";
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.start_date = date;
    console.log("The start date value:-----", this.start_date);
    if (this.end_date) this.getReports();
  }

  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, "yyyy-MM-dd");
    this.end_date = date;
    console.log("The End date value:-----", this.end_date);
    if (this.start_date) this.getReports();
  }

  ShowDatePicker() {
    this.Hide = false;
  }

  printAll() {
    this.dialog.open(PrintPopupComponent, {
      data: { pdf: this.downloadreportPDF, excel: this.downloadreport },
      width: "300px",
    });
  }

  reset() {
    this.initForm();
    this.selectedID = 1;
    this.Hide = true;
    this.getReports();
  }

  changeSelection(event: any) {
    console.log(
      "The select menu option is :-----",
      event.source.selected.value
    );
    this.selectedID = event.source.selected.value;
    if (this.selectedID != 6) this.getReports();
  }
}
