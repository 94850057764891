<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="left">
          <h2>Dashboard</h2>
        </div>

        <div class="indicator">
          <div class="indicator-head">
            <h1>Performance Indicators</h1>
          </div>

          <div class="indicator-body">
            <div class="row">
              <div class="col-md-4">
                <span>Average Prepartion Time</span>
                <h1>{{ avgPrepareTime }} mins</h1>
              </div>

              <div class="col-md-4">
                <span>Order Rejection rate</span>
                <h1>{{ rejectionRate }}%</h1>
              </div>

              <div class="col-md-4">
                <span>Customer Conversion Rate</span>
                <h1>{{ conversionRate }}%</h1>
              </div>
            </div>
          </div>
        </div>

        <div class="tabs">
          <div class="row">
            <div class="col-md-4 tab">
              <div class="row">
                <div class="col-md-2">
                  <img
                    src="assets/images/sahlalogo.png"
                    width="50"
                    height="50"
                    alt="image"
                  />
                </div>
                <div class="col-md-10">
                  <h3>{{ ordersToday }}</h3>
                  <span>Orders Today</span>
                </div>
              </div>
            </div>

            <div class="col-md-4 tab">
              <div class="row">
                <div class="col-md-2">
                  <img
                    src="assets/images/sahlalogo.png"
                    width="50"
                    height="50"
                    alt="image"
                  />
                </div>
                <div class="col-md-10">
                  <h3>{{ cancelledOrders }}</h3>
                  <span>Cancelled Orders Today</span>
                </div>
              </div>
            </div>

            <div class="col-md-4 tab">
              <div class="row">
                <div class="col-md-2">
                  <img
                    src="assets/images/sahlalogo.png"
                    width="50"
                    height="50"
                    alt="image"
                  />
                </div>
                <div class="col-md-10">
                  <h3>0</h3>
                  <span>offline Resturants Now</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="summary">
          <div class="row">
            <div class="col-md-6">
              <h3>Business Summary</h3>
              <div class="card">
                <div class="card-header">
                  <h4>Orders</h4>
                  <div class="row">
                    <div class="col-md-6">
                      Total
                      <p>{{ total }}</p>
                    </div>
                    <div class="col-md-6">
                      Cancelled
                      <p>{{ cancelledOrders }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <p-chart
                    type="line"
                    [data]="basicData"
                    [options]="basicOptions"
                  ></p-chart>
                </div>
                <div class="card-footer">
                  <button class="btn btn-link">SEE ALL ORDERS</button>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="compare">
                <mat-slide-toggle>{{
                  "Comparison" | translate
                }}</mat-slide-toggle>
                <button class="btn btn-link">This week</button>
                <button class="btn btn-link">Last week</button>
                <button class="btn btn-link">
                  <i class="fa fa-ellipsis-v"></i>
                </button>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Revenue</h4>
                  <span
                    >{{ totalRevenue | number }} {{ "EGP" | translate }}</span
                  >
                </div>

                <div class="card-body">
                  <p-chart
                    type="line"
                    [data]="basicData1"
                    [options]="basicOptions"
                  ></p-chart>
                </div>

                <div class="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
