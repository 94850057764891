<!-- <nav class="navbar navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <img src="assets/images/sahlalogo.png" width="30" height="30" alt="">
    </a>

        <ul class="navbar-nav mr-auto">

          <li class="nav-item">
            <a class="nav-link" (click)="gotoPage('verifyOtp')">{{'verifyOTP' | translate}}</a>
          </li>
      
          <li class="nav-item">
            <a class="nav-link" (click)="gotoPage('opt-orders')">{{'Advanced Reports' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="gotoPage('opt-adv-repo')">{{'orders' | translate}}<span class="sr-only">(current)</span> </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="gotoPage('opt-branch')">{{'Branch' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['../otphome/about-us', lang]">{{'about-us' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['../otphome/contact', lang]">{{'contact' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" *ngIf="isLogged" [routerLink]="['../otphome/profile', lang]">{{'Profile' | translate}}</a>
          </li>

        </ul>
      

    <div [ngClass]="lang=='ar'?'rgt':'lft'">
        <span class="nav-item nav-link" [ngClass]="lang=='ar'?'log-ar':'log-en'" *ngIf="!isLogged" (click)="gotoLogin()">
            {{'login' | translate}}
        </span>
        <span class="nav-item nav-link" [ngClass]="lang=='ar'?'log-ar':'log-en'" *ngIf="isLogged" (click)="logout()">
            {{'logout' | translate}}
        </span>
    
        <ul class="nav-item nav-link" [ngClass]="lang=='ar'?'lang-ar':'lang-en'">
            <li class="mobile-language">
                <a  (click)="changeLang('en')" *ngIf="lang == 'en'"><img class="topbar-flag"  src="./assets/images/ar.png"> <span class="txt">Arabic</span></a>
                <a (click)="changeLang('ar')" *ngIf="lang == 'ar'"><img class="topbar-flag"  src="./assets/images/en.webp" > <span class="txt">English</span></a>
            </li>
        </ul>
    </div>
</nav> -->

<nav class="navbar navbar-expand-lg navbar-light bg-light otp-nav">
  <a class="navbar-brand" href="#">
    <img src="assets/images/sahlalogo.png" width="30" height="30" alt="" />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav m-auto">
      <!-- <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li> -->

      <!-- <li class="nav-item">
        <a
          class="nav-link"
          (click)="gotoPage('reports')"
       
          >{{ "Reports" | translate }}</a
        >
      </li> -->

      <!-- <li class="nav-item">
        <a
          class="nav-link"
          (click)="gotoPage('verifyOtp')"
          *ngIf="type && type != 'brand_manager'"
          >{{ "instore" | translate }}</a
        >
      </li> -->

      <!-- <li class="nav-item">
        <a class="nav-link" (click)="gotoPage('verifyOtp')">{{'verifyOTP' | translate}}</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="gotoPage('opt-branch')">{{'Branch' | translate}}</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="gotoPage('opt-orders')">{{'Advanced Reports' | translate}}</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="gotoPage('opt-adv-repo')" *ngIf="type"
          >{{ "onlineOrders" | translate
          }}<span class="sr-only">(current)</span>
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" (click)="gotoPage('opt-branch')">{{'Branch' | translate}}</a>
      </li> -->

      <li>
        <div [ngClass]="lang == 'ar' ? 'rgt' : 'lft'">
          <!-- <span
            class="nav-item nav-link"
            [ngClass]="lang == 'ar' ? 'log-ar' : 'log-en'"
            *ngIf="!isLogged"
            (click)="gotoLogin()"
          >
            {{ "login" | translate }}
          </span> -->
          <span
            class="nav-item nav-link"
            [ngClass]="lang == 'ar' ? 'log-ar' : 'log-en'"
            *ngIf="isLogged"
            (click)="logout()"
          >
            {{ "logout" | translate }}
          </span>
        </div>
      </li>
    </ul>
    <div [ngClass]="lang == 'ar' ? 'rgt' : 'lft'">
      <ul
        class="nav-item nav-link"
        [ngClass]="lang == 'ar' ? 'lang-ar' : 'lang-en'"
      >
        <li class="mobile-language">
          <a (click)="changeLang('en')" *ngIf="lang == 'en'"
            ><img class="topbar-flag" src="./assets/images/ar.png" />
            <span class="txt">Arabic</span></a
          >
          <a (click)="changeLang('ar')" *ngIf="lang == 'ar'"
            ><img class="topbar-flag" src="./assets/images/en.webp" />
            <span class="txt">English</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
<!-- <app-footer-one></app-footer-one> -->
