import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-otp-products',
  templateUrl: './otp-products.component.html',
  styleUrls: ['./otp-products.component.css']
})
export class OtpProductsComponent implements OnInit {

  lang;
  categoryName;
  products:any[] = [];
  closeResult = '';
  productForm: FormGroup;

  constructor(
    private translate: TranslateService,
    private http: HttpClientService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);

    this.categoryName = localStorage.getItem('OtpCategory');
    this.products = JSON.parse(localStorage.getItem('OtpProducts'));

    this.initForm();
    console.log('produsct', this.products);
  }

  updateStatus(event, id){
    console.log('Status', event.value);
    console.log('prod', id);
    let body = {
      'published': event.value
    }
    this.http.post('merchant/update-product-publishing/' + id, body, {}).subscribe(
      res=>{
        console.log(res);
        if(res['success'] == true){
          this.toaster.success(res['message']);
        }else{
          this.toaster.error(res['message']);
        }
      }
    );
  }

  goBack(){
    let routs:any[] = this.router.url.split('/');
    localStorage.setItem('activated', 'true');
    this.router.navigate([routs[1] + '/opt-adv-repo', this.lang]);
  }

  open(content, product) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.productForm.patchValue({
      'product_id': product.id,
      'name_en': product.title,
      'name_ar': product.title,
      'price':product.price
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  initForm(){
    this.productForm = new FormGroup({
      'product_id': new FormControl(''),
      'name_en': new FormControl(''),
      'name_ar': new FormControl(''),
      'price': new FormControl('')
    });
  }

  onSubmit(){
    console.log(this.productForm.value);
    this.http.post('merchant/send-updating-product-request', this.productForm.value, []).subscribe(
      res=>{
        console.log('res', res);
        if(res['success'] == true){
          this.toaster.success(res['message']);
          this.modalService.dismissAll();
        }
      }
    );
  }

}
