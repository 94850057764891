<mat-card>
  <div class="alert alert-success" role="alert" *ngIf="prod.approved">
    <mat-icon>check_circle</mat-icon>
    <div>{{ "Item Approved" | translate }}</div>
    <div class="btn-wrapper">
      <button mat-button (click)="markAsRead()">
        {{ "MARK AS READ" | translate }}
      </button>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="prod.rejected">
    <mat-icon>error_outline</mat-icon>
    <div>
      {{
        " Dear partner, Due to some technical issues, we have updated your request manually and it is now successfully updated on our Platforms"
          | translate
      }}
    </div>
    <div class="btn-wrapper">
      <button mat-button (click)="view()">{{ "VIEW" | translate }}</button>
    </div>
  </div>
  <div class="content">
    <img
      mat-card-image
      [src]="prod.mediaCollection[0].default"
      [alt]="prod.title"
      *ngIf="prod.mediaCollection[0] !== null"
    />
    
    <mat-card-content>
      <h2 *ngIf="prod.title_en">
        {{ prod.title_en }}
      </h2>

      <h2 *ngIf="!prod.title_en">
        {{ prod.title }}
      </h2>

      <p *ngIf="prod.currency">
        {{ prod.price +   " " +  (lang == "en" ? prod.currency.en : prod.currency.ar) }}
      </p>

      <p *ngIf="!prod.currency">
        {{prod.price }}
      </p>

    </mat-card-content>

    <span *ngIf="prod.rejected">{{ "Rejected" | translate }}</span>
    <mat-card-actions>
      <mat-icon class="edit" mat-button (click)="editProd(prod)">edit</mat-icon>
      <mat-icon
        class="delete"
        mat-button
        *ngIf="prod.isDraft"
        (click)="deleteProd()"
        >delete</mat-icon
      >
    </mat-card-actions>
  </div>
</mat-card>
