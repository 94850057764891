import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zakah1',
  templateUrl: './zakah1.component.html',
  styleUrls: ['./zakah1.component.scss']
})
export class Zakah1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
