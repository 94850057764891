import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(private httpClientService: HttpClientService) { }

  getStoreInfo() {
    return this.httpClientService.get('info', []);
  }

  getSociallinks(){
    return this.httpClientService.get('social-links', []);
  }
  
  contactus(contactdata){
    return this.httpClientService.post('contact-us',contactdata,{});

  }

}
