<div class="collection-mobile-back" (click)="mobileFilterBack()">
  <span class="filter-back">
    <i class="fa fa-angle-left" aria-hidden="true"></i> {{'back'|translate}}
</span>
</div>
<div class="collection-collapse-block border-0 open">
  <h3 class="collapse-block-title">{{'CAT'|translate}}</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <li *ngFor="let cat of categories">
          <a (click)="navigateToCategory(cat.id)" *ngIf="lang == 'en'">{{cat?.name?.en}}</a>
          <a (click)="navigateToCategory(cat.id)" *ngIf="lang == 'ar'">{{cat?.name?.ar}}</a>
      </li>
      </ul>
    </div> 
  </div>
</div>
