import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-search-customer",
  templateUrl: "./search-customer.component.html",
  styleUrls: ["./search-customer.component.scss"],
})
export class SearchCustomerComponent implements OnInit {
  result;
  results1: any[] = [];
  type = "Mobile";
  displayStyle;
  text;
  amount = null;

  constructor(private http: HttpClientService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.displayStyle = "none";
    // this.search();
    document.getElementById("balance").style.display = "none";
    document.getElementById("balance1").style.display = "none";
    document.getElementById("balance2").style.display = "none";
  }

  search() {
    if (this.text) {
      let body = {};
      switch (this.type) {
        case "Name":
          body = {
            name: this.text,
          };
          break;
        case "Mobile":
          body = {
            mobile: this.text,
          };
          break;
        case "Transaction":
          body = {
            transaction: this.text,
          };
          break;
        case "Order":
          body = {
            order: this.text,
          };
          break;
        default:
          break;
      }
      let headers: HttpHeaders = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("optToken")}`,
      });
      this.http.post("searchCustomer", body, {}, headers).subscribe(
        (res: any) => {
          this.result = res.data;
        },
        (err) => {
          console.log(err);
          this.toastr.error(err.error.message);
        }
      );
    }
  }

  open() {
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = "none";
    document.getElementById("balance").style.display = "block";
    document.getElementById("balance1").style.display = "block";
    document.getElementById("balance2").style.display = "block";
  }
  addCashBack() {
    if (this.amount) {
      let body = {
        user_id: this.result.id,
        user_cashback: this.amount,
      };
      let headers: HttpHeaders = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("optToken")}`,
      });
      this.http.post("wallet/add-balance", body, {}, headers).subscribe(
        (res: any) => {
          this.amount = null;
          this.toastr.success(res.message);
          this.search();
          this.closePopup();
        },
        (err) => {
          console.log(err);
          this.toastr.error(err.error.message);
        }
      );
    }
  }
}
