<div class="breadcrumb full-width">
    <div class="background">
        <div class="container">
            <div class="contact">
                <h1 class="contactfont" id="title-page">{{"forget-password"|translate}}</h1>
            </div>
        </div>
    </div>
</div>


<div class="main-content full-width">
    <div class="background-content"></div>
    <div class="background">
        <div class="shadow"></div>
        <div class="pattern">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div id="content" class="col-md-9 center-column body-other content-with-background">
                                <div class="row">
                                    <div class="col-sm-6" style="font-family: Poppins, Sans-Serif;font-size: 16px;">
                                        <div class="well">
                                            <h2>{{'newcustomer'|translate}}</h2>
                                            <p><strong>{{'createaccount'|translate}}</strong></p>
                                            <p>{{'createaccounttext'|translate}}</p>
                                            <a  *ngIf="lang=='en'" style="margin-left: 0;" [routerLink]="['/signup6']"
                                                class="btn btn-primary">{{'createaccount'|translate}}</a>
                                      
                                                <a  *ngIf="lang=='ar'"  [routerLink]="['/signup6']"
                                                class="btn btn-primary">{{'createaccount'|translate}}</a>
                                            </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div id="recover-password" class="well" style="display: block;">
                                            <h2>{{ "change-password" | translate }}</h2>
                                            <p><strong>{{ "forget-password" | translate }}</strong></p>
                                            <p>{{ "type email" | translate }}</p>

                                            <form [formGroup]="forgetPasswordForm" class="theme-form">

                                                <div class="form-group required">
                                                    <label class="control-label" for="input-password">{{"storemail"|translate}}</label>
                    
                                                    <input formControlName="email" name="email" required="required" type="email" class="form-control {{ !forgetPasswordForm.controls['email'].valid? 'is-invalid': ''}}" id="recover-email" placeholder="{{ 'email' | translate }}" />


                                                    <span *ngIf="
                                                          forgetPasswordForm.controls['email'].touched &&
                                                          forgetPasswordForm.controls['email']?.errors?.required
                                                        " class="error-msg">
                                                        {{ "emailReqMes" | translate }}
                                                    </span>
                                                    <span *ngIf="
                                                          forgetPasswordForm.controls['email'].touched &&
                                                          forgetPasswordForm.controls['email']?.errors?.email
                                                        " class="error-msg">
                                                        {{ "emailmustbe" | translate }}.
                                                    </span>
                                                </div>
                                                <div class="action_bottom">
                                                    <!-- <input type="submit" value="ارسال" > -->
                                                    <button type="submit" class="btn btn-primary">{{'submit'|translate}}</button>
                                                    <span class="ml20"><a class="cancel"
                                                            [routerLink]="['/login6']">العودة</a></span>
                                                </div>
                                            </form>
                                        </div>
                                        <script></script>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>