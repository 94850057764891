import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { ResponseContentType } from "@angular/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpClientService {
  constructor(private httpClient: HttpClient) {}

  get(url, queryParams?, headers?) {
    return this.httpClient.get(`${environment.base_url}/${url}`, {
      params: queryParams,
      headers,
    });
  }

  post(url, body, queryParams?, headers?) {
    return this.httpClient.post(`${environment.base_url}/${url}`, body, {
      params: queryParams,
      headers,
    });
  }

  put(url, body, queryParams) {
    return this.httpClient.put(`${environment.base_url}/${url}`, body, {
      params: queryParams,
    });
  }

  delete(url, queryParams, headers?) {
    return this.httpClient.delete(`${environment.base_url}/${url}`, {
      params: queryParams,
      headers,
    });
  }

  download(url, body, queryParams) {
    const options = { responseType: ResponseContentType.Blob };
    return this.httpClient.get(url).map((res: any) => {
      return new Blob([res.blob()], { type: "application/pdf" });
    });
  }
}
