<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <h2>
          {{ "Finances" | translate }}
        </h2>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <nav>
                  <div
                    class="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      (click)="setTab('i')"
                      [class.active]="selectedTab == 'i'"
                      >{{ "Invoices" | translate }}</a
                    >
                    <!-- <a
                      class="nav-item nav-link"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      (click)="setTab('s')"
                      [class.active]="selectedTab == 's'"
                      >{{ "Statement of account" | translate }}</a
                    > -->
                    <!-- <a
                      class="nav-item nav-link"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#nav-contact"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                      (click)="setTab('p')"
                      [class.active]="selectedTab == 'p'"
                      >{{ "Payments" | translate }}</a
                    > -->
                    <a
                      class="nav-item nav-link"
                      id="nav-about-tab"
                      data-toggle="tab"
                      href="#nav-about"
                      role="tab"
                      aria-controls="nav-about"
                      aria-selected="false"
                      (click)="setTab('f')"
                      [class.active]="selectedTab == 'f'"
                      >{{ "Financial Reports" | translate }}</a
                    >
                  </div>
                </nav>
                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                  <div
                    class="tab-pane fade"
                    id="nav-home"
                    role="tabpanel"
                    [class.show]="selectedTab == 'i'"
                    [class.active]="selectedTab == 'i'"
                    *ngIf="selectedTab == 'i'"
                    aria-labelledby="nav-home-tab"
                  >
                    <div class="text-desc-content-card">
                      <div id="accordion">
                        <div class="invoices__header">
                          <h3>{{ "Invoices" | translate }}</h3>
                          <mat-form-field appearance="outline">
                            <mat-label>{{
                              "Choose Year" | translate | titlecase
                            }}</mat-label>
                            <mat-select
                              [(ngModel)]="year"
                              (selectionChange)="getInvoices()"
                            >
                              <mat-option
                                *ngFor="let item of yearsList"
                                [value]="item"
                              >
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <mat-spinner *ngIf="!invoices"></mat-spinner>
                        <mat-accordion *ngIf="invoices?.length > 0">
                          <mat-expansion-panel
                            *ngFor="let invoice of invoices"
                            [expanded]="step === invoice.id"
                          >
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                {{
                                  (invoice.month.toString() | translate) +
                                    " " +
                                    invoice.year
                                }}
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul>
                              <li class="list_item">
                                <p>{{ "Commission Inovice" | translate }}</p>
                                <mat-icon
                                  (click)="
                                    getFile(invoice.commission_file_path)
                                  "
                                  >file_download</mat-icon
                                >
                              </li>
                              <li class="list_item">
                                <p>{{ "Detailed Report" | translate }}</p>
                                <mat-icon
                                  (click)="getFile(invoice.detailed_file_path)"
                                  >file_download</mat-icon
                                >
                              </li>
                              <li class="list_item">
                                <p>{{ "Summary Report" | translate }}</p>
                                <mat-icon
                                  (click)="getFile(invoice.summary_file_path)"
                                  >file_download</mat-icon
                                >
                              </li>
                            </ul>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    [class.show]="selectedTab == 's'"
                    [class.active]="selectedTab == 's'"
                    *ngIf="selectedTab == 's'"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div class="card">
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">Sep 2021</div>
                      </div>
                    </div>

                    <div class="card">
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">Aug 2021</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    [class.show]="selectedTab == 'p'"
                    [class.active]="selectedTab == 'p'"
                    *ngIf="selectedTab == 'p'"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div class="card">
                      <div class="card-header">
                        <h2>{{ "Payments" | translate }}</h2>
                      </div>
                      <div class="card-body">
                        <table class="table">
                          <tr>
                            <th scope="col">Payment date</th>
                            <th scope="col">Payment details</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Expected transfer time</th>
                            <th scope="col">Status</th>
                          </tr>
                          <tr>
                            <td>6/28/2021</td>
                            <td>3rd Down Payment of June</td>
                            <td>5508.18</td>
                            <td></td>
                            <td>Paid</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-about"
                    role="tabpanel"
                    [class.show]="selectedTab == 'f'"
                    [class.active]="selectedTab == 'f'"
                    *ngIf="selectedTab == 'f'"
                    aria-labelledby="nav-about-tab"
                  >
                    <div
                      class="alert alert-warning"
                      role="alert"
                      style="text-align: center"
                    >
                      {{
                        "These numbers as subjected to change, full financial reports and invoices for month will be ready within the first 5 business days in the following month in the invoices tab."
                          | translate
                      }}
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <form>
                          <div class="row">
                            <div class="col-md-3">
                              <h4>{{ "Report Type" | translate }}</h4>
                              <mat-form-field appearance="outline">
                                <mat-select
                                  [(ngModel)]="type"
                                  [ngModelOptions]="{ standalone: true }"
                                >
                                  <mat-select-trigger>
                                    {{ type | translate }}
                                  </mat-select-trigger>
                                  <mat-option value="summary">
                                    {{ "Summary Report" | translate }}
                                  </mat-option>
                                  <mat-option value="detailed">
                                    {{ "Detailed Report" | translate }}
                                  </mat-option>
                                  <mat-option value="commission">
                                    {{ "Commission Report" | translate }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <h4>{{ "From" | translate }}</h4>
                                <mat-form-field
                                  appearance="outline"
                                  style="background-color: white"
                                >
                                  <input
                                    matInput
                                    [(ngModel)]="startDate"
                                    [matDatepicker]="picker"
                                    [ngModelOptions]="{ standalone: true }"
                                    [min]="minDate"
                                    [max]="endDate"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <h4>{{ "To" | translate }}</h4>
                                <mat-form-field appearance="outline">
                                  <input
                                    matInput
                                    [(ngModel)]="endDate"
                                    [matDatepicker]="endPicker"
                                    [ngModelOptions]="{ standalone: true }"
                                    [min]="startDate"
                                    [max]="maxDate"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="endPicker"
                                  ></mat-datepicker-toggle>
                                  <mat-datepicker #endPicker></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <h4>{{ "All branches" | translate }}</h4>
                                <mat-form-field appearance="outline">
                                  <mat-select #select multiple>
                                    <div class="select-all">
                                      <mat-checkbox
                                        [(ngModel)]="allSelected"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="toggleAllSelection()"
                                        >{{
                                          "All branches" | translate
                                        }}</mat-checkbox
                                      >
                                    </div>
                                    <mat-option
                                      (click)="optionClick()"
                                      *ngFor="let m of merchantsList"
                                      [value]="m.id"
                                    >
                                      {{ m.name }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-3">
                        <h4 style="visibility: hidden">d</h4>
                        <button
                          class="form-control btn btn-primary"
                          (click)="generateReports()"
                          [disabled]="!type || !select?.value"
                        >
                          {{ "Generate" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header">
                        {{ "Generated Reports" | translate }}
                      </div>

                      <div class="card-body">
                        <table class="table">
                          <tr>
                            <th scope="col">{{ "Report Type" | translate }}</th>
                            <th scope="col">{{ "From" | translate }}</th>
                            <th scope="col">{{ "To" | translate }}</th>
                            <th scope="col">
                              {{ "All branches" | translate }}
                            </th>
                            <th scope="col">
                              {{ "Date generated" | translate }}
                            </th>
                            <!-- <th scope="col">{{ "Status" | translate }}</th> -->
                            <th scope="col"></th>
                          </tr>
                          <tr
                            *ngFor="let report of financeReports; let i = index"
                          >
                            <td>{{ report.report_type | translate }}</td>
                            <td>{{ report.from | date : "yyyy-MM-dd" }}</td>
                            <td>{{ report.to | date : "yyyy-MM-dd" }}</td>
                            <td>
                              {{ report.merchants_name }}
                            </td>
                            <td>
                              {{ report.generated_at | date : "yyyy-MM-dd" }}
                            </td>
                            <!-- <td>
                              <span [class]="report.status">{{
                                report.status | translate
                              }}</span>
                            </td> -->

                            <td *ngIf="report.status == 'ready'">
                              <mat-icon (click)="getFile(report.file_path)"
                                >file_download</mat-icon
                              >
                            </td>
                            <td
                              *ngIf="report.status == 'processing'"
                              class="processing-spinner"
                            >
                              <mat-spinner></mat-spinner>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
