<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">

            <!-- Start form card -->
            <div class="panel panel-default">
                <div class="panel-heading ar-style panel-body-padding">
                    <h4>بطاقات الإهداء</h4>
                </div>
                <div class="panel-body ar-style panel-body-padding">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>إهداء من</label>
                            <input type="text" class="form-control custom-input" id="DedicationFrom" value="">
                        </div>
                        <div class="form-group">
                            <label>مبلغ التبرع</label><span style="color:maroon;">(أقل مبلغ للتبرع 50 ريال)</span>
                            <input type="text" class="form-control custom-input" id="DonAmount" value="">
                        </div>
                        <div class="form-group">
                            <label>
                                جوال المهدى إليه
                                <span style="color:maroon;">(أضف المفتاح الدولي مثل 9665XXXXXXXX)</span>
                            </label>
                            <input type="text" class="form-control custom-input" id="Phone" name="Phone" placeholder="رقم جوال المهدى إليه" value="" onblur="FixDedicatedToPhone()">
                        </div>
                        <div class="form-group">
                            <label>اسم المهدى إليه</label>
                            <input type="text" class="form-control custom-input" id="Name" name="Name" value="" placeholder="اسم المهدى إليه">
                        </div>
                        <div class="form-group">
                            <label>نموذج الإهداء</label>
                            <select class="form-control custom-input" id="Relation" name="Relation" onchange="DisplayCurrentMessage()">
                                <option value="0">إختر نموذج إهداء</option>
                                    <option value="1">عيادة مريض</option>
                                    <option value="2">إلى أختي</option>
                                    <option value="3">إلى أخي</option>
                                    <option value="4">إلى زوجتي</option>
                                    <option value="5">إلى زوجي</option>
                                    <option value="6">إلى صديقي</option>
                                    <option value="7">إلى والدي</option>
                                    <option value="8">إلى أمي</option>
                                    <option value="9">تهنئة مولود</option>
                                    <option value="10">تهنئة زواج</option>
                                    <option value="11">إلى صديقتي</option>
                                    <option value="12">عيدية</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-2">
                            </div>
                            <div class="col-sm-10">
                                <img id="template" width="100%">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-footer">
                    <input class="btn btn btn-block btn-default" style="background: #88bb40;color: #fff; " type="button" value="إهداء" onclick="FastDedicate()">
                </div>
            </div>
            <!-- End   form card -->

        </div>
    </div>
</div>