import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { element } from "protractor";

const AUTH_URLS = [
  "/user",
  "/orders",
  "/notifications",
  "/direct-payment",
  "/reviews",
  "/returns",
  "/cart",
  "/favourites",
  "admin-notifications",
  "wallet/verify-otp",
  "wallet/confirm-redeem-transaction",
  "wallet/confirm-reward-transaction",
  "merchant/search-ordered-users",
  "merchant/products",
  "merchant/update-product-publishing",
  "merchant/update-order-status",
  "merchant/make-merchant-busy",
  "merchant/update-merchant-status",
  "merchant/transactions-report",
  "merchant/orders-report",
  "merchant/reasons-list",
  "merchant/performance",
  "merchants_brand/performance",
  "merchant-areas",
  "address",
  "merchant/online-performance-report",
  "merchant/online-orders-sales-report",
  "merchant/send-updating-product-request",
  "change-password",
  "user-update",
  "order-cancel",
  "wallet/generate-reward-otp",
  "wallet/generate-redeem-otp",
  "merchant/get-dashboard-data",
  "merchants/upload-data",
  "review-criterias",
  "merchant/area-sales-report",
  "merchant/performance-overview-report",
  "merchants/import-products",
  "brand/end-of-day-report",
  "report/end-of-day",
  "report/area-sales",
  "report/performance",
  "report/menu-items-sales",
  "report/order-details",
  "support_request/update"
];

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  lang;
  constructor(
    private translate: TranslateService,
    private authservice: AuthService,
    private router: Router
  ) {
    this.lang = localStorage.getItem("lang_ref");
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // exclude translations get requests
    if (req.url.indexOf("i18n") <= -1) {
      let headers: any;
      if (this.lang === "en") {
        headers = req.headers.set("X-localization", "En");
      } else {
        headers = req.headers.set("X-localization", "ar");
      }

      AUTH_URLS.forEach((URL) => {
        if (req.url.indexOf(URL) > -1) {
          if (this.router.url.split("/")[1] == "otphome") {
            if (localStorage.getItem("optToken")) {
              if (this.lang === "en") {
                headers = req.headers
                  .set(
                    "Authorization",
                    "Bearer " + localStorage.getItem("optToken")
                  )
                  .set("X-localization", "En");
              } else {
                headers = req.headers
                  .set(
                    "Authorization",
                    "Bearer " + localStorage.getItem("optToken")
                  )
                  .set("X-localization", "ar");
              }
            }
          } else {
            if (localStorage.getItem("token")) {
              if (this.lang === "en") {
                headers = req.headers
                  .set(
                    "Authorization",
                    "Bearer " + localStorage.getItem("token")
                  )
                  .set("X-localization", "En");
              } else {
                headers = req.headers
                  .set(
                    "Authorization",
                    "Bearer " + localStorage.getItem("token")
                  )
                  .set("X-localization", "ar");
              }
            }
          }
        }
      });

      const authReq = req.clone({ headers });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
