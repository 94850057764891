import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupComponent } from "src/app/shared/New_Components/print-popup/print-popup.component";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-verify-otp",
  templateUrl: "./verify-otp.component.html",
  styleUrls: ["./verify-otp.component.css"],
})
export class VerifyOtpComponent implements OnInit {
  lang;
  verifyOtp: FormGroup;
  id: number;
  transactionId;
  transactionType;
  totalAmount;
  merchantName;
  constructor(
    private router: Router,
    private service: HttpClientService,
    private toast: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.id = +localStorage.getItem("merchantId");
    this.merchantName = localStorage.getItem("merchantName");
    this.initForm();
  }

  initForm() {
    this.verifyOtp = new FormGroup({
      otp: new FormControl("", [Validators.required]),
      amount: new FormControl("", [Validators.required]),
    });
  }

  gotoHome() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/", this.lang]);
  }

  verifyOtpCode() {
    console.log("The code is:- ", this.verifyOtp.value);
    localStorage.setItem("otpCode", this.verifyOtp.value.otp);
    let body = {
      otp: this.verifyOtp.value.otp,
      merchant_id: this.id,
    };

    this.service.post("wallet/verify-otp", body, []).subscribe(
      (res) => {
        console.log("Response", res);
        if (res["success"] == true) {
          this.toast.success(res["message"]);
          let routs: any[] = this.router.url.split("/");
          this.router.navigate([routs[1] + "/confirmTransaction", this.lang]);
          localStorage.setItem("transactionId", res["data"].transaction.id);
          localStorage.setItem("transactionType", res["data"].transaction.type);
        } else {
          if (this.lang == "en") {
            this.toast.error("Please Enter a Valid OPT Code");
          } else {
            this.toast.error("من فضلك قم بادخال رقم سرى متغير صالح");
          }
        }
      },
      (error) => {
        console.log("error", error.error.message);
        this.toast.error(error.error.message);
      }
    );
  }

  check(event) {
    if (event.target.value.length == 6) {
      console.log(event.target.value);
      localStorage.setItem("otpCode", this.verifyOtp.value.otp);
      let body = {
        otp: this.verifyOtp.value.otp,
        merchant_id: this.id,
      };

      this.service.post("wallet/verify-otp", body, []).subscribe(
        (res) => {
          console.log("Response", res);
          if (res["success"] == true) {
            this.toast.success(res["message"]);
            // let routs:any[] = this.router.url.split('/');
            // this.router.navigate([routs[1] + '/confirmTransaction', this.lang]);
            this.transactionId = res["data"].transaction.id;
            this.transactionType = res["data"].transaction.type;
            this.totalAmount = res["data"].transaction.wallet_amount;
          } else {
            if (this.lang == "en") {
              this.toast.error("Please Enter a Valid OPT Code");
            } else {
              this.toast.error("من فضلك قم بادخال رقم سرى متغير صالح");
            }
          }
        },
        (error) => {
          console.log("error", error.error.message);
          this.toast.error(error.error.message);
        }
      );
    }
  }

  confirmAmount() {
    let body1 = {
      transaction_id: this.transactionId,
      amount: this.verifyOtp.value.amount,
    };

    let body2 = {
      transaction_id: this.transactionId,
      total: this.verifyOtp.value.amount,
    };

    console.log("The code is:- ", body1);
    if (this.transactionType == "debit") {
      this.service
        .post("wallet/confirm-redeem-transaction", body2, [])
        .subscribe(
          (res) => {
            console.log("Response", res);
            localStorage.setItem(
              "transaction",
              JSON.stringify(res["data"].transaction)
            );
            localStorage.setItem(
              "credit-transaction",
              JSON.stringify(res["data"].credit_transaction)
            );

            this.toast.success(res["message"]);
            let routs: any[] = this.router.url.split("/");
            this.router.navigate([routs[1] + "/print-receipt", this.lang]);
            // this.printDocument(res['data']);
          },
          (error) => {
            console.log("error", error);
          }
        );
    } else if (this.transactionType == "credit") {
      this.service
        .post("wallet/confirm-reward-transaction", body1, [])
        .subscribe(
          (res) => {
            console.log("Response", res);
            localStorage.setItem(
              "transaction",
              JSON.stringify(res["data"].transaction)
            );
            localStorage.setItem(
              "credit-transaction",
              JSON.stringify(res["data"].credit_transaction)
            );

            this.toast.success(res["message"]);
            let routs: any[] = this.router.url.split("/");
            this.router.navigate([routs[1] + "/print-receipt", this.lang]);
            // this.printDocument(res['data']);
          },
          (error) => {
            console.log("error", error);
          }
        );
    }
  }
  dowloadReport() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.service
      .post("report/end_of_shift", {}, {}, headers)
      .subscribe((res: any) => {
        this.dialog.open(PrintPopupComponent, {
          data: {
            pdf: res.data.pdf_download_file,
            excel: res.data.download_file,
          },
        });
      });
  }
}
