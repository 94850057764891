<section class="pwd-page">
    <div class="container">
      <div class="row">
  
        <div class="col-lg-6 offset-lg-3">
          <h2>{{ "uploadData" | translate }}</h2>
          <form class="theme-form">
            <div class="form-row">
                <div class="col-md-6">
                    <label>{{'merchant data'|translate}}</label>
                    <br />
                    <br />
                    <label for="profile_img" class="btn btn-outlined x"> {{"uploadData" | translate}}<i
                            class="fa fa-upload mr-1"></i></label>
                    <div class="col"><span>{{filename}}</span></div>
                    <input (change)="readImage($event)" style="visibility:hidden;" type="file"
                        placeholder="Upload" id="profile_img" accept=".json">
                </div>

                <div class="col-md-12">
                    <label>{{'Talabat Branch'|translate}}</label>
                    <input type="text" class="form-control" [(ngModel)]="merchantId" required name="branch_id">
                </div>
                <div class="col-md-12">
                  <label>{{'Brand on sahla system'|translate}}</label>
                  <input type="text" class="form-control" [(ngModel)]="brandId" required name="branch_id">
                </div>
              <button [disabled]="!isChanged || isClicked" (click)="confirmUpload()" class="btn btn-solid color--white form-control">
                {{ "submit" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>