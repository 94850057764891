import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MerchantGuard implements CanActivate {
  constructor() {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let type = localStorage.getItem("type");
    if (!localStorage.getItem("optToken")) {
      return false;
    } else {
      if (type == "brand_manager") {
        // if (next.routeConfig.path.includes("opt-adv-repo")) return false;
        if (next.routeConfig.path.includes("links")) return false;
        if (next.routeConfig.path.includes("verifyOtp")) return false;
        return true;
      } else if (type == "cashier") {
        if (next.routeConfig.path.includes("dashboard")) return false;
        if (next.routeConfig.path.includes("reports")) return false;
        if (next.routeConfig.path.includes("opt-orders")) return false;
        if (next.routeConfig.path.includes("opt-branch")) return false;
        if (next.routeConfig.path.includes("finances")) return false;
        if (next.routeConfig.path.includes("opening")) return false;
        if (next.routeConfig.path.includes("online-orders")) return false;
        if (next.routeConfig.path.includes("all-areas")) return false;
        if (next.routeConfig.path.includes("help")) return false;
        return true;
      } else {
        return true;
      }
    }
  }
}
