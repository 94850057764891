import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-otp-performance-report",
  templateUrl: "./otp-performance-report.component.html",
  styleUrls: ["./otp-performance-report.component.css"],
})
export class OtpPerformanceReportComponent implements OnInit {
  lang;
  orders: any[] = [];
  displayedColumns: string[] = [
    "orders_count",
    "date",
    "cancellations_count",
    "total_sales",
    "total_cashback",
  ];
  perPage: any;
  totalCount: any;
  page: number = 1;
  config: any;
  isLoading: boolean = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.getOrders(1);

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount,
    };
  }

  getOrders(page?) {
    this.isLoading = true;
    this.http
      .get("merchant/online-performance-report", { page: page })
      .subscribe((res) => {
        this.orders = res["data"].data;
        this.totalCount = res["data"].total;
        this.perPage = res["data"].per_page;
        this.isLoading = false;
      });
  }

  handlePageEvent(event) {
    console.log(event);
    this.getOrders(event.pageIndex + 1);
  }

  download() {
    localStorage.setItem("orders", JSON.stringify(this.orders));
    localStorage.setItem(
      "displayedColumns",
      JSON.stringify(this.displayedColumns)
    );
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/print-order", this.lang]);
  }
}
