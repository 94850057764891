<div class="ng-scope">
    <div class="ng-scope">
        <section class="ng-scope">
            <!-- container  -->
            <div class="menu-page">
                <!-- <ngx-slick-carousel class="category-m no-arrow" #slickModal="slick-carousel" [config]="catSlideConfig">
                    <div ngxSlickItem *ngFor="let slide of sliderImages" class="slide">
                        <div class="overlay"></div>
                        <img src="{{ slide?.media_collection[0]?.default }}" alt="" width="100%">
                        <div class="slide-content">
                            <h2 class="heading-text" *ngIf="lang == 'en'">{{slide?.title?.en}}</h2>
                            <h2 class="heading-text" *ngIf="lang == 'ar'">{{slide?.title?.ar}}</h2>
                        </div>
                    </div>
                </ngx-slick-carousel> -->
                <!-- End Slider -->

                <div class="hero-image">
                    <div class="overlay"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 hero_section">
                                <div class="hero-banner">
                                    <div class="row">
                                        <div class="col-12 col-md-8">
                                            <h1 class="heading-text">{{'buy online and get cashback' | translate}}</h1>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="info-box">
                                                        <span>{{'buy from' | translate}}</span>
                                                        <h5>100+</h5>
                                                        <span>{{'sites' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="info-box">
                                                        <span>{{'you will get' | translate}}</span>
                                                        <h5>3500{{'EGP' | translate}}</h5>
                                                        <span>{{'every month' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="info-box">
                                                        <span>{{'money' | translate}}</span>
                                                        <h5>{{'free' | translate}}</h5>
                                                        <span>{{'no condition' | translate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row header-links-container">
                                                <div class="col-md-10 col-12">
                                                    <div class="row">
                                                        <div class="col-2" *ngFor="let brand of brands | slice:0:5">
                                                            <div class="header-link">
                                                                <a (click)="gotoMerchant(brand?.merchants[0]?.id)">
                                                                    <img [src]="brand?.cover_image[0]?.default? brand?.cover_image[0]?.default: 'assets/images/sahlalogo.png'"
                                                                        [alt]="brand.title">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 header-singup">
                                            <div class="sigin-box">
                                                <div class="box-header">
                                                    <h2 class="box-title">{{'search' | translate}}</h2>
                                                </div>
                                                <div class="box-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <input type="text" class="form-control" [(ngModel)]="query"
                                                                (ngModelChange)="search($event)"
                                                                on-focusout="menuHide($event)" on-focus="menuShow()"
                                                                placeholder="{{'search' | translate}}" />
                                                        </div>

                                                        <div class="col-md-12">
                                                            <select class="form-control"
                                                                (change)="changeSelection($event)"
                                                                [(ngModel)]="selectedID">
                                                                <option [attr.selected]="true">{{'Select Area' |
                                                                    translate}}</option>
                                                                <option *ngFor="let area of areas"
                                                                    [attr.value]="area.id">
                                                                    <span *ngIf="lang == 'ar'">{{area.name.ar}}</span>
                                                                    <span *ngIf="lang == 'en'">{{area.name.en}}</span>
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <ul *ngIf="(searchRes.length > 0 || searchRes.length != null) && showMenu"
                                                        id="search_id2" class="list-group">
                                                        <a *ngFor="let product of searchRes"
                                                            class="list-group-item text-left"
                                                            (mousedown)="gotoMerchant(product?.id)">
                                                            <img width="40px" height="40px"
                                                                [src]="product?.brand_cover_image[0]?.default? product?.brand_cover_image[0]?.default: 'assets/images/sahlalogo.png'">
                                                            <span class="search-text">
                                                                {{ product.merchant_brand_title }}
                                                            </span>

                                                        </a>

                                                        <!-- <a *ngFor="let product of searchRes" class="list-group-item text-left">
                                                            <span class="search-text" *ngIf="this.lang == 'ar'">
                                                                {{ product.name.ar }}
                                                            </span>

                                                            <span class="search-text" *ngIf="this.lang == 'en'">
                                                                {{ product.name.en }}
                                                            </span>
                                                        </a> -->
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Start How to earn money in 3 simple steps -->
                <section class="earn-money">
                    <div class="container">
                        <h2>
                            How to earn money in 3 simple steps
                        </h2>
                        <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="row">
                            <div class="col-md-4">
                                <div class="icon-card">
                                    <span class="count">1.</span>
                                    <span class="icon">
                                        <img src="./assets/images/search-interface-symbol.png" alt="">
                                    </span>
                                </div>

                                <h3>
                                    Find your website
                                </h3>
                                <p>
                                    Browse Sahla site and click through to your favourite website
                                </p>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-card">

                                    <span class="count">2.</span>
                                    <span class="icon">
                                        <img src="./assets/images/shopping-cart.png" alt="">
                                    </span>
                                </div>

                                    <h3>
                                        Shop as usual
                                    </h3>
                                    <p>
                                        Shop as you usually do on the store's website. Cashback will appear in your
                                        wallet
                                        when your purchase is reported to us.
                                    </p>
                            </div>
                            <div class="col-md-4">
                                <div class="icon-card">

                                    <span class="count">3.</span>
                                    <span class="icon">
                                        <img src="./assets/images/cash-back.png" alt="">
                                    </span>
                                </div>

                                    <h3>
                                        Use your Cashback
                                    </h3>
                                    <p>
                                        Every quarter you can use your cashback to buy from more than 5,000 stores
                                        paying
                                        with sahla App, or cash it out with your bank account or any Aman store.
                                    </p>
                            </div>
                        </div>
                        </div>
                    </div>
                        <p class="text-center sahla-note">
                            Websites pay Sahla a commission for sending you their way, and sahla shares the commission
                            with you as cashback.
                        </p>
                    </div>
                </section>
                <!-- End How to earn money in 3 simple steps -->

                <!-- Start Top cashbacks Stores -->
                <section class="cash-back">
                    <div class="container">
                        <h2>
                            {{'Top cashbacks Stores' | translate}}

                        </h2>
                        <div class="row justify-content-center" *ngIf="topStores.length > 0">
                            <div class="col-lg-3 col-md-6 mb-5" *ngFor="let store of topStores">
                                <a (click)="gotoDetails(store?.id)">
                                    <img [src]="store?.brand_cover_image[0]?.default?store?.brand_cover_image[0]?.default:'assets/images/sahlalogo.png'" alt="">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <img class="img-store" [src]="store?.brand_cover_image[0]?.default?store?.brand_cover_image[0]?.default:'assets/images/sahlalogo.png'" alt="">

                                        </div>
                                        <div class="col-md-9">

                                            <div class="card-text">
                                                <div class="card-title h5">{{store?.merchant_brand_title}}</div>
                                                <span class="text-gray" *ngIf="store?.cachback_percent">{{'Get up to' |
                                                    translate}}</span>
                                                <span class="cashback-text" *ngIf="store?.cachback_percent">
                                                    {{store?.cachback_percent}}% {{'Cashback' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn btn-block">
                                        {{'shopnowbtn' | translate}}
                                    </div>
                                    </a>
                            </div>
                        </div>
                        <div class="row justify-content-center" *ngIf="topStores.length == 0">
                            There is no Cashback Stores
                        </div>

                        <div class="seeAllStors-text text-center">
                            <a (click)="gotoMerchants()" class="hvr-icon-forward">
                                <span>
                                    See All The Stores
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </section>
                <!-- End Top cashbacks Stores -->

                <!-- Start Top cashbacks categories -->
                <section class="categories-cashbacks">
                    <div class="container">
                        <h2>
                            Top cashbacks categories
                        </h2>
                    <div class="row justify-content-center">
                        <div class="col-md-10">

                        <div class="row justify-content-center" *ngIf="topCategories.length > 0">
                            <div class="col-md-4" *ngFor="let category of topCategories | slice:0:3">
                                <div class="cashback-card">
                                    <a (click)="navigateCategory(category)" >
                                    <img class="card-img w-100" [style.backgroundColor]="category?.color" [src]="category?.home_image[0]?.default">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <img class="card-img w-100 sm-img"
                                                [src]="category?.home_image[0]?.default? category?.home_image[0]?.default: 'assets/images/sahlalogo.png'"
                                                [style.backgroundColor]="category?.color">
                                        </div>

                                        <div class="col-md-9">
                                            <div class="card-right">
                                                <div class="card-title h5">{{category?.name}}</div>
                                                <span class="card-text">
                                                    <!-- <span class="text-gray">Get up to</span> -->
                                                    <span class="cashback-text"> {{category?.merchants_count}} {{'store' | translate}}</span>
                                                </span>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="btn">{{'shopnowbtn' |
                                        translate}}</div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center" *ngIf="topCategories.length == 0">
                            There is no Cashback Categories
                        </div>

                        <div class="seeAllStors-text text-center">
                            <a (click)="gotoCategories()" class="hvr-icon-forward">
                                <span>
                                    See All Categories
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                            </a>
                        </div>

                    </div>
                    </div>
                </div>
                </section>
                <!-- End Top cashbacks categories -->


                <!-- Start be first to know -->
                <!-- <section class="contact-info">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <div class="sub-title-div">
                                <h2 class="sub-title title-full">Be the first to know</h2>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <p class="footer-text">Subscribe and get first-hand news and offers from your
                                        favorite brands.
                                    </p>
                                    <form role="form" class="">
                                        <div class="form-group"><label class="form-label" for="formEmail">E-mail</label>
                                            <div class="field-container">
                                                <input type="email" name="email" placeholder="E.g. john@mail.com"
                                                    value="" id="formEmail" class="form-control">
                                                <div class="icon-container"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-12"><button class="btn btn-block btn-primary"
                                                    type="submit" disabled="">Subscribe</button></div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-3 d-xs-none offset-md-3">
                                    <img class=" "
                                        src="https://luckyegypt.com/_next/static/images/newsletter-cf4449e0c085ae0d6705e36201dfd9e4.png"
                                        alt="newsletter">
                                </div>
                            </div>
                        </div>
                    </div>

                </section> -->
                <!-- End be first to know -->
            </div>

        </section>
    </div>
</div>
<!-- </div> -->