
<div class="comment_block">
     <div class="new_comment">

        <div *ngIf="reviews.length == 0 || reviews == null">
              <p>{{'noreview'| translate}}</p>
        </div>

         <ul class="user_comment" *ngFor="let review of reviews">
             <!-- <div class="user_avatar">
                 <img src="">
             </div> -->
             <div class="comment_body">
                 <p>
                     {{review.comment}}
                 </p>
             </div>

             <div class="comment_toolbar">
                 <div class="comment_details">
                     <ul>
                         <li><i class="fa fa-clock-o"></i>{{'Createdat'| translate}} {{review.created_at | date}}</li>
                         <li><i class="fa fa-calendar"></i>{{'updateat'| translate}} {{review.updated_at | date}}</li>
                         <!-- <li><i class="fa fa-pencil"></i> <span class="user">John Smith</span></li> -->
                     </ul>
                 </div><div class="comment_tools">
                     <ul>
                         <li><star-rating value={{review.rank}} totalstars="5" checkedcolor="orange" uncheckedcolor="black" size="18px" readonly="true"></star-rating></li>
                     </ul>
                 </div>

             </div>


         </ul>


     </div>


    </div>