<div class="container-fluid px-0" id="print">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">

      <div class="mx-3 px-3 my-3 header">
        <div class="header-content">
          <mat-icon [routerLink]="['/otphome/menu', lang]" *ngIf="lang == 'en'"
            >arrow_back</mat-icon
          >
          <mat-icon [routerLink]="['/otphome/menu', lang]" *ngIf="lang == 'ar'"
            >arrow_forward</mat-icon
          >
          <div>
            <h2>
              {{ "menuoperation" | translate }}
            </h2>
            <h3>
              {{ merchantName }}
            </h3>
          </div>
        </div>

        <div *ngIf="type == 'brand_manager'">
          <mat-form-field>
            <mat-select
              (selectionChange)="onMerchantChange($event)"
              [value]="merchant"
            >
              <mat-option
                class="cuurent-status"
                *ngFor="let m of merchantsList"
                [value]="m"
              >
                {{ m.name | titlecase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div>
        <mat-card>

          <section class="p-3 side-list">
            <div class="section-header">
              <h1>{{ "Menu Section" | translate }}</h1>
              <!-- <mat-icon (click)="open(null, editCategory)">add</mat-icon> -->
            </div>
            <nav class="nav nav-pills flex-column">
              <div
                *ngFor="let cat of categories; let i = index"
                [class.active]="currentSection == i"
                class="nav-link"
                (click)="onSectionChange(cat, i)"
              >
                <a>  {{ cat.title | titlecase }}</a>
                <span class="arrow"> <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> </span>
                <!-- <div>
                  <mat-icon class="edit" (click)="open(cat, editCategory)"
                    >edit</mat-icon
                  >
                  <mat-icon class="delete" (click)="open(cat, deleteCategory)"
                    >delete</mat-icon
                  >
                </div> -->
              </div>
              
            </nav>

            <div>
              <h1>{{ "Requests" | translate }}</h1>
            </div>
            <nav class="nav nav-pills flex-column">
              <div class="nav-link" (click)="openStatusBar('draft')">
                <a>{{ "draft" | translate }}</a>
                <div>
                  <span>{{ Draft_products?.length }}</span>
                </div>
              </div>
              <div class="nav-link" (click)="openStatusBar('pending')">
                <a>{{ "pending" | translate }}</a>
                <span>{{Pending_products.length}}</span>
              </div>
              <div class="nav-link" (click)="openStatusBar('rejected')">
                <a>{{ "rejected" | translate }}</a>
                <span class="rejected">{{Rejected_products.length}}</span>
              </div>
              <div class="nav-link" (click)="openStatusBar('approved')">
                <a>{{ "approved" | translate }}</a>
                <span class="approved">{{Approved_products.length}}</span>
              </div>
            </nav>


          </section>

          <section class="p-3 side-list" *ngIf="Sub_categories">
            <div class="section-header">
              <h1>{{ "Menu Sub Section" | translate }}</h1>
            </div>
            <nav class="nav nav-pills flex-column">
              <div
                *ngFor="let cat of Sub_categories; let j = index"
                [class.active]="currentSection2 == j"
                class="nav-link"
                (click)="onSectionChange2(cat, j)"
              >
                <a>{{ cat.name | titlecase }}</a>
                <span class="arrow"> <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> </span>

                
              </div>
              
            </nav>
            
          </section>

          <section class="p-3 main-list">
            <div class="section-header">
              <h1>{{ "Menu Items" | translate }}</h1>
              <div
                style="display: flex; align-items: center; cursor: pointer"
                (click)="addProduct()"
              >
                <mat-icon>add</mat-icon>
                <p style="margin: 0px">{{ "ADD NEW ITEM" | translate }}</p>
              </div>
            </div>

            

            <div
              *ngFor="let prod of displayCat?.data; let i = index"
              class="prod-card"
              style="width: 90%;"
            >
              <app-prod-card
                [prod]="prod"
                (delete)="open($event, deleteProd)"
                (edit)="addProduct($event)"
                (view)="addProduct($event)"
              ></app-prod-card>
            </div>


          </section>

        </mat-card>
      </div>
    </div>
  </div>
</div>

<ng-template #editCategory let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" id="modal-basic-title">
        {{ "Edit menu section" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <div>
      <div role="document">
        <div class="modal-content">
          <div class="px-3 modal-body">
            <div class="alert alert-primary" role="alert">
              {{
                "Choose your section name from suggestions as you type to make your menu changes faster. These match menu guidelines and won’t need review."
                  | translate
              }}
            </div>
            <div class="input-names">
              <mat-form-field appearance="legacy">
                <mat-label>Section name (En)</mat-label>
                <input
                  matInput
                  placeholder="Placeholder"
                  [(ngModel)]="editCat.title"
                />
              </mat-form-field>
              <mat-form-field appearance="legacy">
                <mat-label>اسم القسم (عربي)</mat-label>
                <input
                  matInput
                  placeholder="Placeholder"
                  [(ngModel)]="editCat.title"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link" (click)="modal.dismiss('Cross click')">
      {{ "Cancel" | translate }}
    </button>
    <button class="btn btn-default">
      {{ "Confirm" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #deleteCategory let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" id="modal-basic-title">
        {{ "Are you sure you want to delete?" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <div>
      <div role="document">
        <div class="modal-content">
          <div class="px-3 modal-body">
            {{ "This section" | translate }} ({{ displayCat.title }})
            {{
              "and all the items under it will be deleted and cannot be recovered."
                | translate
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link" (click)="modal.dismiss('Cross click')">
      {{ "Cancel" | translate }}
    </button>
    <button class="btn btn-default">
      {{ "Confirm" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #deleteProd let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" id="modal-basic-title">
        {{ "Are you sure you want to delete?" | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <div>
      <div role="document">
        <div class="modal-content">
          <div class="px-3 modal-body">
            {{ "You are about to delete" | translate }} ({{ editCat.title }}).
            {{ "This action cannot be undone." | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link" (click)="modal.dismiss('Cross click')">
      {{ "Cancel" | translate }}
    </button>
    <button class="btn btn-default" (click)="modal.dismiss(editCat)">
      {{ "Confirm" | translate }}
    </button>
  </div>
</ng-template>

<div
  class="sidebar-slider"
  [class.sidebar-slide-in]="showUpdateBar"
  [class.sidebar-slider-ar]="lang == 'ar'"
>
  <div class="sidebar-header">
    <div
      class="sidebar-close"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'en'"
    >
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div
      class="sidebar-close"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'ar'"
    >
      <mat-icon>arrow_forward</mat-icon>
    </div>
    <div class="branch-name">
      <h1>{{ requestType | translate }}</h1>
    </div>
  </div>

  <div class="sidebar-content" *ngIf="requestType == 'approved'">
    <div *ngFor="let item of Approved_products">
      <app-prod-card
        [prod]="getApproved(item.product)"
        (delete)="open($event, deleteProd)"
        (edit)="addProduct($event)"
        (view)="addProduct($event)"
      ></app-prod-card>
    </div>
  </div>

  <div class="sidebar-content" *ngIf="requestType == 'rejected'">
    <div *ngFor="let item of Rejected_products">
      <app-prod-card
        [prod]="getRejected(item.product)"
        (delete)="open($event, deleteProd)"
        (edit)="addProduct($event)"
        (view)="addProduct($event)"
      ></app-prod-card>
    </div>
  </div>

  <div class="sidebar-content" *ngIf="requestType == 'pending'">
    <div *ngFor="let item of Pending_products">
      <app-prod-card
        [prod]="item.product"
        (delete)="open($event, deleteProd)"
        (edit)="addProduct($event)"
        (view)="addProduct($event)"
      ></app-prod-card>
    </div>
  </div>

  <div class="sidebar-content" *ngIf="requestType == 'draft'">
    <div *ngFor="let item of Draft_products">
      <app-prod-card
        [prod]="item.product"
        (delete)="open($event, deleteProd)"
        (edit)="addProduct($event)"
        (view)="addProduct($event)"
      ></app-prod-card>
    </div>
  </div>

</div>
