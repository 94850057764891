import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fast-donation',
  templateUrl: './fast-donation.component.html',
  styleUrls: ['./fast-donation.component.scss']
})
export class FastDonationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
