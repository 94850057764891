<div class="container">
    <div class="row">

        <div class="col-md-7 col-sm-12 col-xs-12">
            <div class="panel panel-default ar-style">
                <div class="panel-heading">
                    التبرع السريع
                </div>
                <div class="panel-body panel-body-padding">
                    <div class="form-virtical">
                        <div class="form-group">
                            <input type="radio" name="DonationType" value="10268" checked=""> <label class="radio-btn-padding">صدقة</label>
                        </div>
                        <div class="form-group">
                            <input type="radio" name="DonationType" value="10245"> <label class="radio-btn-padding">زكاة</label>
                        </div>
                        <div class="form-group">
                            <input type="radio" name="DonationType" value="180"> <label class="radio-btn-padding">وقف</label>
                            
                        </div>
                        <div class="form-group" style="display:flex">
                            <label>مبلغ التبرع</label>
                            <input type="number" class="txtamount form-control" name="txtAmount" id="txtAmount" value="0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <a  class="btn btn-primary main-btn">متابعة التسوق</a>
                </div>
            </div>

        </div>

        <div class="col-md-5 col-sm-12 col-xs-12">
            <div class="panel panel-default ar-style">
                <div class="panel-heading">
                 بيانات الداعم
                </div>
                <div class="panel-body panel-body-padding">
                    <div class="">
                        <div class="beside">
                            <div class="form-group">
                                <input type="radio" name="DonationType" value="10268" checked=""> <label class="radio-btn-padding">تسجيل دخول</label>
                            </div>
                            <div class="form-group" style="margin-right: 140px;">
                                <input type="radio" name="DonationType" value="10245"> <label class="radio-btn-padding">متبرع جديد</label>
                            </div>
                        </div>
                        
                        
                        <div class="form-group">
                            <label>رقم الجوال</label><br>
                            <input type="number" class="form-control custom-input" name="txtAmount" id="txtAmount" value="">
                        </div>

                        <div class="form-group">
                            <label>كلمة المرور</label><br>
                            <input type="password" class="form-control custom-input" name="txtAmount" id="txtAmount" value="">
                        </div>

                        <div>
                            <div class="form-group">
                                <button type="button" id="btnLogin" class="btn btn-primary">تسجيل الدخول</button>
                                <button type="button" id="btnForgetPassword_" class="btn btn-danger pull-right">نسيت كلمة المرور</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="panel panel-default top-space">
                <div id="PaymentHeader" class="panel-heading custom-text">
                    <h4 class="panel-title">
                        <a class="white-text" data-toggle="collapse" data-parent="#accordion" href="#collapse1">بطاقة ائتمانية أو بطاقة الصراف (مدى) - دفع آمن 100%</a>
                    </h4>
                </div>
                <div id="collapse1" class="panel-collapse collapse">                                            
                    <br>
                    <div class="panel-body" id="pnlCreditCardPayment">
                    </div>
                </div>
            </div>

            <div class="panel panel-default top-space">
                <div id="PaymentHeader" class="panel-heading custom-text">
                    <h4 class="panel-title">
                        <a class="white-text" data-toggle="collapse" data-parent="#accordion" href="#collapse1">تحويل بنكي  </a>
                    </h4>
                </div>
                <!-- <div id="collapse1" class="panel-collapse collapse">                                            
                    <br>
                    <div class="panel-body" id="pnlCreditCardPayment">
                    </div>
                </div> -->
            </div>
            

        </div>

       



    </div>
</div>