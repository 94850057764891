<nav class="navbar navbar-expand-lg navbar-light mobile-nav">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent2"
    aria-controls="navbarSupportedContent2"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent2">
    <!-- <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
  
    </ul> -->

    <ul class="navbar-nav mr-auto">
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../dashboard', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/clipboard.png"
            alt=""
          />

          {{ "Dashboard" | translate }}<span class="sr-only">(current)</span>
        </a>
      </li> -->

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../reports', lang]">
          <!-- <img class="src-icon" src="./assets/images/sahla-icons/clipboard.png" alt=""> -->
          <i class="fas fa-file-alt src-icon side-nav-icons"></i>

          {{ "Reports" | translate }}<span class="sr-only"></span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../opt-orders', lang]">
          <!-- <img class="src-icon" src="./assets/images/sahla-icons/clipboard.png" alt=""> -->
          <i class="fas fa-file-signature src-icon side-nav-icons"></i>
          {{ "Advanced Reports" | translate }}<span class="sr-only"></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../opt-adv-repo', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/shopping-bag.png"
            alt=""
          />

          {{ "orders" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../opt-branch', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/local-network.png"
            alt=""
          />

          {{ "Branch Status" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../discounts', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/check-list.png"
            alt=""
          />
          {{ "discounts" | translate }}</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../menu-performance', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/check-list.png"
            alt=""
          />

          {{ "Menu Performance" | translate }}</a
        >
      </li> -->

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../finances', lang]">
          <!-- <img class="src-icon" src="./assets/images/sahla-icons/maps-and-flags.png" alt=""> -->
          <i class="fas fa-money-bill-wave src-icon side-nav-icons"></i>

          {{ "Finances" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../opening', lang]">
          <!-- <img class="src-icon" src="./assets/images/sahla-icons/cashier.png" alt=""> -->
          <!-- <i class="fas fa-money-bill-wave src-icon side-nav-icons"></i> -->
          <i class="fas fa-clock src-icon side-nav-icons"></i>

          {{ "openingTime" | translate }}</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../online-orders', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/shopping-cart.png"
            alt=""
          />

          {{ "onlineOrders" | translate }}</a
        >
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../all-areas', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/route.png"
            alt=""
          />

          {{ "AllAreas" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../coverage-area', lang]">
          <img
            class="src-icon"
            src="./assets/images/sahla-icons/route.png"
            alt=""
          />

          {{ "Coverage Area" | translate }}</a
        >
      </li>

      <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['../../online-performance', lang]">
          <i class="fas fa-file-contract src-icon side-nav-icons"></i>
            {{'onlinePerformanceReport' | translate}}</a>
        </li> -->

      <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['../../online-sales', lang]">
          <i class="fal fa-file-spreadsheet src-icon side-nav-icons"></i>
            {{'onlineSalesReport' | translate}}</a>
        </li> -->

      <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['../../vendor-performance', lang]">
          <i class="fas fa-chart-bar src-icon side-nav-icons"></i>
            {{'Vendor Performance' | translate}}</a>
        </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../menu', lang]">
          <mat-icon class="src-icon">restaurant_menu</mat-icon>
          {{ "menu management" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../help', lang]">
          <!-- <img class="src-icon" src="./assets/images/sahla-icons/route.png" alt=""> -->
          <i
            class="fas fa-phone-square src-icon side-nav-icon"
            style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
          ></i>
          {{ "Help Center" | translate }}</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['../../requests', lang]">
          <i
            class="fas fa-phone-square src-icon side-nav-icon"
            style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
          ></i>
          {{ "Support Requests" | translate }}</a
        >
      </li> -->
    </ul>
  </div>
</nav>

<div class="side-nav">
  <ul>
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../dashboard', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/clipboard.png"
          alt=""
        />

        {{ "Dashboard" | translate }}<span class="sr-only">(current)</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf="type == 'brand_manager'">
      <a class="nav-link" [routerLink]="['../../reports', lang]">
        <!-- <img class="src-icon" src="./assets/images/sahla-icons/clipboard.png" alt=""> -->
        <i class="fas fa-file-alt src-icon side-nav-icons"></i>
        {{ "Reports" | translate }}<span class="sr-only"></span>
      </a>
    </li>

    <li class="nav-item" *ngIf="type == 'brand_manager'">
      <a class="nav-link" [routerLink]="['../../opt-orders', lang]">
        <!-- <img class="src-icon" src="./assets/images/sahla-icons/clipboard.png" alt=""> -->
        <i class="fas fa-file-signature src-icon side-nav-icons"></i>
        {{ "Advanced Reports" | translate }}<span class="sr-only"></span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../opt-adv-repo', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/shopping-bag.png"
          alt=""
        />

        {{ "orders" | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../opt-branch', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/local-network.png"
          alt=""
        />

        {{ "Branch Status" | translate }}</a
      >
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../menu', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/check-list.png"
          alt=""
        />
        {{ "menuoperation" | translate }}</a
      >
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../menu-performance', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/check-list.png"
          alt=""
        />

        {{ "Menu Performance" | translate }}</a
      >
    </li> -->
    <li class="nav-item" *ngIf="type == 'brand_manager'">
      <a class="nav-link" [routerLink]="['../../finances', lang]">
        <!-- <img class="src-icon" src="./assets/images/sahla-icons/maps-and-flags.png" alt=""> -->
        <!-- <i class="fas fa-money-bill-wave-alt src-icon side-nav-icons"></i> -->
        <i class="fas fa-money-bill-wave src-icon side-nav-icons"></i>
        {{ "Finances" | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../opening', lang]">
        <!-- <img class="src-icon" src="./assets/images/sahla-icons/cashier.png" alt=""> -->
        <i class="fas fa-clock src-icon side-nav-icons"></i>
        {{ "openingTime" | translate }}</a
      >
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../online-orders', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/shopping-cart.png"
          alt=""
        />

        {{ "onlineOrders" | translate }}</a
      >
    </li> -->
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../all-areas', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/route.png"
          alt=""
        />

        {{ "AllAreas" | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../coverage-area', lang]">
        <img
          class="src-icon"
          src="./assets/images/sahla-icons/route.png"
          alt=""
        />

        {{ "Coverage Areas" | translate }}</a
      >
    </li>

    <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['../../online-performance', lang]">
            <i class="fas fa-file-contract src-icon side-nav-icons"></i>

              {{'onlinePerformanceReport' | translate}}</a>
          </li> -->

    <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['../../online-sales', lang]">
           
            <i class="fas fa-file-invoice src-icon side-nav-icons"></i>

              {{'onlineSalesReport' | translate}}</a>
          </li> -->

    <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['../../vendor-performance', lang]">
            <i class="fas fa-chart-bar src-icon side-nav-icons"></i>

              {{'Vendor Performance' | translate}}</a>
          </li> -->
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../menu', lang]">
        <mat-icon class="src-icon">restaurant_menu</mat-icon>
        {{ "menu management" | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../help', lang]">
        <!-- <img class="src-icon" src="./assets/images/sahla-icons/route.png" alt=""> -->
        <i
          class="fas fa-phone-square src-icon side-nav-icon"
          style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
        ></i>

        {{ "Help Center" | translate }}</a
      >
    </li>
    
    <!-- <li class="nav-item">
      <a class="nav-link" [routerLink]="['../../requests', lang]">
        <i
          class="fas fa-phone-square src-icon side-nav-icon"
          style="font-size: 27px; filter: drop-shadow(2px 4px 6px black)"
        ></i>

        {{ "Support Request" | translate }}</a
      >
    </li> -->

  </ul>
</div>
