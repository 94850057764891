import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AboutService} from '../../../shared/services/about.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  lang;
  TermsData;
  Terms_Content_EN;
  Terms_Content_AR;
  termsClass: string = '';

  constructor(private translate: TranslateService,
     private service:AboutService,
     private spinner:NgxSpinnerService,
     private toastrService:ToastrService,
     private router: Router

     ) { }

  ngOnInit() {
    this.spinner.show();
    this.termsClass = this.router.url.toString().split('/')[1];
    console.log(this.termsClass)
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();

      });
    },1999)

    this.service.getStoreInfo().subscribe(res=>{
      this.TermsData = res;
      // console.log("Terms Data =====>", this.TermsData)
      this.Terms_Content_EN = this.TermsData[2]?.content.en
      this.Terms_Content_AR = this.TermsData[2]?.content.ar
      this.spinner.hide();

      // this.About_Image = this.aboutData[0].media_collection[0].preview
    },error=>{
      this.spinner.hide();

      console.log(error.status);
      this.toastrService.error("PLease Check your Internet Connection,and Try Again"); // toasr services

    })


  }

}
