<!--footer section -->
<footer class="footer-light">

	<section class="theme-colored-background">
		<div class="container">
			<div class="row footer-theme partition-f justify-content-between">
				<div class="col-auto">
					<div class="footer-logo">
						<img src="assets/images/sahlalogo.png" alt="">
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<ul class="page-links">
						<li class="nav-list-li inner-li nav-item ">
							<a  class="nav-link" (click)="gotoHome()">
								{{'home'|translate}}
							</a>
						</li>
	
						<!-- <li class="nav-list-li inner-li nav-item " *ngIf="!defaultAddress">
							<a  class="nav-link" (click)="gotoHome2()">
								{{'home'|translate}}
							</a>
						</li> -->
	
	
						<li class="nav-list-li inner-li nav-item " >
							<a (click)="gotoCategories()" class="nav-link">
								{{'categories'|translate}}
							</a>
						</li>
	
						<li class="nav-list-li inner-li nav-item">
							<a (click)="gotoAbout()"  class="nav-link">
							   {{'about-us'|translate}}
							</a>
						</li>
	
						<li>
							<app-why-we-choose></app-why-we-choose>	
						</li>

						<li>
							<app-social></app-social>
						</li>
					</ul>
				</div>

				<div class="col-lg-3 col-md-6">
					<h4>{{'Cities' | translate}}</h4>
					<ul class="two-columns-list">
						<li *ngFor="let city of cities">
							<a>
								{{city.title | titlecase}}
							</a>
						</li>

					</ul>
				</div>

				<div class="col-lg-4 col-md-6 areas-col">
					<h4>{{'Areas' | translate}} </h4>
					<ul class="two-columns-list">
						<li *ngFor="let area of areas">
							<a *ngIf="lang == 'ar'" (click)="gotoMerchants(area.id)">
								{{area.name.ar | titlecase}}
							</a>
							<a *ngIf="lang == 'en'" (click)="gotoMerchants(area.id)">
								{{area.name.en | titlecase}}
							</a>
						</li>
					</ul>
				</div>


				<!-- <div class="col-lg-3 col-md-6">
					<h4>{{'Products' | translate}} </h4>
					<ul class="two-columns-list">
						<li *ngFor="let product of products">
							<a *ngIf="lang == 'ar'">
								{{product.title.ar}}	
							</a>

							<a *ngIf="lang == 'en'">
								{{product.title.en}}	
							</a>
						</li>
					</ul>
				</div> -->

				<!-- <div class="col-lg-3 col-md-6">

					<div style="display: block !important;">
						<div class="footer-logo">
							<img src="assets/images/sahlalogo.png" alt="">
						</div>
						<app-information></app-information>
					</div>
				</div> -->

				<!-- <div class="col-lg-3 col-md-6">
					<div class="sub-title">
						<app-why-we-choose></app-why-we-choose>

					</div>
				</div> -->


				<!-- <div class="col-lg-3 col-md-6">
					<div class="sub-title">
						<div class="footer-title">
							<h4>{{'STORE_INFORMATION'|translate}}</h4>
						</div>
						<div>
							<ul class="contact-list">
								<li class="gray-med-color" *ngIf="lang == 'en' && address_en"><i
										class="fa fa-map-marker"></i>
									<span class="gray-med-color lineHeight" [innerHTML]="address_en"></span>

								</li>
								<li class="gray-med-color" *ngIf="lang == 'ar' && address_ar"> <i
										class="fa fa-map-marker"></i>
									<span class="footer-location gray-med-color lineHeight1"
										[innerHTML]="address_ar"></span>
								</li>
								<li class="gray-med-color" *ngIf="phone"><i class="fa fa-phone"></i>
									<span class="gray-med-color lineHeight1">{{'call-us'|translate}}:</span>
									<p class="gray-med-color lineHeight" [innerHTML]="phone"></p>
								</li>
								<li class="gray-med-color" *ngIf="email"><i class="fa fa-envelope-o"></i><span
										class="gray-med-color lineHeight1">{{'storemail'|translate}}:</span>
									<p class="gray-med-color lineHeight" [innerHTML]="email"></p>
								</li>
								<li class="gray-med-color" *ngIf="fax"><i class="fa fa-fax"></i><span
										class="gray-med-color lineHeight1">{{'storefax'|translate}}</span> <span
										class="gray-med-color lineHeight" [innerHTML]="fax"></span></li>
							</ul>
						</div>
					</div>
				</div> -->

				<!-- <div class="col-lg-3 col-md-6">
					<div class="sub-title">

						<div class="footer-title">
							<h4>{{'FOLLOW_US'|translate}}</h4>
						</div>
						<app-social></app-social>
					</div>
				</div> -->
			</div>
		</div>
	</section>
	<!-- <section class="footer-links">
		<div class="container">
			<div class="row align-items-center">
			<div class="col-sm-3 social">
				<app-social></app-social>
			</div>
			<div class="col-sm-9">
				
				<ul class="page-links">
					<li class="nav-list-li inner-li nav-item " *ngIf="defaultAddress">
						<a  class="nav-link" (click)="gotoHome()">
							{{'home'|translate}}
						</a>
					</li>

				


					<li class="nav-list-li inner-li nav-item " >
						<a (click)="gotoCategories()" class="nav-link">
							{{'categories'|translate}}
						</a>
					</li>

					<li class="nav-list-li inner-li nav-item">
						<a (click)="gotoAbout()"  class="nav-link">
						   {{'about-us'|translate}}
						</a>
					</li>

					<li>
						<app-why-we-choose></app-why-we-choose>	
					</li>
				</ul>

			
			</div>
		</div>
	</div>
	</section> -->
	
	
	<div class="sub-footer">
		<div class="container">
			<app-copyright></app-copyright>
		</div>
	</div>
</footer>
<!--footer section end -->
<!-- Rtl start -->
<!-- <div class="rtl-btn" id="">
    <a href="javascript:void(0)" class="RTL_cls">
        RTL
    </a>
</div> -->
<!-- rtl end -->