<div class="cover">
    <button class="back1" *ngIf="isCitySelected" (click)="goBack()">
        <i class="fas fa-angle-left"></i>
    </button>

    <button class="back" (click)="close()">
        <i class="fas fa-times"></i>
    </button>
    <div class="icon-cover">

        <img src="./assets/images/delivery-truck.png" alt="">
    </div>
    <h3>
        Choose your delivery location
        <span *ngIf="isCitySelected">
            In 
        </span>
        <span class="main-color" *ngIf="isCitySelected">
            {{cityTitle}}
        </span>
    </h3>

    <div class="search-locate">
        <i class="fas fa-search"></i>
        <input type="text" placeholder="Your Location ...." />
        <i class="fa fa-map-pin" (click)="detectLocation()"></i>
    </div>

</div>
<div class="height-content">
    <div class="row mx-0">
        <div class="col-md-6 px-0">
            <div *ngIf="!isCitySelected">
                <button class="locate" *ngFor="let city of cities" (click)="selectCity(city.id, city.title)">
                    <span>
                        {{city.title | titlecase}}
                    </span>
                    <div class="select">
                        <i class="fas fa-arrow-right"></i>
                    </div>
                </button>
            </div>

            <div *ngIf="isCitySelected && !isAreaSelected">
                <button class="locate locate-city" *ngFor="let area of areas" (click)="selectArea(area.id, area.name)">
                    <div class="d-flex align-items-center">
                        <div class="circle">
                            
                        </div>
                        <span *ngIf="lang == 'ar'">
                            {{area.name.ar | titlecase}}
                        </span>
                        <span *ngIf="lang == 'en'">
                            {{area.name.en | titlecase}}
                        </span>
                    </div>
                    <div class="select">
                        <i class="fas fa-arrow-right"></i>
                    </div>
                </button>

                <span class="locate locate-city" *ngIf="areas.length == 0 && show">
                    {{'No Areas Found' | translate}}
                </span>
            </div>

        </div>
        <div class="col-md-6 px-0">
            <div class="choose-city">

                <img src="./assets/images/location.png" alt="">
                <span *ngIf="!isCitySelected">
                    Choose your city
                </span>
                <span *ngIf="isCitySelected && !isAreaSelected">
                    Choose your Area
                </span>
            </div>

            <!-- <div class="select-city">
             
                <button class="locate">
                    <span>
                        Safia Zaghloul
                    </span>
                    <div class="select">
                        Select
                    </div>
                </button>


            </div> -->
        </div>
    </div>
</div>