<!-- Home Slider -->
<section class="p-0">
<ngx-slick-carousel class="home-slider" #slickModal="slick-carousel" [config]="sliderConfig">
        <div *ngFor="let item of slides" ngxSlickItem>
            
            <div [style.background]="'url(' + item['media_collection'][0]?.default + ')'" class="home heading-image text-left p-left"> 
          <!-- <img [src]="item['media_collection'][0]?.large" alt=""> -->
                <div class="container">
                    <div class="row">
                        
                        <div class="col">

                            <div class="slider-contain" *ngIf="item?.title['ar'] || item?.title['en']">
                                <div class="slider-title">
                                    <h1>{{translate.currentLang == 'ar' ? item?.title['ar'] : item?.title['en']}}</h1>
                                    <span class="border-1"></span> 
                                    <span class="border-2"></span>
                                    <span class="border-3"></span>
                                    <span class="border-4"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
</ngx-slick-carousel>
</section>
<!-- Home slider end -->