import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { ProdDetailsComponent } from "src/app/home/components/prod-details/prod-details.component";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { runInThisContext } from "vm";

@Component({
  selector: "app-menu-availability",
  templateUrl: "./menu-availability.component.html",
  styleUrls: ["./menu-availability.component.scss"],
})
export class MenuAvailabilityComponent implements OnInit {
  merchantName;
  id;
  type;
  merchantsList;
  lang;
  merchant: any;
  categories: any;
  currentSection;
  unAvailabeProds: any;
  displayCat: any = [];

  Sub_categories: any = [];
  Basic_Design;
  CategoryID;

  selected_SubCategoryID;
  products: any = [];

  BrandName;
  BrandID;

  products_requests: any = [];
  brand;
  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private toaster: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.type = localStorage.getItem("type");
    this.lang = localStorage.getItem("merchantId");
    this.lang = localStorage.getItem("lang_ref");
    this.getList();
  }

  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http.get("brands_list", {}, headers).subscribe((res: any) => {
      this.merchantsList = res.data;
      console.log("Brand  list ::::::::::", this.merchantsList);
    });
  }

  onbrandchange(BrandID) {
    console.log("Brand Id Changed ::::::::::", BrandID.value.id);
    console.log("Brand  Changed ::::::::::", BrandID.value.title);
    this.brand = BrandID;
    this.BrandName = BrandID.value.title;
    this.BrandID = BrandID.value.id;

    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      brand_id: this.BrandID,
    };
    this.http
      .get("merchant/product-request?status=pending", body, headers)
      .subscribe((res: any) => {
        this.products_requests = res.product_requests;
      });
  }

  updateStatus(e, id) {
    console.log("approve / decline product function ::::::::::::", e.value);

    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });

    let body = {};

    this.http
      .get(
        "merchant/product-request/change-status/" + id + "/" + e.value,
        body,
        headers
      )
      .subscribe((res) => {
        if (res["success"] == true) {
          // this.getCategories();
          this.toaster.success(res["message"]);
          this.onbrandchange(this.brand);
        } else {
          this.toaster.error(res["message"]);
        }
      });
  }
  prodDetails(prod) {
    this.dialog.open(ProdDetailsComponent, {
      data: prod,
      panelClass: "prod-details",
    });
  }
}
