import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { HttpClientService } from "../http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
interface userDeviceModel {
  id: number;
  user_id: number;
  device_id: string;
  device_token: string;
  operating_system: string;
}

@Injectable({
  providedIn: "root",
})
export class FirebaseMessaging {
  currentMessage$: BehaviorSubject<any> = new BehaviorSubject(null);
  token$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "not generated"
  );
  userId: number;
  deviceId = this.getDeviceId();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpClientService: HttpClientService,
    private toasterService: ToastrService
  ) {
    this.userId = JSON.parse(localStorage.getItem("userObject"))?.id;
  }
  requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission == "granted") {
        this.angularFireMessaging.getToken.subscribe(
          (token) => {
            this.token$.next(token);
            if (token) {
              this.createDeviceToken(token);
            }
          },
          (err) => {
            this.token$.next("error from firebase");
            console.error("Unable to get permission to notify.", err);
          }
        );
      } else {
        this.toasterService.error(
          "Please Allow Notifications Permission, Go to browser settings and reset notifications or allow the notification for the website",
          "",
          {
            disableTimeOut: true,
            tapToDismiss: false,
            positionClass: "permission",
          }
        );
        this.token$.next("permission denied");
      }
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      this.currentMessage$.next(payload);
    });
  }

  createDeviceToken(firebaseToken: string) {
    const body = {
      user_id: this.userId,
      operating_system: "merchant",
      device_id: this.deviceId,
      device_token: firebaseToken,
    };
    this.httpClientService
      .post("user-devices", body)
      .subscribe((res: userDeviceModel) => {
        localStorage.setItem("firbaseToken", firebaseToken);
        localStorage.setItem("userDeviceId", res.id.toString());
      });
  }

  updateDeviceToken(firebaseToken: string) {
    const body = {
      operating_system: "merchant",
      device_id: this.deviceId,
      device_token: firebaseToken,
      _method: "put",
    };
    this.httpClientService
      .post(`user-devices/${+localStorage.getItem("userDeviceId")}`, body)
      .subscribe(() => {});
  }

  deleteFirebaseToken() {
    if (localStorage.getItem("firbaseToken")) {
      const headers: HttpHeaders = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("optToken")}`,
      });
      localStorage.removeItem("firbaseToken");
      this.angularFireMessaging.getToken.subscribe(
        (res) => {
          console.log(res, "delted token");
          this.angularFireMessaging.deleteToken(res);
        },
        (e) => {
          console.log(e);
        }
      );
      this.httpClientService
        .get(`auth/logout?device_id=${this.deviceId}`, {}, headers)
        .subscribe();
    }
  }

  generateUUIDV4(): string {
    const s = [];
    const hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";

    const uuid = s.join("");
    return uuid;
  }

  getDeviceId() {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = this.generateUUIDV4();
      localStorage.setItem("deviceId", deviceId);
    }
    return deviceId;
  }
}
