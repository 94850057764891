import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AboutService} from '../../../shared/services/about.service';

declare var $:any;
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  lang;

  privacyData;
  privacy_Content_EN;
  privacy_Content_AR;
  privacyClass: string = '';

  constructor(
    private translate: TranslateService, 
    private service:AboutService,
    private router: Router) { }

  ngOnInit() {
    this.privacyClass = this.router.url.toString().split('/')[1];
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 

    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)


    this.service.getStoreInfo().subscribe(res=>{
    
      this.privacyData = res;
      // console.log("Pivacy Policy Data =====>", this.aboutData[0])
      this.privacy_Content_EN = this.privacyData[1]?.content.en
      this.privacy_Content_AR = this.privacyData[1]?.content.ar
      // this.About_Image = this.aboutData[0].media_collection[0].preview
    })
 
  }

}
