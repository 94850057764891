import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-categories-products',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  lang;
  categories = [];
  constructor(
    public translate: TranslateService, 
    private categoriesService: CategoriesService,
    private router: Router,
    private route: ActivatedRoute) { }

  // collapse toggle
  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang); 
    this.lang = this.translate.currentLang;
    $('.collapse-block-title').on('click', function (e) {
      e.preventDefault;
      var speed = 300;
      var thisItem = $(this).parent(),
        nextLevel = $(this).next('.collection-collapse-block-content');
      if (thisItem.hasClass('open')) {
        thisItem.removeClass('open');
        nextLevel.slideUp(speed);
      } else {
        thisItem.addClass('open');
        nextLevel.slideDown(speed);
      }
    });

    this.categoriesService.getCategories().subscribe((res: any[]) => {
       this.categories = res['categories'];

      //  console.log(this.categories)
     })

  }

  // For mobile view
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

  navigateToCategory(id: number){
    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/category', this.lang], {queryParams:{'categories':id}});
  }

}
