import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-prod-card",
  templateUrl: "./prod-card.component.html",
  styleUrls: ["./prod-card.component.scss"],
})
export class ProdCardComponent implements OnInit {
  @Input() prod;
  @Input() lang;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    
    console.log("The product details :::::::::::::",this.prod);
    this.lang = localStorage.getItem("lang_ref");
    console.log ("edit event ::::::::::", this.edit)
  }
  deleteProd() {
    this.delete.emit(this.prod);
  }
  markAsRead() {}

  editProd(ID) {
    this.edit.emit(this.prod);
  }

  view() {
    this.edit.emit(this.prod);
  }

}
