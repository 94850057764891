import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-side-nav2",
  templateUrl: "./side-nav2.component.html",
  styleUrls: ["./side-nav.component.css"],
})
export class SideNav2Component implements OnInit {
  lang;

  constructor() {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
  }
}
