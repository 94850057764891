<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-md-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-md-11">
      <div class="container-fluid py-5">
        <div class="row">
          <div class="col-md-9"></div>
          <div class="col-md-3">
            <div class="mx-3 px-3 my-3">
              <div class="header">
                <div *ngIf="type == 'brand_manager'">
                  <mat-form-field>
                    <mat-select
                      (selectionChange)="onMerchantChange($event)"
                      [value]="merchant"
                    >
                      <mat-option
                        class="cuurent-status"
                        *ngFor="let m of merchantsList"
                        [value]="m"
                      >
                        {{ m.name | titlecase }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <mat-form-field class="example-full-width">
              <mat-label>Select City</mat-label>
              <input
                type="text"
                placeholder="Pick one"
                aria-label="Select City"
                matInput
                [formControl]="cityCtrl"
                [matAutocomplete]="auto"
              />
              <!-- <p>{{cityTitle['title']}}</p> -->
              <button
                *ngIf="cityCtrl"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clear()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onCitySelectionChanged($event)"
              >
                <mat-option *ngFor="let city of citiesList" [value]="city.id">
                  {{ city.title }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <!-- <mat-form-field class="example-full-width">
              <mat-label>Select Area</mat-label>
              <input
                type="text"
                placeholder="Pick one"
                aria-label="Select Area"
                matInput
                [formControl]="AreaCtrl"
              />
  
              <button
                *ngIf="AreaCtrl"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clear()"
              >
                <mat-icon>close</mat-icon>
              </button>
  
              
                <mat-option *ngFor="let Mainarea of areasList" [value]="Mainarea.id" (onSelectionChange)="onAreaSelectionChanged($event)"  id="mat-option">
                  {{ Mainarea.name }}
                </mat-option>
  
  
            </mat-form-field> -->

            <mat-form-field appearance="fill">
              <mat-label>Select Area</mat-label>
              <mat-select
                [formControl]="AreaCtrl"
                (selectionChange)="onAreaSelectionChanged($event)"
              >
                <mat-option
                  *ngFor="let Mainarea of areasList"
                  [value]="Mainarea.id"
                >
                  {{ Mainarea.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-8">
            <div [formGroup]="areaForm" class="card my-3 mat-elevation-z8">
              <div class="card-header d-flex justify-content-between">
                <p class="card-title" *ngIf="new_area != ''">
                  Apply to all areas in "{{ new_area }}"
                </p>

                <mat-slide-toggle formControlName="is_active"
                  >Active</mat-slide-toggle
                >
              </div>

              <div class="card-body px-0">
                <table class="example-full-width">
                  <tr>
                    <td>
                      <mat-form-field class="min-width">
                        <mat-label class="text-align">{{
                          "deliverfees" | translate
                        }}</mat-label>
                        <input matInput type="number" formControlName="price" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field class="min-width">
                        <mat-label class="text-align">{{
                          "deliveryTime" | translate
                        }}</mat-label>
                        <input
                          matInput
                          type="number"
                          formControlName="duration"
                        />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field class="min-width">
                        <mat-label class="text-align">duration_type</mat-label>
                        <mat-select formControlName="duration_type">
                          <mat-option value="minutes">Minutes</mat-option>
                          <mat-option value="hours">Hours</mat-option>
                          <mat-option value="days">Days</mat-option>
                          <mat-option value="months">Months</mat-option>
                          <mat-option value="years">Years</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td>
                      <button
                        [disabled]="areaForm.invalid"
                        mat-button
                        mat-icon-button
                        style="color: #198dca"
                        (click)="addArea(areaForm.value)"
                      >
                        <mat-icon>save</mat-icon>
                      </button>
                      <button
                        [disabled]="areaForm.invalid"
                        mat-button
                        mat-icon-button
                        style="color: #108d10"
                        (click)="addArea(areaForm.value)"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div
            class="col-md-6 col-sm-12 col-xs-12"
            *ngFor="let area of innerareasList"
          >
            <app-area [area]="area"></app-area>
          </div>
        </div>

        <div class="row text-center" *ngIf="innerareasList?.length > 0">
          <div class="col-sm-12">
            <pagination-controls
              (pageChange)="pageChanged($event)"
              directionLinks="true"
              responsive="true"
              previousLabel="{{ 'previous' | translate }}"
              nextLabel="{{ 'next' | translate }}"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template class="text-center" #noAreas>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-auto">
        <h2>no innerareas on this area</h2>
      </div>
    </div>
  </div>
</ng-template>
