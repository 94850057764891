import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class CoverageAreaService {
  headers: HttpHeaders = new HttpHeaders({
    Authorization: `Bearer ${localStorage.getItem("optToken")}`,
  });
  constructor(private http: HttpClientService) {}

  getCities(ID) {
    let params = {
      merchant_id: ID,
    };
    return this.http.get(
      "merchant/coverage-areas/cities",
      params,
      this.headers
    );
  }

  getAreas(cityId, page, pageSize,ID) {
    let params = {
      merchant_id: ID,
      city_id: cityId,
      // per_page: pageSize,
      // page: page,
      
    };
    return this.http.get("merchant/coverage-areas/areas", params, this.headers);
  }

  getInnerAreas(cityId, page, pageSize,ID,ParentID) {
    let params = {
      merchant_id: ID,
      city_id: cityId,
      // per_page: pageSize,
      // page: page,
      parent_id:ParentID
      
    };
    return this.http.get("merchant/coverage-areas/areas", params, this.headers);
  }

  createAreaMerchant(body: any) {
    return this.http.post(
      `merchant/coverage-areas/create`,
      body,
      {},
      this.headers
    );
  }

  updateAreaMerchant(body: any, areaId) {
    return this.http.post(
      `merchant/coverage-areas/update/${areaId}`,
      body,
      {},
      this.headers
    );
  }
}
