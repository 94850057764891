<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{'createaccount'|translate}}</li>
                    </ol>
                </nav>
            </div>
          
        </div>
    </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>{{'createaccount'|translate}}</h3>
                <div class="theme-card">
                    <form  class="theme-form" [formGroup]="registerForm">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="fname">{{'firstname'|translate}}</label>
                                <input formControlName="fname" type="text" class="form-control {{registerForm.controls['fname'].touched && registerForm.controls['fname'].errors ? 'error-control' : ''}}"
                                    id="fname" placeholder="{{'firstname' | translate}}">
                                <span *ngIf="registerForm.controls['fname'].touched && registerForm.controls['fname']?.errors?.required" class="error-msg">
                                    {{'fnameReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['fname'].touched && registerForm.controls['fname']?.errors?.pattern" class="error-msg">
                                    {{'fnameMustBeCharc'|translate}}.
                                </span>

                            </div>
                            <div class="col-md-6">
                                <label for="lname">{{'lastname'|translate}}</label>
                                <input formControlName="lname" type="text" class="form-control {{registerForm.controls['lname'].touched && registerForm.controls['lname'].errors ? 'error-control' : ''}}"
                                    id="lname" placeholder="{{'lastname' | translate}}">
                                <span *ngIf="registerForm.controls['lname'].touched && registerForm.controls['lname']?.errors?.required" class="error-msg">
                                    {{'lnameReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['lname'].touched && registerForm.controls['lname']?.errors?.pattern" class="error-msg">
                                    {{'lnameMustBeCharc'|translate}}.
                                </span>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="email">{{'email'|translate}}</label>
                                <!-- <input type="text" class="form-control is-valid" id="email" required> -->
                                <input formControlName="email" type="text" class="form-control {{registerForm.controls['email'].touched && registerForm.controls['email'].errors ? 'error-control' : ''}}"
                                    id="email" placeholder="{{'email' | translate}}">
                                <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email']?.errors?.required" class="error-msg">
                                    {{'emailReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['email'].touched && registerForm.controls['email']?.errors?.email" class="error-msg">
                                    {{'emailmustbe'|translate}}.
                                </span>
                            </div>
                            <div class="col-md-6">
                                <label for="phoneno">{{'phoneno'|translate}}</label>
                                <input formControlName="mobile" type="tel" class="form-control {{registerForm.controls['mobile'].touched && registerForm.controls['mobile'].errors ? 'error-control' : ''}}"
                                    id="mobile" placeholder="{{'phoneno' | translate}}" style="font-family: sans-serif;">
                                <span *ngIf="registerForm.controls['mobile'].touched && registerForm.controls['mobile']?.errors?.required" class="error-msg">
                                    {{'phoneReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['mobile'].touched && registerForm.controls['mobile']?.errors?.pattern" class="error-msg">
                                    {{'phonePatMes'|translate}}.
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="password">{{'Password'|translate}}</label>
                                <input formControlName="password" type="password" class="form-control {{registerForm.controls['password'].touched && registerForm.controls['password'].errors ? 'error-control' : ''}}"
                                    id="password" placeholder="{{'Password'|translate}}">
                                <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password']?.errors?.required" class="error-msg">
                                    {{'passwordReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['password'].touched && registerForm.controls['password']?.errors?.minlength" class="error-msg">
                                    {{'passwordMinLengMes'|translate}}.
                                </span>
                            </div>
                            <div class="col-md-6">
                                <label for="password">{{'repeatPassword'|translate}}</label>
                                <input formControlName="retypePassword" type="password" class="form-control {{registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword'].errors ? 'error-control' : ''}}"
                                    id="password" placeholder="{{'repeatPassword' | translate}}">
                                <span *ngIf="registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword']?.errors?.required"
                                    class="error-msg">
                                    {{'repeatPasswordReqMes'|translate}}
                                </span>
                                <span *ngIf="registerForm.controls['retypePassword'].touched && registerForm.controls['retypePassword']?.errors?.minlength"
                                    class="error-msg">
                                    {{'repeatPasswordMinLengMes'|translate}}.
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">

                            <button [disabled]="!registerForm.valid" (click)="register()" class="btn btn-solid login-btn">{{'createaccount'|translate}}</button>
                        </div>
                    </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->