<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Coverage Areas" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="header">
          <mat-form-field class="example-full-width">
            <mat-label>{{ "search" | translate | titlecase }}</mat-label>
            <input matInput [(ngModel)]="searchValue" />
          </mat-form-field>
          <button mat-button (click)="onSearch()" [disabled]="!searchValue">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button mat-raised-button (click)="getAllAreas()">
            {{ "reset" | translate | titlecase }}
          </button>
        </div>
        <ul>
          <li *ngFor="let area of areas" class="list-item">
            <div>
              <h3>{{ area?.area_title }}</h3>
              <p>
                {{ "Min Order Amount" | translate }}: {{ area?.minimum_orders }}
              </p>
              <p>
                {{ "deliveryTime" | translate }}: {{ area?.duration }}
                {{ area?.duration_type_name }}
              </p>
              <p>
                {{ "deliverfees" | translate }}: {{ area?.price }}
                {{ "EGP" | translate }}
              </p>
            </div>
            <div>
              <mat-slide-toggle
                class="example-margin"
                [checked]="area?.is_active == 1 ? true : false"
                (change)="updateArea($event, area)"
              >
              </mat-slide-toggle>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
