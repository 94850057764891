import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private httpClientService: HttpClientService) { }

  getCategories() {
    
    return this.httpClientService.get('categories', {});
  }
  getsubCategories(id) {
    return this.httpClientService.get(`sub-categories`, { categories: id });
  }

  getSubSubCategories(subCatID) {
    return this.httpClientService.get(`sub-sub-categories`, {categories: subCatID})
  }


  getCategoryAttributes(catID) {
    return this.httpClientService.get('attributes', { category: catID });
  }

  getBrands(){
    return this.httpClientService.get('brands/1',{});
  }

  getCuisines(){
    return this.httpClientService.get('cuisines',{});
  }

  getAreas(){
    return this.httpClientService.get('areas', {});
  }

}
