<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <!-- <div class="col-1">
      <app-side-nav></app-side-nav>
    </div> -->

    <div class="col-12">
      <div class="mx-3 px-3 my-3">
        <div class="row justify-content-between">
          <div class="col-1 align-self-start">
            <h3 style="width: max-content">{{ merchantName }}</h3>
          </div>
          <div class="col-1 align-self-end">
            <button class="btn cuurent-status" (click)="showUpdateBar = true">
              <span class="circle" [class]="status"></span>
              <span>{{ status | translate }}</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 tabs">
            <div class="list-group" id="list-tab" role="tablist">
              <a
                class="list-group-item list-group-item-action active"
                (click)="resetproducts()"
                id="list-home-list"
                data-toggle="list"
                href="#list-home"
                role="tab"
                aria-controls="home"
              >
                {{ "Order Overview" | translate }}
              </a>
              <a
                class="list-group-item list-group-item-action"
                (click)="resetproducts()"
                id="list-profile-list"
                data-toggle="list"
                href="#list-profile"
                role="tab"
                aria-controls="profile"
              >
                {{ "Recent Orders" | translate }}
              </a>
              <a
                class="list-group-item list-group-item-action"
                (click)="resetproducts()"
                id="list-messages-list"
                data-toggle="list"
                href="#list-messages"
                role="tab"
                aria-controls="messages"
                >{{ "my menu" | translate }}</a
              >
            </div>
          </div>
          <div class="col-md-8">
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show"
                [ngClass]="!active ? 'active show' : ''"
                id="list-home"
                role="tabpanel"
                aria-labelledby="list-home-list"
              >
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h3 class="new_order_title">
                      {{ "new" | translate }}
                      <span class="new_order_no">{{ newOrders?.length }}</span>
                    </h3>
                    <div class="card" *ngIf="newOrders?.length == 0">
                      <div class="card-body">
                        {{ "noNewOrders" | translate }}
                      </div>
                    </div>

                    <!-- <div class="sidebar-opener" *ngFor="let order of newOrders">
                      <div class="card accepted-card">
                        <div class="card-body purple-bg">
                          <div class="d-flex justify-content-between align-items-center" (click)="openSideBar(order)">
                            <div>
                              <h4 class="mb-0">
                                #{{order?.id}}
                              </h4>
                            </div>
                            <div>
                             {{order?.created_at | date:'shortTime'}}

                            </div>
                          </div>
                          <div class="d-flex flex-wrap justify-content-between accepted-btns">
                            <button type="button" class="btn btn-outline-light" (click)="updateOrderStatus(order?.id, 'accepted')">{{'Accept' | translate}}</button>
                            <button type="button" class="btn btn-outline-light" (click)="open(content)">{{'Reject' | translate}}</button>
                          </div>

                        </div>
                      </div>
                    </div> -->

                    <div
                      class="card accepted-status"
                      [ngClass]="
                        order.order_status != 'rejected' &&
                        order.order_status != 'delivered' &&
                        order.order_status != 'cancelled' &&
                        order.order_status != 'canceled'
                          ? ''
                          : 'dn'
                      "
                      *ngFor="let order of newOrders"
                    >
                      <div>
                        <!-- <div class="card-body" (click)="openSideBar(order)"> -->
                        <div
                          class="card-body new-order-padding"
                          (click)="OpenNewOrder(content3, order)"
                        >
                          <div
                            class="w-100 d-flex flex-wrap justify-content-between align-items-center"
                          >
                            <h2 class="mb-0 order-ti">#{{ order?.id }}</h2>
                            <!-- <span class="badge badge-pill badge-secondary">
                              {{order?.status_name}}
                            </span> -->
                            <span class="order-t">
                              {{ order.delivery_time }} {{ "min" | translate }}
                            </span>
                            <!-- <span class="badge badge-pill badge-primary">
                              Test Order
                            </span> -->
                            <!-- <span class="badge badge-pill badge-info">
                              <span>
                                {{order.payment_method[0]}}
                              </span>
                              <span *ngIf="order.payment_method[1]">
                                , {{order.payment_method[1]}}
                              </span>
                            </span> -->
                          </div>

                          <p class="itemsNo">
                            {{ order.products?.length
                            }}<span style="padding: 0px 5px">{{
                              "Items" | translate
                            }}</span>
                          </p>
                          <!-- <p *ngFor="let product of order.products">
                            <span *ngIf="lang == 'ar'">
                              {{product?.product?.title.ar}}
                            </span>
  
                            <span *ngIf="lang == 'en'">
                              {{product?.product?.title.en}}
                            </span>
                          </p> -->
                        </div>
                        <!-- <button type="button" class="btn btn-outline-dark form-control" (click)="open(content, order.id, 'rejection')">{{'Reject' | translate}}</button> -->
                      </div>
                    </div>

                    <div
                      class="sidebar-slider"
                      [class.sidebar-slide-in]="sidebarShow"
                    >
                      <div
                        class="sidebar-close"
                        (click)="sidebarShow = !sidebarShow"
                      >
                        X
                      </div>

                      <div class="sidebar-content">
                        <div>
                          <div
                            class="my-2 w-100 d-flex flex-wrap justify-content-between"
                          >
                            <h2
                              class="modal-title mb-0 order-id"
                              id="modal-basic-title"
                            >
                              {{ "Order" | translate }}
                              <span style="padding: 0px 5px"
                                >#{{ slictedOrder?.id }}</span
                              >
                            </h2>

                            <!-- <span class="mints">{{slictedOrder?.created_at | date:'shortTime'}}</span> -->
                          </div>

                          <!-- <p>{{slictedOrder?.status_name}}</p> -->

                          <!-- <div class="badge badge-pill badge-primary text-uppercase">
                            Test Order
                          </div> -->

                          <div class="order-content">
                            <div class="d-flex" style="margin-bottom: 10px">
                              <a
                                href="https://www.google.com/search?q={{
                                  lat
                                }},{{ lng }}"
                                target="_blank"
                                ><i class="fas fa-map-pin"></i
                              ></a>
                              <div>
                                <div class="main-det">
                                  <a
                                    href="https://www.google.com/search?q={{
                                      lat
                                    }},{{ lng }}"
                                    target="_blank"
                                    style="margin-top: 10px; color: black"
                                  >
                                  </a>
                                  <p class="floor-no">
                                    <span>{{ "street" | translate }}</span
                                    >:
                                    {{
                                      slictedOrder?.shipping_address_arr
                                        .street_name
                                    }}
                                  </p>
                                  <p class="floor-no">
                                    <span>{{ "building" | translate }}</span
                                    >:
                                    {{
                                      slictedOrder?.shipping_address_arr
                                        .building_number
                                    }}
                                  </p>
                                  <p class="floor-no">
                                    <span>{{ "Floor" | translate }}</span
                                    >:
                                    {{
                                      slictedOrder?.shipping_address_arr
                                        .floor_number
                                    }}
                                  </p>
                                  <p class="floor-no">
                                    <span>{{ "Apt" | translate }}</span
                                    >:
                                    {{
                                      slictedOrder?.shipping_address_arr
                                        .apartment_number
                                    }}
                                  </p>
                                </div>

                                <!-- <div class="det">
                                  <p>
                                    Floor:1
                                  </p>

                                  <p>
                                    APT:42
                                  </p>
                                </div> -->
                              </div>
                            </div>

                            <div
                              class="d-flex align-items-center"
                              style="margin-bottom: 10px"
                            >
                              <i class="fas fa-phone-alt"></i>
                              <p
                                class="floor-no"
                                style="margin-top: 10px; color: black"
                              >
                                <span>{{ slictedOrder?.user?.mobile }}</span>
                              </p>
                            </div>

                            <div
                              class="d-flex align-items-center"
                              style="margin-bottom: 10px"
                            >
                              <i class="fas fa-user"></i>

                              <p
                                class="floor-no"
                                style="margin-top: 10px; color: black"
                              >
                                <span
                                  >{{ slictedOrder?.user?.first_name }}
                                  {{ slictedOrder?.user?.last_name }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="seperator"></div> -->

                        <div class="gray-bg">
                          <div
                            class="row order-row"
                            *ngFor="let product of slictedOrder?.products"
                          >
                            <div class="col-sm-2">
                              <h2 style="margin-top: -10px">
                                <span style="color: #f96755; font-weight: bold">
                                  {{ product?.quantity }} X</span
                                >
                              </h2>
                            </div>
                            <div class="col-sm-8">
                              <h4>
                                <p *ngIf="lang == 'ar'">
                                  {{ product?.product?.title.ar }}
                                </p>
                                <p *ngIf="lang == 'en'">
                                  {{ product?.product?.title.en }}
                                </p>
                              </h4>

                              <span
                                class="text-purple"
                                *ngIf="lang == 'ar'"
                                [innerHtml]="product?.product?.content?.ar"
                              >
                              </span>

                              <span
                                class="text-purple"
                                *ngIf="lang == 'en'"
                                [innerHtml]="product?.product?.content?.en"
                              >
                              </span>
                            </div>
                            <div class="col-sm-2">
                              <span>
                                {{ product?.price }} {{ "EGP" | translate }}
                              </span>
                            </div>

                            <div
                              class="row"
                              style="width: 100%"
                              *ngIf="product.order_product_fields?.length > 0"
                            >
                              <!-- <div class="col-md-4">
                                <p class="special-request">
                                  {{ "Special Requests" | translate }} :
                                </p>
                              </div> -->

                              <div class="col-md-12">
                                <div
                                  class="total"
                                  *ngFor="
                                    let special of product.order_product_fields
                                  "
                                >
                                  <div
                                    class="d-flex justify-content-between"
                                    style="color: gray"
                                    *ngIf="lang == 'en'"
                                  >
                                    {{ special?.field_option.value.en }}
                                    <span class="specisl-req-value"
                                      >{{ special?.value }}
                                      {{ "EGP" | translate }}</span
                                    >
                                  </div>

                                  <div
                                    class="d-flex justify-content-between"
                                    style="color: gray"
                                    *ngIf="lang == 'ar'"
                                  >
                                    {{ special?.field_option.value.ar }}
                                    <span class="specisl-req-value"
                                      >{{ special?.value }}
                                      {{ "EGP" | translate }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="size">
                            <div class="row">
                              <div class="col-sm-2"></div>
                              <div class="col-sm-8">

                                <p>
                                  0 x large
                                </p>
                              </div>
                              <div class="col-sm-2">

                                <p>
                                  0.00$
                                </p>
                              </div>

                            </div>
                          </div> -->

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "deliverfees" | translate }}</p>

                              <p>
                                {{ slictedOrder?.delivery_fees }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "vat" | translate }}</p>

                              <p>
                                {{ slictedOrder?.vat }} {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "cashback" | translate }}</p>

                              <p>
                                {{ slictedOrder?.cashback_amount }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "subtotal" | translate }}</p>

                              <p>
                                {{ slictedOrder?.sub_total }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "total" | translate }}</p>

                              <p>
                                {{ slictedOrder?.total }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total">
                            <div class="d-flex justify-content-between">
                              <p>{{ "Amount Paid By Wallet" | translate }}</p>

                              <p>
                                {{ slictedOrder?.wallet_amount }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>

                          <div class="total2">
                            <div class="d-flex justify-content-between">
                              <p>
                                {{
                                  "the remaining amount to be paid" | translate
                                }}
                              </p>

                              <p>
                                {{
                                  slictedOrder?.total -
                                    slictedOrder?.wallet_amount
                                }}
                                {{ "egp" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <p
                          *ngIf="slictedOrder?.comment != null"
                          class="order-comment"
                        >
                          {{ "Comment" | translate }}:
                          <span class="order-comment-span"
                            >{{ slictedOrder?.comment }}
                          </span>
                        </p>

                        <div class="payment-btns">
                          <button
                            class="btn w-100 mb-3 order-details-payment-method"
                            *ngFor="
                              let item of slictedOrder?.payment_method;
                              let i = index
                            "
                          >
                            {{ item }}
                          </button>
                          <!-- <button class="btn w-100 mb-3 order-details-payment-method" *ngIf="slictedOrder?.payment_method[1]"> {{slictedOrder?.payment_method[1]}}</button> -->
                        </div>

                        <!-- <div class="alert alert-primary my-3" role="alert">
                          On its way!
                        </div> -->

                        <div class="on-way-container">
                          <!-- <button class="form-control" *ngIf="slictedOrder?.order_status == 'pending'" (click)="updateOrderStatus(slictedOrder?.id, 'accepted')">{{'Accept' | translate}}</button> -->

                          <!-- <button class="form-control" *ngIf="slictedOrder?.order_status == 'pending'" (click)="updateOrderStatus(slictedOrder?.id, 'processing')">{{'Processing' | translate}}</button>
                          <button class="form-control" *ngIf="slictedOrder?.order_status == 'processing'" (click)="updateOrderStatus(slictedOrder?.id, 'picked_up')">{{'Pick up' | translate}}</button>
                          <button class="form-control" *ngIf="slictedOrder?.order_status == 'picked_up'" (click)="updateOrderStatus(slictedOrder?.id, 'delivered')">{{'Deliver' | translate}}</button> -->

                          <!-- <button 
                            class="form-control my-2" 
                            *ngIf="slictedOrder?.order_status != 'delivered' || slictedOrder?.order_status != 'cancelled' || slictedOrder?.order_status != 'rejected'" 
                            (click)="open(content, slictedOrder?.id, 'cancellation')">
                            {{'Cancel' | translate}}
                          </button> -->

                          <h3 class="its-way-label">
                            Delivery expected for
                            {{ slictedOrder?.delivery_time }}
                            {{
                              slictedOrder?.delivery_type == 1
                                ? ("min" | translate)
                                : slictedOrder?.delivery_type == 2
                                ? ("hours" | translate)
                                : slictedOrder?.delivery_type == 3
                                ? ("days" | translate)
                                : ("month" | translate)
                            }}
                          </h3>
                          <button
                            class="its-way-btn-details"
                            (click)="
                              UpdateOrderStatusNoTime(
                                slictedOrder?.id,
                                'picked_up'
                              )
                            "
                          >
                            {{ "On its way" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h3 class="new_order_title">
                      {{ "Accepted" | translate }}
                      <span class="new_order_no">{{
                        acceptedOrders?.length
                      }}</span>
                    </h3>

                    <div class="card" *ngIf="acceptedOrders?.length == 0">
                      <div class="card-body">
                        {{ "noAcceptedOrders" | translate }}
                      </div>
                    </div>

                    <div
                      class="card accepted-status"
                      [ngClass]="
                        order.order_status != 'rejected' &&
                        order.order_status != 'delivered'
                          ? ''
                          : 'dn'
                      "
                      *ngFor="let order of acceptedOrders"
                    >
                      <div>
                        <div
                          class="card-body"
                          style="padding: 0px 10px 5px 10px !important"
                          (click)="openSideBar(order)"
                        >
                          <div class="my-2 w-100 align-items-center">
                            <div class="row">
                              <div
                                class="col-md-4 col-sm-4 col-xs-4 third-width"
                              >
                                <h2 class="mb-0 order-ti">#{{ order?.id }}</h2>
                                <!-- <span class="badge badge-pill badge-secondary">
                                    {{order?.order_status}}
                                  </span> -->
                              </div>

                              <div
                                class="col-md-8 col-sm-8 col-xs-8 text-right twothird-width2"
                              >
                                <span
                                  class="payment-method"
                                  *ngFor="
                                    let item of order.payment_method;
                                    let i = index
                                  "
                                >
                                  {{ item }}
                                </span>

                                <button
                                  class="its-way-btn"
                                  (click)="
                                    UpdateOrderStatusNoTime(
                                      order.id,
                                      'picked_up'
                                    )
                                  "
                                >
                                  {{ "On its way" | translate }}
                                </button>
                              </div>
                            </div>

                            <!-- <span class="badge badge-pill badge-info">
                              <span>
                                {{order.payment_method[0]}}
                              </span>
                              <span *ngIf="order.payment_method[2]">
                                , {{order.payment_method[2]}}
                              </span>
                            </span> -->
                          </div>

                          <p class="itemsNo">
                            {{ order.products?.length
                            }}<span style="padding: 0px 5px">{{
                              "Items" | translate
                            }}</span>
                          </p>

                          <!-- <p *ngFor="let product of order.products">
                            <span *ngIf="lang == 'ar'">
                              {{product?.product?.title.ar}}
                            </span>
  
                            <span *ngIf="lang == 'en'">
                              {{product?.product?.title.en}}
                            </span>
                          </p> -->
                        </div>
                        <div class="seperator2"></div>
                        <div class="row" style="padding: 5px 25px">
                          <div
                            class="col-md-8 col-sm-8 col-xs-8 twothird-width3 usr-data"
                            style="padding: 0px"
                          >
                            <i class="fas fa-user usr-icon"></i>
                            <span class="usr-name" style="color: #878787">{{
                              order.merchant.name
                            }}</span>
                          </div>
                          <div
                            class="col-md-4 col-sm-4 col-xs-4 text-right third-width3"
                          >
                            <h5 class="time">
                              {{ order.delivery_time }}
                              {{
                                order?.delivery_type == 1
                                  ? ("min" | translate)
                                  : order?.delivery_type == 2
                                  ? ("hours" | translate)
                                  : order?.delivery_type == 3
                                  ? ("days" | translate)
                                  : ("month" | translate)
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- <button type="button" class="btn btn-outline-dark" (click)="open(content, order.id)">{{'Reject' | translate}}</button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="list-profile"
                role="tabpanel"
                aria-labelledby="list-profile-list"
              >
                <h2>
                  {{ "Recent Orders" | translate }}
                </h2>

                <table class="table" *ngIf="latestOrders?.length > 0">
                  <thead>
                    <tr style="background: #f8f9fa">
                      <th scope="col">{{ "id" | translate }}</th>
                      <th scope="col">{{ "created_at" | translate }}</th>
                      <th scope="col">{{ "total" | translate }}</th>
                      <!-- <th scope="col">{{'payment_methods' | translate}}</th> -->
                      <!-- <th scope="col">{{'shipping_address' | translate}}</th> -->
                      <th scope="col">{{ "Customer" | translate }}</th>
                      <th scope="col">{{ "status" | translate }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="let order of latestOrders"
                      (click)="OpenNewOrder(content3, order, true)"
                    >
                      <th>{{ order.id }}</th>
                      <td>{{ order.time_only }}</td>
                      <td>
                        {{ order.total }}
                        <!-- {{'EGP' | translate}} -->
                        <span *ngIf="lang == 'en'">{{
                          order.currency.code.en
                        }}</span>
                        <span *ngIf="lang == 'ar'">{{
                          order.currency.code.ar
                        }}</span>
                      </td>
                      <!-- <td>
                        <span>{{order.payment_methods_translation[0]}}</span>
                        <span *ngIf="order.payment_methods_translation[1]">, {{order.payment_methods_translation[1]}}</span>
                      </td> -->
                      <!-- <td>{{order.shipping_address}}</td> -->
                      <td>{{ order.first_name }} {{ order.last_name }}</td>
                      <td>
                        <span
                          class="pending"
                          *ngIf="order.order_status == 'pending'"
                          >{{ "In_progress" | translate }}</span
                        >
                        <span
                          class="picked_up"
                          *ngIf="order.order_status == 'picked_up'"
                          >{{ "On_its_way" | translate }}</span
                        >
                        <span
                          class="accept"
                          *ngIf="order.order_status == 'processing'"
                          >{{ "Accepted" | translate }}</span
                        >
                        <span
                          class="status"
                          *ngIf="order.order_status == 'delivered'"
                          >{{ "Delivered" | translate }}</span
                        >
                        <span
                          class="rejected"
                          *ngIf="order.order_status == 'rejected'"
                          >{{ "Rejected" | translate }}</span
                        >
                        <span
                          class="canceled"
                          *ngIf="order.order_status == 'cancelled'"
                          >{{ "Cancelled" | translate }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="text-center" *ngIf="latestOrders?.length == 0">
                  {{ "NO_ORDERS" | translate }}
                </div>

                <div
                  class="w-100 pagination mt-3 text-center"
                  *ngIf="latestOrders?.length > 0"
                >
                  <!-- <mat-paginator
                    class="w-100"
                    (page)="handlePageEvent($event)"
                    [length]="totalCount"
                    [pageSize]="perPage"
                  >
                  </mat-paginator> -->
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="list-messages"
                role="tabpanel"
                aria-labelledby="list-messages-list"
              >
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 third-width">
                    <mat-accordion class="example-headers-align">
                      <mat-expansion-panel hideToggle class="unavailable">
                        <mat-expansion-panel-header
                          (click)="getunAvailabeProds()"
                        >
                          <mat-panel-title>
                            {{ "unavailable products" | translate }}
                          </mat-panel-title>
                          <span class="productcount">
                            {{ unAvailabeProds?.length }}
                          </span>
                        </mat-expansion-panel-header>
                      </mat-expansion-panel>
                      <mat-expansion-panel
                        hideToggle
                        (click)="getProducts(i, category.title)"
                        *ngFor="let category of categories; let i = index"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{ category.title }}
                          </mat-panel-title>
                          <span class="productcount">{{
                            getlength(category.data)
                          }}</span>
                        </mat-expansion-panel-header>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 twothird-width">
                    <div class="products-div" *ngIf="prods?.length != 0">
                      <div class="col-md-12" style="padding: 0px">
                        <h4 class="category">{{ categoryName | translate }}</h4>
                        <div *ngFor="let product of prods">
                          <div class="details">
                            <span>{{ product?.title }}</span>
                            <!-- <span>
                                {{product?.price}}
                                <span *ngIf="lang == 'ar'">{{product?.currency.ar}}</span>
                                <span *ngIf="lang == 'en'">{{product?.currency.en}}</span>
                              </span> -->

                            <!-- <button class="btn" (click)="open2(content2, product)">
                                  <i class="fa fa-pencil"></i>
                              </button> -->

                            <mat-form-field
                              appearance="fill"
                              style="font-size: 10px"
                            >
                              <!-- <mat-label>{{'select status' | translate}}</mat-label> -->
                              <mat-select
                                [(value)]="product.published"
                                (selectionChange)="
                                  updateStatus($event, product?.id)
                                "
                              >
                                <mat-option [value]="1">
                                  {{ "avaialable" | translate }}
                                </mat-option>
                                <mat-option [value]="0">
                                  {{ "Unavailable Today" | translate }}
                                </mat-option>
                                <mat-option [value]="2">
                                  {{ "Unavailable Indefinitely" | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- <div *ngIf="products.length == 0" class="details">
                          {{ "noproduct" | translate }}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- =============================== Start Popups ============================ -->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="type == 'rejection'">
      {{ "Choose Reject Reason" | translate }}
    </h4>
    <h4
      class="modal-title"
      id="modal-basic-title"
      *ngIf="type == 'cancellation'"
    >
      {{ "Choose Cancel Reason" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <select class="form-control" (change)="onChange($event)">
      <option selected disabled *ngIf="type == 'cancellation'">
        {{ "cancelReason" | translate }}
      </option>
      <option selected disabled *ngIf="type == 'rejection'">
        {{ "rejectReason" | translate }}
      </option>
      <option [value]="reason?.id" *ngFor="let reason of cancellationReasons">
        {{ reason?.title }}
      </option>
    </select>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-dark"
      *ngIf="type == 'rejection'"
      (click)="cancelOrder('rejected')"
    >
      {{ "Reject" | translate }}
    </button>
    <button
      class="btn btn-outline-dark"
      *ngIf="type == 'cancellation'"
      (click)="cancelOrder('cancelled')"
    >
      {{ "Cancel" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0" id="modal-basic-title">
      {{ "Update Product Request" | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>{{ "En Name" | translate }}</label>
            <input
              type="text"
              formControlName="name_en"
              class="form-control"
              placeholder="{{ 'En Name' | translate }}"
            />
            <span
              *ngIf="
                productForm.controls['name_en'].dirty &&
                productForm.controls['name_en']?.errors?.required
              "
              class="error-msg"
            >
              {{ "EnnameReq" | translate }}
            </span>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>{{ "Ar Name" | translate }}</label>
            <input
              type="text"
              formControlName="name_ar"
              class="form-control"
              placeholder="{{ 'Ar Name' | translate }}"
            />
            <span
              *ngIf="
                productForm.controls['name_ar'].dirty &&
                productForm.controls['name_ar']?.errors?.required
              "
              class="error-msg"
            >
              {{ "ArnameReq" | translate }}
            </span>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>{{ "price" | translate }}</label>
            <input
              type="text"
              formControlName="price"
              class="form-control"
              placeholder="{{ 'price' | translate }}"
            />
            <span
              *ngIf="
                productForm.controls['price'].dirty &&
                productForm.controls['price']?.errors?.required
              "
              class="error-msg"
            >
              {{ "priceReq" | translate }}
            </span>
          </div>
        </div>
      </div>

      <button class="btn btn-primary" type="submit">
        {{ "save" | translate }}
      </button>
    </form>
  </div>
  <!-- <div class="modal-footer">
      
  </div> -->
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <button
      class="decline-btn"
      (click)="
        modal.dismiss('Cross click');
        OpenDeclineOrder(content4, NewOrderObj.id, 'rejection')
      "
    >
      {{ "Decline" | translate }}
    </button>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <h2 class="modal-title mb-0 order-id" id="modal-basic-title">
      {{ "Order" | translate }}
      <span style="padding: 0px 5px">#{{ NewOrderObj.id }}</span>
    </h2>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <a
          href="https://www.google.com/search?q={{ lat }},{{ lng }}"
          target="_blank"
          ><i class="fas fa-map-marker-alt order-address-icon"></i>
        </a>
        <p class="floor-no">
          <span>{{ "street" | translate }}</span
          >: {{ NewOrderObj.shipping_address_arr.street_name }}
        </p>
        <p class="floor-no">
          <span>{{ "building" | translate }}</span
          >: {{ NewOrderObj.shipping_address_arr.building_number }}
        </p>
        <p class="floor-no">
          <span>{{ "Floor" | translate }}</span
          >: {{ NewOrderObj.shipping_address_arr.floor_number }}
        </p>
        <p class="floor-no">
          <span>{{ "Apt" | translate }}</span
          >: {{ NewOrderObj.shipping_address_arr.apartment_number }}
        </p>

        <p style="margin-top: 40px">
          <i class="fas fa-phone order-address-icon"></i>
          <span class="order-address">{{ NewOrderObj.mobile }}</span>
        </p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">

        <agm-map [latitude]="lat" [longitude]="lng">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>

      </div>
    </div> -->

    <div class="row grey-bg">
      <div
        class="row order-row"
        style="padding: 20px"
        *ngFor="let product of NewOrderObj?.products"
      >
        <div class="col-sm-2">
          <h2 style="margin-top: -10px">
            <span style="color: #f96755; font-weight: bold">
              {{ product?.quantity }} X</span
            >
          </h2>
        </div>
        <div class="col-sm-8">
          <h6>
            <p *ngIf="lang == 'ar'" style="font-size: 17px; font-weight: bold">
              {{ product?.product?.title.ar }}
            </p>
            <p *ngIf="lang == 'en'" style="font-size: 17px; font-weight: bold">
              {{ product?.product?.title.en }}
            </p>
          </h6>

          <span
            class="text-purple"
            *ngIf="lang == 'ar'"
            [innerHtml]="product?.product?.content?.ar"
          >
          </span>

          <span
            class="text-purple"
            *ngIf="lang == 'en'"
            [innerHtml]="product?.product?.content?.en"
          >
          </span>

          <div *ngIf="product?.notes">
            {{ "Notes" | translate }}: {{ product?.notes }}
          </div>
        </div>
        <div class="col-sm-2">
          <span> {{ product?.price }} {{ "EGP" | translate }} </span>
        </div>

        <div
          class="row"
          style="width: 100%"
          *ngIf="product.order_product_fields?.length > 0"
        >
          <!-- <div class="col-md-4">
                                <p class="special-request">
                                  {{ "Special Requests" | translate }} :
                                </p>
                              </div> -->

          <div class="col-md-12">
            <div
              class="total"
              *ngFor="let special of product.order_product_fields"
            >
              <div
                class="d-flex justify-content-between"
                style="color: gray"
                *ngIf="lang == 'en'"
              >
                {{ special?.field_option.value.en }}
                <span class="specisl-req-value"
                  >{{ special?.value }} {{ "EGP" | translate }}</span
                >
              </div>

              <div
                class="d-flex justify-content-between"
                style="color: gray"
                *ngIf="lang == 'ar'"
              >
                {{ special?.field_option.value.ar }}
                <span class="specisl-req-value"
                  >{{ special?.value }} {{ "EGP" | translate }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-xs-12 seperator"></div>

      <!-- <div  class="col-md-12 col-sm-12 col-xs-12 total" *ngFor="let special of NewOrderObj.products.order_product_fields">

        <div
        class="d-flex justify-content-between"
        style="color: gray"
        *ngIf="lang == 'en'">
          {{ special?.field_option.value.en }}
        <span class="specisl-req-value">{{ special?.value }} {{ "EGP" | translate }}</span>
        </div>

       <div
        class="d-flex justify-content-between"
        style="color: gray"
        *ngIf="lang == 'ar'" >
          {{ special?.field_option.value.ar }}
        <span class="specisl-req-value">{{ special?.value }}{{ "EGP" | translate }}</span>
       </div>
          

      </div> -->

      <div class="col-md-9 col-sm-9 col-xs-9">
        <h5 class="product-total">{{ "total" | translate }}</h5>
      </div>

      <div class="col-md-3 col-sm-3 col-xs-3">
        <h5 class="product-total-value">
          {{ NewOrderObj.total }}
          <!-- {{'EGP' | translate}} -->
          <span *ngIf="lang == 'ar'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.ar }}
          </span>

          <span *ngIf="lang == 'en'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.en }}
          </span>
        </h5>
      </div>

      <div class="col-md-9 col-sm-9 col-xs-9">
        <h5 class="product-total">{{ "Amount Paid By Wallet" | translate }}</h5>
      </div>

      <div class="col-md-3 col-sm-3 col-xs-3">
        <h5 class="product-total-value">
          {{ NewOrderObj.wallet_amount }}
          <!-- {{'EGP' | translate}} -->
          <span *ngIf="lang == 'ar'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.ar }}
          </span>

          <span *ngIf="lang == 'en'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.en }}
          </span>
        </h5>
      </div>

      <div class="col-md-9 col-sm-9 col-xs-9">
        <h5 class="product-total2">
          {{ "the remaining amount to be paid" | translate }}
        </h5>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-3">
        <h5 class="product-total-value">
          {{ NewOrderObj.total - NewOrderObj.wallet_amount }}
          <!-- {{'EGP' | translate}} -->
          <span *ngIf="lang == 'ar'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.ar }}
          </span>

          <span *ngIf="lang == 'en'" style="padding: 0px 5px">
            {{ NewOrderObj.currency.code.en }}
          </span>
        </h5>
      </div>

      <div class="col-md-12 col-sm-12 col-xs-12 seperator"></div>

      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center; margin-top: 15px"
      >
        <!-- <button class="payment-btn">{{'cc'|translate}}</button> -->
        <h5 class="order-payment-method">{{ NewOrderObj.payment_method }}</h5>
      </div>
      <!-- <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;margin-bottom: 15px;">
        <button class="payment-btn">{{'Cash'|translate}}</button>
      </div> -->
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12 seperator"
      *ngIf="!hideDelivery"
    ></div>

    <!-- <div class="row">
      
    </div> -->

    <!-- <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px ; text-align: center; margin-bottom: 20px; border-bottom: 1px dashed gainsboro; padding-bottom: 25px;">
          <button class="delivery-time-btn-days">{{'Fraud Order'|translate}}</button>
       </div>
    </div> -->

    <div class="row" *ngIf="!hideDelivery">
      <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px">
        <h5 class="delivery-title-type">
          {{ "Select the delivery time type" | translate }}
        </h5>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <a class="card mins-button" (click)="ChooseMinutesTime()">
          <span class="mins-icon"><i class="fas fa-hourglass-start"></i></span>
          <h6 class="mins-text">{{ "Minutes" | translate }}</h6>
        </a>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <a class="card hours-button" (click)="ChooseHoursTime()">
          <span class="hours-icon"><i class="fas fa-clock"></i></span>
          <h6 class="hours-text">{{ "Hours" | translate }}</h6>
        </a>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <a class="card days-button" (click)="ChooseDaysTime()">
          <span class="days-icon"><i class="fas fa-calendar-alt"></i></span>
          <h6 class="days-text">{{ "days" | translate }}</h6>
        </a>
      </div>
    </div>

    <div class="row delivery-container" id="minsdiv" *ngIf="!hideDelivery">
      <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px">
        <h5 class="delivery-title">
          {{ "Select the delivery time in minutes" | translate }}
        </h5>
      </div>
      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="display: flex; padding: 10px 5px"
      >
        <button class="delivery-time" (click)="TakeDeliveryValue(20)">
          20
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(25)">
          25
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(30)">
          30
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(35)">
          35
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(40)">
          40
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(45)">
          45
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(50)">
          50
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(55)">
          55
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(60)">
          60
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(65)">
          65
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(70)">
          70
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(75)">
          75
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(80)">
          80
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(85)">
          85
        </button>
        <button class="delivery-time" (click)="TakeDeliveryValue(90)">
          90
        </button>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
        <button
          class="delivery-time-btn"
          (click)="
            modal.dismiss('Cross click');
            AddToAcceptOrder(NewOrderObj.id, 'processing', Delivery_Time, 1)
          "
        >
          {{ "Deliver in" | translate }} {{ Delivery_Time }}
          {{ "min" | translate }}
        </button>
      </div>
    </div>

    <div class="row delivery-container" id="hoursdiv" *ngIf="!hideDelivery">
      <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px">
        <h5 class="delivery-title-hours">
          {{ "Select the delivery time in hours" | translate }}
        </h5>
      </div>
      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center; padding: 10px 5px"
      >
        <button class="delivery-time-hours" (click)="TakeDeliveryValue(1)">
          1
        </button>
        <button class="delivery-time-hours" (click)="TakeDeliveryValue(2)">
          2
        </button>
        <button class="delivery-time-hours" (click)="TakeDeliveryValue(3)">
          3
        </button>
        <button class="delivery-time-hours" (click)="TakeDeliveryValue(4)">
          4
        </button>
        <button class="delivery-time-hours" (click)="TakeDeliveryValue(5)">
          5
        </button>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
        <button
          class="delivery-time-btn-hours"
          (click)="
            modal.dismiss('Cross click');
            AddToAcceptOrder(NewOrderObj.id, 'processing', Delivery_Time, 2)
          "
        >
          {{ "Deliver in" | translate }} {{ Delivery_Time }}
          {{ "hours" | translate }}
        </button>
      </div>
    </div>

    <div class="row delivery-container" id="daysdiv" *ngIf="!hideDelivery">
      <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px">
        <h5 class="delivery-title-days">
          {{ "Select the delivery time in days" | translate }}
        </h5>
      </div>
      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="display: flex; padding: 10px 5px"
      >
        <button class="delivery-time-days" (click)="TakeDeliveryValue(1)">
          1
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(2)">
          2
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(3)">
          3
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(4)">
          4
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(5)">
          5
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(6)">
          6
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(7)">
          7
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(8)">
          8
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(9)">
          9
        </button>
        <button class="delivery-time-days" (click)="TakeDeliveryValue(10)">
          10
        </button>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center">
        <button
          class="delivery-time-btn-days"
          (click)="
            modal.dismiss('Cross click');
            AddToAcceptOrder(NewOrderObj.id, 'processing', Delivery_Time, 3)
          "
        >
          {{ "Deliver in" | translate }} {{ Delivery_Time }}
          {{ "days" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content4 let-modal>
  <div class="modal-header">
    <button
      class="decline-btn"
      (click)="modal.dismiss('Cross click'); OpenDeclineOrder(content3)"
    >
      <span *ngIf="lang == 'en'">
        <i class="fas fa-arrow-left" style="padding: 2px 5px 0px 5px"></i>
      </span>
      <span *ngIf="lang == 'ar'">
        <i class="fas fa-arrow-right" style="padding: 2px 5px 0px 5px"></i
      ></span>
      {{ "back" | translate }}
    </button>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h2 style="font-weight: bold; color: black">
          {{ "Sad to see you decline" | translate }}
        </h2>
        <h5>{{ "Select your reasons for decline" | translate }}</h5>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-4 col-sm-6 col-xs-12"
        *ngFor="let reason of cancellationReasons"
      >
        <a
          class="card decline-card"
          (click)="modal.dismiss('Cross click'); open5(content5, reason.id)"
        >
          <!-- <span class="cancel-icon"><i class="fas fa-times-circle"></i></span> -->
          <img class="cancel-icon" src="{{ reason?.icon_image[0]?.default }}" />

          <h5 class="cancel-reason">{{ reason.title }}</h5>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <button
      class="decline-btn"
      (click)="
        modal.dismiss('Cross click');
        OpenDeclineOrder(content4, NewOrderObj.id, 'rejection')
      "
    >
      <span *ngIf="lang == 'en'">
        <i class="fas fa-arrow-left" style="padding: 2px 5px 0px 5px"></i>
      </span>
      <span *ngIf="lang == 'ar'">
        <i class="fas fa-arrow-right" style="padding: 2px 5px 0px 5px"></i
      ></span>
      {{ "back" | translate }}
    </button>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-3 col-sm-3 col-xs-12"></div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <img class="sad-img" src="assets/images/sad-sandwich.jpg" />
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12"></div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h2 style="font-weight: bold; color: black">
          {{ "Are you sure ?" | translate }}
        </h2>
        <h5 style="font-weight: bold">
          {{
            "Getting rejected is not fun. 92% of the rejected customers do not reorder"
              | translate
          }}
        </h5>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <button
          class="decline-order-btn"
          (click)="
            modal.dismiss('Cross click');
            DeclineOrder(NewOrderObj.id, 'rejected')
          "
        >
          {{ "Decline Order" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div
  [class.sidebar-slider-status]="lang == 'en'"
  [class.sidebar-slide-in-status]="showUpdateBar"
  [class.sidebar-slider-status-ar]="lang == 'ar'"
>
  <div class="sidebar-header">
    <div
      class="sidebar-close-status"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'en'"
    >
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div
      class="sidebar-close-status"
      (click)="showUpdateBar = !showUpdateBar"
      *ngIf="lang == 'ar'"
    >
      <mat-icon>arrow_forward</mat-icon>
    </div>
    <div>
      <mat-icon>info</mat-icon>
    </div>
  </div>
  <div class="sidebar-content-status">
    <div class="info-wrapper row">
      <div class="branch-name">
        <h1>{{ "Your Restaurant Status" | translate }}</h1>
      </div>
      <div class="branch-name">
        <p>{{ "Update your availability status below." | translate }}</p>
      </div>
    </div>
    <div class="row info-wrapper">
      <mat-form-field class="list">
        <div class="merchant-name">
          <span
            class="circle"
            [class]="
              currentStatus != 'closed' &&
              currentStatus != 'busy' &&
              currentStatus != 'open'
                ? 'busy'
                : currentStatus
            "
          ></span>
          <div style="display: flex; flex-direction: column">
            <label>{{ merchantName }}</label>
            <span *ngIf="currentStatus == 'busy'">{{
              currentStatus | translate | titlecase
            }}</span>
          </div>
        </div>
        <mat-select
          [(ngModel)]="currentStatus"
          (selectionChange)="onStatusChange($event)"
        >
          <mat-option
            class="cuurent-status"
            *ngFor="let s of StatusList"
            [value]="s"
            [disabled]="
              s == status || (status == 'closed' && s.includes('busy'))
            "
            ><span
              [class]="s != 'closed' && s != 'busy' && s != 'open' ? 'busy' : s"
              class="circle"
            ></span>
            <span>{{ s | translate | titlecase }}</span></mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row info-wrapper justify-content-end">
      <button mat-button class="btn" (click)="updateBranchStatus()">
        {{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="btn-container">
  <button (click)="openTokenPopup()" class="btn token-btn btn-primary">
    generated token
  </button>
</div>
