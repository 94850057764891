import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-home-main",
  templateUrl: "./home-main.component.html",
  styleUrls: ["./home-main.component.scss"],
})
export class HomeMainComponent implements OnInit {
  lang;
  token;
  isLogged;
  type;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    $(".loader-wrapper").fadeOut("slow", function () {
      console.log("its ready");
      $(this).remove();
    });

    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);

    this.token = localStorage.getItem("optToken");
    this.type = localStorage.getItem("type");

    if (localStorage.getItem("optToken")) {
      this.isLogged = true;
    }
  }

  gotoHome() {
    // let routs:any[] = this.router.url.split('/');
    // console.log(routs);
    this.router.navigate(["home/search", this.lang]);
  }
}
