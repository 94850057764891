import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  avgPrepareTime:any;
  cancelledOrders: any;
  conversionRate: any;
  rejectionRate:any;
  ordersToday: any;
  offlineResturans: any;
  basicData: any;
  basicData1: any;
  basicOptions: any;
  total;
  totalRevenue: any = 0;
  constructor(
    private http: HttpClientService
  ) { }

  ngOnInit(): void {
    this.getDashboardData();
  }

  getDashboardData(){
    this.http.post('merchant/get-dashboard-data', {}, []).subscribe(
      res=>{

        this.avgPrepareTime = res['data'].preparation_time_avg;
        this.cancelledOrders = res['data'].today_cancelled_orders_count;
        this.conversionRate = res['data'].conversion_rate;
        this.rejectionRate = res['data'].rejection_rate;
        this.ordersToday = res['data'].today_orders_count;
        this.total = res['data'].orders.length;
        let labels:any[] = [];
        let data:any[] = [];
        let data1:any[] = [];
        res['data'].orders.forEach(element => {
          labels.push(element.date);
          data.push(element.count);
          data1.push(element.revenue);
          this.totalRevenue += element.revenue;
        });

        this.basicData = {
          labels: labels,
          datasets: [
              {
                  label: 'Orders',
                  data: data,
                  fill: false,
                  borderColor: '#42A5F5',
                  tension: .4
              }
          ]
      };

      this.basicData1 = {
        labels: labels,
        datasets: [
            {
                label: 'Revenue',
                data: data1,
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            }
        ]
    };
      }
    );
  }
  

}
