import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/observable/interval';
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
 
  // Using Output EventEmitter
  @Output() priceFilters = new EventEmitter();
  @Output() rateFilter = new EventEmitter();
	
  // define min, max and range
  public min : number = 100;
  public max : number = 1000;
  public range = [100,1000];
  
  constructor() { }
  
  ngOnInit() {  }

  // rangeChanged
  onRate(event) {
    console.log("rate filter =>", event)
}
  priceChanged(event:any) {
    setInterval(() => {
      this.priceFilters.emit(event);
    }, 1000);
  }

  
}
