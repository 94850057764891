import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpClientService } from "../../services/http-client.service";

@Component({
  selector: "app-print-popup",
  templateUrl: "./print-popup.component.html",
  styleUrls: ["./print-popup.component.scss"],
})
export class PrintPopupComponent implements OnInit {
  selectedFile;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PrintPopupComponent>,
    private http: HttpClientService
  ) {}

  ngOnInit(): void {
    this.selectedFile = this.data.pdf;
  }
  print() {
    // const fileURL = URL.createObjectURL(res);
    // window.open(this.selectedFile, "_blank");
    window.location = this.selectedFile;

    this.dialogRef.close();
    // this.http.download(this.selectedFile, {}, []).subscribe((res) => {});
  }
}
