<div class="row" >
    <div class="col-lg-6">
      <div class="header-contact">
        <ul>
          <li>{{ 'topbar-title' | translate }}</li>
          <li><i class="fa fa-phone" aria-hidden="true"></i>{{ 'call-us' | translate }}: 123 - 456 - 7890</li>
        </ul>
      </div>
    </div>
    <div class="col-lg-6 text-right" *ngIf="!productsService?.catalogMode">
      <ul class="header-dropdown">
        <!-- <li class="compare">
          <a [routerLink]="'/compare'"><i class="fa fa-random" aria-hidden="true"></i>{{ 'compare' | translate }}</a>
        </li> -->
        <!-- <li class="donate-btn" (click)="open(content)">
          <a class="donation">{{'DONATE'|translate}}</a>
        </li> -->

        <!-- <li class="mobile-compare"> 
          <a (click)="navigateToCompare()">
            <i class="fa fa-sync"></i>
          </a>
        </li> -->

        <li class="mobile-wishlist" *ngIf="check_login != null">
          <a (click)="navigateToFavorite()">
            <i class="fa fa-heart" aria-hidden="true"></i>
            <!-- <span class="txt">{{ 'wishlist' | translate }}</span> -->
          </a>
        </li>

        <li class="onhover-dropdown mobile-account">
          <i class="fa fa-user" aria-hidden="true"></i>
           <!-- {{ 'my-account' | translate }} -->
          <ul class="onhover-show-div">
            <li>
              <a (click)="navigateToLogin()" data-lng="en"  *ngIf="check_login === null">
                  {{ 'login' | translate }}
              </a>
            </li>
            <li>
              <a (click)="navigateToRegister()" data-lng="en"  *ngIf="check_login === null">
                  {{ 'register' | translate }}
              </a>
            </li>

            <li (click)="navigateToProfile()" profile *ngIf="check_login !== null">
              <a  data-lng="es">
                  {{ 'Profile' | translate }}
              </a>
            </li>
            <li *ngIf="check_login !== null">
              <a  data-lng="es" (click)=" logout()">
                  {{ 'logout' | translate }}
              </a>
            </li>
          </ul>
        </li>
        <li class="mobile-language">
          <a  (click)="changeLang('en')" *ngIf="lang == 'en'">
              <img class="topbar-flag"  src="./assets/images/ar.png"> <span class="txt">
              <!-- Arabic -->
            </span></a>
          <a (click)="changeLang('ar')" *ngIf="lang == 'ar'">
              <img class="topbar-flag"  src="./assets/images/en.webp" > <span class="txt">
              <!-- English -->
            </span></a>
        </li>
      </ul>
    </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'DOUNATION'|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group text-left custom">
        <p>{{'DONATE_AMOUNT'|translate}}</p>
        <input type="number" class="form-control" [ngClass]="lang == 'ar'? 'amount':'amount-ar'" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}">
        <input type="text" [value]="lang=='ar'?'ر.س':'S.R'" disabled class="form-control" [ngClass]="lang == 'ar'? 'currency':'currency-ar'"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveAmount()" style="border-radius: 20px;">{{'DONATE NOW'|translate}}</button>
  </div>
</ng-template>
<ngx-spinner color="#f95733"></ngx-spinner>






