import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.css']
})
export class WalletPageComponent implements OnInit {

  totalBalance: any;
  transactions: any[] = [];
  lang;
  closeResult = '';
  isCollect: boolean = false;
  isRedeem: boolean = false;
  otp:any;
  redeemOtp:any;
  amount:any;
  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.getUserWallet();
  }

  getUserWallet() {
    this.http.get('wallet/user-wallet-transactions', []).subscribe(
      res=>{
        this.totalBalance = res['data'].balance;
        this.transactions = res['data'].transactions;
      }
    );
  }

  open(content, type) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    if(type == 'collect'){
      this.isCollect = true;
      this.isRedeem = false;
    }else{
      this.isCollect = false;
      this.isRedeem = true;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  collect(){
    this.http.post('wallet/generate-reward-otp',{}, {}).subscribe(
      (res:any)=>{
        console.log(res);
        if(res.success){
          this.toaster.success(res.message);
          this.otp = res.data.otp;
        }
        
      }
    );
  }

  redeem(amount){
    console.log(amount);
    let body={
      wallet_amount: amount
    }
    this.http.post('wallet/generate-redeem-otp', body,{}).subscribe(
      (res: any)=>{
        console.log(res);
        if(res.success){
          this.toaster.success(res.message);
          this.redeemOtp = res.data.otp;
        }
      }
    );
  }

  close(){
    this.modalService.dismissAll();
    this.otp = 0;
    this.redeemOtp = 0;
    this.amount = 0;
  }

}
