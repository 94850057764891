import { Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";

import { FirebaseMessaging } from "../../../shared/services/Firebase-messageing/firebase-messaging.service";
import { OrderService } from "src/app/shared/services/order.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";
import { window } from "rxjs/operators";

@Component({
  selector: "app-home-otp",
  templateUrl: "./home-otp.component.html",
  styleUrls: ["./home-otp.component.css"],
})
export class HomeOtpComponent implements OnInit {
  lang;
  isLogged: boolean = false;
  type: string;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private firebaseMessaging: FirebaseMessaging
  ) {}

  ngOnInit(): void {
    this.router?.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.type = localStorage.getItem("type");
      }
      if (this.router.url?.includes("otplogin")) this.type = null;
    });
    this.lang = localStorage.getItem("lang_ref");
    this.type = localStorage.getItem("type");
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    let id = +localStorage.getItem("merchantId");
    // this.pushNotification();
    if (localStorage.getItem("optToken")) {
      this.isLogged = true;
    }

    this.userService.isLogged.subscribe((res) => {
      this.isLogged = res;
    });
  }

  // pushNotification() {
  //   this.FirebaseMessaging.channel.bind("order.created", (data) => {
  //     if (this.lang == "en") {
  //       this.toaster
  //         .success("New Order Recevied " + data.order_id)

  //         .onTap.subscribe(() => {
  //           let routs: any[] = this.router.url.split("/");
  //           this.router.navigate([routs[1] + "/opt-adv-repo", this.lang]);
  //         });
  //         this.playAudio();

  //     } else {
  //       this.toaster.success("تم استقبال طلب جديد " + data.order_id);
  //       this.playAudio();

  //     }

  //     console.log("1Data====", data);

  //   });
  // }

  changeLang(lang) {
    console.log(lang);
    if (lang == "en") {
      this.lang = "ar";
      $("body").addClass("rtl");
      this.translate.use("ar");
      localStorage.setItem("lang_ref", "ar");
      this.router.navigateByUrl(this.router.url.replace("/en", "/ar"));
      location.reload();
    } else {
      this.lang = "en";
      $("body").removeClass("rtl");
      $("body").addClass("ltr");
      this.translate.use("en");
      localStorage.setItem("lang_ref", "en");
      this.router.navigateByUrl(this.router.url.replace("/ar", "/en"));
      location.reload();
    }

    console.log("final lang => ", this.lang);
  }

  gotoLogin() {
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/otplogin", this.lang]);
  }

  logout() {
    this.firebaseMessaging.deleteFirebaseToken();
    localStorage.removeItem("optToken");
    localStorage.removeItem("wishlistItem");
    localStorage.removeItem("userObject");
    localStorage.removeItem("cartToken");
    localStorage.removeItem("type");
    console.log("local storage is cleared");
    let msg: string = "";
    this.translate.get("You Have Been Logout").subscribe((res) => {
      msg = res;
    });

    this.userService.isLogged.next(false);
    localStorage.setItem("isLogged", "false");
    let routs: any[] = this.router.url.split("/");
    this.router.navigate([routs[1] + "/otplogin", this.lang]);
  }

  gotoPage(link: string) {
    console.log(this.isLogged);
    let routs: any[] = this.router.url.split("/");
    if (this.isLogged) {
      console.log([routs[1] + "/" + link, this.lang]);
      this.router.navigate([routs[1] + "/" + link, this.lang]);
    } else {
      this.router.navigate([routs[1] + "/otplogin", this.lang]);
    }
  }

  RefreshPage() {
    location.reload();
    //  this.FirebaseMessaging.OrderSound();
  }
}
