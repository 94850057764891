<div class="container">
    <div class="row">
        <div class="col-sm-6 col-xs-12  product_description">
            <h2>زكاة</h2>
            <p>تبرعك بزكاتك لجمعية زمزم زكاة لمالك وتزكية لنفسك وإنقاذ لحياة آلاف المرضى الفقراء الذين هم في أمس الحاجة إلى الدعم والمساعدة ممن اجتمع عليهم فقر الصحة وفقر المال، فزكاتك سبب لإعادة الأمل لهم بالشفاء والعافية وإدخال السرور عليهم وعلى أسرهم 

            يتم صرف أمول الزكاة لمستحقيها من المرضى في تقديم الخدمات العلاجية والدوائية والأجهزة الطبية لهم.
            💰‏كيفية حساب زكاة المال

            المبلغ ➗ ٤٠ = مبلغ الزكاة</p>
        </div>

        <div class="col-xs-12 col-sm-6">
            <iframe style="width: 100%; height: 480px" src="https://www.youtube.com/embed/kk6U89AMJ-o" frameborder="0" allowfullscreen=""></iframe>
        </div>

    </div>
</div>