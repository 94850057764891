import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { Menu, MENUITEMS } from './navbar-items';

@Component({
  selector: 'app-navbar2',
  templateUrl: './navbar2.component.html',
  styleUrls: ['./navbar2.component.scss']
})
export class Navbar2Component implements OnInit {

  public menuItems: Menu[];
  public toggleNavBar : boolean = false;

  categories: any[];
  lang:string;
  

  constructor(private categoriesService: CategoriesService, private translate: TranslateService) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 

  }

   //  start categories
   getData() {
    this.categoriesService.getCategories().subscribe((result: any[]) => {
      this.categories = result['data'];
      for (let index = 0; index < result['data'].length; index++) {
        const category = result['data'][index];
        const dataToPush = {
          title: category.title.En,
          type:  "link",
         
        };
        if (dataToPush.type === "link") {
          dataToPush["path"] = `/category/${category.id}`;
        }
        this.menuItems.push(dataToPush);
      }
    });
  }

  toggleNav() {
    this.toggleNavBar = !this.toggleNavBar
  }

}
