import { Component, OnInit } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-edit-prod",
  templateUrl: "./edit-prod.component.html",
  styleUrls: ["./edit-prod.component.scss"],
})
export class EditProdComponent implements OnInit {
  merchantName;
  id;
  lang;
  merchant: any;
  // @ViewChild("file") file: HTMLElement;
  prodId;
  editCat: any = {
    title_en: "",
    title_ar: "",
    content_ar: "",
    content_en: "",
    category_id: "",
    price: 0,
    is_draft: 0,
    mediaCollection: [],
    custom_fields: [
      {
        name_ar: "",
        name_en: "",
        min_qty: "",
        max_qty: "",
        choices: [
          {
            name_ar: "",
            name_en: "",
            price: 0,
          },
        ],
      },
    ],
  };
  custom_fields: any = [];
  edit = false;
  filename: any;
  uploaded_file: boolean;
  image_url: any;
  isChanged: boolean;
  uploadForm: FormGroup;
  formData: FormData;

  validation_errors: any = [];

  constructor(
    private http: HttpClientService,
    private toaster: ToastrService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.merchantName = localStorage.getItem("merchantName");
    this.lang = localStorage.getItem("lang_ref");
    this.id = localStorage.getItem("merchantId");
    if (this.router.url.split("/")[4]) {
      if (localStorage.getItem("prod")) {
        this.edit = true;
        this.prodId = this.router.url.split("/")[4];
        this.editCat = {
          ...JSON.parse(localStorage.getItem("prod")),
          title_en: JSON.parse(localStorage.getItem("prod")).title_all?.en,
          title_ar: JSON.parse(localStorage.getItem("prod")).title_all?.ar,
          content_en: JSON.parse(localStorage.getItem("prod")).content_all?.en,
          content_ar: JSON.parse(localStorage.getItem("prod")).content_all?.ar,
        };
        this.image_url = this.editCat?.mediaCollection[0]?.default;
      }
    }
  }
  back() {
    this.router.navigate([
      "/otphome/menu-operations",
      localStorage.getItem("lang_ref"),
    ]);
  }
  deleteCat(index) {
    this.custom_fields = this.custom_fields.filter((c, i) => i != index);
  }

  deleteProd(j, index) {
    this.custom_fields[j].choices = this.custom_fields[j].choices.filter(
      (c, i) => i != index
    );
  }
  addProduct() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    console.log(this.edit);
    if (this.edit) {
      if (this.editCat.is_draft == true){
        let body = {
          ...this.editCat,
          merchant_id: this.id,
          mediaCollection: [this.image_url],
          category_id: localStorage.getItem("catId"),
          custom_fields: [...this.custom_fields],
          product_id: this.prodId,
          is_draft: 1,
        };
        this.http
        .post("merchant/product-request/create", body, {}, headers)
        .subscribe(
          (res: any) => {
            this.toaster.success(res.message);
            this.back();
          },
          (err) => {
            if(err.status == 422) {

              // console.log(err.error.errors)
               
              this.validation_errors.push(err.error.errors)
              this.validation_errors.forEach((element:any) => {

                if(element.content_ar)
                {this.toaster.error(element.content_ar)}

                else if (element.content_en)
                {this.toaster.error(element.content_en)}

                else if (element.price)
                {this.toaster.error(element.price)}

                else if (element.title_en)
                { this.toaster.error(element.title_en)}

                else if (element.title_ar)
                {this.toaster.error(element.title_ar)}

              })

            }
            else {
              this.toaster.error(err.error.message);

            }

          }
        );
      }
      else {
        let body = {
          ...this.editCat,
          merchant_id: this.id,
          mediaCollection: [this.image_url],
          category_id: localStorage.getItem("catId"),
          custom_fields: [...this.custom_fields],
          product_id: this.prodId,
           is_draft: 0,
        };
        this.http
        .post("merchant/product-request/create", body, {}, headers)
        .subscribe(
          (res: any) => {
            this.toaster.success(res.message);
            this.back();
          },
          (err) => {
            if(err.status == 422) {

              // console.log(err.error.errors)
               
              this.validation_errors.push(err.error.errors)
              this.validation_errors.forEach((element:any) => {

                if(element.content_ar)
                {this.toaster.error(element.content_ar)}

                else if (element.content_en)
                {this.toaster.error(element.content_en)}

                else if (element.price)
                {this.toaster.error(element.price)}

                else if (element.title_en)
                { this.toaster.error(element.title_en)}

                else if (element.title_ar)
                {this.toaster.error(element.title_ar)}

              })

            }
            else {
              this.toaster.error(err.error.message);

            }

          }
        );
      }
      
     
    } else {
      if(this.editCat.is_draft == true){
        let body = {
          ...this.editCat,
          merchant_id: this.id,
          mediaCollection: [this.image_url],
          category_id: localStorage.getItem("catId"),
          custom_fields: [...this.custom_fields],
          is_draft: 1,

        };

        this.http
        .post("merchant/product-request/create", body, {}, headers)
        .subscribe(
          (res: any) => {
            this.toaster.success(res.message);
            this.back();
            
          },
          (err) => {
            if(err.status == 422) {

              // console.log(err.error.errors)
               
              this.validation_errors.push(err.error.errors)
              this.validation_errors.forEach((element:any) => {

                if(element.content_ar)
                {this.toaster.error(element.content_ar)}

                else if (element.content_en)
                {this.toaster.error(element.content_en)}

                else if (element.price)
                {this.toaster.error(element.price)}

                else if (element.title_en)
                { this.toaster.error(element.title_en)}

                else if (element.title_ar)
                {this.toaster.error(element.title_ar)}

              })

            }
            else {
              this.toaster.error(err.error.message);

            }

          }
        );

      }
       else {
        let body = {
          ...this.editCat,
          merchant_id: this.id,
          mediaCollection: [this.image_url],
          category_id: localStorage.getItem("catId"),
          custom_fields: [...this.custom_fields],
          is_draft: 0,

        };

        this.http
        .post("merchant/product-request/create", body, {}, headers)
        .subscribe(
          (res: any) => {
            this.toaster.success(res.message);
            this.back();
            
          },
          (err) => {
            if(err.status == 422) {

              // console.log(err.error.errors)
               
              this.validation_errors.push(err.error.errors)
              this.validation_errors.forEach((element:any) => {

                if(element.content_ar)
                {this.toaster.error(element.content_ar)}

                else if (element.content_en)
                {this.toaster.error(element.content_en)}

                else if (element.price)
                {this.toaster.error(element.price)}

                else if (element.title_en)
                { this.toaster.error(element.title_en)}

                else if (element.title_ar)
                {this.toaster.error(element.title_ar)}

              })

            }
            else {
              this.toaster.error(err.error.message);

            }

          }
        );
       }
     
    }
  }
  handleReaderLoaded(files: FileList) {
    let file = files.item(0);
    this.image_url = files;
    this.image_url.append("default", file, file.name);
  }
  editDraft() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    let body = {
      ...this.editCat,
      merchant_id: this.id,
      mediaCollection: [this.image_url],
      category_id: localStorage.getItem("catId"),
      custom_fields: [...this.custom_fields],
      is_draft: 1,
    };
    this.http
      .post(`merchant/product-request/update/${this.prodId}`, body, {}, headers)
      .subscribe(
        (res: any) => {
          this.toaster.success(res.message);
          this.back();
        },
        (err) => {
          this.toaster.error(err.message);
        }
      );
  }

  Draft(event){
    console.log("is selected ::::::::::",event)
    //  this.editCat.is_draft = 1
  }


}
