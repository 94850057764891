import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {

  lang;
  VerifyForm: FormGroup;
  phone: any;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private http: HttpClientService,
    private route: ActivatedRoute,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.route.queryParams.subscribe(
      res=>{
        this.phone = res['phone'];
      }
    );

    this.initForm();
  }

  initForm() {
    this.VerifyForm = new FormGroup({
      'code':new FormControl('')
    });
  }

  verifyPhone(){
    console.log(this.VerifyForm.value);
    this.http.get('verify-phone/' + this.phone + '/' + this.VerifyForm.value.code , []).subscribe(
      res=>{
        console.log('Res', res);
        this.toaster.success(res['message']);
        let routs:any[] = this.router.url.split('/');
        this.router.navigate([routs[1] + '/login', this.lang]);
      }
    );
  }

}
