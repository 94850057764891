<!-- Just an image -->
<nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="assets/images/sahlalogo.png" width="30" height="30" alt="" />
  </a>
</nav>

<div class="my-5">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="linko">
                    <a (click)="gotoHome()">

                        <div class=" content">
                            <div class="screen">
                                <img src="assets/images/main-page/screenshot-9.png">
                            </div>
                        </div>

                        <div class="demo-num">
                            <i class="fa fa-home"></i>
                            <span>

                                {{'website' | translate}}
                            </span>

                        </div>
                    </a>
                </div>
            </div> -->

            <div class="col-md-3 col-sm-12 col-xs-12"></div>

            <div class="col-md-6 col-sm-12 col-xs-12" >
              <div class="linko" *ngIf="token">
                <a [routerLink]="['../otphome/requests', lang]">
                  <div class="content">
                    <div class="screen">
                      <img
                        src="assets/admin.PNG"
                        style="object-fit: fill"
                      />
                    </div>
                  </div>
      
                  <div class="demo-num">
                    <i class="fa fa-home"></i>
                    <span>
                      {{ "Admin Dashboard" | translate }}
                    </span>
                  </div>
                </a>
              </div>
      
              <div class="linko" *ngIf="!token">
                <a [routerLink]="['../otphome/otplogin', lang]">
                  <div class="content">
                    <div class="screen">
                      <img
                        src="assets/WhatsApp Image 2022-12-17 at 7.13.13 PM.jpeg"
                        style="object-fit: fill"
                      />
                    </div>
                  </div>
      
                  <div class="demo-num">
                    <i class="fa fa-home"></i>
                    <span>
                      {{ "Reports" | translate }}
                    </span>
                  </div>
                </a>
              </div>
            </div>
      
            <div class="col-md-3 col-sm-12 col-xs-12"></div>
      

      <!-- <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="linko" *ngIf="token">
          <a [routerLink]="['../otphome/general-analysis', lang]">
            <div class="content">
              <div class="screen">
                <img
                  src="assets/images/anaysis.png"
                  style="object-fit: cover"
                />
              </div>
            </div>

            <div class="demo-num">
              <i class="fa fa-home"></i>
              <span>
                {{ "General Analysis" | translate }}
              </span>
            </div>
          </a>
        </div>

        <div class="linko" *ngIf="!token">
          <a [routerLink]="['../otphome/otplogin', lang]">
            <div class="content">
              <div class="screen">
                <img
                  src="assets/images/anaysis.png"
                  style="object-fit: cover"
                />
              </div>
            </div>

            <div class="demo-num">
              <i class="fa fa-home"></i>
              <span>
                {{ "General Analysis" | translate }}
              </span>
            </div>
          </a>
        </div>

      </div> -->

    </div>
  </div>
</div>
<!-- <app-copyright></app-copyright> -->
