import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Product } from '../../../shared/classes/product';
import { ProductsService } from '../../../shared/services/products.service';
import { WishlistService } from '../../../shared/services/wishlist.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  public product        :   Observable<Product[]> = of([]);
  public wishlistItems  :   Product[] = [];
  token: any;

  constructor(
    private translate: TranslateService,
    private router: Router, 
    private wishlistService: WishlistService,
    private productsService: ProductsService, 
    private cartService: CartService
  ) {}
  lang;
  ngOnInit() { 
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang);
    this.token = localStorage.getItem('token');

    if(this.token != null){
      this.wishlistService.getProducts().subscribe((products:any) => {
        this.wishlistItems = products;
      },
      error=>{
        console.log(error);
      }
      )
    }

  }
  // Add to cart
  public addToCart(product: Product,  quantity: number = 1) {
     if (quantity > 0)
      this.cartService.addToCart(product,quantity, []);
      this.wishlistService.removeFromWishlist(product);
  }
  
  // Remove from wishlist
  public removeItem(product: Product) {
    this.wishlistService.removeFromWishlist(product);
  }

}
 