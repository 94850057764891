import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/shared/services/user.service";
import { window } from "rxjs/operators";

@Component({
  selector: "app-otp-login",
  templateUrl: "./otp-login.component.html",
  styleUrls: ["./otp-login.component.css"],
})
export class OtpLoginComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {}

  lang;
  loginForm: FormGroup;

  fieldTextType: boolean;

  password;

  show = false;

  ngOnInit() {
    this.lang = localStorage.getItem("lang_ref");
    if (this.lang != null) this.translate.use(this.lang);
    else {
      this.translate.use(this.router.url.split("/")[3]);
    }
    localStorage.removeItem("optToken");
    $(".loader-wrapper").fadeOut("slow", function () {
      console.log("its ready");
      $(this).remove();
    });
    this.initForm();
    this.password = "password";
  }

  initForm() {
    this.loginForm = new FormGroup({
      name: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  login() {
    if (localStorage.getItem("cartToken")) {
      this.loginForm.value.cart_token = localStorage.getItem("cartToken");
    } else {
      let routs: any[] = this.router.url.split("/");
      console.log("Login Data", this.loginForm.value);
      this.userService.login(this.loginForm.value).subscribe(
        (res: any) => {
          localStorage.setItem("userObject", JSON.stringify(res));
          //  this.userService.handleResponse(res);
          // console.log("The Response",res)
          localStorage.setItem("optToken", res.token);
          let name = res.name.split(" ");
          let firstName = name[0];
          let lastName = name[1];
          let email = res.email;
          let mobile = res.mobile;

          //  let userObject = {firstName: firstName, lastName: lastName, email: email, mobile: mobile};
          //  localStorage.setItem('userObject', JSON.stringify(userObject));
          this.userService.isLogged.next(true);
          //  localStorage.setItem('isLogged', 'true');
          localStorage.setItem("type", res.type);

          if (res.type == "brand_manager") {
            localStorage.setItem(
              "Brand_Merchants_array",
              JSON.stringify(res.brand.merchants)
            );
            localStorage.setItem("status", res.brand.merchants[0]?.status);

            localStorage.setItem("merchantId", res.brand.merchants[0]?.id);

            localStorage.setItem("merchantName", res.brand.merchants[0]?.name);
            console.log(
              "Brand Manager Merchants Array:----",
              res.brand.merchants
            );
            localStorage.setItem("basic_design", res.brand.basic_design);
          } else if (res.type == "cashier") {
            localStorage.setItem("status", res.merchant_branch?.status);

            localStorage.setItem("merchantId", res.merchant_branch?.id);

            localStorage.setItem("merchantName", res.merchant_branch?.name);
          } else {
            localStorage.setItem("status", res.merchant?.status);

            localStorage.setItem("merchantId", res.merchant?.id);

            localStorage.setItem("merchantName", res.merchant?.name);
          }

          if (res.type == "brand_manager") {
            this.router.navigate(["/", this.lang]);
          } else {
            this.router.navigate(["/", this.lang]);
          }
        },
        (error) => {
          if (error?.error?.errors?.email) {
            error?.error?.errors?.email.forEach((element) => {
              this.toastrService.error(element);
            });
          }
          // toasr services
        }
      );
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
}
