import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)
  }

}
