<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-6">
                <div class="page-title">
                    <h2>{{'contact'|translate}}</h2>
                </div>
            </div> -->
            <div class="col-sm-6 text-left">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a (click)="gotoHome()">{{'home'|translate}}</a></li>
                        <li class="breadcrumb-item active">{{'contact'|translate}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->
<!--section start-->
<section class=" contact-page section-b-space" [ngClass]="contactClass">
    <div class="container">
        <div class="row section-b-space"> 
            <div class="col-lg-7 map">
                <!-- <agm-map #map [mapTypeId]="'terrain'" [latitude]="location.lat" [longitude]="location.lng" style="height: 300px">
                    <agm-marker [latitude]="location.lat" [longitude]="location.lng"></agm-marker>
                </agm-map> -->
                <!-- <iframe width="100%" src="https://www.google.com/maps/embed/v1/place?q=26.389890, 50.172114&amp;key=AIzaSyBxs1ljqBRFETA7tAB9LTt1TM96ZY0qqCA"   allowfullscreen></iframe> -->
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <!-- <ul>
                        <li *ngFor="let item of newStructure">
                            <div class="contact-icon">
                               <i [ngClass]="item.icon"
                               aria-hidden="true"></i>
                                <h6 class="color--main">{{item.text}}</h6>
                            </div>
                            <div class="media-body">
                                <p class="color--main">{{item.value}}</p>
                            </div>
                        </li>
                    </ul> -->
                    <ul>
                        <li>
                            <div class="contact-icon">
                               <i class="fa fa-phone color--main" aria-hidden="true"></i>
                                <h6 class="color--main">{{'contactus'|translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p class="color--main" [innerHtml]="phone"></p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker color--main" aria-hidden="true"></i>
                                <h6 class=" color--main">{{'address'|translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p *ngIf="lang == 'en'" [innerHTML]="address_en"></p>
                                <p *ngIf="lang == 'ar'" [innerHTML]="address_ar"></p>

                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-envelope-o color--main" aria-hidden="true"></i>
                                <h6 class="color--main">{{'email'|translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p [innerHtml]="email"></p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-fax color--main" aria-hidden="true"></i>
                                <h6 class="color--main">{{'storefax'|translate}}</h6>
                            </div>
                            <div class="media-body">
                                <p [innerHtml]="fax"></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form"  [formGroup]="contactForm">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label for="name">{{'firstname'|translate}}</label>
                            <input type="text" class="form-control" formControlName="first_name" id="name" placeholder="" required="">
                            <div *ngIf="contactForm.controls.first_name.touched && contactForm.controls.first_name.errors?.required" class="text text-danger">
                                {{'firstname'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="contactForm.controls['first_name']?.touched && contactForm.controls['first_name']?.dirty && contactForm.controls['first_name']?.errors?.pattern" class="text text-danger">
                                {{'fnameMustBeCharc' | translate}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email">{{'lastname'|translate}}</label>
                            <input type="text" class="form-control" formControlName="last_name" id="last-name" placeholder="" required="">
                            <div *ngIf="contactForm.controls.last_name.touched && contactForm.controls.last_name.errors?.required" class="text text-danger">
                                {{'lastname'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="contactForm.controls.last_name.touched && contactForm.controls.last_name.errors?.pattern" class="text text-danger">
                                {{'lnameMustBeCharc' | translate}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="review">{{'phoneno'|translate}}</label>
                            <input type="text" class="form-control customFont" formControlName="phone" id="review" placeholder="" required="">
                            <div *ngIf="contactForm.controls.phone.touched && contactForm.controls.phone.errors?.required" class="text text-danger">
                                {{'phoneno'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="contactForm.controls.phone.touched && contactForm.controls.phone.errors?.pattern" class="text text-danger">
                                {{'phonePatMes' | translate}}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email">{{'email'|translate}}</label>
                            <input type="text" class="form-control" formControlName="email" id="email" placeholder="" required="">
                            <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.required" class="text text-danger">
                                {{'email'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.pattern" class="text text-danger">
                                {{ 'emailmustbe' | translate}}
                            </div>

                        </div>
                        <div class="col-md-12">
                            <label for="review">{{'message'|translate}}</label>
                            <textarea class="form-control" formControlName="message" placeholder="" id="exampleFormControlTextarea1" rows="6"></textarea>
                            <div *ngIf="contactForm.controls.message.touched && contactForm.controls.message.errors?.required" class="text text-danger">
                                {{'message'|translate}} {{'required'|translate}}
                            </div>
                            <div *ngIf="contactForm.controls.message.touched && contactForm.controls.message.errors?.pattern" class="text text-danger">
                                {{'' | translate}}
                            </div>

                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" [disabled]="!contactForm.valid" (click)="contactusmessage()">{{'messagebtn'|translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ngx-spinner color="#f95733"></ngx-spinner>
    
</section>

<!--Section ends-->