import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { AllAreasComponent } from "./pages/all-areas/all-areas.component";
import { AllProductsComponent } from "./pages/all-products/all-products.component";
import { AllReportsComponent } from "./pages/all-reports/all-reports.component";
import { BarRatingModule } from "ngx-bar-rating";
import { CarouselModule } from "ngx-owl-carousel-o";
import { CatComponent } from "./pages/cat/cat.component";
import { CategoryCardComponent } from "./components/category-card/category-card.component";
import { ChartModule } from "primeng/chart";
import { ChoiceCardComponent } from "./components/choice-card/choice-card.component";
import { CollectionSliderComponent } from "./components/collection-slider/collection-slider.component";
import { ConfirmTransactionComponent } from "./pages/confirm-transaction/confirm-transaction.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { DragDirective } from "./pages/drapDrop.directive";
import { EditOpeningTimesComponent } from "./pages/edit-opening-times/edit-opening-times.component";
import { EditProdComponent } from "./pages/edit-prod/edit-prod.component";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { FinancesComponent } from "./pages/finances/finances.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FourCategoriesComponent } from "./components/four-categories/four-categories.component";
import { HeaderComponent } from "./components/header/header.component";
import { HelpCenterComponent } from "./pages/help-center/help-center.component";
import { HomeComponent } from "./pages/home/home.component";
import { HomeMainComponent } from "./pages/home-main/home-main.component";
import { HomeOtpComponent } from "./pages/home-otp/home-otp.component";
import { HomeTalabatComponent } from "./pages/home-talabat/home-talabat.component";
import { HttpClient } from "@angular/common/http";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageUploadComponent } from "./components/image-upload/image-upload.component";
import { InStoreComponent } from "./pages/home/in-store/in-store.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LayoutModule } from "@angular/cdk/layout";
import { LinksComponent } from "./pages/links/links.component";
import { Main9Component } from "./pages/main9/main9.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MaterialModule } from "./../material/material.module";
import { MenuAvailabilityComponent } from "./pages/menu-availability/menu-availability/menu-availability.component";
import { MenuViewComponent } from "./pages/menu-view/menu-view.component";
import { MenuoperationComponent } from "./pages/menuoperation/menuoperation.component";
import { MerchantDetailsComponent } from "./pages/merchant-details/merchant-details.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgModule } from "@angular/core";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxImgZoomModule } from "ngx-img-zoom";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxParallaxScrollModule } from "ngx-parallax-scroll";
import { NgxPayPalModule } from "ngx-paypal";
import { NgxPrintModule } from "ngx-print";
import { NgxSpinnerModule } from "ngx-spinner";
import { OpeningTimeComponent } from "./pages/opening-time/opening-time.component";
import { OrderDetailsReportComponent } from "./pages/order-details-report/order-details-report.component";
import { OtpActiveMenuComponent } from "./pages/otp-active-menu/otp-active-menu.component";
import { OtpAdvancedReportsComponent } from "./pages/otp-advanced-reports/otp-advanced-reports.component";
import { OtpAreaComponent } from "./pages/otp-area/otp-area.component";
import { OtpBranchComponent } from "./pages/otp-branch/otp-branch.component";
import { OtpCashierComponent } from "./pages/otp-cashier/otp-cashier.component";
import { OtpLoginComponent } from "./pages/otp-login/otp-login.component";
import { OtpMenuComponent } from "./pages/otp-menu/otp-menu.component";
import { OtpMonthlyReportComponent } from "./pages/otp-monthly-report/otp-monthly-report.component";
import { OtpOnlineOrdersComponent } from "./pages/otp-online-orders/otp-online-orders.component";
import { OtpOrdersComponent } from "./pages/otp-orders/otp-orders.component";
import { OtpPerformanceReportComponent } from "./pages/otp-performance-report/otp-performance-report.component";
import { OtpProductsComponent } from "./pages/otp-products/otp-products.component";
import { OtpSalesReportComponent } from "./pages/otp-sales-report/otp-sales-report.component";
import { OwlModule } from "ngx-owl-carousel";
import { PrintOrdersComponent } from "./pages/print-orders/print-orders.component";
import { PrintReceiptComponent } from "./pages/print-receipt/print-receipt.component";
import { ProdCardComponent } from "./components/prod-card/prod-card.component";
import { ProductNew2Component } from "./components/product-new2/product-new2.component";
import { RangeSliderModule } from "ngx-rangeslider-component";
import { RatingModule } from "ng-starrating";
import { RevenueHourReportComponent } from "./pages/revenue-hour-report/revenue-hour-report.component";
import { RouterModule } from "@angular/router";
import { SalesByAreaComponent } from "./pages/sales-by-area/sales-by-area.component";
import { SalesReportComponent } from "./pages/sales-report/sales-report.component";
import { ScrollSpyDirective } from "./pages/spied-tags.directive";
import { SearchPageComponent } from "./pages/search-page/search-page.component";
import { SharedModule } from "../shared/shared.module";
import { SingleInstoreComponent } from "./pages/home/single-instore/single-instore.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SliderFiveComponent } from "./components/slider/slider.component";
import { SpecialProductsFiveComponent } from "./components/special-products/special-products.component";
import { ThreeTabsComponent } from "./components/three-tabs/three-tabs.component";
import { TimePipe } from "../shared/pipes/time.pipe";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { UploadComponent } from "./pages/upload/upload.component";
import { UploadExcelComponent } from "./pages/upload-excel/upload-excel.component";
import { VendorPerformanceComponent } from "./pages/vendor-performance/vendor-performance.component";
import { VerifyOtpComponent } from "./pages/verify-otp/verify-otp.component";
import { WalletPageComponent } from "./pages/home/wallet-page/wallet-page.component";
import { HelpOrderComponent } from "./pages/help-order/help-order.component";
import { CoverageAreaComponent } from "./pages/coverage-area/coverage-area.component";
import { AreaComponent } from "./pages/coverage-area/area/area.component";
import { AnalysisComponent } from "./pages/analysis/analysis.component";
import { SupportRequestsComponent } from "./pages/support-requests/support-requests.component";
import { SearchCustomerComponent } from './pages/search-customer/search-customer.component';
import { SoundPopupComponent } from './pages/otp-advanced-reports/components/sound-popup/sound-popup.component';
import { TokenPopupComponent } from './pages/otp-advanced-reports/components/token-popup/token-popup.component';
import { ProdDetailsComponent } from './components/prod-details/prod-details.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    ImageCropperModule,
    FormsModule,
    NgxPrintModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    BarRatingModule,
    ChartModule,
    RangeSliderModule,
    InfiniteScrollModule,
    NgxPayPalModule,
    NgxImgZoomModule,
    TranslateModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NgxParallaxScrollModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatExpansionModule,
    NgxChartsModule,
    RatingModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSelectModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CarouselModule,
    OwlModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    SharedModule,
    Ng2SearchPipeModule,
    NgxMaterialTimepickerModule,
  ],
  declarations: [
    CollectionSliderComponent,
    SliderFiveComponent,
    SpecialProductsFiveComponent,
    TimePipe,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ErrorPageComponent,
    ContactComponent,
    CatComponent,
    AllProductsComponent,
    FourCategoriesComponent,
    ThreeTabsComponent,
    ProductNew2Component,
    HomeMainComponent,
    Main9Component,
    VerifyOtpComponent,
    ConfirmTransactionComponent,
    HomeOtpComponent,
    OtpLoginComponent,
    OtpOrdersComponent,
    OtpAdvancedReportsComponent,
    OtpActiveMenuComponent,
    OtpProductsComponent,
    OtpBranchComponent,
    HomeTalabatComponent,
    OtpMenuComponent,
    OtpAreaComponent,
    OtpCashierComponent,
    InStoreComponent,
    WalletPageComponent,
    OtpOnlineOrdersComponent,
    MerchantDetailsComponent,
    PrintReceiptComponent,
    PrintOrdersComponent,
    AllAreasComponent,
    SearchPageComponent,
    OtpPerformanceReportComponent,
    OtpSalesReportComponent,
    SingleInstoreComponent,
    DashboardComponent,
    AllReportsComponent,
    OpeningTimeComponent,
    FinancesComponent,
    MenuoperationComponent,
    LinksComponent,
    UploadComponent,
    SalesReportComponent,
    SalesByAreaComponent,
    VendorPerformanceComponent,
    EditOpeningTimesComponent,
    HelpCenterComponent,
    UploadExcelComponent,
    OrderDetailsReportComponent,
    RevenueHourReportComponent,
    ScrollSpyDirective,
    MenuAvailabilityComponent,
    MenuViewComponent,
    OtpMonthlyReportComponent,
    ProdCardComponent,
    EditProdComponent,
    DragDirective,
    ImageUploadComponent,
    CategoryCardComponent,
    ChoiceCardComponent,
    HelpOrderComponent,
    AnalysisComponent,
    SupportRequestsComponent,
    CoverageAreaComponent,
    AreaComponent,
    SearchCustomerComponent,
    SoundPopupComponent,
    TokenPopupComponent,
    ProdDetailsComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HomeMainComponent,
    HomeTalabatComponent,
    WalletPageComponent,
  ],
  providers: [DatePipe],
})
export class HomeModule {}
