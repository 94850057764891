import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoverageAreaService } from "src/app/shared/services/coverage-area.service";

@Component({
  selector: "app-area",
  templateUrl: "./area.component.html",
  styleUrls: ["./area.component.scss"],
})
export class AreaComponent implements OnInit {
  @Input() area: any;
  ID;

  areaForm: FormGroup = new FormGroup({
    merchant_id: new FormControl(122),
    is_active: new FormControl(),
    price: new FormControl("", Validators.required),
    duration: new FormControl("", Validators.required),
    duration_type: new FormControl("", Validators.required),
  });

  constructor(
    private coverageAreaService: CoverageAreaService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.ID = localStorage.getItem("merchantId");

    console.log("inner area from area page :::::::::::::::::::", this.area)

    this.areaForm.patchValue({
      merchant_id: this.ID,
      is_active: this.area.merchant_area?.is_active == true ? 1 : 0,
      price: this.area.merchant_area?.price,
      duration: this.area.merchant_area?.duration,
      duration_type: this.area.merchant_area?.duration_type,
    });
    
  }

  updateArea(data, id) {
    let areaId = id;
    let body = {
      merchant_id: this.ID,
      is_active: data.is_active == true ? 1 : 0,
      price: data.price,
      duration: data.duration,
      duration_type: data.duration_type,
    };
    this.coverageAreaService.updateAreaMerchant(body, areaId).subscribe(
      (res: any) => {
        this.toaster.success(res["message"]);
      },
      (err: any) => [this.toaster.error(err["message"])]
    );
  }

  addArea(data, id) {
    let body = {
      merchant_id: this.ID,
      area_id: id,
      is_active: data.is_active == true ? 1 : 0,
      price: data.price,
      duration: data.duration,
      duration_type: data.duration_type,
    };
    this.coverageAreaService.createAreaMerchant(body).subscribe(
      (res: any) => {
        this.toaster.success(res["message"]);
      },
      (err: any) => [this.toaster.error(err["message"])]
    );
  }

}
