<div class="img-wrapper">
  <div class="lable-block">
    <span class="lable3" *ngIf='product.new'>{{'new'|translate}}</span>
    <span class="lable4" *ngIf='product.sale'> {{'sale'|translate}}</span>
  </div>

  <div class="card">
    <div class="product-image front">
      <a (click)="gotoDetails(product.id)"  class="d-block w-100">
        <img [src]="product?.cover_image[0]?.default? product?.cover_image[0]?.default : 'assets/images/sahlalogo.png'" class="img-fluid card-img-top"
          style="height: 200px !important;width: 100%!important;" alt="">
      </a>
      <!-- <div class="hover-col"></div> -->
    </div>
    <div class="box-wrapper">

      <div class="" [ngClass]="lang == 'ar'? 'alignment1': ''">
        <a (click)="gotoDetails(product.id)">
          <div>
            <div>
              <div class="rest-logo">
                <div class="media-card">

                  <div class="ratio-content">
                    <img [src]="product?.brand_cover_image[0]?.default? product?.brand_cover_image[0]?.default : 'assets/images/sahlalogo.png'">
                  </div>

                  <div class="main-data">
                    <div class="offer-title">
                      <h3 class="card-title">
                        <a>
                          <h6>{{product.merchant_brand_title}}</h6>
                        </a>
                      </h3>
                      <bar-rating rate={{product.avg_rank}} [readOnly]="true"></bar-rating>

                    </div>
                    <!-- <ul class="cuisines-list h-dots-list truncate">
                      <li class="list-item">
                        Desserts
                      </li>
                      <li class="list-item">
                        Fast Food
                      </li>
                      <li class="list-item">
                        Burger
                      </li>
                      <li class="list-item">
                        Sandwiches
                      </li>
                    </ul> -->
                    <!---->
                  </div>
                </div>


                <div class="content">

                  <div class="card-footer clickable-item">
                    <a href="/cairo/mcdonalds-3q54" class="clickable-anchor"></a>

                    <!-- <div class="offer-text">
                      <span>
                        30EGP on orders above 130EGP
                      </span>
                    </div> -->

                    <div class="more-details">
                      <span class="delivery-time">
                        <i class="fas fa-biking"></i>
                        {{product?.delivery_time}}
                      </span>
                    <span *ngIf="product.delivery_fees > 0" class="d-block font-weight-bold my-2">{{'deliverfees' | translate}} {{product.delivery_fees }} {{'EGP' | translate}}</span>
                      <span  class="online-ordering-flag d-block" *ngIf="product?.online_order == 1">

                      <span>
                        <i class="fas fa-circle"></i>
                        Order online
                      </span>

                    </span>

                    </div>
                    <div *ngIf="product.cachback_percent > 0">
                      {{product.cachback_percent}}% {{'Cashback' | translate}}
                    </div>
                    <!---->

                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-12 col-sm-12 col-xs-12">
              <h4>
              
              </h4>
            </div> -->
          </div>
        </a>
      </div>

      <!-- <div [ngClass]="lang == 'ar'? 'cart-wrap-ar': 'cart-wrap-en'" class="cart-info cart-wrap custom"
        style="z-index: 1000;" *ngIf="!productsService?.catalogMode">
        
        <i class="ti-shopping-cart" [attr.data-target]="'#addtocart'+product.id" data-toggle="modal"
          (click)="addToCart(product)" title="Add to cart"></i>
        
        <i class="ti-heart" aria-hidden="true" (click)="addToWishlist(product)" title="Add to Wishlist"></i>
        
        <i class="ti-reload" aria-hidden="true" (click)="addToCompare(product)" title="Compare"></i>
        

      </div> -->
    </div>
  </div>
</div>