<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="../../home">{{ "home" | translate }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "checkout" | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<section class="section-b-space">
  <div class="container">
    <div class="checkout-page">

      <!--Start Order table -->
      <div class="order-box">

        <!-- {{ "product" | translate }}
            <span> {{ "total" | translate }}</span> -->
        <div class="order-sum">
          <h2 class="mb-0">{{'Order Summary' | translate}}</h2>
          <!-- <a class="text-uppercase" href="#">
            Modify Order
          </a> -->
        </div>

        <div class="order-status">
          <p class="mb-0 font-weight-bold">{{merchantName}}</p>
          <span class="status">
            {{merchantStatus}}
          </span>
        </div>

        <div *ngIf="cartItems?.length > 0" class="order-items">
          <div *ngFor="let item of cartItems" class="prod-row">
            <div class="item-title">
              <h3>
                {{'Items' | translate}}
              </h3>
              <span *ngIf="lang == 'en'">
                {{ item?.itemable.title?.en }}
              </span>

              <span *ngIf="lang == 'ar'">
                {{ item?.itemable.title?.ar }}
              </span>

            </div>

            <div class="prod-qty">
              <h3>
                {{'Qty' | translate}}
              </h3>
              <span>
                × {{ item?.quantity }}
              </span>
            </div>

            <div class="product-price">
              <h3>
                {{'price' | translate}}
              </h3>
              <span>
                {{ (item?.itemable.price * item?.quantity) }}
              </span>
              <span *ngIf="lang == 'en'">
                {{ item?.itemable?.currency?.en }}
              </span>
              <span *ngIf="lang == 'ar'">
                {{ item?.itemable?.currency?.ar }}
              </span>
            </div>

            <div class="product-currency">
              <h3>
                {{ "subtotal" | translate }}

              </h3>
              <span>{{ subTotal | number}}</span>

            </div>

            <div class="total">
              <h3>
                {{ "total" | translate }}
              </h3>
              <span>{{ total | number }}</span>
            </div>

          </div>
        </div>

        <div *ngIf="cartItems.length == 0">
          <div class="empty-checkout">{{ "noproduct" | translate }}</div>
        </div>


      </div>

      <div class="address-section">


        <div class="add-sum">
          <h2 class="mb-0">{{'Delivery Address' | translate}}</h2>
          <div>

            <!-- <a class="text-uppercase" href="#">
              Edit
            </a>
            <a class="text-uppercase" href="#">
              Add
            </a> -->
          </div>
        </div>

        <div class="add-details">
          <div class="font-weight-bold">
            <b>
              {{'Address name' | translate}}:
            </b>
            <span>
              {{user?.default_address?.building_number}}
            </span>
          </div>

          <div>
            <b>
              {{'area' | translate}}:
            </b>
            <span *ngIf="lang == 'en'">
              {{user?.default_address?.area?.name.en}}
            </span>
            <span *ngIf="lang == 'ar'">
              {{user?.default_address?.area?.name.ar}}
            </span>
          </div>



          <div>
            <b>
              {{'phoneno' | translate}}:
            </b>
            <span>
              {{user?.mobile}}
            </span>
          </div>
        </div>
      </div>


      <!-- Collapse section -->
      <div class="my-3 add-req">
        <mat-accordion>
          <mat-expansion-panel [expanded]='true'>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mb-0">
                  {{'Add special requests here' | translate}}
                </h3>
              </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">

                  <textarea class="form-control" rows="4" cols="50" [(ngModel)]="note"
                    placeholder="eg. if you have a food allergy or a request for the driver">
                  </textarea>
                </div>
              </div>
            </div>

            <!-- <p class="gray-text">
              Do not add chareable items,as this may cause your order to be canelled
            </p> -->
          </mat-expansion-panel>

        </mat-accordion>
      </div>


      <!-- payment Section -->

      <div class="payment-section">
        <h2>{{'Payment Summary' | translate}}</h2>

        <!-- <div class="voucher-pay">
          <div class="row">
            <div class="col-md-6">
              <div class="form-inline">
                <div class="form-group ">

                  <input class="form-control" type="number" />

                </div>
                <div class="form-group">

                  <button type="button" class="mx-3 btn btn-outline-secondary text-uppercase">
                    Redeem
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div> -->

        <!-- <hr /> -->
        <div class="row">
          <div class="col-md-6">
            <p class="dark-gray">
              {{'Select your payment method' | translate}}
            </p>
            <!-- <div class="alert alert-secondary" role="alert">
              Discount available for Bank Cards <a href="#">More</a>
            </div> -->
          </div>
        </div>


        <div class="row">
          <div class="col-md-6">
            <!-- <div class="col-trash">

              <div class="custom-control custom-radio custom-control-inline">
                <input 
                  type="radio" 
                  id="customRadioInline1" 
                  name="customRadioInline1"
                  (change)="getPaymentMethod('credit')" 
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadioInline1">Debit/Credit Card</label>
              </div>
            </div> -->
            <!-- <div class="col-trash">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                <label class="custom-control-label" for="customRadioInline2">xxxx-0101 /11/654</label>
              </div>

              <button>
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <button class="btn-link text-uppercase">
              <i class="fas fa-plus"></i>
              Add new card payment
            </button> -->
            <div class="col-trash">

              <div class="custom-control custom-radio custom-control-inline">
                <input 
                  type="radio" 
                  id="customRadioInline2" 
                  name="customRadioInline1"
                  value="cash"
                  [(ngModel)]="method"
                  (change)="getPaymentMethod('cash')"  
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadioInline2">{{'Cash' | translate}}</label>
              </div>
            </div>

            <div class="col-trash">

              <div class="custom-control custom-radio custom-control-inline">
                <input 
                  type="radio" 
                  id="customRadioInline3" 
                  name="customRadioInline1"
                  value="wallet"
                  [(ngModel)]="method"
                  (change)="getPaymentMethod('wallet')"  
                  class="custom-control-input">
                <label class="custom-control-label" for="customRadioInline3">{{'Wallet' | translate}}</label>
              </div>
            </div>

            <div class="" *ngIf="showWallet">
              <div id="bank_section">
                <div class="row payment-transfer">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <label>{{ 'Wallet Amount' | translate }}</label>
                    <input type="text" class="form-control" [(ngModel)]="walletAmount">
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-trash">

              <div class="custom-control custom-radio custom-control-inline">
                <input 
                  type="radio" 
                  id="customRadioInline3" 
                  name="customRadioInline1" 
                  class="custom-control-input"
                  (change)="getPaymentMethod('bank_deposit')" >
                <label class="custom-control-label" for="customRadioInline3">Bank</label>
              </div>
            </div> -->

            <!-- <div class="" *ngIf="showBank">
              <div id="bank_section">
                <div class="row payment-transfer">
                  <div class="col-md-12 col-sm-12 col-xs-12 payment-note">
                    <p>{{'Bank_Name' | translate}} : {{bankInfo.bank_name}}</p>
                    <p>{{'Bank_Number' | translate}} : {{bankInfo.bank_account_number}}</p>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <label>{{ "WIRE_TRANSFER" | translate }} <span class="err">*</span></label>
                    <br />

                    <label for="filess" class="btn btn-outlined x"> {{"upload" | translate}}<i
                        class="fa fa-upload mr-1"></i></label>
                    <div class="col"><span>{{filename}}</span></div>

                    <input id="filess" height="1" (change)="readFile($event)" required
                      style="visibility:hidden;" type="file">
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <p class="my-5">
              By ordering through Credit Card you agree to the 
              <a class="text-uppercase" href="#">
                terms and conditions
              </a>
            </p> -->

          </div>
          <div class="col-md-6 order-col-det">
            <div class="row">
              <div class="col-md-6">
                <p class="py-1">
                  {{'subtotal' | translate}}
                </p>
              </div>
              <div class="col-md-6">
                <p class="py-1">

                  {{subTotal}} {{'EGP' | translate}}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p class="py-1">
                  {{'deliverfees' | translate}}
                </p>
              </div>
              <div class="col-md-6">
                <p class="py-1">
                  {{deliveryFees}} {{'EGP' | translate}}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <p class="py-1">

                  VAT
                </p>
              </div>
              <div class="col-md-6">
                <p class="py-1">

                  {{vat}} {{'EGP' | translate}}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <p class="py-1">
                  {{'Cashback' | translate}}
                </p>
              </div>
              <div class="col-md-6">
                <p class="py-1">
                  {{cashback}} {{'EGP' | translate}}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <p class="py-1">

                  <b>
  
                    {{'total' | translate}}
                  </b>
                </p>
              </div>
              <div class="col-md-6">
                <p class="py-1">

                <b>

                  {{total | number}} {{'EGP' | translate}}
                </b>
                </p>
              </div>
            </div>
<hr />
            <!-- <div class="row">
              <div class="col-md-6">
                <p>

                  Pay by Credit Card
                </p>
              </div>
              <div class="col-md-6">
                <p>

                  EGP 33.13
                </p>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-12">
                <button class="mt-5 btn btn-success w-100 text-uppercase" (click)="submitOrder()">
                  {{'Place Order' | translate}}
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>
      <!-- End Order Table -->
      <div class="checkout-form">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-xs-12">
            <!-- <form [formGroup]="checkoutForm">
              <div class="checkout-title">
                <h3>{{ "Billing Details" | translate }}</h3>
              </div>

              <div class="row check-out">
                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">{{ "firstname" | translate }} <span class="err">*</span></div>
                  <input type="text" name="firstname" formControlName="first_name" value="" placeholder=""
                    autocomplete="off" />
                  <div *ngIf="
                      checkoutForm?.controls?.first_name?.touched &&
                      checkoutForm?.controls?.first_name?.errors?.required
                    " class="text text-danger">
                    {{ "First Name is required" | translate }}
                  </div>
                  <div *ngIf="
                      checkoutForm?.controls?.first_name?.touched &&
                      checkoutForm?.controls?.first_name?.errors?.pattern
                    " class="text text-danger">
                    {{ "First Name must be an alphabates" | translate }}
                  </div>
                </div>
                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">{{ "lastname" | translate }} <span class="err">*</span></div>
                  <input type="text" name="lastname" formControlName="last_name" value="" placeholder=""
                    autocomplete="off" />
                  <div *ngIf="
                      checkoutForm?.controls?.last_name?.touched &&
                      checkoutForm?.controls?.last_name?.errors?.required
                    " class="text text-danger">
                    {{ "Last Name is required" | translate }}
                  </div>
                  <div *ngIf="
                      checkoutForm?.controls?.last_name?.touched &&
                      checkoutForm?.controls?.last_name?.errors?.pattern
                    " class="text text-danger">
                    {{ "Last Name must be an alphabates" | translate }}
                  </div>
                </div>
                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">{{ "phoneno" | translate }} <span class="err">*</span></div>
                  <input type="text" name="phone" formControlName="mobile" value="" placeholder="" autocomplete="off"
                    class="customFont" />
                  <div *ngIf="
                      checkoutForm?.controls?.mobile?.touched &&
                      checkoutForm?.controls?.mobile?.errors?.required
                    " class="text text-danger">
                    {{'phoneReqMes'|translate}}
                  </div>
                  <div *ngIf="
                      checkoutForm?.controls?.mobile?.touched &&
                      checkoutForm?.controls?.mobile?.errors?.pattern
                    " class="text text-danger">
                    {{'phonePatMes'|translate}}.
                  </div>
                </div>

                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">{{ "email" | translate }} <span class="err">*</span></div>
                  <input type="email" name="email" formControlName="email" value="" placeholder="" autocomplete="off" />
                  <div *ngIf="
                      checkoutForm?.controls?.email?.touched &&
                      checkoutForm?.controls?.email?.errors?.required
                    " class="text text-danger">
                    {{ "emailReqMes" | translate }}
                  </div>
                  <div *ngIf="
                      checkoutForm?.controls?.email?.touched &&
                      checkoutForm?.controls?.email?.errors?.email
                    " class="text text-danger">
                    {{ "emailmustbe" | translate }}
                  </div>
                </div>

                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">
                    {{ "shippingcity" | translate }} <span class="err">*</span>
                  </div>
                  <select name="shipping_city" (click)="getselectvalue('shipping_city', $event)"
                    formControlName="shipping_city">
                    <option *ngFor="let citylist of allCities" value="{{ citylist.id }}">
                      {{ citylist.title }}
                    </option>
                  </select>

                  <input type="number" class="formdata_hidden" name="shipping_city" placeholder="" autocomplete="off" />

                  <div *ngIf="
                      checkoutForm?.controls?.shipping_city?.touched &&
                      checkoutForm?.controls?.shipping_city?.errors?.required
                    " class="text text-danger">
                    {{ "Shipping City is required" | translate }}
                  </div>
                </div>
                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label font-tajawal">
                    {{ "shippingaddress" | translate }} <span class="err">*</span>
                  </div>
                  <input type="text" name="state" formControlName="shipping_address" value="" placeholder=""
                    autocomplete="off" />
                  <div *ngIf="
                      checkoutForm?.controls?.shipping_address?.touched &&
                      checkoutForm?.controls?.shipping_address?.errors?.required
                    " class="text text-danger">
                    {{ "Shipping Address is required" | translate }}
                  </div>
                </div>
                <div [ngClass]=" is_shippable == false ? 'form-group col-md-6 col-sm-6 col-xs-12'
                   : 'form-group col-md-6 col-sm-6 col-xs-12' " class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label font-tajawal">{{ "comment" | translate }} <span class="err">*</span></div>
                  <input type="text" name="postalcode" formControlName="comment" value="" placeholder=""
                    autocomplete="off" />
                  <div *ngIf="
                      checkoutForm?.controls?.comment?.touched &&
                      checkoutForm?.controls?.comment?.errors?.required
                    " class="text text-danger">
                    {{ "comment is required" | translate }}
                  </div>
                </div>
              </div>
            </form> -->
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <!-- <div class="checkout-details">


              <div class="payment-box">
                <div class="upper-box">
                  <div class="payment-options">
                    <ul>
                      <li>
                        <div class="radio-option">
                          <input type="radio" name="payment-group" id="payment-2" [formControl]="
                              checkoutForm.controls['payment_transaction_id']
                            " [value]="1" (change)="getPaymentMethod('online')" checked />
                          <label for="payment-2">{{ "ONLINE" | translate }}
                          </label>
                        </div>
                      </li>

                      <li>
                        <div class="radio-option">
                          <input type="radio" name="payment-group" id="payment-3" [formControl]="
                              checkoutForm.controls['payment_transaction_id']
                            " [value]="2" (change)="getPaymentMethod('bank')" />
                          <label for="payment-3">{{ "BANK_TRANSFER" | translate }}
                          </label>
                        </div>
                      </li>

                      <li>
                        <div class="radio-option">
                          <input type="radio" name="payment-group" id="payment-4" [formControl]="
                              checkoutForm.controls['payment_transaction_id']
                            " [value]="3" (change)="getPaymentMethod('cash')" />
                          <label for="payment-4">{{ "Cash" | translate }}
                          </label>
                        </div>
                      </li>

                      <div id="bank_section" class="display--none">
                        <div class="row payment-transfer">
                          <div class="col-md-12 col-sm-12 col-xs-12 payment-note">
                            <p>{{'Bank_Name' | translate}} : {{bankInfo.bank_name}}</p>
                            <p>{{'Bank_Number' | translate}} : {{bankInfo.bank_account_number}}</p>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <label>{{ "WIRE_TRANSFER" | translate }} <span class="err">*</span></label>
                            <br />

                            <label for="filess" class="btn btn-outlined x"> {{"upload" | translate}}<i
                                class="fa fa-upload mr-1"></i></label>
                            <div class="col"><span>{{filename}}</span></div>

                            <input id="filess" height="1" (change)="readFile($event)" required
                              style="visibility:hidden;" type="file">
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <label> {{ "NOTES" | translate }}</label>
                            <textarea [(ngModel)]="bankTransferNote" class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="text-right">
                  <button (click)="submitOrder()" class="btn-solid btn" [disabled]="!checkout || !checkoutForm.valid">
                    {{ "checkout" | translate }}
                  </button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>