import { Component, OnInit } from '@angular/core';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../../shared/services/products.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/shared/services/cart.service';
import { Product } from 'src/app/shared/classes/product';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { HttpClientService } from 'src/app/shared/services/http-client.service';
import { event } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';


declare var $: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  ordersList:any[] = [];
  OrderData;
  orderdetails;
  lang;
  emptyList:Boolean = false;

  currentPage: number = 0;
  lastPage: number = 0;
  totalItems: number = 0;
  fromItem: number = 0;
  finished = false  // boolean when end of data is reached
  isFromProfile: boolean = false;
  currentCriteria: any = {
    'category_id': [],
    'price-from': 0,
    'price-to': 1000
  }

  pages: number;
  closeResult = '';
  returnReasons: any[] = [];
  cancellationReasons:any[] = [];
  OrderProducts: any[] = [];
  isChecked: boolean = false;
  config: any;
  totalCount: number;
  perPage: number;
  page = 1;
  checky: any;
  returnObject: any = {
    "order_id":0,
    "return_reason_id":0,
    "products":[]
  };
  reasonId: any;
  cancelReasonId:any;
  orderClass: string = '';
  user:any;
  totalBalance:any;
  criteria: any[] = [];
  formRating:any;
  
  constructor(private checkoutService: CheckoutService,
              private translate: TranslateService,
              private productsService: ProductsService,
              private toastrService: ToastrService,
              private activeRoute: ActivatedRoute,
              private cartService: CartService,
              private router: Router,
              private modalService: NgbModal,
              private lookupsService: LookupService,
              private ordersService: OrderService,
              private route: ActivatedRoute,
              private http: HttpClientService,
              private spinner: NgxSpinnerService
              ) { }

  ngOnInit() {
    this.orderClass = this.router.url.toString().split('/')[1];
    this.activeRoute.queryParams.subscribe(res => {
      if(res.ref == 'profile') {
        this.isFromProfile = true;
      }
    })
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang);
    this.spinner.show();

    this.getRatingsCriteria();
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.getUserWallet();
    // this.lang = this.translate.currentLang;


    setTimeout(function(){
      $('.loader-wrapper').fadeOut('slow', function() {
        $(this).remove();
      });
    },1999)

    

    this.filterProducts()
    this.getAllProducts(1);
    this.getReturnReasons();

    this.config = {
      itemsPerPage: this.perPage,
      currentPage: 1,
      totalItems: this.totalCount
    };

  }




  showOrders(i,item) {
    // console.log("////////////////////////////////////")
    // console.log("item=> ",item)
    let show  = $(`#orders_list_body_${i}`).attr('show');
    if(show == 'false') {

        $(`#orders_list_body_${i}`).attr("show","true")
        $(`#orders_list_body_${i}`).removeClass('bounceOutUp');

        $(`#orders_list_body_${i}`).addClass('animated bounceInDown');
        $(`#orders_list_body_${i}`).removeClass('display--none');
        // $(`#orders_list_body_${i}`).addClass('display_block');

        return;


    }

    $(`#orders_list_body_${i}`).attr("show","false")
    $(`#orders_list_body_${i}`).removeClass('bounceInDown');
    $(`#orders_list_body_${i}`).addClass('bounceOutUp');

    setTimeout(function(){
      $(`#orders_list_body_${i}`).addClass('display--none');
    },1000)


    console.log(i);

  }

  filterProducts() {
    this.finished = false;

    this.productsService.getProductsByCriteria(this.currentCriteria).subscribe((res:any) => {
      // this.allproducts = [...res['data']];
      // this.allproducts = res.data;
      this.OrderData = res['data'];
      this.currentPage = res['current_page'];
      this.lastPage = res['last_page'];
      this.totalItems = res['total'];
      this.fromItem = res['from'];
      this.finished = true;
      // console.log ("Filter Criteria Response============>",res)

    });

  }

  setPages(currentPage, lastPage) {
    this.currentCriteria['page'] = currentPage;
    this.lastPage = lastPage;
    console.log ("Current Page", this.currentPage);
    console.log ("last Page", this.lastPage)


  }

  // Infinite scroll
  public onScroll() {

    if(this.currentPage < this.lastPage) {
      this.currentPage++;
      this.setPages(this.currentPage, this.lastPage)
      this.filterProducts()
      // console.log("on scroll fuction",this.currentPage,this.lastPage)

    }
  }

  public reOrderProduct(item: any) {
      item.products.forEach((element:any)=>{
        this.cartService.addToCart(element.product,1, []);
        this.cartService.cartUpdated.emit();
        console.log("product", element.product);
      })

    let routs:any[] = this.router.url.split('/');
    this.router.navigate([routs[1] + '/cart', this.lang]);

    console.log("The order", item.products);

  }

  getAllProducts(index: number){
    this.checkoutService.listUserOrders(1).subscribe((res:any[]) => {
      this.ordersList = res['data'];
      this.totalCount = res['total'];
      this.perPage = res['per_page'];
      this.spinner.hide();
      
      this.updateConfig();
    },error=>{
      console.log("error order=> ",error)
      this.toastrService.error(error.error.message);
    });
    if((index + 1) > this.pages){
      this.reverseArray();
    }
    
  }

  reverseArray(){
    this.ordersList = this.ordersList.reverse();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getReturnReasons(){
    this.lookupsService.getReturnReasons().subscribe(
      (res: any[])=>{
        this.returnReasons = res;
        console.log("Return Reasons", this.returnReasons);
      },
      error=>{
        console.log(error);
      }
    );
  }

  
  getOrderProducts(id: number){
    // this.ordersService.getOrderById(id).subscribe(
    //   (res:any)=>{
    //     console.log("The Order", res);
    //     this.OrderProducts = res.products;
    //   },
    //   error=>{
    //     console.log(error);
    //   }
    // );

    let order: any;
    this.ordersList.forEach(item=>{
      if(item.id == id){
        order = item;
        this.OrderProducts = order.products;
      }
    });

    console.log("The order is:", order);
    console.log("The order Products are:", this.OrderProducts);
  }

  identify(index){
    return index; 
 }
  arr:any[] = [];
  change(product){
    console.log(product);
    let index = this.arr.indexOf(product.id);
    if(index > -1){
      this.arr.splice(index,1);
    }else{
      this.arr.push({"product_id":product.id, "quantity": product.quantity});
    }
    console.log('The Array',this.arr);
    this.returnObject['products'] = this.arr;
    this.returnObject['order_id'] = product.order_id;
  }

  submitReturn() {
    console.log("Reason ID:- ", this.reasonId);
    this.returnObject['return_reason_id'] = this.reasonId;

    console.log("The Return Object:- ", this.returnObject);

    this.ordersService.SubmitReturn(this.returnObject).subscribe(
      res=>{
        console.log("The Response:- ", res);
        this.toastrService.success(res['message']);
        this.modalService.dismissAll();
        this.getAllProducts(1);
      },
      error =>{
        console.log(error);
        this.toastrService.error(error.message);
        this.modalService.dismissAll();
      }
    );
  }

  submitCancel(id){
    let body = {
      "order_id": id
    }
    console.log('Body', body);

    this.http.post('order-cancel', body, {}).subscribe(
      res=>{
        console.log(res);
        if(res['success'] == false){
          this.toastrService.error(res['message']);
        }else{
          this.toastrService.success(res['message']);
          this.getAllProducts(1);
        }
      }
    );
  }

  openReasons(){
    this.isChecked = true;
  }

  openDetails(id: number){
    this.router.navigate(['order-details'], {queryParams:{'id':id}, relativeTo: this.route});
  }

  onChangePage(event){
    this.config.currentPage = event;
    console.log("Event", event);
    this.page = event;
    this.checkoutService.listUserOrders(event).subscribe(
      (res: any)=>{
        this.ordersList = res['data'].reverse();
        this.updateConfig();
      },
      error=>{
        console.log(error);
      }
    );
  }

  updateConfig(){
    this.config = {
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount
    };
  }

  gotoHome(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/' , this.lang]);
  }

  gotoProfile(){
    let routs:any[] = this.router.url.split('/');
    console.log(routs);
    this.router.navigate([routs[1] + '/profile' , this.lang]);
  }

  getUserWallet() {
    this.http.get('wallet/user-wallet-transactions', []).subscribe(
      res=>{
        this.totalBalance = res['data'].balance;
      }
    );
  }

  rateOrder(){

  }

  getRatingsCriteria(){
    this.http.get('review-criterias', {'type':'order'}).subscribe(
      res=>{
        this.criteria = res['review_criterias'];
      }
    );
  }

}
