<!-- brand filter start -->
<div class="collection-collapse-block open">
    <h3 class="collapse-block-title">Filter</h3>
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
        <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let filter of tagsFilters">
          <input type="checkbox" class="custom-control-input" [value]="filter.filter" (change)=checkedFilter($event) id="{{filter.filter}}">
          <label class="custom-control-label" for="{{filter.filter}}">{{filter.filter}}</label>
        </div>
      </div>
    </div>
  </div>
  