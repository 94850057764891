import { Component, OnInit } from '@angular/core';
import { MENUITEMS, Menu } from './navbar-items';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from 'src/app/shared/services/categories.service';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  public menuItems: Menu[];
  public toggleNavBar : boolean = false;

  categories: any[];
  lang:string;
  

  constructor(private categoriesService: CategoriesService, private translate: TranslateService) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    this.lang = localStorage.getItem("lang_ref"); 
    this.translate.use(this.lang); 
    // this.categoriesService.getCategories().subscribe((res: any[]) => {
    //   this.categories = res['categories'];
    //   console.log(this.categories)
    //   console.log("categoriess",this.categories);
    //   console.log("Categories Length", res['categories'].length)
    // })
    // this.getData();

  }

   //  start categories
   getData() {
    this.categoriesService.getCategories().subscribe((result: any[]) => {
      this.categories = result['data'];
      for (let index = 0; index < result['data'].length; index++) {
        const category = result['data'][index];
        const dataToPush = {
          title: category.title.En,
          // type: category.sub_categories.length > 0 ? "sub" : "link",
          type:  "link",

         
        };
        if (dataToPush.type === "link") {
          dataToPush["path"] = `/category/${category.id}`;
        }
        this.menuItems.push(dataToPush);
        // console.log("menu items",this.menuItems)
      }
      // setTimeout(this.intializeSmartMenue, 1000);
    });
  }

  toggleNav() {
    this.toggleNavBar = !this.toggleNavBar
  }

  

}
