<div class="tweets-news">
    <div id="overlay" >
        <div class="container">
            <div class="row" style="padding: 60px 0px;">
                
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <h2 class="text-green">رأيك يهمنا</h2>
                    <div class="question-poll">
                        <h5 class="text-black">ما هو انطباعك حول الخدمات التي تقدمها الجمعية للمستفيدين؟</h5>
                        <div class="form-group text-black bot-buffer-30">
                            <div class="radio">
                                <label>
                                    <input type="radio" class="radio" name="rating"> راض تماما
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio" class="radio" name="rating"> راض
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio" class="radio" name="rating"> غير راض
                                </label>
                            </div>
                        </div>
                        <button class="btn btn-default btn-custom btn-vote"><i class="fa fa-check-square-o"></i> تصويت</button>
                        <button class="btn btn-default btn-custom btn-result"><i class="fa fa-list"></i> النتيجة</button>
                    </div>
                    <div class="result-poll">
                        <h5 class="text-black">ما هو انطباعك حول الخدمات التي تقدمها الجمعية للمستفيدين؟</h5>
                        <div class="form-group text-black">
                            <span>راض تماما</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100" style="width:55%">55%</div>
                            </div>
                        </div>
                        <div class="form-group text-black">
                            <span>راض</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style="width:30%">30%</div>
                            </div>
                        </div>
                        <div class="form-group text-black">
                            <span>غير راض</span>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style="width:15%">15%</div>
                            </div>
                        </div>
                        <button class="btn btn-default btn-custom btn-back-vote"><i class="fa fa-check-square-o"></i> تصويت</button>
                    </div>
                </div>
        
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <h2 class="text-green">تغريدات</h2>
                    <a *ngIf="lang=='ar'" class="twitter-timeline"  data-height="250" data-width="510"   data-chrome="nofooter noborders"    data-lang="ar"  data-dnt="true" content="on" href="https://twitter.com/TwitterDev?ref_src=twsrc%5Etfw">
                        Tweets by TwitterDev
                     </a>
                     <a *ngIf="lang=='en'" class="twitter-timeline"  data-height="250" data-width="510"  data-chrome="nofooter noborders"   data-lang="en"  data-dnt="true" content="on" href="https://twitter.com/TwitterDev?ref_src=twsrc%5Etfw">
                        Tweets by TwitterDev
                     </a>

                </div>
        
               <div class="col-md-4 col-sm-12 col-xs-12">
                    <h2 class="text-green most-viewed-res">الأكثر مشاهدة</h2>
                    <ngx-slick-carousel class="carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig" 
                    >

                            <div ngxSlickItem  class="slide">
                                <div class="news-content">
                                    <h4 class="news-text">أبناء إنسان المتفوقين لصحيفة الجزيرة : رعاية أمير منطقة الرياض وسام نضعه على صدورنا</h4>
                                    <span class="news-span">23 يناير 2018</span>
                                </div> 
                                <img class="news-img" src="assets/images/default/shop.jpg" alt="" >
                           
                            </div>

                            <div  ngxSlickItem  class="slide">
                                <div class="news-content">
                                    <h4 class="news-text">أبناء إنسان المتفوقين لصحيفة الجزيرة : رعاية أمير منطقة الرياض وسام نضعه على صدورنا</h4>
                                    <span class="news-span">23 يناير 2018</span>
                                </div>
                                <img class="news-img" src="assets/images/default/hair.png" alt="" >

                            </div>

                            <div ngxSlickItem  class="slide">
                                <div class="news-content">
                                    <h4 class="news-text">أبناء إنسان المتفوقين لصحيفة الجزيرة : رعاية أمير منطقة الرياض وسام نضعه على صدورنا</h4>
                                    <span class="news-span">23 يناير 2018</span>
                                </div>
                                <img class="news-img" src="assets/images/default/makeup.png" alt="" >

                            </div>

                            <div ngxSlickItem  class="slide">
                                <div class="news-content">
                                    <h4 class="news-text">أبناء إنسان المتفوقين لصحيفة الجزيرة : رعاية أمير منطقة الرياض وسام نضعه على صدورنا</h4>
                                    <span class="news-span">23 يناير 2018</span>
                                </div>
                                <img class="news-img" src="assets/images/default/skin.png" alt="" >

                            </div>

                            <div ngxSlickItem  class="slide">
                                <div class="news-content">
                                    <h4 class="news-text">أبناء إنسان المتفوقين لصحيفة الجزيرة : رعاية أمير منطقة الرياض وسام نضعه على صدورنا</h4>
                                    <span class="news-span">23 يناير 2018</span>
                                </div>
                                <img class="news-img" src="assets/images/default/perfumes.png" alt="" >

                            </div>
                            

                    </ngx-slick-carousel>

                </div> 
        
    
    
            </div>
            
    
        </div>
    </div>
</div>

