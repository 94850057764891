import { Component, OnInit } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-sound-popup",
  templateUrl: "./sound-popup.component.html",
  styleUrls: ["./sound-popup.component.scss"],
})
export class SoundPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SoundPopupComponent>) {}

  ngOnInit(): void {}

  closeDilog() {
    this.dialogRef.close();
  }
}
