import { HttpClientService } from "./http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FinancesService {
  constructor(private http: HttpClientService) {}
  getInvoice(body) {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    return this.http.post("financial/invoice-report", body, {}, headers);
  }
  getReports() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    return this.http.get("financial/reports", {}, headers);
  }
  generateReports(body) {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    return this.http.post("financial/generate-reports", body, {}, headers);
  }
}
