import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-otp-online-orders",
  templateUrl: "./otp-online-orders.component.html",
  styleUrls: ["./otp-online-orders.component.css"],
})
export class OtpOnlineOrdersComponent implements OnInit {
  id: number;
  name: string;
  type: string;
  merchantsList: any;
  merchant: any;
  lang;
  page: number = 1;
  perPage: any;
  totalCount: any;
  orders: any[] = [];
  isLoading: boolean = false;
  displayedColumns: string[] = [
    "id",
    "delivery_fees",
    "payment_methods",
    "total",
    "cashback",
    "billable_status",
    "delivered_at",
    "created_at",
    "canceled_at",
    "cancellation_reason",
    "rejected_at",
    "rejected_reason",
  ];
  constructor(
    private translate: TranslateService,
    private http: HttpClientService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.id = +localStorage.getItem("merchantId");
    this.name = localStorage.getItem("merchantName");
    this.getList();
  }

  getOrders(page?, body?) {
    this.isLoading = true;
    // let body = {}
    this.http.post("merchant/orders-report", body, { page: page }).subscribe(
      (res) => {
        this.orders = res["data"].data;
        this.perPage = res["data"].perPage;
        this.totalCount = res["data"].total;
        this.isLoading = false;
        console.log("The Data :------", this.orders);
        // this.orders.forEach(element=>{
        //   element.payment_method = JSON.parse(element.payment_method)
        // });
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  onChangePage(event) {
    console.log("Event", event);
    this.page = event;
    this.getOrders(event);
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          this.merchant = res.data.merchants
            .filter((r) => r.id == this.id)
            .pop();

          this.name = this.merchant.name;
          this.getOrders(1);
        }
      });
  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.name = event.value.name;
    this.getOrders(1);
  }
}
