import { AbstractControl } from "@angular/forms";


    export function nameValidator(control: AbstractControl) {
        const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        if (control.value && nameRegexp.test(control.value)) {
           return { invalidName: true };
        }

        return null;
    }
