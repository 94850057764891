<nav class="navbar navbar-expand-lg" style="height:50px ;">
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active" *ngFor="let menuItem of menuItems" (click)="toggleNav()" class="text-right">
                <div id="megamenu-header-menu1" class="dropdown" style="width: 307px;" *ngIf="menuItem.type === 'dropdown'">
                    <div id="menuHeading" 
                     class="megamenuToogle-wrapper megamenuToogle-pattern container fontdeb nav-link dropbtn"
                     *ngIf="lang == 'ar'" [ngClass]="{'rtl_dep': lang == 'ar'}">
                        {{menuItem.title | translate}}
                    </div>
                    <div id="menuHeading" 
                    class="megamenuToogle-wrapper megamenuToogle-pattern container 
                    fontdeb nav-link dropbtn"
                    *ngIf="lang == 'en'" [ngClass]="{'ltr_dep': lang == 'en'}">
                        {{menuItem.title | translate}}
                    </div>
                    <div class="dropdown-content" >
                        <a  *ngFor="let cat of categories"  >
                            <div (click)="navigateCategory(cat)" *ngIf="lang==='en'" style="font-family: Tajawal; float: left;">{{cat.name.en}}</div>
                            <div (click)="navigateCategory(cat)" *ngIf="lang==='ar'" style="font-family: Tajawal; float: right;">{{cat.name.ar}}</div>
                        </a>
                      </div>
                </div>
                
            </li>
            <li class="nav-item" *ngFor="let menuItem of menuItems" (click)="toggleNav()" class="text-right"
                [class]="menuItem.megaMenu ? 'mega' : ''" [id]="menuItem.megaMenuType == 'large' ? 'hover-cls' : ''">
                <a class="nav-link wid" [routerLink]="!menuItem.type ? null : [menuItem.path, lang]"
                    *ngIf="menuItem.type === 'link' && !( menuItem.title==='CAT') ">
                    <img class="imgeProp"
                        src="//cdn.shopify.com/s/files/1/0339/2074/5609/files/baseline_grade_white_18dp.png?v=1584195104">
                    {{menuItem.title | translate}}
                </a>
            </li>
        </ul>
    </div>

</nav>

<div class="pos-f-t">
    <nav class="navbar navbar-dark bg-dark">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav>
    <div class="collapse" id="navbarToggleExternalContent">
      <div class="bg-dark p-0">
     <ul class="navbar-nav d-flex p-0">
            <li class="nav-item" *ngFor="let menuItem of menuItems" (click)="toggleNav()" class="text-right"
                [class]="menuItem.megaMenu ? 'mega' : ''" [id]="menuItem.megaMenuType == 'large' ? 'hover-cls' : ''">
                <a class="nav-link wid" [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                    *ngIf="menuItem.type === 'link'">
                    <img class="imgeProp"
                        src="//cdn.shopify.com/s/files/1/0339/2074/5609/files/baseline_grade_white_18dp.png?v=1584195104">
                    {{menuItem.title | translate}}</a>
            </li>
        </ul>
      </div>
    </div>
  
  </div>