

<div class="programs_wrap" id="head_b">
    <div class="container">
        <div class="">
                    <div class="program_item ">
                        <div class="program_title">
                            زكاة
                        </div>
                        <div class="program_img">
                            <img src="assets/images/new/zakah.png" class="zakah-img">
                        </div>
                        <div class="program_text">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8 share-btn">
                                    <label class="margin-alignment">مشاركة</label>
                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #3b5998; color: white" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://ezmzm.org/DonorsArea/Products/ProductPreview/10245">

                                        <i class="fa fa-facebook" style="width: 15px"></i>
                                    </a>

                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #d34836; color: white" target="_blank" href="https://plus.google.com/share?url=https://ezmzm.org/DonorsArea/Products/ProductPreview/10245">

                                        <i class="fa fa-google-plus" style="width: 15px"></i>
                                    </a>

                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #40A44C; color: white" target="_blank" href="whatsapp://send?text=https://ezmzm.org/DonorsArea/Products/ProductPreview/10245">

                                        <i class="fa fa-whatsapp" style="width: 15px"></i>
                                    </a>

                                    <a class="fb-xfbml-parse-ignore btn btn-xs" style="background-color: #1DA1F2; color: white" target="_blank" href="https://twitter.com/intent/tweet?text=https://ezmzm.org/DonorsArea/Products/ProductPreview/10245">
                                        <i class="fa fa-twitter" style="width: 15px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="program_link stype" data-id="10245" data-require-ref="0">
                            <input type="hidden" class="DocRef" value="">
                                <div class="price">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-4 ar-style"><span class="">المبلغ</span></div>
                                        <div class="col-md-4 col-sm-4 col-xs-4" >
                                            <input type="text" value="0" placeholder="0" class="form-control custom-input" id="txt_f_10245">
                                            <input type="hidden" value="1" placeholder="1" class="DonQty">
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2"><button type="button" class="btn btn-primary calculate-zakah-btn" >احسب زكاتك</button></div>
                                        <div class="col-md-2 col-sm-2 col-xs-2"></div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-4  ar-style"><span class="">مبلغ الزكاة</span></div>
                                        <div class="col-md-4 col-sm-4 col-xs-4"><input type="text" value="0" placeholder="0" class="form-control DonAmount custom-input" id="txt_t_10245"></div>
                                        <div class="col-md-2 col-sm-2 col-xs-2"></div>
                                        <div class="col-md-2 col-sm-2 col-xs-2"></div>

                                    </div>
                                </div>
                                    <a  class="add_to_cart"  title="شراء"><i class="fa fa-credit-card"></i></a>
                                    <a  class="add_to_cart"  title="إضافة إلى السلة"><i class="fa fa-shopping-cart"></i></a>
                        </div>
                        <div class="clearfix"></div>
                    </div>
        </div>
    </div>
</div>