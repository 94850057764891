<div class="container-fluid px-0" id="print" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "End of day report" | translate }}</h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="seperator"></div>

        <form>
          <div class="row">
            <div class="col-md-3 res">
              <mat-form-field appearance="outline">
                <!-- <mat-label>{{'select status' | translate}}</mat-label> -->
                <mat-select
                  (selectionChange)="changeSelection($event)"
                  [(ngModel)]="selectedID"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option [value]="1">
                    {{ "Today" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "Yesterday" | translate }}
                  </mat-option>
                  <mat-option [value]="3" (click)="ShowDatePicker()">
                    {{ "Custom" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3 res">
              <div class="" *ngIf="selectedID == 3">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "Choose date" | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="data"
                    [matDatepicker]="picker"
                    [ngModelOptions]="{ standalone: true }"
                    (dateChange)="getStartDate('change', $event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >
            <div class="btns">
              <button (click)="reset()" class="btn btn-success">
                {{ "Reset" | translate }}
              </button>

              <button
                (click)="printAll()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>
        <mat-spinner *ngIf="!isLoading"></mat-spinner>

        <div class="text-center" *ngIf="reports?.length == 0 && isLoading">
          {{ "NO_ORDERS" | translate }}
        </div>
        <div *ngIf="isLoading">
          <div class="row" *ngFor="let item of brandreports?.areas">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="card" style="margin-top: 40px">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-4 third-width">
                    <h2 class="sub-total-size">
                      {{ item.name | translate }}
                    </h2>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-4 third-width"></div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h5 style="margin-block: 20px">
                      {{ "onlineOrders" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of item?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.name | translate | titlecase }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="small-size quantity-title">
                      {{ "quantity" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of item?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.count }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="quantity-title">{{ "Amount" | translate }}</h5>
                    <p
                      *ngFor="let methods of item?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.total | number }}
                    </p>
                  </div>
                </div>
                <!-- <div class="black-sepaerator"></div> -->
                <!-- <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h5 style="margin-block: 20px">
                      {{ "instore" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of item?.instore_payment_methods"
                      class="payment-method"
                    >
                      {{ methods.name | translate | titlecase }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="small-size quantity-title">
                      {{ "quantity" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of item?.instore_payment_methods"
                      class="payment-method"
                    >
                      {{ methods.count }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="quantity-title">{{ "Amount" | translate }}</h5>
                    <p
                      *ngFor="let methods of item?.instore_payment_methods"
                      class="payment-method"
                    >
                      {{ methods.total | number }}
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="card" style="margin-top: 40px">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-4 third-width">
                    <h2 class="sub-total-size">
                      {{ "Grand Total" | translate }}
                    </h2>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-4 third-width"></div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h5 style="margin-block: 20px">
                      {{ "onlineOrders" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of brandreports?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.name | translate | titlecase }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="small-size quantity-title">
                      {{ "quantity" | translate }}
                    </h5>
                    <p
                      *ngFor="let methods of brandreports?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.count }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="quantity-title">{{ "Amount" | translate }}</h5>
                    <p
                      *ngFor="let methods of brandreports?.payment_methods"
                      class="payment-method"
                    >
                      {{ methods.total | number }}
                    </p>
                  </div>
                </div>

                <div class="black-sepaerator"></div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                    <h5 style="margin-block: 20px">
                      {{ "instore" | translate }}
                    </h5>
                    <p
                      *ngFor="
                        let methods of brandreports?.istore_payment_methods
                      "
                      class="payment-method"
                    >
                      {{ methods.name | translate | titlecase }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="small-size quantity-title">
                      {{ "quantity" | translate }}
                    </h5>
                    <p
                      *ngFor="
                        let methods of brandreports?.istore_payment_methods
                      "
                      class="payment-method"
                    >
                      {{ methods.count }}
                    </p>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-3 quarter-width">
                    <h5 class="quantity-title">{{ "Amount" | translate }}</h5>
                    <p
                      *ngFor="
                        let methods of brandreports?.istore_payment_methods
                      "
                      class="payment-method"
                    >
                      {{ methods.total | number }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isLoading">
          <div
            class="w-100 pagination mt-3 text-center"
            *ngIf="reports?.length > 0"
          >
            <mat-paginator
              #TablePaginator="matPaginator"
              class="w-100"
              (page)="handlePageEvent($event)"
              [length]="length"
              [pageSize]="perPage"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
