<div class="container-fluid px-0" [class.ar]="lang == 'ar'">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>
              {{ "Order details" | translate }}
            </h2>
            <h3>{{ name | titlecase }}</h3>
          </div>
          <div *ngIf="type == 'brand_manager'">
            <mat-form-field>
              <!-- <mat-label>{{ "Branch" | translate | titlecase }}</mat-label> -->
              <mat-select
                (selectionChange)="onMerchantChange($event)"
                [value]="merchant"
              >
                <mat-option
                  class="cuurent-status"
                  *ngFor="let m of merchantsList"
                  [value]="m"
                >
                  {{ m.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="seperator"></div>

        <form>
          <div class="row">
            <div class="col-md-3 res">
              <mat-form-field
                appearance="outline"
                style="background-color: white"
              >
                <!-- <mat-label>{{'select status' | translate}}</mat-label> -->
                <mat-select
                  (selectionChange)="changeSelection($event)"
                  [(value)]="selectedID"
                >
                  <mat-option [value]="1">
                    {{ "last 7 days" | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ "last 30 days" | translate }}
                  </mat-option>

                  <mat-option [value]="3">
                    {{ "Current month" | translate }}
                  </mat-option>
                  <mat-option [value]="4">
                    {{ "Previous month" | translate }}
                  </mat-option>
                  <mat-option [value]="5">
                    {{ "Last 3 months" | translate }}
                  </mat-option>

                  <mat-option [value]="6" (click)="ShowDatePicker()">
                    {{ "Custom" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3 res">
              <div class="" *ngIf="Hide == false">
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <mat-label>{{ "Choose start date" | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="startDate"
                    [matDatepicker]="picker"
                    [ngModelOptions]="{ standalone: true }"
                    (dateChange)="getStartDate('change', $event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3 res">
              <div class="" *ngIf="Hide == false">
                <mat-form-field
                  appearance="outline"
                  style="background-color: white"
                >
                  <mat-label>{{ "Choose end date" | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="endDate"
                    [matDatepicker]="picker"
                    [ngModelOptions]="{ standalone: true }"
                    (dateChange)="getEndDate('change', $event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div [class.ar]="lang == 'ar'">
          <div class="actions">
            <mat-slide-toggle
              (change)="allBranches($event)"
              *ngIf="type == 'brand_manager'"
              >{{ "All branches" | translate }}</mat-slide-toggle
            >
            <div class="btns">
              <button (click)="reset()" class="btn btn-success">
                {{ "Reset" | translate }}
              </button>

              <button
                (click)="printAll()"
                class="btn btn-success mx-2 pull-right"
              >
                {{ "Print All" | translate }}
              </button>
            </div>
          </div>
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>

        <div class="text-desc-content-card" *ngIf="!isLoading">
          <div class="card">
            <div class="card-header">
              <h2>
                {{ "Order details" | translate }}
              </h2>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                  <!-- <h2>Download the report as a .csv to see all details of your orders.</h2> -->

                  <p class="order-count">{{ reports?.order_count }}</p>
                  <span class="total-sales">{{
                    "onlineOrders" | translate
                  }}</span>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 half-width">
                  <p class="order-count">
                    {{ reports?.instore_transaction_count }}
                  </p>
                  <span class="total-sales">{{
                    "instoreOrders" | translate
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="reports?.length == 0 && isLoading">
            <div class="card-body">
              {{ "NO_ORDERS" | translate }}
            </div>
          </div>
        </div>

        <!-- <div class="text-center" *ngIf="reports?.length == 0 && !isLoading">
                {{'NO_ORDERS' | translate}}
              </div> -->
        <!-- <mat-spinner *ngIf="!isLoading"></mat-spinner> -->
        <!-- <div style="overflow-x:auto;" id="element">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{'Name' | translate}}</th>
                      <th scope="col">{{'selling count' | translate}}</th>
                      <th scope="col">{{'Total Selling amount' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>aaaa</td>
                      <td>123</td>
                      <td>12345</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->

        <!-- <div class="text-center">
                {{'NO_ORDERS' | translate}}
            </div> -->

        <!-- <div class="row">
              <div class="w-100 pagination mt-3 text-center" *ngIf="orders?.length > 0">
                <mat-paginator 
                  class="w-100"
                  (page)="handlePageEvent($event)"
                  [length]="totalCount"
                  [pageSize]="perPage"
                  [pageSizeOptions]="perPage">
                </mat-paginator>
              </div>
            </div> -->
      </div>
    </div>
  </div>
</div>
