import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private httpClientService: HttpClientService) { }
    
  getNotification() {
    return this.httpClientService.get('notifications', []);
  }

  getAdminNotification() {
    return this.httpClientService.get('admin-notifications', []);
  }

  deleteNotification(id: number){
    return this.httpClientService.delete(`notifications/${id}`, []);

  }
  
}
