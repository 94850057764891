<div class="container-fluid px-0">
  <div class="row no-gutters navigation">
    <div class="col-1">
      <app-side-nav></app-side-nav>
    </div>

    <div class="col-11">
      <div class="mx-3 px-3 my-3">
        <div class="header">
          <div>
            <h2>{{ "Advanced Reports" | translate }}</h2>
            <h3>{{ merchantName | titlecase }}</h3>
          </div>
        </div>

        <div class="text-desc-content-card">
          <div class="card" (click)="gotoPage('sales-reports')">
            <div class="card-body">
              <h3>{{ "Sales Report" | translate }}</h3>
            </div>
          </div>

          <div class="card" (click)="gotoPage('monthly-reports')">
            <div class="card-body">
              <h3>{{ "Monthly Performance Report" | translate }}</h3>
            </div>
          </div>

          <!-- <div class="card" (click)="gotoPage('finances')">
            <div class="card-body">
              <h3>{{'Financial Reports' | translate}}</h3>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
