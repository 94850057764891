import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDialogComponent } from '../shared/header/change-dialog/change-dialog.component';
import { HomeService } from '../shared/services/home.service';

@Component({
  selector: 'app-home-nine',
  templateUrl: './home-nine.component.html',
  styleUrls: ['./home-nine.component.css']
})
export class HomeNineComponent implements OnInit {
  lang: string;

  constructor(
    public translate: TranslateService,
    private homeService: HomeService,
    public dialog: MatDialog) { }

  lat:any;
  long:any;
  ngOnInit(): void {
    this.lang = localStorage.getItem("lang_ref");
    this.translate.use(this.lang);
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.homeService.getAreaByCoords(position.coords.latitude, position.coords.longitude).subscribe(
            res=>{
              console.log(res);
              if(res['data'] == false && !localStorage.getItem('area_id')){
                this.openDialog();
              }
            },
            error=>{
              console.log(error);
            }
          );
          console.log(this.lat);
          console.log(this.lat);
        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ChangeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
