    <div class="row">

        <div class="col-md-4 col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

    </div>

    <div class="row">

        <div class="col-md-4 col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

    </div>

    <div class="row">

        <div class="col-md-4 col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

        <div class="col-md-4  col-sm-12 col-xs-12 nyp-product product type-product post-1953 status-publish instock product_cat-70 has-post-thumbnail shipping-taxable purchasable product-type-simple">
            <div class="product-item text-center">
        
            
                        <div class="product-thumb"><img width="300" height="300" src="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png" class="img-responsive thumb-size wp-post-image" alt="" title="1" srcset="https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2.png 300w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-150x150.png 150w, https://store.ensan.org.sa/wp-content/uploads/2019/12/1-2-100x100.png 100w" sizes="(max-width: 300px) 100vw, 300px">
                </div>
                    
                        <h4><a >الرعاية التعليمية</a></h4>
                    
                    <div class="" style="text-align:center !important;min-height: 71px;display: inline-flex;">
            
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المستهدف</b>
                        <br>
                        <p style="padding: 2px;">1,925,000</p>
                        </div>
                        <div class="spa">|</div>
                        <div class="ta-cs">
                        <i class="fa fa-money"></i>
                        <br>
                        <b>المتبقي</b>
                        <br>
                        <p style="padding: 2px;">1,778,766</p>
                        </div>
            
                    </div>
                        <div class="p-overlay" style="clear: both;">
                            <div class="group"><iframe name="hides-1953" style="display:none;"></iframe><form action="https://store.ensan.org.sa/wp-content/themes/denorious/cart-ajax.php" method="post" enctype="multipart/form-data" target="hides-1953"><input class="product" name="product_d" type="hidden" value="1953">
                    <input class="min_p" name="min_p" type="hidden" value="1">
                    
                    <div id="qt-1953">
                    
                    <div class="qty-inner2 " style="width: 14px;position: absolute;left: 3px;top: 2px;">
                        <div class="rectangle-19-copy-2-holder quantity-right-plus" data-type="plus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-plus"></i>
                        </div>
                        <div class="rectangle-19-copy-holder quantity-left-minus" data-type="minus" data-field="price-1953" style="font-size: 6px;line-height: 14px;">
                            <i class="fa fa-minus"></i>
                        </div>
                    </div>
                    
                        <span style="color: #1b6b5d;font-weight: bold;"> قيمة التبرع (ر.س) </span><input type="number" min="1" required="" name="price_d" id="price-1953" value="100" style="color: #1b6b5d;font-weight: bold;text-align: center;height: 33px;">
                    </div>
                    <div class="cart-btn" style="margin-top: 12px; display: flex;">
                            <a  class="add-btn">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span> أضف الى السلة</span>
                            </a>
                            <!-- <a href="#" class="shopping wishlist_icon 1953" style="display:none;margin-top: 14px;">
                                <i class="fa fa-check"></i>
                            </a> -->
                            <a class="wishlist wishlist_icon add-btn">
                                <i class="fa fa-file-text-o"> </i> <span>التفاصيل</span>
                            </a>
                    </div>
                    </form>
                        </div>		
                    </div>
                    
                </div>
        </div>

    </div>

