import { Component, OnInit } from "@angular/core";

import { HttpClientService } from "src/app/shared/services/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-menuoperation",
  templateUrl: "./menuoperation.component.html",
  styleUrls: ["./menuoperation.component.scss"],
})
export class MenuoperationComponent implements OnInit {
  merchantName;
  id;
  type;
  merchantsList;
  lang;
  merchant: any;

  Basic_Design;

  constructor(
    private http: HttpClientService,
    private translate: TranslateService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.merchantName = localStorage.getItem("merchantName");
    this.type = localStorage.getItem("type");
    this.id = localStorage.getItem("merchantId");
    this.lang = localStorage.getItem("merchantId");
    this.lang = localStorage.getItem("lang_ref");
    this.getList();

  }
  onMerchantChange(event) {
    this.id = event.value.id;
    this.merchantName = event.value.name;
    localStorage.setItem("merchantId", this.id);
  }
  getList() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("optToken")}`,
    });
    this.http
      .get("merchant/get-merchant-status", {}, headers)
      .subscribe((res: any) => {
        if (res["success"] == true) {
          this.merchantsList = res.data.merchants;
          if (this.id) {
            this.merchantName = res.data.merchants.find(
              (r) => r.id == this.id
            ).name;
            this.merchant = res.data.merchants.find((r) => r.id == this.id);
          } else {
            this.id = res.data.merchants[0].id;
            this.merchantName = res.data.merchants[0].name;
            this.merchant = res.data.merchants[0];
          }
          this.toaster.success(res["message"]);
        }
      });
  }
}
