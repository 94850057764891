<div class="container-fluid px-0">

    <div class="row no-gutters navigation">
        <div class="col-1">
          <app-side-nav></app-side-nav>
        </div>

        <div class="col-11">
            <div class="mx-3 px-3 my-3">
                <h2>
                    {{'Print Receipt' | translate}} 
                </h2>

                <div class="row">
                    <div class="col-md-12 m-auto" id="receipt">
                        <div id="invoice-POS">
                            <div id="top" class="text-center">
                                <div class="info">
                                    <h2>Sahla LLC</h2>
                                    <br>
                                    <p>www.sahlaegypt.com</p>
                                </div>
                            </div>
                            <div id="mid">
                                <div class="info">
                                    <h2>{{'Transaction Info' | translate}}</h2>
                                    <p *ngIf="transaction">
                                        {{'code' | translate}} : 542147<br>
                                        <span *ngIf="transaction?.created_at">
                                            {{'date' | translate}} : {{transaction?.created_at | date:'short'}}
                                        </span><br>
                                        <span *ngIf="transaction?.date">
                                            {{'date' | translate}} : {{transaction?.date | date:'short'}}
                                        </span><br>
                                        {{'Amount' | translate}} : {{transaction?.total_amount}} {{'EGP' | translate}}<br>
                                        {{'Wallet Amount' | translate}} : {{transaction?.wallet_amount}} {{'EGP' | translate}}<br>
                                        <span *ngIf="credittransaction?.wallet_amount">{{'Cashback' | translate}} : {{credittransaction?.wallet_amount}} {{'EGP' | translate}}</span><br>
                                        <!-- <span *ngIf="credittransaction?.wallet_amount">{{'Cashback' | translate}} : {{credittransaction?.wallet_amount}} {{'EGP' | translate}}</span><br> -->

                                        {{'type' | translate}} : {{transaction?.source | titlecase}}<br>
                                    </p>

                                    <div *ngIf="transactions.length > 0">
                                        <p *ngFor="let transaction of transactions">
                                            {{'code' | translate}} : 542147<br>
                                            <span *ngIf="transaction?.created_at">
                                                {{'date' | translate}} : {{transaction?.created_at | date:'short'}}
                                            </span><br>
                                            <span *ngIf="transaction?.date">
                                                {{'date' | translate}} : {{transaction?.date | date:'short'}}
                                            </span>
                                            <br>
                                            {{'Amount' | translate}} : {{transaction?.total_amount}} {{'EGP' | translate}}<br>
                                             {{'type' | translate}} : {{transaction?.type}}<br>

                                        </p>
                                    </div>
                                    <br>
                                    <p>
                                        <span *ngIf="transaction?.merchant?.name">{{'Merchant' | translate}} : {{transaction?.merchant?.name}}</span><br>
                                        <span *ngIf="transaction?.user?.name">{{'Merchant' | translate}} : {{transaction?.user?.name}}</span><br>
                                        {{'Consumer' | translate}} : {{transaction?.user?.first_name}} {{transaction?.user?.last_name}}<br>
                                        {{'Consumer' | translate}} Ref ID : #{{transaction?.user?.id}}<br>
                                        {{'Consumer' | translate}} Mobile : {{transaction?.user?.mobile}}<br>

                                        
                                    </p>
                                </div>
                            </div>
                            <div id="bot">
                                <div id="legalcopy">
                                    <p class="legal"><strong>{{'Thank you for your Using Sahla !' | translate}}</strong>&nbsp; {{'receipt info' | translate}}.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 m-auto">
                        <button 
                            class="print-btn"
                            [useExistingCss]="true"
                            printSectionId="receipt" 
                            printTitle="Receipt" 
                            ngxPrint>{{'print' | translate}}</button>

                            <!-- <button (click)="print()">{{'print' | translate}}</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>