import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-category-card",
  templateUrl: "./category-card.component.html",
  styleUrls: ["./category-card.component.scss"],
})
export class CategoryCardComponent implements OnInit {
  @Input() item: any = {};
  @Input() isSubcategory = false;
  @Output() delete = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.item = { choices: [] };
  }
}
