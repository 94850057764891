import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.css']
})
export class PrintReceiptComponent implements OnInit {

  code: any;
  lang;
  transaction: any;
  credittransaction: any;

  transactions: any[] = [];
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang_ref');
    this.translate.use(this.lang);
    this.code = localStorage.getItem('otpCode');

    this.route.queryParams.subscribe(
      res=>{
        if(res['ref']){
          this.transactions = JSON.parse(localStorage.getItem('allTransactions'));
          this.credittransaction = JSON.parse(localStorage.getItem('credit-transaction'));
          console.log("reciet data credit:------",JSON.parse(localStorage.getItem('credit-transaction')))
          console.log("reciet data transaction:------", JSON.parse(localStorage.getItem('allTransactions')))

        }
        else{
          this.transaction = JSON.parse(localStorage.getItem('transaction'));
          this.credittransaction = JSON.parse(localStorage.getItem('credit-transaction'));

          console.log("reciet data credit:------",JSON.parse(localStorage.getItem('credit-transaction')))
          console.log("reciet data transaction:------", JSON.parse(localStorage.getItem('allTransactions')))

        }
        
      }
    );
    
  }

  print(){
    const printContent = document.getElementById("receipt");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
  
}
